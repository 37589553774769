import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { imageURL } from "../../../Constants/commonURLS";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import Pagination from "@mui/material/Pagination";
import { styleObj, paginateStyles } from "../../../assets/MUI/useStyles";
import { DataGridStyles } from "../../../assets/MUI/useStyles";
import { globalTheme } from "../../../assets/MUI/globalStyles";
import { ThemeProvider } from "@mui/material/styles";
import CustomNoRowsFound from "../../../Nodatafound/customNoRowsFound";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import Tooltip from "@mui/material/Tooltip";
import GlobalSearch from "../../../hooks/GlobalSearch";
import { formatdate } from "../../../helpers/common/FormateDate";
import { useNavigate } from "react-router-dom";



// import "./table.css";

function MyAppliedJobs({ referData, page, totalCount, setPage, loading, pageSize }) {
  const classes = DataGridStyles();
  const navigate = useNavigate();

  const rowsData = referData?.map((item, index) => {
    const createdAt = item?.createdAt;

    const deadlineTime = new Date(item?.application_deadline)

    const date = new Date(createdAt);
    const options = {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
      timeZone: "Asia/Kolkata",
    };

    const formattedTime = date.toLocaleString("en-US", options);
    const formattedDeadlineTime = deadlineTime.toLocaleString("en-US", options);


    return {
      id: `${item?.id}`,
      userId: `${item?.user_id}`,
      jobPostId: `${item?.job_post_id}`,
      Image: `${imageURL}${item.company_details?.company?.company_image}`,
      companyName: `${item?.company_details?.company?.company_name ?? "-"}`,
      jobTitle: `${item?.job_title ?? "-"}`,
      ctc: `${item?.company_details?.max_sal_by_am
        ? `${item.company_details.max_sal_by_am}/${item?.company_details?.payout?.replace(/_/g, ' ')}`
        : "NA"
        }`,
      status: `${item?.job_post_pipeline_stage?.stage_name}`,
      resume: `${item?.resumes?.[0] ?? "N/A"}`,
      interviews: "",
      feedback: "",
      payout: `${item?.company_details?.payout ? item?.company_details?.payout?.replace(/_/g, ' ') : ""}`,
      country: `${item?.company_details?.country ?? ""}`,
      state: `${item?.company_details?.state ?? ""}`,
      city: `${item?.company_details?.city ?? ""}`,
      deadlineDate: formatdate(`${item?.application_deadline}`) + `${" "}` + `${formattedDeadlineTime}`,
      appliedDate: formatdate(`${item?.createdAt}`) + `${" "}` + `${formattedTime}`,
      jobStatus: `${item?.is_active && new Date(item?.application_deadline) > new Date()
        ? "Active"
        : !item?.is_active && new Date(item?.application_deadline) <= new Date()
          ? "Expired"
          : item?.is_active && new Date(item?.application_deadline) <= new Date()
            ? "Closed"
            : ""
        }`
    };
  });

  const columns = [
    {
      field: "Image",
      headerName: "Image",
      headerClassName: classes.headerCellStyles,
      minWidth: 100,
      flex: 1,
      renderCell: params => (
        <img
          src={params.value}
          alt={params.value}
          style={{ width: 30, borderRadius: "50%" }}
        />
      ),
    },

    {
      field: "companyName",
      headerClassName: classes.headerCellStyles,
      style: { color: "red" },
      headerName: "Company Name",
      minWidth: 150,
      flex: 1,
      renderCell: ({ value }) => (
        <span style={styleObj}>{value}</span>
      ),
    },
    {
      field: "jobTitle",
      headerClassName: classes.headerCellStyles,
      headerName: "Job Title",
      minWidth: 200,
      flex: 1,
      renderCell: ({ value }) => {
        return (
          <span style={styleObj}>{value}</span>
        );
      },
    },
    {
      field: "ctc",
      headerClassName: classes.headerCellStyles,
      headerName: "CTC",
      minWidth: 200,
      flex: 1,
      renderCell: ({ value }) => <span style={styleObj}>{value}</span>,
    },
    {
      field: "status",
      headerClassName: classes.headerCellStyles,
      headerName: "Application Status",
      minWidth: 200,
      flex: 1,
      renderCell: ({ value }) => (
        <div
          style={{
            display: "inline-block",
            padding: "5px 10px",
            borderRadius: "8px",
            backgroundColor:
              value === "Rejected"
                ? "#ffe9e9"
                : value === "Applied"
                  ? "#cde2e7"
                  : value === "On boarded"
                    ? "#d7f6dd"
                    : value === "Technical Evalutation(External)"
                      ? "#ffc6f6"
                      : "rgba(8, 23, 83, 0.2)",
            color:
              value === "Rejected"
                ? "#a10e25"
                : value === "Applied"
                  ? "#066a4c"
                  : value === "On boarded"
                    ? "#066a4c"
                    : value === "Technical Evalutation(External)"
                      ? "#066a4c"
                      : "#066a4c",
            fontWeight: 500,
            fontSize: "12px",
            fontFamily: "Inter,sans-serif",
            textTransform: "capitalize",
          }}
        >
          {value}
        </div>
      ),
    },

    {
      field: "jobStatus",
      headerClassName: classes.headerCellStyles,
      headerName: "Job Status",
      minWidth: 100,
      flex: 1,
      renderCell: ({ value }) => {
        return (
          <span style={styleObj}>{value}</span>
        )
      }
    },

    {
      field: "appliedDate",
      headerClassName: classes.headerCellStyles,
      headerName: "Applied Date",
      minWidth: 150,
      flex: 1,
      renderCell: ({ value }) => (
        <span style={styleObj}>{value}</span>
      ),
    },
    {
      field: "deadlineDate",
      headerClassName: classes.headerCellStyles,
      headerName: "Application Deadline",
      minWidth: 200,
      flex: 1,
      renderCell: ({ value }) => (
        <span style={styleObj}>{value}</span>
      ),
    },


    {
      field: "resume",
      headerClassName: classes.headerCellStyles,
      headerName: "Resume",
      minWidth: 100,
      flex: 1,
      renderCell: ({ value }) => (
        <a href={`${imageURL}${value}`} target="_blank" onClick={(e) => e.stopPropagation()}>
          <Tooltip title="Preview">
            <VisibilityOutlinedIcon size="small" sx={{ color: "rgb(6, 106, 76)" }} />
          </Tooltip>
        </a>
      ),
    },
    {
      field: "country",
      headerClassName: classes.headerCellStyles,
      headerName: "Country",
      minWidth: 150,
      flex: 1,
      renderCell: ({ value }) => (
        <span style={styleObj}>{value}</span>
      ),
    },

    {
      field: "state",
      headerClassName: classes.headerCellStyles,
      headerName: "State",
      minWidth: 150,
      flex: 1,
      renderCell: ({ value }) => (
        <span style={styleObj}>{value}</span>
      ),
    },

    {
      field: "city",
      headerClassName: classes.headerCellStyles,
      headerName: "City",
      minWidth: 150,
      flex: 1,
      renderCell: ({ value }) => (
        <span style={styleObj}>{value}</span>
      ),
    },

    {
      field: "interviews",
      headerClassName: classes.headerCellStyles,
      headerName: "Interviews",
      minWidth: 100,
      flex: 1,
      renderCell: ({ value }) => <span style={styleObj}>N/A</span>,
    },
    {
      field: "feedback",
      headerClassName: classes.headerCellStyles,
      headerName: "Feedback",
      minWidth: 100,
      flex: 1,
      renderCell: ({ value }) => <span style={styleObj}>N/A</span>,
    },

  ];


  return (
    <ThemeProvider theme={globalTheme}>
      <div>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          style={{ paddingBottom: "20px" }}
          width="80%"
        >
          <GlobalSearch />
        </Box>
        <div style={{ height: "65vh", width: "98%" }} className="ShifytScroll">
          <DataGrid
            loading={loading}
            rows={rowsData}
            columns={columns}
            disableColumnSelector
            disableDensitySelector
            disableRowSelectionOnClick
            onRowClick={(params, event, details) => {
              navigate(`../job?id=${params?.row?.jobPostId}&userId=${params?.row?.userId}`);
            }}
            slots={{

              pagination: props => (
                <Pagination
                  {...props}
                  // color="primary"
                  // count={Math.ceil(rowsData.length / rowsPerPage)}
                  count={Number(totalCount)}
                  page={Number(page)}
                  rowsPerPage={Number(pageSize)}
                  onChange={(_, newPage) => setPage(newPage)}
                  showFirstButton
                  showLastButton
                  sx={{
                    "& .MuiPaginationItem-root": {
                      "&:hover": {
                        backgroundColor: "#cde2e7",
                        color: "#066a4c",
                      },
                      "&.Mui-selected": {
                        backgroundColor: "#024430",
                        color: "#ffe7bb",
                        "&:hover": {
                          backgroundColor: "#cde2e7",
                          color: "#066a4c",
                        },
                      },
                    },
                  }}
                />
              ),
            }}

          />
        </div>
      </div>
    </ThemeProvider>
  );
}

export default MyAppliedJobs;
