import React from 'react';
import { Grid, Stack, Typography, Box, Button, Chip } from "@mui/material";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
function TeamSection() {

    return (
        <Grid container sx={{
            paddingTop: "40px", paddingBottom: "100px",
            "@media (max-width: 600px)": {
                paddingTop: "20px",
                paddingBottom: "50px",
            },
        }}>
            <Grid item xs={12} sm={12}
                sx={{
                    paddingTop: "20px",
                }}
            >
                <Box>
                    <Stack
                        direction="column"
                        sx={{
                            gap: "10px"
                        }}
                    >
                        <Typography
                            sx={{
                                color: "var(--text-color-one)",
                            }}
                        >Team</Typography>
                        <Typography
                            sx={{
                                fontSize: "40px",
                                fontWeight: "500",
                                width: "70%",
                                "@media (max-width: 1600px)": {
                                    fontSize: "30px",
                                },
                                "@media (max-width: 1200px)": {
                                    fontSize: "25px",
                                },
                                "@media (max-width: 900px)": {
                                    fontSize: "20px",
                                    width: "100%",
                                },
                                "@media (max-width: 600px)": {
                                    fontSize: "18px",
                                    width: "100%",
                                },
                            }}
                        >Meet the joinee team</Typography>
                        <Typography
                            sx={{
                                color: "var(--text-color-one)",
                                width: "50%",
                                "@media (max-width: 900px)": {
                                    width: "100%",
                                },
                            }}
                        >Optimized checkout suite delivers a frictionless customer experience. Increase revenue and save thousands of engineering hours with prebuilt payment</Typography>
                    </Stack>
                    <Box
                        sx={{
                            display: "grid",
                            gridTemplateColumns: "repeat(2, 1fr)",
                            "@media (max-width: 900px)": {
                                gridTemplateColumns: "repeat(2, 1fr)",
                            },
                            "@media (max-width: 600px)": {
                                gridTemplateColumns: "repeat(1, 1fr)",
                            },
                            gap: "20px",
                            marginTop: "20px",
                        }}
                    >
                        {[
                            { id: "1", image: "https://cdn-icons-png.flaticon.com/512/9308/9308310.png", title: "Dileep Kumar", designation: "CTO & Co-Founder", tag: "@Dileepkumar", text: "Vestibulum eget aliquet penatibus interdum pellentesque. Diam ultrices in risus ac nunc imperdiet ultricies. Viverra est nunc condimentum", },
                            { id: "2", image: "https://cdn-icons-png.flaticon.com/512/9308/9308310.png", title: "Kristin Watson", designation: "CTO & Co-Founder", tag: "@kristionwatson", text: "Vestibulum eget aliquet penatibus interdum pellentesque. Diam ultrices in risus ac nunc imperdiet ultricies. Viverra est nunc condimentum", },
                            { id: "3", image: "https://cdn-icons-png.flaticon.com/512/9308/9308310.png", title: "Annette Black", designation: "CTO & Co-Founder", tag: "@annetteblack", text: "Vestibulum eget aliquet penatibus interdum pellentesque. Diam ultrices in risus ac nunc imperdiet ultricies. Viverra est nunc condimentum", },
                            { id: "4", image: "https://cdn-icons-png.flaticon.com/512/9308/9308310.png", title: "Ralph Edwards", designation: "CTO & Co-Founder", tag: "@ralpedward001", text: "Vestibulum eget aliquet penatibus interdum pellentesque. Diam ultrices in risus ac nunc imperdiet ultricies. Viverra est nunc condimentum", },
                        ]?.map((item, index) => (
                            <Stack
                                sx={{
                                    border: "1px solid #E5E5E5",
                                    background: "#FAFAFA",
                                    minHeight: "150px",
                                    gap: "10px",
                                    padding: "25px"
                                }}
                                
                                direction="column"

                            >
                                <Stack
                                    sx={{
                                        height: "50px",
                                        width: "50px",
                                        borderRadius: "50%",
                                        background: "var(--app-color-one)",

                                    }}
                                    justifyContent="center"
                                    alignItems="center"
                                >
                                    <img src={item?.image} alt="" style={{ width: "100%" }} />
                                </Stack>
                                <Stack
                                sx={{
                                    marginTop: "15px",
                                    gap: "5px"
                                }}
                                >
                                    <Typography
                                        sx={{
                                            fontSize: "25px",
                                            fontWeight: "500",
                                            "@media (max-width: 900px)": {
                                                fontSize: "18px",
                                            },
                                            "@media (max-width: 600px)": {
                                                fontSize: "15px",
                                            },
                                        }}
                                    >{item?.title}</Typography>
                                    <Typography
                                        sx={{
                                            fontSize: "15px",
                                            fontWeight: "400",
                                            color: "var(--text-color-one)",
                                            "@media (max-width: 900px)": {
                                                fontSize: "13px",
                                            },
                                            "@media (max-width: 600px)": {
                                                fontSize: "11px",
                                            },
                                        }}
                                    >{item?.designation}</Typography>
                                </Stack>
                                <Stack
                                sx={{
                                    marginTop: "15px",
                                    gap: "15px"
                                }}
                                >
                                    <Typography
                                        sx={{
                                            color: "var(--text-color-one)",
                                            fontSize: "15px",
                                            "@media (max-width: 600px)": {
                                                fontSize: "12px",
                                            },
                                        }}
                                    >{item?.text}</Typography>
                                    <Chip
                                        label={item?.tag}
                                        onDelete={() => {}}
                                        deleteIcon={<ArrowForwardIcon />}
                                        sx={{
                                            background: "#FFFFFF",
                                            width: "fit-content",
                                            padding: "12px, 12px, 12px, 20px",
                                            boxShadow: "0px 4px 11px -1px #0A0A0A0A",
                                            '& .MuiChip-label': {
                                                fontWeight: "600",
                                            },
                                        }}
                                    />
                                </Stack>

                            </Stack>
                        ))}
                    </Box>
                </Box>
            </Grid>
        </Grid>
    )
}

export default TeamSection;