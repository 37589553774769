


import Box from "@mui/material/Box";
import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import Typography from "@mui/material/Typography";
import React from "react";
import { MuiTelInput } from "mui-tel-input";
import { globalTheme } from "../../../assets/MUI/globalStyles";
import { ThemeProvider } from "@mui/material/styles";


export default function MuiCustomTelInput({
  required,
  label,
  sx,
  error,
  helperText,
  useFormRef,
  InputProps,
  ...rest
}) {
  return (
    <ThemeProvider theme={globalTheme}>
    <Box>
      {/**
       *
       *
       * input field label if any
       *
       */}
      {label ? <InputLabel required={required}>{label}</InputLabel> : null}

      {/**
       *
       *
       * input field
       *
       */}
      <MuiTelInput
        fullWidth
        {...rest}
        {...useFormRef}
        onWheel={(e) => e.target.blur()}
        sx={[
          { backgroundColor: "var(--paper-color)", },
          sx,
        ]}
        InputProps={{
          ...InputProps,
          style: {
            height: "40px",
            borderRadius: "6px",
            border: error ? "1px solid var(--error-color)" : "none",
            padding: "0px",
            "&:focus": {
              border: "none",
            },
            "&::active": {
              border: "none",
            },
            ...(InputProps && InputProps.style),
          },
        }}
      />

      {/**
       *
       *
       * error message if any
       *
       */}
      {error ? (
        <Typography
          variant="body2"
          sx={{
            fontSize: "12px",
            fontWeight: 400,
            color: "var(--error-color)",
          }}
        >
          {helperText}
        </Typography>
      ) : null}
    </Box>
   </ThemeProvider>
  );
}





// const customStyle = {
//     inputStyle: {
//       width: "100%",
//       padding: "23px",
//       fontSize: "16px",
//       border: "1px solid #ccc",
//       borderRadius: "5px",
//       paddingLeft: "50px"
//     },
//     containerStyle: {
//     },
//     buttonStyle: {
//       border: "none",
//       background: "none",
//       marginTop: "13px"
//     },
//     dropdownStyle: {
//       position: "fixed",
//       zIndex: "999",
//       boxShadow: "rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px",
//       marginTop: "15px",
//       border: "1px solid #ccc",
//     },
//     flagStyle: {
//       position: "relative",
//     },
//     countryNameStyle: {
//       fontSize: "14px",
//       color: "red",
//     },
//   };


{/* <PhoneInput
                    flagStyle={customStyle.flagStyle}
                    listItemClassName={customStyle.countryNameStyle}
                    inputStyle={{ ...customStyle.inputStyle, borderColor: errors.phone_number && "red" }}
                    containerStyle={customStyle.containerStyle}
                    buttonStyle={customStyle.buttonStyle}
                    dropdownStyle={customStyle.dropdownStyle}
                    value={getValues("phone_number")}
                    onChange={e => {
                      setValue("phone_number", e);
                    }}
                    // {...register("phone_number", { required: true })}
                    country={"in"}
                    countryCodeEditable={true}
                    error={errors.phone_number ? true : false}
                    isValid={(value, country) => {
                      if (value.match(/12345/)) {
                        return "Invalid value: " + value + ", " + country.name;
                      } else if (value.match(/1234/)) {
                        return false;
                      } else {
                        return true;
                      }
                    }}
                  />

                  {errors.phone_number && (
                    <p
                      style={{ color: "red", fontSize: "12px" }}
                    >
                      phone number must be valid
                    </p>
                  )} */}