import React, { useState } from 'react';
import { Grid, Stack, Typography, Box, Button, Chip, InputAdornment, IconButton, ToggleButtonGroup, ToggleButton } from "@mui/material";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import BlogCatImg1 from "../../../assets/icons/Blogs/BlogCatImg1.svg";
import ArrowRight from '../../../assets/icons/LandingPage/ArrowRight';

function BlogRelatedArticleSection() {

    /**
    *
    * redux dispatch
    *
    */
    const dispatch = useDispatch();

    /**
     *
     * navigate
     *
     */
    const navigate = useNavigate();

    const dummyBlogsData = [
        {
            id: "1",
            categories: "Engineering",
            date: "October 23, 2023",
            image: BlogCatImg1,
            title: "Accenture on the future of payments and financial automation",
            desc: "Vel egestas ultrices condimentum consectetur enim non elit aenean lacus. Justo rutrum libero urna id quis ipsum. Dictumst vel posuere nec lobortis. Porttitor pretium aenean non pellentesque nunc ultrices commodo sagittis sed. Vulputate duis quis lorem lectus. Et ut viverra eros vulputate.",
        },
        {
            id: "2",
            categories: "Engineering",
            date: "October 23, 2023",
            image: BlogCatImg1,
            title: "Accenture on the future of payments and financial automation",
            desc: "Vel egestas ultrices condimentum consectetur enim non elit aenean lacus. Justo rutrum libero urna id quis ipsum. Dictumst vel posuere nec lobortis. Porttitor pretium aenean non pellentesque nunc ultrices commodo sagittis sed. Vulputate duis quis lorem lectus. Et ut viverra eros vulputate.",
        },
    ];

    return (
        <Grid container sx={{
            paddingTop: "50px",
            paddingBottom: "45px",
            width: "85%",
            margin: "auto",
            "@media (max-width: 600px)": {
                paddingTop: "30px",
                paddingBottom: "30px",
                width: "100%",
            },
        }}>
            <Typography
                sx={{
                    fontSize: "30px",
                    fontWeight: "600",
                    width: "70%",
                    "@media (max-width: 1600px)": {
                        fontSize: "25px",
                    },
                    "@media (max-width: 1200px)": {
                        fontSize: "20px",
                    },
                    "@media (max-width: 900px)": {
                        fontSize: "18px",
                        width: "100%",
                    },
                    "@media (max-width: 600px)": {
                        fontSize: "15px",
                        width: "100%",
                    },
                }}
            >Related Article</Typography>
            <Grid item xs={12} sm={12}
                sx={{
                    display: "grid",
                    gridTemplateColumns: "repeat(2, 1fr)",
                    gap: "20px",
                    marginTop: "20px",
                    "@media (max-width: 900px)": {
                        gridTemplateColumns: "repeat(1, 1fr)",
                    },
                }}
            >
                {dummyBlogsData?.map((item, index) => (
                    <Grid
                        container
                        key={index}
                        sx={{
                            border: "1px solid var(--border-color-2)",
                            padding: "10px",
                            boxShadow: "0px 4px 11px -1px #0A0A0A0A",
                            background: "#fff",
                            marginBottom: "25px"
                        }}
                    >
                        <Grid item xs={12} sm={12}>
                            <Stack
                                justifyContent="center"
                                alignItems="center"
                                sx={{
                                    height: "100%"
                                }}
                            >
                                <Box
                                    sx={{
                                        width: "100%",
                                        height: "fit-content",
                                        "@media (max-width: 600px)": {
                                            marginBottom: "20px"
                                        },
                                    }}
                                >
                                    <img src={item?.image} alt="blogs" style={{ width: "100%", height: "auto" }} />
                                </Box>
                            </Stack>
                        </Grid>
                        <Grid item xs={12} sm={12}
                            sx={{
                                minHeight: "100%",
                            }}
                        >
                            <Stack sx={{
                                width: "100%", margin: "auto", padding: "20px",
                                "@media (max-width: 600px)": {
                                    padding: "0px",
                                },
                            }} spacing={3}>
                                <Stack
                                    direction="row"
                                    alignItems="center"
                                    justifyContent="flex-start"
                                    spacing={1}
                                >
                                    <Chip label="Engineering"
                                        sx={{
                                            background: "#ECFAE2",
                                            '& .MuiChip-label': {
                                                fontWeight: "700",
                                            },
                                        }}
                                    />
                                    <Chip label="October 23, 2023" size="medium"
                                        sx={{
                                            backgroundColor: "transparent"
                                        }}
                                    />
                                </Stack>
                                <Stack
                                    direction="column"
                                    sx={{
                                        width: "90%",
                                        "@media (max-width: 900px)": {
                                            width: "100%",
                                        },
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            fontSize: "30px",
                                            fontWeight: "600",
                                            "@media (max-width: 1200px)": {
                                                fontSize: "25px",
                                            },
                                            "@media (max-width: 900px)": {
                                                fontSize: "18px",
                                            },
                                        }}
                                    >{item?.title}</Typography>
                                    <Typography
                                        sx={{
                                            fontSize: "16px",
                                            fontWeight: "500",
                                            color: "#737373",
                                            "@media (max-width: 1200px)": {
                                                fontSize: "14px",
                                            },
                                            "@media (max-width: 900px)": {
                                                fontSize: "13px",
                                            },
                                        }}
                                    >
                                        {item?.desc}
                                    </Typography>
                                    <Button
                                        type="submit"
                                        size="large"
                                        onClick={() => { navigate(`/blog/${item?.id}`) }}
                                        loading={false}
                                        loadingPosition="end"
                                        variant="contained"
                                        sx={{
                                            borderRadius: "50px",
                                            boxShadow: "none",
                                            width: "fit-content",
                                            background: "#fff",
                                            color: "#000",
                                            padding: "5px 25px",
                                            border: "1px solid var(--border-color-2)",
                                            "&:hover": {
                                                boxShadow: "none",
                                                background: "var(--app-button-color1)",
                                                color: "#fff",
                                            },
                                            marginTop: "30px",
                                        }}
                                        endIcon={<IconButton
                                            edge="end"
                                            size="small"
                                        >
                                            <ArrowRight />
                                        </IconButton>}
                                    >
                                        <span style={{ textTransform: "capitalize", fontWeight: "600" }}>Read More</span>
                                    </Button>
                                </Stack>
                            </Stack>
                        </Grid>
                    </Grid>
                ))}
            </Grid>
        </Grid>
    )
}

export default BlogRelatedArticleSection