import React, { useEffect, useState } from "react";
import "../../assets/JobList/MainSerachFilter.css";
import Input from "@mui/joy/Input";
import { Search } from "@mui/icons-material";
import { Form } from "reactstrap";
import { useSearchParams } from "react-router-dom";
import { Col } from "reactstrap";
import { Button, CssVarsProvider } from "@mui/joy";
import EmployerList from '../../pages/Employers/List/EmployersList';
// import noJobs from "../../assets/images/no_jobs.svg";
import noJobs from "../../assets/images/nojobsShiyft.png";
import { useForm } from "react-hook-form";
import { PlacesAutocomplete } from "../../data/locationSearch";
import { globalTheme } from '../../assets/MUI/globalStyles';
import { ThemeProvider } from "@mui/material/styles";
import { OutlinedInput } from "@mui/material";

function CompanyMainSearch({ name, setName, location, companies }) {
  let [searchParams, setSearchParams] = useSearchParams();

  const [searchLocation, setSearchLocation] = useState("");

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  // const handleSearchData = () => {
  //   setSearchParams({ name, location });
  // };

  const handleSearchData = () => {
    if (name) {
      setSearchParams({ name, location });
    } else {
      setSearchParams({});
    }
  };

  useEffect(() => {
    if (!searchParams.toString()) {
      setValue("company_name", "");
      setSearchLocation("");
    }
  }, [searchParams, setValue]);

  const onSubmit = async data => {
    // await setSearchParams({
    //   name: data.company_name,
    //   location: searchLocation,
    // });
    if (data) {
      setSearchParams({ name: data.company_name, location: searchLocation });
    } else {
      setSearchParams({});

    }
  };

  return (
    <>
      <section className="section-box-2">
        <div className="none-bg">
          <div className="comp_filter_search_cont">
            <div className="box-shadow-bdrd-15 box-filters">
              <form
                className="row"
                onSubmit={e => {
                  e.preventDefault();
                  handleSearchData();
                }}
              >
                <div className="col-lg-10 col-sm-12 col-md-10 ">
                  <ThemeProvider theme={globalTheme}>
                    <OutlinedInput
                      startDecorator={
                        <Search
                          style={{ color: "#066a4c" }}
                        />
                      }
                      className="input-search-job mb-1"
                      value={name}
                      placeholder="Job title, Keyword, or Company name..."
                      onChange={e => setName(e.target.value)}
                      sx={{ width: "100%" }}
                    />
                  </ThemeProvider>
                </div>

                <div className="col-lg-2 col-sm-12 col-md-2">
                  <CssVarsProvider>
                  <Button
                    size="sm"
                    type="submit"
                    variant="solid"
                    style={{ height: "50px", width: "100%", background: "#cde2e7", color: "#066a4c", fontFamily: "Inter,sans-serif" }}
                  >
                    Search
                  </Button>
                  </CssVarsProvider>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>

      {/* <section className="section-box-2">
        <div className="none-bg">
          <div className="comp_filter_search_cont">
            <div className="box-shadow-bdrd-15 box-filters">
              <Form className="row" onSubmit={handleSubmit(onSubmit)}>
                <div className="col-lg-5 col-sm-12 col-md-5 ">
                  <Input
                    startDecorator={
                      <Search
                        // color={`${errors.job_name ? "danger" : "#081753 !important"}`}
                        style={{color:`${errors.company_name ? "danger" : "#081753"}`}}
                      />
                    }
                    type="text"
                    className="input-search-job mb-1"
                    error={errors.company_name ? true : false}
                    {...register("company_name", { required: false })}
                    placeholder="Company name..."
                  />
                </div>
                <div className="col-lg-5 col-sm-12 col-md-5">
                  <div className="box-search-job search-job-cont">
                    <div className="form-search-job search-job-cont">
                      <PlacesAutocomplete
                        className="input-search-location"
                        location={searchLocation}
                        setLocation={e => {
                          setSearchLocation(e);
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className=" col-lg-2 col-sm-12 col-md-2 find_jobs_btn">
                  <CssVarsProvider>
                    <Button
                      type="submit"
                      style={{ height: "50px",color:"#ffffff",background:"#081753",fontFamily:"Inter,sans-serif" }}
                      // className="btn btn-default float-right job_find_btn"
                    >
                      Search
                    </Button>
                  </CssVarsProvider>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </section> */}

    </>
  );
}
export default CompanyMainSearch;
