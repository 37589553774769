import React, { useState } from 'react';
import { Link, useNavigate } from "react-router-dom";

import { Grid, Stack, Typography, Box, Divider, Button } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import { NewsletterInput } from '../../../../assets/MUI/materialUiStyles';
import NewslatterLeft from "../../../../assets/icons/LandingPage/NewslatterLeft.png";
import NewslatterRight from "../../../../assets/icons/LandingPage/NewslatterRight.png";

function NewsLatterSection() {


    /**
     * 
     * Navigation context
     * */
    const navigate = useNavigate();


    return (
        <Grid container mt={3} mb={3}>
            <Grid xs={12} sm={12} md={3}>
                <Stack
                    sx={{
                        width: "100%",
                        height: "100%",
                        "@media (max-width: 900px)": {
                            display: "none"
                        },
                    }}
                >
                    <img src={NewslatterLeft}
                        style={{
                            height: "auto",
                            width: "auto"
                        }}
                    />

                </Stack>
            </Grid>
            <Grid xs={12} sm={12} md={6} pb={2} >
                <Stack
                    sx={{
                        width: "70%",
                        margin: "auto",
                        marginTop: "20px",
                        marginBottom: "20px",
                        "@media (max-width: 600px)": {
                            width: "95%",
                        },

                    }}
                >
                    <Box mb={2}>
                        <Typography
                            sx={{
                                fontSize: "40px",
                                fontWeight: "600",
                                textAlign: "center"
                            }}
                        >Please subscribe to our newsletter</Typography>
                    </Box>
                    <Typography
                        sx={{
                            fontSize: "16px",
                            fontWeight: "400",
                            textAlign: "center",
                            color: "#5A7184"
                        }}
                    >Try joinee free for 14 days. No credit card required.</Typography>
                </Stack>
                <form
                    onSubmit={e => {
                        e.preventDefault();
                        navigate(`/`);
                    }}
                >
                    <Stack
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        spacing={2}
                        sx={{
                            margin: "auto",
                            border: "1px solid #E5EAF4",
                            borderRadius: "50px",
                            width: "80%",
                            boxShadow: "rgba(0, 0, 0, 0.04) 0px 3px 5px",
                            "@media (max-width: 600px)": {
                                width: "95%",
                            },

                            padding: "5px 10px",

                        }}
                    >
                        <Box
                            sx={{
                                width: "70%",
                            }}
                        >
                            <NewsletterInput
                                placeholder="yourname@mail.com"
                                sx={{
                                    width: "100%",
                                    border: "none",
                                }}
                            />

                        </Box>
                        <Box
                            sx={{
                                minWidth: "100px",
                                minWidth: "30%",
                            }}
                        >
                            <Button
                                type="submit"
                                size="large"
                                onClick={() => { console.log("Fired...") }}
                                loading={false}
                                loadingPosition="end"
                                variant="contained"
                                sx={{
                                    borderRadius: "50px",
                                    boxShadow: "none",
                                    width: "100%",
                                    background: "var(--app-button-color1)",
                                    padding: "8px 30px",
                                    "&:hover": {
                                        boxShadow: "none",
                                        background: "var(--app-button-color1)",
                                    }
                                }}
                            >
                                <span style={{ textTransform: "capitalize", fontWeight: "700" }}>Subscribe</span>
                            </Button>
                        </Box>
                    </Stack>
                </form>


            </Grid>
            <Grid xs={12} sm={12} md={3}>
                <Stack
                    sx={{
                        width: "100%",
                        height: "100%",
                        "@media (max-width: 900px)": {
                            display: "none"
                        },
                    }}
                >
                    <img src={NewslatterRight}
                        style={{
                            height: "auto",
                            width: "auto"
                        }}
                    />

                </Stack>

            </Grid>
        </Grid>
    );
}

export default NewsLatterSection;
