import React, { useEffect } from "react";
import { Navigate } from "react-router-dom";
import { addUserAction } from "../store/auth/actions";
import { useDispatch } from "react-redux";
import { socket } from "../Sockets/Socket";

const Logout = () => {
  const dispatch = useDispatch();
  const userId = (localStorage.getItem("user_id"))
  useEffect(() => {
    if(userId) {
      socket.emit("logout", {
        userId: userId,
      });
    }
    localStorage.clear();
    dispatch(addUserAction({}));
  }, [dispatch]);

  return <Navigate to="/login" replace={true} />;
};

export default Logout;