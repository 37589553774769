import React from 'react';
import { Grid } from "@mui/material";
import ApplyJobSection from './Components/ApplyJobSection';
import CompanyHiredSection from './Components/CompanyHiredSection';
import OpenedJobSection from './Components/OpenedJobSection';
import NewFooter from '../../../layouts/footer/NewFooter';
import Questions from '../CommonPage/Questions';
import OurStorySection from '../CommonPage/Components/OurStorySection';
import ReferTestimonialSection from './Components/ReferTestimonialSection';
import ApplyForJobSection from './Components/ApplyForJobSection';
import ReferAndEarnSection from './Components/ReferAndEarnSection';

function ReferAndEarnPage() {
    return (
        <React.Fragment>
            <Grid container
                sx={{
                    background: "linear-gradient(120deg,rgba(250, 204, 21, 0.08), #ffffff 75%, rgba(219, 248, 231, 0.48))",
                    minHeight: "100vh",
                    margin: "auto",
                }}
            >
                {/* Apply job Section */}
                <Grid item xs={12} sm={12}
                    sx={{
                        paddingLeft: "5%",
                        paddingRight: "5%"
                    }}
                >
                    <ApplyJobSection />
                </Grid>

                {/* Company Hired section */}
                <Grid item xs={12} sm={12}
                    sx={{
                        paddingLeft: "5%",
                        paddingRight: "5%"
                    }}
                >
                    <CompanyHiredSection />
                </Grid>

                {/* Opened job section */}
                <Grid item xs={12} sm={12}
                    sx={{
                        paddingLeft: "5%",
                        paddingRight: "5%"
                    }}
                >
                    <OpenedJobSection />
                </Grid>

                {/* Refer and earn section */}
                <Grid item xs={12} sm={12}
                    sx={{
                        paddingLeft: "5%",
                        paddingRight: "5%"
                    }}
                >
                    <ReferAndEarnSection />
                </Grid>

                {/* Apply for job section */}
                <Grid item xs={12} sm={12}>
                    <ApplyForJobSection />
                </Grid>

                {/* People earning section */}
                <Grid item xs={12} sm={12}
                    sx={{
                        paddingLeft: "5%",
                        paddingRight: "5%"
                    }}
                >
                    <ReferTestimonialSection />
                </Grid>

                {/* Our story section */}
                <Grid item xs={12} sm={12}
                    sx={{
                        paddingLeft: "5%",
                        paddingRight: "5%"
                    }}
                >
                    <OurStorySection />
                </Grid>

                {/* FAQ section */}
                <Grid item xs={12} sm={12}
                    sx={{
                        paddingLeft: "5%",
                        paddingRight: "5%"
                    }}
                >
                    <Questions />
                </Grid>
            </Grid>
            <NewFooter />
        </React.Fragment>

    )
}

export default ReferAndEarnPage