import React from 'react'
import not_found from '../assets/images/not_found.svg';
export default function NotFound() {
    return (
        <div className='text-center'>
            <img
                style={{ width: "30%" }}
                alt="not-found"
                src={not_found} />

            <div className='text-center h6 mt-4'>Page Not Found</div>
        </div>
    )
}

