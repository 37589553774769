
import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import "../header/notification.css";
import "./index.css";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { IconButton } from "@mui/material";
import {
    MenuOutlined,
} from "@mui/icons-material";
import Drawer from "@mui/material/Drawer";
import SingupNavs from "./SignUpNavs";
import JoineeLogo from "../../assets/images/joinee_logo2.png";
import NotificationDrawer from "../../pages/Notifications/NotificationDrawer";
import LoginNavs from "./loginNavs";
import CountryDropdowns from "./components/CountryDropdowns";

import { Grid, Box, Stack } from "@mui/material";
import UserDropdowns from "./components/UserDropdowns";
import GlobalSidebar from "./GlobalSidebar";



function GlobalNavbar() {

    const [drawer, setDrawer] = useState(false);

    const navigate = useNavigate();
    const [state, setState] = useState(false);
    const [scrollPosition, setScrollPosition] = useState(0);

    const toggleDrawer = open => event => {
        if (
            event.type === "keydown" &&
            (event.key === "Tab" || event.key === "Shift")
        ) {
            return;
        }

        setState(open);
    };

    const user = useSelector(state => state.User.user);
    const [scrollNav, setScrollNav] = useState(false);
    const [changeValue, setChangeValue] = useState(0);
    const changeNav = () => {
        setChangeValue(window.scrollY);
    };
    useEffect(() => {
        if (changeValue) {
            setScrollNav(true);
        } else {
            setScrollNav(false);
        }
    }, [changeValue]);

    /* scroll indicator function */
    const onScroll = e => {
        const scrollHeight =
            document.documentElement.scrollHeight -
            document.documentElement.clientHeight;
        const scrollTop = window.pageYOffset;
        const scrollFraction = scrollTop / scrollHeight;
        const scrollPercentage = scrollFraction * 100;
        setScrollPosition(scrollPercentage);
    };

    /* scroll indicator  function ends */

    useEffect(() => {
        window.addEventListener("scroll", changeNav);
        window.addEventListener("scroll", onScroll);
        return () => {
            window.removeEventListener("scroll", changeNav);
            window.removeEventListener("scroll", onScroll);
        };
    }, []);


    const location = useLocation().pathname;



    return (
        <Grid container
            sx={{
                // background: ({ scrollnav }) => (scrollnav ? "white" : location === "/" ? "#cde2e7" : 'white'),
                // boxShadow: ({ changevalue }) => changevalue >= 60 ? "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;" : "none",

                background: "rgba(255, 255, 255, 0.14)",
                boxShadow: "rgba(0, 0, 0, 0.05) 0px 1px 2px 0px",
                backdropFilter: "blur(5px)",
                webkitBackdropFilter: "blur(5px)",
                // border: "1px solid rgba(255, 255, 255, 0.3)",
                height: "70px",
                display: "flex",
                margin: "auto",
                marginTop: "0px",
                paddingTop: "0px",
                justifyContent: "left",
                alignItems: "left",
                fontSize: "20px",
                fontWeight: "bold",
                position: "sticky",
                top: "0",
                zIndex: "1000",
            }}
        >
            <Grid item xs={4} sm={2.2}
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <Box
                    sx={{
                        "@media (max-width: 800px)": {
                            marginLeft: "20px",
                        },
                    }}
                >
                    <Link to="/">
                        <img
                            alt="Joinee"
                            src={JoineeLogo}
                            style={{
                                width: "134px",
                                height: "28px",
                            }}
                        />
                    </Link>
                </Box>

            </Grid>

            <Grid item xs={8} sm={9.8} >
                <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="flex-end"
                    sx={{
                        height: "100%"
                    }}
                    spacing={2}
                    pr={3}
                >
                    <Box
                        sx={{
                            height: "fit-content",
                            color: "black",
                            "@media screen and (max-width: 1050px)": {
                                display: "none"
                            }
                        }}
                    >
                        <Link to="../jobs-list"
                            style={{
                                color: "black",
                            }}
                        >
                            <span
                                style={{
                                    fontSize: "15px",
                                }}
                            >Discover Jobs</span>
                        </Link>
                    </Box>
                    {/* <Box
                        sx={{
                            height: "fit-content",
                            color: "black",
                            "@media screen and (max-width: 1050px)": {
                                display: "none"
                            }
                        }}
                    >
                        <Link to="../about-us"
                            style={{
                                color: "black",
                            }}
                        >
                            <span
                                style={{
                                    fontSize: "15px",
                                }}
                            >About us</span>
                        </Link>
                    </Box>
                    <Box
                        sx={{
                            height: "fit-content",
                            color: "black",
                            "@media screen and (max-width: 1050px)": {
                                display: "none"
                            }
                        }}
                    >
                        <Link to="../careers"
                            style={{
                                color: "black",
                            }}
                        >
                            <span
                                style={{
                                    fontSize: "15px",
                                }}
                            >Careers</span>
                        </Link>
                    </Box>
                    <Box
                        sx={{
                            height: "fit-content",
                            color: "black",
                            "@media screen and (max-width: 1050px)": {
                                display: "none"
                            }
                        }}
                    >
                        <Link to="../blogs"
                            style={{
                                color: "black",
                            }}
                        >
                            <span
                                style={{
                                    fontSize: "15px",
                                }}
                            >Blogs</span>
                        </Link>
                    </Box> */}
                    {/* <Box
                        sx={{
                            height: "fit-content",
                            color: "black",
                            "@media screen and (max-width: 1050px)": {
                                display: "none"
                            }
                        }}
                    >
                        <Link className="nav_items_link" to="../employer-list">
                            <span className="header_items"> Companies</span>
                        </Link>
                    </Box>
                    <Box
                        sx={{
                            height: "fit-content",
                            color: "black",
                            "@media screen and (max-width: 1050px)": {
                                display: "none"
                            }
                        }}
                    >
                        <Link className="nav_items_link" to="../blogs">
                            <span className="header_items">Blogs</span>
                        </Link>
                    </Box>
                    <Box
                        sx={{
                            height: "fit-content",
                            color: "black",
                            "@media screen and (max-width: 1050px)": {
                                display: "none"
                            }
                        }}
                    >
                        <Link className="nav_items_link" to="/refernow">
                            <span className="header_items">Refer</span>
                        </Link>
                    </Box>
                    <Box
                        sx={{
                            height: "fit-content",
                            color: "black",
                            "@media screen and (max-width: 1050px)": {
                                display: "none"
                            }
                        }}
                    >
                        <Link className="nav_items_link" to="/employerpage">
                            <span className="header_items">Employers</span>
                        </Link>
                    </Box>
                    <Box
                        sx={{
                            height: "fit-content",
                            color: "black",
                            "@media screen and (max-width: 1050px)": {
                                display: "none"
                            }
                        }}
                    >
                        <Link className="nav_items_link" to="../about-us">
                            <span className="header_items">About us</span>
                        </Link>
                    </Box>
                    <Box
                        sx={{
                            height: "fit-content",
                            color: "black",
                            "@media screen and (max-width: 1050px)": {
                                display: "none"
                            }
                        }}
                    >
                        <Link className="nav_items_link" to="../contact-us">
                            <span className="header_items">Contact us</span>
                        </Link>
                    </Box> */}

                    {/* Authorised Menus */}
                    {user?.access && (
                        <>
                            {/* <Box
                                sx={{
                                    height: "fit-content",
                                    color: "black",
                                    "@media screen and (max-width: 1050px)": {
                                        display: "none"
                                    }
                                }}
                            >
                                <Link className="nav_items_link" to="../jobs-list">
                                    <span className="header_items">Discover Jobs</span>
                                </Link>
                            </Box> */}
                        </>
                    )}


                    {/* Global action menus */}

                    <Box sx={{
                        height: "fit-content",
                        "@media screen and (max-width: 1050px)": {
                            display: "none"
                        }
                    }}>
                        <Link to="/company"
                            style={{
                                border: "1px solid black",
                                color: "black",
                                padding: "6px 15px",
                                fontSize: "15px",
                                borderRadius: "50px"
                            }}
                        >
                            Company
                        </Link>
                    </Box>
                    {/* <Box sx={{
                        height: "fit-content",
                        "@media screen and (max-width: 1050px)": {
                            display: "none"
                        }
                    }}>
                        <Link to=""
                            style={{
                                border: "1px solid #066a4c",
                                color: "#fff",
                                padding: "6px 15px",
                                fontSize: "15px",
                                borderRadius: "50px",
                                background: "#066a4c"
                            }}
                        >
                            Apply for job
                        </Link>
                    </Box> */}

                    <Box sx={{ height: "fit-content" }}>
                        <CountryDropdowns />
                    </Box>

                    {/* Private Action menus */}
                    {user?.access ? (
                        <>
                            <Box sx={{ height: "fit-content" }}>
                                <NotificationDrawer isOpen={drawer} />
                            </Box>
                            <Box sx={{ height: "fit-content" }}>
                                <UserDropdowns user={user} />
                            </Box>
                        </>
                    ) : (
                        <>
                            <Box sx={{ height: "fit-content" }}>
                                <LoginNavs />
                            </Box>
                            <Box sx={{ height: "fit-content" }}>
                                <SingupNavs />
                            </Box>
                        </>
                    )}
                    <Box
                        sx={{
                            display: "none",
                            "@media screen and (max-width: 1050px)": {
                                display: "block",
                                top: "0",
                                cursor: "pointer",
                                margin: "0",
                            }
                        }}
                    >
                        <IconButton onClick={toggleDrawer(true)}>
                            <MenuOutlined fontSize="medium" />
                        </IconButton>
                    </Box>
                    <Drawer
                        anchor={"left"}
                        open={state}
                        onClose={toggleDrawer(false)}
                        sx={{
                            ".css-4t3x6l-MuiPaper-root-MuiDrawer-paper": {
                                background: "#cde2e7",
                            },
                        }}
                    >
                        <GlobalSidebar anchor="left" toggleDrawer={toggleDrawer} />
                    </Drawer>

                </Stack>
            </Grid>
        </Grid>
    )
}

export default GlobalNavbar;