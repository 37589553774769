import React, { useState } from 'react';
import { Grid, Stack, Typography, Box, Divider, Button } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import Frame1 from '../../../../assets/icons/Frame1';
import Frame2 from '../../../../assets/icons/Frame2';
import ApplyIcon from '../../../../assets/icons/LandingPage/ApplyIcon';
import HireIcon from '../../../../assets/icons/LandingPage/HireIcon';
import ReferIcon from '../../../../assets/icons/LandingPage/ReferIcon';
import ArrowIcon from '../../../../assets/icons/LandingPage/ArrowIcon';
import SearchIcon from '@mui/icons-material/Search';
import AutoComplete from '../../../../components/autocomplete/Autocomplete';
import JobSearchBox from '../../../../components/formElements/JobSearchBox';
import PlaceOutlinedIcon from '@mui/icons-material/PlaceOutlined';
import jobRefer1 from "../../../../assets/icons/LandingPage/jobRefer1.svg"
import jobRefer2 from "../../../../assets/icons/LandingPage/jobRefer2.svg"
function ApplyJobSection() {
    const [title, setTitle] = useState("");
    const [location, setLocation] = useState("");


    /**
     * 
     * Navigation context
     * */
    const navigate = useNavigate();

    return (
        <Grid container sx={{ paddingTop: "100px", paddingBottom: "100px" }}>
            <Grid item xs={12} sm={2}>
                <Box
                    sx={{
                        margin: "auto",
                        "@media (max-width: 600px)": {
                            display: "none"
                        },
                    }}
                >
                    <img src={jobRefer1} />
                </Box>
            </Grid>
            <Grid
                item
                xs={12}
                sm={8}
                pt={3}
            >

                <Stack
                    sx={{
                        textAlign: "center",
                        position: "relative",
                    }}
                    justifyContent="center"
                    alignItems="center"
                    spacing={2}
                >
                    <Box
                        sx={{
                            fontSize: "48px",

                        }}
                    >Apply for your dream job</Box>
                    <Box
                        sx={{
                            background: "linear-gradient(126.95deg, #FDE7EA 2.31%, #FDEFDA 100%)",
                            padding: "8px 30px",
                            borderRadius: "50px",
                            fontSize: "16px"
                        }}
                    >15+ Top companies hiring now</Box>
                </Stack>
                <form
                    onSubmit={e => {
                        e.preventDefault();
                        navigate(
                            `/jobs-list?title=${title}&location=${location}`
                        );
                    }}
                >
                    <Stack
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        spacing={2}
                        sx={{
                            margin: "auto",
                            border: "1px solid #727272",
                            borderRadius: "50px",
                            width: "80%",
                            // overflow: "hidden",
                            boxShadow: "rgba(0, 0, 0, 0.04) 0px 3px 5px",
                            marginTop: "20px",
                            '& hr': {
                                mx: 0.5,
                                minHeight: "40px"
                            },
                            "@media (max-width: 600px)": {
                                width: "95%",
                            },

                            padding: "10px 15px",

                        }}
                    >
                        <Box
                            sx={{
                                width: "300px"
                            }}
                        >
                            <JobSearchBox
                                icon={() => <SearchIcon />}
                                placeHolder={"Enter Skills / Designations / companies"}
                                sx={{
                                    paddingLeft: "5px",
                                    fontSize: "13px"
                                }}
                            />
                        </Box>
                        <Box>
                            <Divider orientation="vertical" variant="middle" flexItem />
                        </Box>
                        <Box
                            sx={{
                                width: "200px"
                            }}
                        >
                            <JobSearchBox
                                placeHolder={"Select Experience"}
                                sx={{
                                    paddingLeft: "5px",
                                    fontSize: "13px"
                                }}
                            />
                        </Box>
                        <Box>
                            <Divider orientation="vertical" variant="middle" flexItem />
                        </Box>
                        <Box
                            sx={{
                                width: "200px",
                            }}
                        >
                            <JobSearchBox
                                icon={() => <PlaceOutlinedIcon />}
                                placeHolder={"Enter Location"}
                                sx={{
                                    paddingLeft: "5px",
                                    fontSize: "13px"
                                }}

                            />
                        </Box>
                        <Box
                            sx={{
                                "@media (max-width: 1200px)": {
                                    width: "100%",
                                },
                            }}
                        >
                            <Button
                                type="submit"
                                size="large"
                                onClick={() => { console.log("Fired...") }}
                                startIcon={<SearchIcon fontSize="large" sx={{}} />}
                                loading={false}
                                loadingPosition="end"
                                variant="contained"
                                sx={{
                                    borderRadius: "50px",
                                    boxShadow: "none",
                                    background: "var(--app-button-color1)",
                                    padding: "10px 30px",
                                    "&:hover": {
                                        boxShadow: "none",
                                        background: "var(--app-button-color1)",
                                    }
                                }}
                            >
                                <span style={{ textTransform: "capitalize" }}>Search</span>
                            </Button>
                        </Box>
                    </Stack>
                </form>
                <Stack
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    mt={3}
                    sx={{
                        textAlign: "center",
                        color: "#636363",
                        fontSize: "14px",
                        '& hr': {
                            mx: 0.5,
                            height: "15px"
                        },
                    }}
                >
                    <Box>REFER & EARN </Box> <Divider orientation="vertical" variant="middle" flexItem />  <Box> APPLY JOB </Box>
                </Stack>
                <Stack
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    spacing={2}
                    mt={3}
                    sx={{
                        textAlign: "center"
                    }}
                >
                    <Box
                        sx={{
                            border: "1px solid #066a4c",
                            color: "#fff",
                            padding: "10px 35px",
                            fontSize: "15px",
                            borderRadius: "50px",
                            background: "#066a4c",
                            cursor: "pointer",
                            "&:hover": {
                                background: "#fff",
                                color: "#000",
                            }
                        }}
                    >Refer now</Box>
                    <Box
                        sx={{
                            border: "1px solid black",
                            color: "black",
                            padding: "10px 35px",
                            fontSize: "15px",
                            borderRadius: "50px",
                            cursor: "pointer",
                            "&:hover": {
                                background: "#066a4c",
                                color: "#fff",
                            }
                        }}
                    >How to refer</Box>
                </Stack>
            </Grid>
            <Grid item xs={12} sm={2}
                sx={{
                    paddingTop: "100px"
                }}

            >
                <Box
                    sx={{
                        margin: "auto",
                        "@media (max-width: 600px)": {
                            display: "none"
                        },
                    }}
                >
                    <img src={jobRefer2} />
                </Box>
            </Grid>
        </Grid>
    )
}

export default ApplyJobSection;