export default function NetflixIcon() {
    return (
        <svg width="13" height="22" viewBox="0 0 13 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.1768 0.239258L8.16845 4.91757L8.1601 9.59587L7.77581 8.50984V8.50705L7.27734 18.9219C7.76745 20.3031 8.02921 21.041 8.032 21.0438C8.03478 21.0466 8.31325 21.0633 8.6502 21.08C9.66941 21.1301 10.9337 21.2387 11.8944 21.3585C12.1172 21.3863 12.3065 21.4003 12.3204 21.3891C12.3344 21.378 12.3372 16.6161 12.3344 10.8044L12.326 0.239258L8.1768 0.239258Z" fill="#B1060F" />
            <path d="M0.674316 0.224709V10.801C0.674316 16.6183 0.679886 21.3829 0.68824 21.3913C0.696594 21.3996 1.05582 21.3634 1.49024 21.3161C1.92465 21.2687 2.52336 21.2075 2.82133 21.1824C3.27802 21.1434 4.64809 21.0571 4.80404 21.0543C4.85138 21.0543 4.85416 20.8176 4.85973 16.5737L4.86809 12.0931L5.19947 13.0288C5.24959 13.1736 5.2663 13.2182 5.31642 13.3602L5.81489 2.94815C5.70907 2.65019 5.76476 2.80613 5.64502 2.4664C5.23845 1.31631 4.89315 0.344451 4.87922 0.299896L4.85138 0.221924H0.674316V0.224709Z" fill="#B1060F" />
            <path d="M0.674316 0.224709V6.16171L4.85695 17.256C4.85695 17.0026 4.85695 16.8633 4.85973 16.5737L4.86809 12.0931L5.19947 13.0288C7.03737 18.2418 8.02594 21.0376 8.03151 21.0432C8.0343 21.046 8.31277 21.0627 8.64972 21.0794C9.66892 21.1295 10.9332 21.2381 11.8939 21.3579C12.1167 21.3857 12.306 21.3996 12.32 21.3885C12.3283 21.3801 12.3339 19.0465 12.3339 15.7522L8.17353 3.78357L8.17075 4.91694L8.16239 9.59525L7.7781 8.50921C7.40217 7.44824 7.15155 6.73814 5.63945 2.4664C5.23288 1.31631 4.88758 0.344451 4.87365 0.299896L4.84581 0.221924H0.674316V0.224709Z" fill="url(#paint0_radial_845_20641)" />
            <path d="M0.674316 0.224709L4.8653 12.1015V12.0959L5.19668 13.0316C7.03737 18.2418 8.02594 21.0376 8.03151 21.0432C8.0343 21.046 8.31277 21.0627 8.64972 21.0794C9.66892 21.1295 10.9332 21.2381 11.8939 21.3579C12.1139 21.3857 12.306 21.3996 12.3172 21.3885L8.16239 9.59246V9.59525L7.77811 8.50921C7.40217 7.44824 7.15155 6.73814 5.63945 2.4664C5.23288 1.31631 4.88758 0.344451 4.87366 0.299896L4.84581 0.221924H2.76006L0.674316 0.224709Z" fill="#E50914" />
            <defs>
                <radialGradient id="paint0_radial_845_20641" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(6.3089 10.6844) rotate(-19.1007) scale(0.768438 1.29117)">
                    <stop />
                    <stop offset="1" stop-opacity="0.01" />
                </radialGradient>
            </defs>
        </svg>
    );
}
