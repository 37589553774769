import { Box, Stack, TextField, InputAdornment } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useDebounce } from "./useDebounce";
import SearchIcon from "@mui/icons-material/Search";

const GlobalSearch = ({ id, placeholder, sx, ...rest}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [query, setQuery] = useState("");

  useEffect(() => {
    setQuery(searchParams.get("q") ?? "");
  }, []);

  const debouncedQuery = useDebounce(query, 500);

  useEffect(() => {
    searchParams.set("q", debouncedQuery);
    setSearchParams(searchParams);
  }, [debouncedQuery]);

  return (
    <Box sx={{ width: sx?.width ? sx?.width : "fit-content", overflow: "hidden" }}>
      <TextField
        placeholder={placeholder ? placeholder : "Search.."}
        value={query}
        sx={[
          {
            width: "100%",
          },
          sx
        ]}
        {...rest}
        onChange={(e) => {
          e.stopPropagation();
          setQuery(e.target.value);
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />
    </Box>
  );
};

export default GlobalSearch;
