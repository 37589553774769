import React, { useRef, useState, useEffect, useReducer } from 'react';
import { Grid, Stack, Typography, Box, Divider, Button, InputAdornment, IconButton, FormControlLabel, Radio, CircularProgress } from "@mui/material";
import GoogleIcon from '../../../assets/icons/LandingPage/GoogleIcon';
import AppleLogoIcon from '../../../assets/icons/LandingPage/AppleLogoIcon';
import TextField from '../../../components/formElements/TextField';
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { Link, useNavigate } from "react-router-dom";
import { useGoogleLogin } from '@react-oauth/google';
import { useForm, Controller } from "react-hook-form";
import toast from "react-hot-toast";
import OtpVerificationModal from '../../OtpVerificationModal';
import { useDispatch } from "react-redux";
import MuiCustomTelInput from '../../../components/formElements/PhoneNumber/PhoneNumber';
import { openModal } from '../../../components/modals/modalsSlice';
import { signup } from '../../../helpers/dataFetcher/profile/profile';



function RegisterAuthForm() {
    const [showPassword, setShowPassword] = useState(false);
    const [signupStatus, setSignupStatus] = useState(false);
    const [phone, setPhone] = React.useState("");

    const reduxDispatch = useDispatch();
    const navigate = useNavigate();
    const {
        register,
        handleSubmit,
        setValue,
        control,
        watch,
        getValues,
        setError,
        clearErrors,
        formState: { errors },
    } = useForm();


    const LoginWithGoogle = useGoogleLogin({
        onSuccess: tokenResponse => console.log(tokenResponse),
    });


    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    /* Sign up for candidates functionality OR Integration start */
    const initState = {
        email: "",
        firstName: "",
        lastName: "",
        password: "",
        phoneNumber: "",
        error: "",
        jsonError: "",
        status: 200,
        otpModal: false,
    };

    const reducer = (state, action) => {
        switch (action.type) {
            case "email":
                return { ...state, email: action.payload };
            case "firstName":
                return { ...state, firstName: action.payload };
            case "lastName":
                return { ...state, lastName: action.payload };
            case "password":
                return { ...state, password: action.payload };
            case "phoneNumber":
                return { ...state, phoneNumber: action.payload };
            case "otpModal":
                return { ...state, otpModal: action.payload };
            case "error":
                return { ...state, error: action.payload };
            case "status":
                return { ...state, status: action.payload };
            case "jsonError":
                return { ...state, jsonError: action.payload };
            default:
                return state;
        }
    };

    const [state, dispatch] = useReducer(reducer, initState);


    const signUpFunc = async data => {
        if (!data?.phoneNumber?.mobile || data?.phoneNumber?.mobile === "") {
            setError('phoneNumber', { type: 'custom', message: 'Phone number is required' });
            return;
        }
        setSignupStatus(true);
        const { status, data: signupData } = await signup({
            email: data.email,
            first_name: data.firstName,
            last_name: data.lastName,
            user_password: data.password,
            usertype: "REFERAL_PARTNER",
            phone_number: `${data.phoneNumber?.ccode + data.phoneNumber?.mobile}`,
        });
        if (status === 201) {
            reduxDispatch(
                openModal({
                    modalName: "otpVerificationModal",
                    modalProps: {
                        email: data.email
                    }
                })
            );
            setSignupStatus(false);
        }
        if (status === 422) {
            dispatch({ type: "error", payload: signupData });
            dispatch({ type: "status", payload: status });
            setSignupStatus(false);
        }
        if (status === 400) {
            dispatch({ type: "jsonError", payload: signupData?.message });
            setSignupStatus(false);
        }
        if (status === 200) {
            toast.success("User Successfully registered");
            setSignupStatus(false);
        }
        setSignupStatus(false);
        return;
    };


    const handleChangePhn = newPhone => {
        setPhone(newPhone);
        clearErrors("phoneNumber");
        var mobile = "";
        var ccode = "";
        if (newPhone.slice(0, 1) == "+" || newPhone.slice(0, 1) == "0") {
            mobile = newPhone.replace(/[^a-zA-Z0-9+]/g, "");
            ccode = newPhone?.split(" ")[0]?.replace(/[^a-zA-Z0-9+]/g, "");
        } else {
            mobile = newPhone.replace(/[^a-zA-Z0-9]/g, "");
            ccode = newPhone?.split(" ")[0]?.replace(/[^a-zA-Z0-9]/g, "");
        }
        setValue("phoneNumber", { ccode: ccode, mobile: newPhone?.split(" ")?.slice(1)?.join('') }, {
            shouldValidate: true,
        })
        dispatch({ type: "phoneNumber", payload: mobile });
        if (state?.error?.includes("phone_number")) {
            dispatch({ type: "error", payload: "" });
        }
    };

    return (
        <React.Fragment>
            <Box
                sx={{
                    minWidth: "300px",
                    width: "60%",
                    '@media (max-width:1200px)': {
                        width: "70%",
                    },
                    '@media (max-width: 900px)': {
                        width: "90%",
                    },
                    '@media (max-width: 600px)': {
                        width: "95%",
                    },
                }}
            >
                <form onSubmit={handleSubmit(signUpFunc)}>
                    <Stack
                        sx={{
                            width: "100%",
                            gap: "35px",
                        }}
                    >
                        <Stack>
                            <Typography
                                sx={{
                                    fontWeight: "600",
                                    fontSize: "30px",
                                    textAlign: "center",
                                }}
                            >Sign Up to Joinee</Typography>
                            <Typography
                                sx={{
                                    fontWeight: "400",
                                    fontSize: "16px",
                                    textAlign: "center",
                                    color: "#737373"
                                }}
                            >Sign up as Job Seeker</Typography>
                        </Stack>
                        <Stack
                            direction="column"
                            spacing={2}
                        >
                            <Stack
                                direction="row"
                                justifyContent="space-between"
                                alignItems="center"
                                spacing={3}
                            >
                                <Button component="label" variant="text" startIcon={<GoogleIcon />}
                                    sx={{
                                        border: "1px solid #DFE1E6",
                                        color: "#000",
                                        borderRadius: "8px",
                                        width: "50%"
                                    }}
                                    onClick={LoginWithGoogle}
                                >
                                    <Box sx={{
                                        textTransform: "none",
                                        fontWeight: "600",
                                        fontSize: "14px",
                                    }}>
                                        Sign up with Google
                                    </Box>
                                </Button>
                                <Button component="label" variant="text" startIcon={<AppleLogoIcon />}
                                    sx={{
                                        border: "1px solid #DFE1E6",
                                        color: "#000",
                                        borderRadius: "8px",
                                        width: "50%"
                                    }}
                                >
                                    <Box sx={{
                                        textTransform: "none",
                                        fontWeight: "600",
                                        fontSize: "14px",
                                    }}>
                                        Sign up with Apple
                                    </Box>
                                </Button>
                            </Stack>
                            <Stack>
                                <Divider sx={{ fontSize: "11px" }}>Or with email</Divider>
                            </Stack>
                            <Stack
                                direction="column"
                                spacing={1.5}
                            >
                                <Stack
                                    direction="row"
                                    justifyContent="space-between"
                                    alignItems="center"
                                    spacing={2}
                                >
                                    <Box
                                        sx={{
                                            width: "50%"
                                        }}
                                    >
                                        <TextField
                                            id="firstName"
                                            name="firstName"
                                            type="text"
                                            placeholder="Fisrt name"
                                            autoFocus
                                            error={errors?.firstName?.type === "required" ? true : false}
                                            useFormRef={{ ...register("firstName", { required: true }) }}
                                            helperText={
                                                errors?.firstName?.type === "required" ? "First name is required" : null
                                            }
                                        />
                                    </Box>
                                    <Box
                                        sx={{
                                            width: "50%"
                                        }}
                                    >
                                        <TextField
                                            id="lastName"
                                            name="lastName"
                                            type="text"
                                            placeholder="Last name"
                                            error={errors?.lastName?.type === "required" ? true : false}
                                            useFormRef={{ ...register("lastName", { required: true }) }}
                                            helperText={
                                                errors?.lastName?.type === "required" ? "Last name is required" : null
                                            }
                                        />
                                    </Box>
                                </Stack>
                                <Box>
                                    <TextField
                                        id="email"
                                        name="email"
                                        type="email"
                                        placeholder="Email or username"
                                        error={errors?.email?.type === "required" ? true : false}
                                        useFormRef={{ ...register("email", { required: true, pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i }) }}
                                        helperText={
                                            errors?.email?.type === "required" ? "Email or username is required" : errors?.email?.type === "pattern" ? "Invalid value" : null
                                        }
                                    />
                                </Box>
                                <Box>
                                    <MuiCustomTelInput
                                        defaultCountry="IN"
                                        fullWidth
                                        countryCodeEditable={true}
                                        value={phone}
                                        error={errors?.phoneNumber?.type === "custom" || state?.error?.includes("phone_number")}
                                        helperText={errors?.phoneNumber?.type === "custom" || state?.error?.includes("phone_number") ? "Phone number is required" : null}
                                        onChange={e => handleChangePhn(e)}
                                        sx={{
                                            width: "100%",
                                        }}
                                    />
                                </Box>
                                <Box>
                                    <TextField
                                        id="password"
                                        name="password"
                                        type={showPassword ? "text" : "password"}
                                        placeholder="Password"
                                        error={errors?.password?.type === "required" ? true : false}
                                        useFormRef={{ ...register("password", { required: true }) }}
                                        helperText={
                                            errors?.password?.type === "required" ? "Password is required" : null
                                        }
                                        endAdornment={
                                            <InputAdornment position="end"
                                                sx={{
                                                    paddingRight: "10px"
                                                }}
                                            >
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowPassword}
                                                    edge="end"
                                                    size="small"
                                                >
                                                    {showPassword ? (
                                                        <VisibilityOff fontSize="inherit" />
                                                    ) : (
                                                        <Visibility fontSize="inherit" />
                                                    )}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                    />
                                </Box>
                            </Stack>
                        </Stack>
                        <Stack>
                            <Button
                                variant="outlined"
                                type="submit"
                                style={{
                                    fontSize: "16px",
                                    border: "0px",
                                    color: "#fff",
                                    background: "#044B36",
                                    borderRadius: "50px",
                                    padding: "7px"
                                }}
                                disabled={signupStatus}
                            >
                                {signupStatus ? <CircularProgress color="success" size={25} /> : (
                                    <span
                                        style={{
                                            textTransform: "none",
                                            fontWeight: "600",
                                            fontSize: "14px",
                                        }}
                                    >
                                        Sign Up
                                    </span>
                                )}
                            </Button>
                        </Stack>
                        <Stack>
                            <Typography
                                sx={{
                                    fontSize: "15px",
                                    fontWeight: "400",
                                    color: "#737373",
                                    textAlign: "center"
                                }}
                            >
                                Already have an account?
                                <Link to="../login"
                                    style={{
                                        fontWeight: "700",
                                        color: "#000"
                                    }}
                                > Sign In
                                </Link>
                            </Typography>

                        </Stack>
                    </Stack>
                </form>
            </Box>
        </React.Fragment>
    )
}

export default RegisterAuthForm