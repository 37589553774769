import India from "../assets/icons/countryFlags/India";
import Uk from "../assets/icons/countryFlags/Uk";
import Usa from "../assets/icons/countryFlags/Usa";
import Australia from "../assets/icons/countryFlags/Australia";
import France from "../assets/icons/countryFlags/France";
import Germany from "../assets/icons/countryFlags/Germany";

export const countryData = [
    {
        id: "6",
        ccode: "India",
        logo: India
    },
    {
        id: "1",
        ccode: "London",
        logo: Uk
    },
    {
        id: "2",
        ccode: "USA",
        logo: Usa
    },
    {
        id: "3",
        ccode: "Australia",
        logo: Australia
    },
    {
        id: "4",
        ccode: "France",
        logo: France
    },
    {
        id: "5",
        ccode: "Germany",
        logo: Germany
    }
]