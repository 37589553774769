import React, { useState, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import SearchIcon from "@mui/icons-material/Search";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
// import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
// import Option from "@mui/joy/Option";
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import Pagination from "@mui/material/Pagination";
import {styleObj} from '../../assets/MUI/useStyles';
import {DataGridStyles} from '../../assets/MUI/useStyles';


function EarningsTable1 ({tableData}){
    const classes = DataGridStyles();
    const [page, setPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(5);


    const rowsData = tableData?.map((item, index) => {
        return {
          id:index,
          Ranking: index,
          CandidateName : `${item?.firstName }${item?.lastName}`,
          company: `${item?.email}`,
          Date: `${item?.date}`,
          Amount: `${item?.finalCommission}`,
        
        };
      });

    const columns = [
        {
          field: "Ranking",
          headerName: "Ranking",
          // headerClassName: "column_styles",
          headerClassName: classes.headerCellStyles,
          minWidth: 100,
          flex:1
        },
    
        {
          field: "CandidateName",
          headerClassName: classes.headerCellStyles,
          style: { color: "red" },
          headerName: "Candidate Name",
          minWidth: 250,
          flex:1,
          renderCell: ({ value }) => <span style={styleObj}>{value}</span>,
        },
       
        {
          field: "Company",
          headerClassName: classes.headerCellStyles,
          headerName: "Company",
          minWidth: 250,
          flex:1,
          renderCell: ({ value }) => <span style={styleObj}>{value}</span>,
        },
        {
            field: "Date",
            headerClassName: classes.headerCellStyles,
            headerName: "Date",
            minWidth: 250,
            flex:1,
            renderCell: ({ value }) => <span style={styleObj}>{value.slice(0,10)}</span>,
          },
        {
            field: "Amount",
            headerClassName: classes.headerCellStyles,
            headerName: "Amount",
            minWidth: 250,
            flex:1,
            renderCell: ({ value }) => <span style={styleObj}>{value}</span>,
          },
      ];

    return (
        <>
          <div style={{ height: "60vh", width: "98%",}}>
              {tableData &&
          <DataGrid
            rows={rowsData}
            columns={columns}
            disableColumnSelector
            disableDensitySelector
            disableRowSelectionOnClick
            components={{
              Pagination: ({ state, api }) => (
                <Pagination
                  {...state}
                  onChangePage={(event, newPage) => api.setPage(newPage)}
                  onChangeRowsPerPage={() => {}}
                  style={{ display: 'none' }} // Hide the rows per page selection
                />
              ),
            }}
           
          />}
        </div>  
      

        </>
    )
}

export default EarningsTable1;