import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import useOnclickOutside from "react-cool-onclickoutside";
import { useEffect } from "react";
import Input from "@mui/joy/Input";
// import Box from "@mui/joy/Box";
import Box from '@mui/material/Box';
import {  OutlinedInput,Button } from "@mui/material";
import {
  LocationCityOutlined,
  LocationOnOutlined,
  Map,
} from "@mui/icons-material";
import PropTypes from "prop-types";

export const PlacesAutocomplete = ({
  location,
  setLocation,
  invalid,
  placeHolder = "search with state or city...",
  className = "",
}) => {
  useEffect(() => {
    setValue(location);
    return () => {
      setValue("");
    };
  }, [location]);

  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {},
    debounce: 300,
  });
  const ref = useOnclickOutside(() => {
    clearSuggestions();
  });

  const handleInput = e => {
    setLocation(e.target.value);
    setValue(e.target.value);
  };

  // const handleSelect =
  //   ({ description }) =>
  //   () => {
  //     setValue(description, false);
  //     setLocation(description);
  //     clearSuggestions();
  //     getGeocode({ address: description }).then(results => {
  //       const { lat, lng } = getLatLng(results[0]);
  //     });
  //   };

  // const renderSuggestions = () =>
  //   data.map(suggestion => {
  //     const {
  //       place_id,
  //       structured_formatting: { main_text, secondary_text },
  //     } = suggestion;

  //     return (
  //       <li
  //         key={place_id}
  //         onClick={handleSelect(suggestion)}
  //         className="map_items"
  //       >
  //         <strong>{main_text}</strong> <small>{secondary_text}</small>
  //       </li>
  //     );
  //   });



  const handleSelect = ({ place_id, structured_formatting }) => () => {
    setValue(structured_formatting.main_text, false);
    setLocation(structured_formatting.main_text);
    clearSuggestions();
    
    if (place_id) {
      getGeocode({ placeId: place_id }).then(results => {
        if (results && results.length > 0) {
          const { lat, lng } = getLatLng(results[0]);
          // Do something with lat and lng if needed
        } else {
          console.error("No results found for the specified placeId");
        }
      });
    } else {
      console.error("place_id is undefined or null");
    }
  };
  
  

  const renderSuggestions = () => {
    return data
      .filter(suggestion => {
        // Check if the types array includes "locality" for cities and "administrative_area_level_1" for states
        return (
          suggestion.types.includes("locality") ||
          suggestion.types.includes("administrative_area_level_1")
        );
      })
      .map(suggestion => {
        const {
          place_id,
          structured_formatting: { main_text },
        } = suggestion;
  
        return (
          <li
            key={place_id}
            onClick={handleSelect(suggestion)}
            // onClick={handleSelect(main_text)}
            className="map_items"
          >
            {/* <strong>{main_text}</strong> <small>{secondary_text}</small> */}
            <strong>{main_text}</strong>
          </li>
        );
      });
  };
  
    

  return (
    <Box ref={ref}>
      <OutlinedInput
        // startDecorator={<LocationOnOutlined color="primary" />}
        startDecorator={<LocationOnOutlined style={{color:"#066a4c"}} />}
        variant="outlined"
        value={value}
        onChange={handleInput}
        disabled={!ready}
        size="sm"
        placeholder={placeHolder}
        invalid={invalid}
        sx={{width:"100%"}}
      />
      {status === "OK" && <ul className="map_list">{renderSuggestions()}</ul>}
    </Box>
  );
};

PlacesAutocomplete.propTypes = {
  location: PropTypes.string.isRequired,
  setLocation: PropTypes.func.isRequired,
  invalid: PropTypes.bool,
  placeHolder: PropTypes.string,
  className: PropTypes.string,
};
