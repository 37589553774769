import React, { useRef, useEffect, useState } from 'react';
import { Grid, Stack, Typography, Box, Chip } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import ArrowRight from '../../../../assets/icons/LandingPage/ArrowRight';
import ArrowLeft from '../../../../assets/icons/LandingPage/ArrowLeft';
import referTestimonial from '../../../../assets/icons/LandingPage/referTestimonial.svg';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
    carouselStyle: {
        // overflow: "visible !important",
        zIndex: 0,
        position: "relative",
        // background: "#fff",
        // borderRadius: "10px",
    },

});
function ReferTestimonialSection() {

    const navigate = useNavigate();
    const [scrollData, setScrollData] = useState(null);
    const [slideData, setSlideData] = useState(null);

    const carouselRef = useRef();


    const classes = useStyles();
    const responsive = {
        superLargeDesktop: {
            breakpoint: { max: 4000, min: 3000 },
            items: 1,
        },
        desktop2: {
            breakpoint: { max: 3001, min: 1600 },
            items: 1,
        },
        desktop1: {
            breakpoint: { max: 1601, min: 1000 },
            items: 1,
            // gap: 2,
        },
        tablet2: {
            breakpoint: { max: 1001, min: 770 },
            items: 1,
        },
        tablet1: {
            breakpoint: { max: 771, min: 464 },
            items: 1,
        },
        mobile: {
            breakpoint: { max: 465, min: 0 },
            items: 1,
        },
    };

    const storyData = [
        {
            id: "1",
            image: referTestimonial

        },
        {
            id: "2",
            image: referTestimonial
        }
    ];

    const CustomButtonGroup = ({ next, previous, goToSlide, ...rest }) => {
        const { carouselState: { currentSlide, slidesToShow }, } = rest;
        setScrollData(slidesToShow);
        setSlideData(currentSlide);
    };


    return (
        <Grid container sx={{ paddingTop: "100px", paddingBottom: "100px", }}>
            <Grid item xs={12} sm={12} >
                <Stack sx={{ width: "100%", margin: "auto" }} spacing={2}>
                    <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="flex-start"
                    >
                        <Chip label="TESTIMONIALS" onClick={() => { }}
                            sx={{
                                background: "#E6F0ED",
                                '& .MuiChip-label': {
                                    fontWeight: "700",
                                },
                            }}
                        />
                    </Stack>
                    <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="space-between"
                    >
                        <Typography
                            sx={{
                                fontSize: "45px",
                                fontWeight: "500",
                                "@media (max-width: 900px)": {
                                    fontSize: "20px",
                                },
                            }}
                        >People earned by referring </Typography>

                        <Stack direction="row" spacing={3}>
                            <Box
                                sx={{
                                    border: "1px solid gray",
                                    height: "40px",
                                    width: "40px",
                                    borderRadius: "50%",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    opacity: slideData == 0 && "0.5",
                                    cursor: "pointer"
                                }}
                                onClick={() => {
                                    if (carouselRef?.current) {
                                        carouselRef?.current?.previous();
                                    }
                                }}
                            ><ArrowLeft /></Box>
                            <Box
                                sx={{
                                    border: "1px solid gray",
                                    height: "40px",
                                    width: "40px",
                                    borderRadius: "50%",
                                    display: "flex",
                                    justifyContent: "center",
                                    opacity: slideData * scrollData === storyData?.length + 1 && "0.5",
                                    alignItems: "center",
                                    cursor: "pointer"
                                }}
                                onClick={() => {
                                    if (carouselRef?.current) {
                                        carouselRef?.current?.next();
                                    }
                                }}
                            ><ArrowRight /></Box>
                        </Stack>
                    </Stack>
                </Stack>
            </Grid>
            <Grid
                item
                xs={12}
                sm={12}
                mt={3}
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "40px",
                    position: "relative",
                }}
            >
                <Stack
                    sx={{
                        // overflow: "hidden",
                        margin: "auto",
                        width: "100%",
                        "@media (max-width: 600px)": {
                        },
                    }}
                >

                    <Carousel
                        ref={carouselRef}
                        responsive={responsive}
                        className={classes.carouselStyle}
                        arrows={false}
                        renderButtonGroupOutside={true}
                        customButtonGroup={<CustomButtonGroup />}
                    >
                        {storyData?.map((data, index) => (
                            <Stack
                                key={index}
                                sx={{
                                    width: "100%",
                                    height: "fit-content",
                                    minHeight: "100%",
                                    margin: "auto",
                                }}
                            >
                                <img
                                    src={data?.image}
                                    style={{
                                        width: "100%",
                                    }}
                                />
                            </Stack>
                        ))}

                    </Carousel>
                </Stack>

            </Grid>

        </Grid >
    )
}

export default ReferTestimonialSection;