import * as React from "react";
import { Box, Paper, Popover } from "@mui/material";

export default function PopoverPopupModel({
  children,
  sx,
  anchorEl,
  setAnchorEl,
  popupAdjust
}) {

  const handleClose = () => {
    if (setAnchorEl) {
      setAnchorEl(null);
    }
  };

  const openModel = Boolean(anchorEl);
  const id = openModel ? "simple-popover" : undefined;

  return (
    <Popover
      onClick={(e) => {
        e.stopPropagation();
      }}
      disableScrollLock
      id={id}
      open={openModel}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      sx={{
        boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px !important",
        '.MuiPopover-paper':{
          boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px !important",
          ...popupAdjust
        }
      }}
    >
      <Paper>
        <Box
          sx={[
            {
              height: "100%",
              overflowY: "scroll",
              msOverflowStyle: "none",
              scrollbarWidth: "none",
              "&::-webkit-scrollbar": {
                display: "none",
              },
            },
            {
              ...sx
            }
          ]}
        >
          {children}
        </Box>
      </Paper>
    </Popover>
  );
}
