import * as React from "react";
import { Link } from "react-router-dom";
import { Stack, Avatar, IconButton, Box } from "@mui/material";
import PopoverPopupModel from "../../../components/popover/components/Popover";
import LanguageIcon from '@mui/icons-material/Language';
import Cookies from 'js-cookie';
import { countryData } from "../../../data/countryData";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

export default function CountryDropdowns() {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const cookieCountryCode = Cookies.get('cookieCountryCode');


    return (
        <div>
            <IconButton
                color="primary"
                component="label"
                onClick={handleClick}
                sx={{
                    height: "35px",
                    width: "fit-content",
                    maxWidth: "fit-content",
                    borderRadius: "5px",
                    padding: "2px 5px",
                }}
            >
                {cookieCountryCode ? (
                    <Stack
                        direction="row"
                         justifyContent="center"
                         alignItems="center"
                    >
                        <Box>
                            {countryData?.find((item => item?.ccode === cookieCountryCode))?.logo()}
                        </Box>
                        <ArrowDropDownIcon fontSize="medium" sx={{ color: "#000" }} />
                    </Stack>
                ) : (
                    <LanguageIcon fontSize="medium" sx={{ color: "#000" }} />
                )}
            </IconButton>

            <PopoverPopupModel
                anchorEl={anchorEl}
                setAnchorEl={setAnchorEl}
                sx={{ maxWidth: "40vw", minWidth: "150px" }}
                popupAdjust={{ marginTop: "15px" }}
            >
                <Stack
                    direction="column"
                    sx={{
                        borderRadius: "5px",
                        overflow: "hidden",
                        width: "100%",
                        display: "grid",
                        gridTemplateColumns: "repeat(1, 1fr)",
                        flexWrap: "wrap",
                        "& > div": {
                            //   borderTop: "1px solid var(--border-color-2)",
                            borderBottom: "1px solid var(--border-color-2)",
                            height: "45px",
                            display: "flex",
                            justifyContent: "flex-start",
                            alignItems: "center",
                            paddingLeft: "10px"
                        },
                        "& > div:last-child": {
                            borderBottom: "0px",
                        },
                        "& > div > div": {
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "flex-start",
                            alignItems: "center",
                            fontSize: "15px",
                            width: "100%",
                            height: "100%",
                            gap: "10px",
                            cursor: "pointer"
                        },
                    }}
                >
                    {countryData?.map((item) => (
                        <Box
                            onClick={(e) => {
                                Cookies.set('cookieCountryCode', item?.ccode);
                                Cookies.set('cookieCountryCodeTriggered', true);
                                handleClose();
                                window.location.reload();
                            }}

                            key={item?.id}
                            sx={{
                                "&:hover": {
                                    background: "var(--theme-Shifyt-bg-color)"
                                },
                                ...(item?.ccode === cookieCountryCode && {
                                    background: "var(--theme-Shifyt-bg-color)"
                                })
                            }}
                        >
                            <Box>
                                {item?.logo()}
                                <span style={{ textTransform: "capatilize", fontWeight: "900" }}>{item?.ccode}</span>
                            </Box>
                        </Box>
                    ))}

                </Stack>
            </PopoverPopupModel>
        </div>
    );
}
