import * as React from "react";
import Button from "@mui/joy/Button";
import { Link } from "react-router-dom";
import { Stack } from "@mui/material";
import PopoverPopupModel from "../../components/popover/components/Popover";


export default function SingupNavs() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        id="basic-demo-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        variant="outlined"
        // color="neutral"
        sx={{ color: "#066a4c", borderColor: "#066a4c !important" }}
        onClick={handleClick}
      >
        Sign Up
      </Button>

      <PopoverPopupModel
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        sx={{ maxWidth: "40vw", minWidth: "150px" }}
        popupAdjust={{ marginTop: "15px"}}
      >
        <Stack
          direction="column"
          sx={{
            borderRadius: "5px",
            background: "#cde2e7",
            backdropFilter: "blur(13px)",
            border: "1px solid #066a4c",
            overflow: "hidden"
          }}
        >
          <Stack
            sx={{
              "&:hover": {
                background: "#fff",
              }
            }}
            onClick={handleClose}
          >
            <Link to="/registration"
              style={{
                color: "black",
                padding: "8px",
              }}
            >
              Job Seeker
            </Link>
          </Stack>
          <Stack
          sx={{
            "&:hover": {
              background: "#fff",
            }
          }}
          onClick={handleClose}
          >
            <Link
              target={"_blank"}
              to="//app.joinee.com/signup?user_type=CA"
              style={{
                color: "black",
                padding: "8px"
              }}
            >
              Employer
            </Link>
          </Stack>
          <Stack
          sx={{
            "&:hover": {
              background: "#fff",
            }
          }}
          onClick={handleClose}
          >
            <Link
              target={"_blank"}
              to="//app.joinee.com/signup?user_type=SP"
              style={{
                color: "black",
                padding: "8px"
              }}
            >
              Partner
            </Link>
          </Stack>

        </Stack>
      </PopoverPopupModel>
    </div>
  );
}
