import React from 'react';
import { Grid, Stack, Typography, Box, Button, Chip, InputAdornment, IconButton } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import Banner from "../../../assets/icons/Blogs/BlogBanner.svg";
import airtable from "../../../assets/icons/Careers/airtable.svg";
import gumroad from "../../../assets/icons/Careers/gumroad.svg";
import mailChimp from "../../../assets/icons/Careers/mailChimp.svg";
import notion from "../../../assets/icons/Careers/notion.svg";
import ArrowRight from '../../../assets/icons/LandingPage/ArrowRight';
function BlogsBannerSection() {
    /**
    *
    * redux dispatch
    *
    */
    const dispatch = useDispatch();


    /**
     *
     * navigate
     *
     */
    const navigate = useNavigate();


    return (
        <Grid container sx={{
            paddingTop: "80px", paddingBottom: "70px",
            "@media (max-width: 600px)": {
                paddingTop: "50px",
                paddingBottom: "60px",
            },
        }}>
            <Grid item xs={12} sm={12}>
                <Grid container>
                    <Grid item xs={12} sm={6}>
                        <Stack sx={{ width: "100%", margin: "auto" }} spacing={3}>
                            <Stack
                                direction="row"
                                alignItems="center"
                                justifyContent="flex-start"
                                spacing={1}
                            >
                                <Chip label="Engineering"
                                    sx={{
                                        background: "#ECFAE2",
                                        '& .MuiChip-label': {
                                            fontWeight: "700",
                                        },
                                    }}
                                />
                                <Chip label="October 23, 2023" size="medium"
                                    sx={{
                                        backgroundColor: "transparent"
                                    }}
                                />
                            </Stack>
                            <Stack
                                direction="column"
                                sx={{
                                    width: "90%",
                                    "@media (max-width: 900px)": {
                                        width: "100%",
                                    },
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontSize: "45px",
                                        fontWeight: "600",
                                        "@media (max-width: 900px)": {
                                            fontSize: "20px",
                                        },
                                    }}
                                >Introducing the Refer& Earn Program</Typography>
                                <Typography
                                    sx={{
                                        fontSize: "16px",
                                        fontWeight: "500",
                                        color: "#737373",
                                        "@media (max-width: 900px)": {
                                            fontSize: "13px",
                                        },
                                    }}
                                >
                                    Egestas tellus viverra sed nulla. Quisque venenatis quis odio aliquet tellus dui pellentesque. Sed dictum id venenatis sed viverra aliquam sapien.
                                </Typography>
                                <Button
                                    type="submit"
                                    size="large"
                                    onClick={() => { console.log("Fired...") }}
                                    loading={false}
                                    loadingPosition="end"
                                    variant="contained"
                                    sx={{
                                        borderRadius: "50px",
                                        boxShadow: "none",
                                        width: "fit-content",
                                        background: "#fff",
                                        color: "#000",
                                        padding: "8px 30px",
                                        border: "1px solid var(--border-color-2)",
                                        "&:hover": {
                                            boxShadow: "none",
                                            background: "var(--app-button-color1)",
                                            color: "#fff",
                                        },
                                        marginTop: "30px",
                                    }}
                                    endIcon={<IconButton
                                        aria-label="toggle password visibility"
                                        edge="end"
                                        size="small"
                                    >
                                        <ArrowRight />
                                    </IconButton>}

                                >
                                    <span style={{ textTransform: "capitalize", fontWeight: "600" }}>Read More</span>
                                </Button>
                            </Stack>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Stack
                            justifyContent="center"
                            alignItems="center"
                        >
                            <Box
                                sx={{
                                    width: "70%",
                                    height: "fit-content",
                                    "@media (max-width: 600px)": {
                                        width: "100%",
                                        marginTop: "20px"
                                    },
                                }}
                            >
                                <img src={Banner} alt="blogs banner" style={{ width: "100%", height: "auto" }} />
                            </Box>
                        </Stack>
                    </Grid>
                </Grid>

            </Grid>
            <Grid
                item
                xs={12}
                sm={12}
            >
                <Stack
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        flexWrap: "wrap",
                        gap: "20px",
                        justifyContent: "space-between",
                        marginTop: "50px"
                    }}
                >
                    <Box>
                        <Typography>Trusted by thousand companies in the world</Typography>
                    </Box>
                    <Box>
                        <img src={mailChimp} alt="" />
                    </Box>
                    <Box>
                        <img src={airtable} alt="" />
                    </Box>
                    <Box>
                        <img src={gumroad} alt="" />
                    </Box>
                    <Box>
                        <img src={notion} alt="" />
                    </Box>
                </Stack>
            </Grid>


        </Grid >
    )
}

export default BlogsBannerSection;
