import React, { useState, useEffect, lazy } from "react";
import { Box, Typography } from "@mui/material";
import { Navigate, useRoutes, useLocation } from "react-router-dom";
import PrivateOutlet from "./PrivateOutlet";
import PublicOutlet from "./PublicOutlet";
import AuthOutlet from "./AuthOutlet";

import EmployerRegistration from "../Authentication/EmployerLogins/EmployerLogin";
import ScreeningPartner from "../Authentication/EmployerLogins/SereeningPartner";
import ForgetPassword from "../Authentication/FogetPassword";
import ChangePassword from "../Authentication/ChangePassword";
import ChangeEmail from "../Authentication/ChangeEmail";
import Login from "../Authentication/Login";
import Logout from "../Authentication/Logout";
import Registration from "../Authentication/Registration";
import ResetPassword from "../Authentication/ResetPassword";
import AboutUs from "../pages/AboutUs/AboutPage";
import ProfilePage from "../pages/Accounts/AccountsIndex";
import AllJobs from "../pages/Accounts/AllJobs/AllJobs";
import AppliedJobs from "../pages/Accounts/AppliedJobs/AppliedJobs";
import DashBoardIndex from "../pages/Accounts/Dashboard/DashboardIndex";
import MyReferals from "../pages/Accounts/MyReferals/myReferal";
import ApplicationStatus from "../pages/ApplicationStatus/ApplicationStatus";
import BlogsListIndex from "../pages/Blogs/BlogsListIndex";
import BlogsSingleIndex from "../pages/Blogs/BlogsSingle/BlogsSingle";
import BrowseJobsList from "../pages/BrowseJobsList/Index";
import CandidateIndex from "../pages/Candidates/Candidates";
import CandidateSingle from "../pages/Candidates/CandidateSingle";
// import Chat from "../pages/Chat";
import ContactUsIndex from "../pages/Contact-Us/ContactUsIndex";
import Employer from "../pages/Employers/EmployerSingle/Index";
import MainEmployerList from "../pages/Employers/List/Index";
import Faq from "../pages/Faq/Faq";
import HomeUI from "../pages/Home/HomeUI";
import InterviewsList from "../pages/interviewlist/InterviewsList";
import MainJobsList from "../pages/JobsList/Index";
import LeadershipBoard from "../pages/Leadershipboard/LeadershipBoard";
import MoreJobs from "../pages/MoreJobs/MoreJobs";
import MyEarning from "../pages/MyEarning/Index";
import DemoChat from "../pages/DemoChat/index";
import MyMoney from "../pages/MyMoney/Index";
import NotFound from "../pages/NotFound";
import Notifications from "../pages/Notifications/Notifications";
import Pricing from "../pages/Pricing/Pricing";
import ReferNow from "../pages/Refernow/ReferNow";
import ReferNowIndex from "../pages/ReferNowPage/ReferNowIndex";
import EmployersIndex from '../pages/EmployersPage/EmployersIndex'
import Settings from "../pages/settings";
import Successfull from "../pages/Successful/Successfull";
import MySavedJobs from "../pages/Accounts/SavedJobs/MySavedJobs";
import ChatChannelIndex from "../pages/DemoChat/DemoChatChannelIndex";

import UserProfile from "../pages/Accounts/Profiles/UserProfile/UserProfile";
import ReferAndEarnPage from "../pages/LandingPages/ReferAndEarnPage/ReferAndEarnPage";
import CompanyLandingPage from "../pages/LandingPages/CompanyPage/CompanyLandingPage";
import CommonLandingPage from "../pages/LandingPages/CommonPage/CommonLandingPage";
import CareersPage from "../pages/Careers/CareersPage";
import LoginPage from "../Authentication/AuthPages/LoginPage";
import RegisterPage from "../Authentication/AuthPages/RegisterPage";
import BlogsPage from "../pages/Blogs/BlogsPage";
import BlogDetailsPage from "../pages/Blogs/BlogDetailsPage";
import PrivacyPolicyPage from "../pages/CompanyPolicies/PrivacyPolicyPage";
import ForgetPasswordPage from "../Authentication/AuthPages/ForgetPasswordPage";
import ResetPasswordPage from "../Authentication/AuthPages/ResetPasswordPage";


export default function AppRoutes() {

  const location = useLocation();
  useEffect(() => {
    document.title = getPageTitle(location.pathname);
  }, [location]);

  const getPageTitle = path => {
    switch (path) {
      case "/jobs-list":
        return "Jobs";
      case "/employer-list":
        return "Companies";
      case "/employer-list":
        return "Companies";
      case "/profile":
        return "Profile";
      case "/dashboard":
        return "Dashboard";
      case "/refered-jobs":
        return "Refered Jobs";
      case "/my-referals":
        return "My Referals";
      case "/applied-jobs":
        return "Applied jobs";
      case "/my-savedjobs":
        return "Saved Jobs";
      case "/demochat":
        return "Chat";
      case "/interviews":
        return "Interviews";
      case "/leadership-board":
        return "Leadership Board";
      case "/myearning":
        return "My Earnings";
      case "/settings":
        return "Settings";
      case "/login":
        return "Login";
      case "/registration":
        return "Registration";
      case "/forget-password":
        return "Forget Password"
      default:
        return "Joinee";
    }
  };


  const routes = useRoutes([
    {
      path: "/",
      element: <PublicOutlet />,
      children: [
        { path: "/", element: <CommonLandingPage /> },
        // { path: "/", element: <HomeUI /> },
        // { path: "/common-landing", element: <CommonLandingPage /> },
        { path: "/company", element: <CompanyLandingPage /> },
        { path: "/common-refer", element: <ReferAndEarnPage /> },
        { path: "/employer-list", element: <MainEmployerList /> },
        { path: "/jobs-list", element: <MainJobsList /> },
        // { path: "/login", element: <Login /> },
        // { path: "/forget-password", element: <ForgetPassword /> },

        { path: "/change-password", element: <ChangePassword /> },
        // { path: "/reset-password", element: <ResetPassword /> },
        { path: "/logout", element: <Logout /> },
        // { path: "/registration", element: <Registration /> },
        { path: "/contact-us", element: <ContactUsIndex /> },
        { path: "/about-us", element: <AboutUs /> },
        { path: "/refernow", element: <ReferNowIndex /> },
        // { path: "/employerpage", element: <EmployersIndex /> },
        // { path: "/blogs", element: <BlogsListIndex /> },
        { path: "/blogs", element: <BlogsPage /> },
        { path: "/blog/:blogId", element: <BlogDetailsPage /> },
        { path: "/privacy-policy", element: <PrivacyPolicyPage /> },
        // { path: "/job/:id", element: <BrowseJobsList /> },
        // { path: "/employer-single-list/:id", element: <Employer /> },
        { path: "/job", element: <BrowseJobsList /> },
        { path: "/employer-single-list", element: <Employer /> },
        { path: "/more-fn-areas", element: <MoreJobs /> },
        { path: "/user-profile", element: <UserProfile /> },
        { path: "/careers", element: <CareersPage /> }
      ],
    },
    {
      path: "/",
      element: <PrivateOutlet />,
      children: [
        // { path: "/jobs-list", element: <MainJobsList /> },
        // { path: "/job/:id", element: <BrowseJobsList /> },
        { path: "/profile", element: <ProfilePage /> },
        { path: "/candidate", element: <CandidateIndex /> },
        { path: "/candidate-single", element: <CandidateSingle /> },
        { path: "/blogs/:id", element: <BlogsSingleIndex /> },
        { path: "/dashboard", element: <DashBoardIndex /> },
        { path: "/refered-jobs", element: <AllJobs /> },
        { path: "/my-referals", element: <MyReferals /> },
        { path: "/applied-jobs", element: <AppliedJobs /> },
        { path: "/saved-jobs", element: <AppliedJobs /> },
        // { path: "/chat", element: <Chat /> },
        { path: "/application-status", element: <ApplicationStatus /> },
        // { path: "/refernow", element: <ReferNow /> },
        // { path: "/refernow", element: <ReferNowIndex /> },
        // { path: "/employerpage", element: <EmployersIndex /> },
        { path: "/screening-partner", element: <ScreeningPartner /> },
        { path: "/employer-registration", element: <EmployerRegistration /> },
        // { path: "/more-fn-areas", element: <MoreJobs /> },
        { path: "/notification", element: <Notifications /> },
        { path: "/pricing", element: <Pricing /> },
        { path: "/interviews", element: <InterviewsList /> },
        { path: "/faq", element: <Faq /> },
        { path: "/myearning", element: <MyEarning /> },
        { path: "/settings", element: <Settings /> },
        { path: "/mymoney", element: <MyMoney /> },
        { path: "/leadership-board", element: <LeadershipBoard /> },
        { path: "/success", element: <Successfull /> },
        // { path: "/employer-single-list/:id", element: <Employer /> },
        { path: "/mymoney", element: <MyMoney /> },
        { path: "/demochat", element: <DemoChat /> },
        { path: "/jobchat", element: <ChatChannelIndex /> },
        { path: "/my-savedjobs", element: <MySavedJobs /> },
      ],
    }, 
    {
      path: "/",
      element: <AuthOutlet />,
      children: [
        { path: "/login", element: <LoginPage /> },
        { path: "/registration", element: <RegisterPage /> },
        { path: "/forget-password", element: <ForgetPasswordPage /> },
        { path: "/reset-password", element: <ResetPasswordPage /> },
      ],
    },
    {
      path: "*",
      element: (
        <Box
          sx={{
            height: "100vh",
            width: "100vw",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <NotFound />
        </Box>
      ),
    },
  ]);

  return routes;
}
