import React, { useState, useEffect, useRef } from "react";
import { Stack, IconButton, Divider, InputAdornment, Typography, TextField, Chip, Box, Badge, Avatar } from "@mui/material";
import "../../assets/css/chat/chat.css";
import DownloadIcon from "@mui/icons-material/Download";
import SendIcon from "@mui/icons-material/Send";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import { makeStyles, propsToClassKey } from "@mui/styles";
import { ThemeProvider } from "@mui/material/styles";
import { globalTheme } from "../../assets/MUI/globalStyles";
import { convertintoStringDates, hoursToStandard } from "../../functions/date";
import checkMouseDown from "../../hooks/mouseDown";
import { imageURL } from "../../Constants/commonURLS";
import { getPreSignedURLForMultipleUpload } from "../../helpers/dataFetcher/pre_signed_urls/multipleUpload";
import { PdfButton } from "../../assets/MUI/globalStyles";
import { SmallAvatar } from "../../assets/MUI/globalStyles";
import moment from "moment";
import NoDataFoundComponent from "../../Nodatafound/NoDataFoundReusable";
import { openModal } from "../../components/modals/modalsSlice";
import FindInPageRoundedIcon from '@mui/icons-material/FindInPageRounded';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import DoneIcon from '@mui/icons-material/Done';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import FaceIcon from '@mui/icons-material/Face';
import { getOnlineUsers } from "../../helpers/dataFetcher/chats/chats";

const useStyles = makeStyles({
  status: {
    "& .css-myurn6-JoyBadge-badge": {
      backgroundColor: "#219653",
    },
  },
  root: {
    height: 48,
    width: "100% ",
    background: "#ffffff",
    border: "1px solid rgba(0, 0, 0, 0.1) !important",
    borderRadius: "12px !important",
    transitionDelay: "0.5ms",
    color: "rgba(0, 0, 0, 0.5)",
    fontFamily: "Inter , sans-serif !important",
    "& input::placeholder": {
      fontFamily: "Inter , sans-serif !important",
      fontWeight: 500,
      fontSize: 14,
      fontStyle: "normal",
      color: "rgba(0, 0, 0, 0.5)",
    },
    //   "& input": {
    //       padding: "12px 14px",
    //       borderRadius: 8,
    //     },
    "& .PrivateNotchedOutline-root-1": {
      display: "none !important",
    },
    "& fieldset": {
      borderWidth: 0,
    },
  },
  fieldSet: {
    "&.MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
    "&.PrivateNotchedOutline-root-5": {
      borderWidth: 0,
    },
    "& fieldset": {
      borderWidth: 0,
    },
  },
  avatar: {
    background: "#cde2e7 !important",
    "& .css-2s90m6-MuiAvatar-root": {
      color: "rgba(0, 0, 0, 0.7)  !important",
      fontFamily: "Inter , sans-serif !important",
      fontSize: "20px !important",
    },
  },
  avatar1: {
    background: "#cde2e7 !important",
    borderRadius: "36px",
    "&.css-2s90m6-MuiAvatar-root": {
      color: "rgba(0, 0, 0, 0.7)",
      fontFamily: "Inter , sans-serif !important",
      fontSize: "20px !important",
    },
  },

  chip: {
    "&.css-w66kx-MuiChip-root": {
      height: "auto !important",
      marginLeft: "4px",
      color: "#006064",
      backgroundColor: "rgba(77, 208, 225, 0.5) !important",
      fontFamily: "Inter , sans-serif !important",
      fontStyle: "normal",
      fontWeight: "500 !important",
      fontSize: "11px !important",
      "@media (max-width:576px)": {
        display: "none",
      },
    },
  },
  chip2: {
    "&.css-w66kx-MuiChip-root": {
      height: "auto !important",
      marginLeft: "4px",
      color: "#B84040",
      backgroundColor: "rgba(204, 51, 51, 0.5) !important",
      fontFamily: "Inter , sans-serif !important",
      fontStyle: "normal",
      fontWeight: "500 !important",
      fontSize: "11px !important",
    },
  },
  chip3: {
    "&.css-w66kx-MuiChip-root": {
      height: "auto !important",
      marginLeft: "4px",
      color: "#827717",
      backgroundColor: "rgba(220, 231, 117, 0.5) !important",
      fontFamily: "Inter , sans-serif !important",
      fontStyle: "normal",
      fontWeight: "500 !important",
      fontSize: "11px !important",
    },
  },
  address: {
    fontFamily: "Inter , sans-serif !important",
    fontStyle: "normal",
    fontWeight: "400 !important",
    fontSize: "12px !important",
    color: "rgba(0, 0, 0, 0.7) !important",
  },
  name: {
    fontFamily: "Inter , sans-serif !important",
    fontStyle: "normal",
    fontWeight: "700 !important",
    fontSize: "14px !important",
    lineHeight: "21px",
    color: "#000000 !important",
  },
  msg: {
    fontFamily: "Inter , sans-serif !important",
    fontStyle: "normal",
    fontWeight: "500 !important",
    fontSize: "12px !important",
    color: "rgba(0, 0, 0, 0.5)!important",
  },
  refer: {
    fontFamily: "Inter , sans-serif !important",
    fontStyle: "normal",
    fontWeight: "500 !important",
    fontSize: "14px !important",
    color: "rgba(0, 0, 0, 0.5)!important",
    lineHeight: "16.8px",
    textTransform: "capitalize",
  },
  referName: {
    fontFamily: "Inter , sans-serif !important",
    fontStyle: "normal",
    fontWeight: "500 !important",
    fontSize: "14px !important",
    color: "#000000 !important",
    textTransform: "capitalize",
  },
  chatReceiver: {
    display: "flex !important",
    alignItems: "center",
    gap: "4px",
    "& .css-fjifyr-MuiAvatar-root": {
      alignSelf: "baseline",
    },
  },
  searchBox: {
  }
});



const successNotification = {
  color: "#219653",
  background: "rgba(39,174,96,0.36)",
  borderRadius: "50%",
  width: "18px",
  display: "grid",
  placeItems: "center",
  height: "18px",
};


function DemoChatMainDummy({
  setShowPicker,
  setText,
  text,
  selectedChannelData,
  userId,
  sendUserMessage,
  setAttachment,
  selectedChannelDetails,
  userStatus,

  setCurrPage,
  currPage,
  wasLastList,
  onlineUser

}) {
  const classes = useStyles();
  const pickerRef = useRef(null);
  const messageRef = useRef(null);
  const messageListRef = useRef(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const [showHideActionModal, setShowHideActionModal] = useState(false);
  const [showChatSearch, setShowChatSearch] = useState(false);


  const receiverIds = searchParams.get("sender_id");


  checkMouseDown({ pickerRef, setShowPicker });

  const imageTypes = [".png", ".jpg", ".jpeg", ".webp"];



  /**
  *
  * redux dispatch
  *
  */
  const dispatch = useDispatch();




  useEffect(() => {
    scrollToBottom();
  }, []);
  const scrollToBottom = () => {
    window.scrollTo(0, document.body.scrollHeight);
  };

  function displayMessage(msg, NotEndUser) {
    if (msg.message.endsWith("pdf")) {
      return (
        <PdfButton
          variant=""
          onClick={() => {
            const link = document.createElement("a");
            link.download = `${imageURL}${msg.message}`;
            link.href = `${imageURL}${msg.message}`;
            link.target = "_blank";
            link.click();
          }}
          startIcon={<DownloadIcon />}
        >
          {msg.message.slice(4)}
        </PdfButton>
      );
    } else if (msg.message.endsWith("png") || msg.message.endsWith("jpeg") || msg.message.endsWith("svg")) {
      return (
        <Stack
          direction="row"
          sx={{
            display: "flex",
            justifyContent: NotEndUser ? "flex-end" : "flex-start",
          }}
        >
          <Box
            sx={{
              height: "fit-content",
              width: "fit-content",
              borderRadius: "10px",
              overflow: "hidden"
            }}
            onClick={() => {
              dispatch(
                openModal({
                  modalName: "showChatAttachmentModal",
                  modalProps: {
                    msg
                  },
                })
              );
            }}
          >
            <img
              src={`${imageURL}${msg.message}`}
              style={{ minHeight: "150px", minWidth: "150px", maxWidth: "200px" }}
            />
          </Box>
        </Stack>
      );
    } else if (msg.message.endsWith("json")) {
      return (
        <PdfButton
          startIcon={<DownloadIcon />}
          onClick={() => {
            const link = document.createElement("a");
            link.download = `${imageURL}${msg.message}`;
            link.href = `${imageURL}${msg.message}`;
            link.click();
          }}
        >
          {msg.message}
        </PdfButton>
      );
    } else if (msg.message.endsWith("docx")) {
      return (
        <PdfButton
          startIcon={<DownloadIcon />}
          onClick={() => {
            const link = document.createElement("a");
            link.download = `${imageURL}${msg.message}`;
            link.href = `${imageURL}${msg.message}`;
            link.click();
          }}
        >
          {msg.message}
        </PdfButton>
      );
    } else {
      return (
        <>
          <p>{msg.message}</p>
        </>
      );
    }
  }


  const scrollChatToBottom = () => {
    if (messageListRef.current) {
      messageListRef.current.scrollTop = messageListRef.current.scrollHeight;
    }
  };

  const onChatScroll = () => {
    if (messageListRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = messageListRef.current;

      if (Math.ceil(scrollTop + clientHeight) === Math.ceil(messageListRef.current.offsetHeight)) {
        // if (!wasLastList) {
        setCurrPage(currPage + 1);
        // }
      }
    }
  };
  return (
    <>
      <div className="chatbox">
        <div className="chat_main_box_cont">
          <Stack
            sx={{
              height: "fit-content",
              width: "100%",
              position: "relative",
            }}
            direction="column"
          >
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="flex-start"
              sx={{
                borderBottom: "0.856774px solid rgba(0, 0, 0, 0.17)",
                height: "10%",
                minHeight: "62px",
                borderRadius: "10px 10px 0px 0px",
                padding: "8px 10px"
              }}>

              <Badge
                badgeInset="14%"
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                className={classes.status}
              >
                <Avatar
                  alt={
                    selectedChannelDetails?.sender_type == "REFERAL_PARTNER"
                      ? selectedChannelDetails?.ucc_receiver_id?.first_name.charAt(
                        0,
                        1
                      ) +
                      selectedChannelDetails?.ucc_receiver_id?.last_name.charAt(
                        0,
                        1
                      )
                      : selectedChannelDetails?.ucc_sender_id?.first_name.charAt(
                        0,
                        1
                      ) +
                      selectedChannelDetails?.ucc_sender_id?.last_name.charAt(
                        0,
                        1
                      )
                  }
                  src={
                    selectedChannelDetails?.sender_type == "REFERAL_PARTNER"
                      ? `${imageURL}${selectedChannelDetails?.ucc_receiver_id?.image}`
                      : `${imageURL}${selectedChannelDetails?.ucc_sender_id?.image}`
                  }
                  className={classes.avatar1}
                  sx={{
                    background: "#cde2e7 !important",
                    color: "rgba(0, 0, 0, 0.7) !important",
                  }}
                />
              </Badge>
              <div className="names_link_cont" style={{ paddingLeft: "10px" }}>
                <div className="d-flex flex-column">
                  <span className={classes.name}>
                    {selectedChannelDetails?.sender_type == "REFERAL_PARTNER"
                      ? selectedChannelDetails?.ucc_receiver_id?.first_name + " " + selectedChannelDetails?.ucc_receiver_id?.last_name
                      : selectedChannelDetails?.ucc_sender_id?.first_name + " " + selectedChannelDetails?.ucc_sender_id?.last_name}
                    <span>
                      <Chip
                        label={
                          selectedChannelDetails?.sender_type ==
                            "REFERAL_PARTNER"
                            ? `${selectedChannelDetails?.ucc_receiver_id?.usertype?.replace(
                              "_",
                              " "
                            )}`
                            : `${selectedChannelDetails?.ucc_sender_id?.usertype?.replace(
                              "_",
                              " "
                            )}`
                        }
                        size="small"
                        // className={classes.chip}
                        sx={{
                          backgroundColor: "rgba(77, 208, 225, 0.5) !important",
                          marginLeft: "10px",
                          '& .MuiChip-label': {
                            fontSize: "9px",
                            fontWeight: "600",
                            color: "#006064",
                          },
                        }}
                      />
                    </span>
                  </span>
                  <Stack direction={'row'} gap={0.9} alignItems={'center'}>
                    <Box
                      sx={{
                        ...successNotification,
                        width: "5px",
                        height: "5px",
                        background: !onlineUser
                          ? "rgb(255 0 0 / 35%)"
                          : "rgba(39,174,96,0.36)",
                      }}
                    />
                    <Typography className={classes.msg}>
                      {onlineUser ? "Online" : "Offline"}
                    </Typography>
                  </Stack>
                </div>
              </div>
              <Stack>
                <IconButton
                  size="small"
                  onClick={() => {
                    setShowHideActionModal(!showHideActionModal);
                    setShowChatSearch(true);
                    if (showChatSearch) {
                      searchParams.delete("chat_query");
                      setSearchParams(searchParams);
                    }
                  }}
                >
                  <FindInPageRoundedIcon fontSize="inherit" />
                </IconButton>
              </Stack>

            </Stack>

            {showHideActionModal && (
              <Stack
                sx={{
                  minHeight: "40px",
                  border: "1px solid rgba(0, 0, 0, 0.1)",
                  position: "absolute",
                  top: "63px",
                  width: "100%",
                  boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px",
                  borderRadius: "5px",
                  overflow: "hidden",
                  zIndex: "99",
                  background: "#fff"
                }}
              >

                {/* Show Search Chat Box */}
                {showChatSearch && (
                  <Stack
                    direction="row"
                    alignItems="center"
                    sx={{
                      position: "relative",
                    }}
                  >
                    <Stack
                      sx={{ width: "100%" }}
                    >
                      <ThemeProvider theme={globalTheme}>
                        <TextField
                          placeholder="Search..."
                          className={classes.searchBox}
                          onChange={(e) => {
                            searchParams.set("chat_query", e.target.value);
                            setSearchParams(searchParams);
                          }}
                          autoFocus
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <SearchIcon />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </ThemeProvider>
                    </Stack>
                    <Stack
                      direction="row"
                      sx={{
                        width: "fit-content",
                        position: "absolute",
                        right: "0"
                      }}
                    >
                      <IconButton
                        size="small"
                        onClick={() => {
                          setShowHideActionModal(!showHideActionModal);
                          searchParams.delete("chat_query");
                          setSearchParams(searchParams);
                          setShowChatSearch(false);
                        }}
                      >
                        <DoneIcon fontSize="inherit" />
                      </IconButton>
                      <IconButton
                        size="small"
                        onClick={() => {
                          setShowHideActionModal(!showHideActionModal);
                          searchParams.delete("chat_query");
                          setSearchParams(searchParams);
                          setShowChatSearch(false);
                        }}
                      >
                        <HighlightOffIcon fontSize="inherit" />
                      </IconButton>
                    </Stack>
                  </Stack>
                )}
              </Stack>
            )}

          </Stack>
          <Stack sx={{ maxHeight: "calc(85vh - 205px)", height: "calc(85vh - 205px)", overflow: "hidden", }}>
            <Box
              onScroll={onChatScroll}
              ref={messageListRef}
              sx={{
                height: "100%",
                overflow: "scroll",
                "&::-webkit-scrollbar": {
                  display: "none",
                },
                position: "relative",
                padding: "10px",
              }}
            >
              <div className="msg-body" style={{ position: "visible" }}>
                <ul
                  style={{
                    position: "relative",
                  }}
                >
                  {receiverIds ? (
                    <>
                      {selectedChannelData?.length > 0 ? (
                        selectedChannelData?.map((each, index) => (
                          <>
                            {convertintoStringDates(selectedChannelData[index - 1]) !== convertintoStringDates(each) ? (
                              <Divider>
                                <Chip
                                  label={
                                    moment(each?.createdAt).calendar({
                                      sameDay: "[Today]",
                                      nextDay: "[Tomorrow]",
                                      nextWeek: "dddd",
                                      lastDay: "[Yesterday]",
                                      lastWeek: "DD/MM/YYYY",
                                      sameElse: "DD/MM/YYYY",
                                    })
                                  }
                                  size="small"
                                />
                              </Divider>
                            ) : (
                              <></>
                            )}

                            <li
                              className={`${userId === each?.sender_id
                                ? "repaly"
                                : `sender  ${classes.chatReceiver}`
                                }`}
                            >
                              {userId !== each?.sender_id && (
                                <SmallAvatar
                                  src={
                                    selectedChannelDetails?.sender_type ==
                                      "REFERAL_PARTNER"
                                      ? `${imageURL}${selectedChannelDetails?.ucc_receiver_id?.image}`
                                      : `${imageURL}${selectedChannelDetails?.ucc_sender_id?.image}`
                                  }
                                  sx={{
                                    background: "rgba(192, 223, 250, 0.4) !important",
                                    color: "rgba(0, 0, 0, 0.7) !important",
                                    alignSelf: "start",
                                    fontSize: "10px"
                                  }}
                                >
                                  {selectedChannelDetails?.sender_type ==
                                    "REFERAL_PARTNER"
                                    ? selectedChannelDetails?.ucc_receiver_id?.first_name.charAt(
                                      0,
                                      1
                                    )
                                    : selectedChannelDetails?.ucc_sender_id?.first_name.charAt(
                                      0,
                                      1
                                    )}
                                </SmallAvatar>
                              )}
                              <div>
                                <>{displayMessage(each, userId === each?.sender_id)}</>
                                <span className="time">
                                  {moment(each?.createdAt).format("LT")}
                                </span>
                              </div>
                            </li>
                          </>
                        ))
                      ) : (
                        <NoDataFoundComponent />
                      )}
                    </>
                  ) : (
                    <Typography
                      sx={{
                        textAlign: "center",
                        color: "var(--text-color-one)"
                      }}
                    >
                      Something goes wrong...
                    </Typography>
                  )}
                </ul>
              </div>
            </Box>
          </Stack>
          <Stack
            sx={{
              height: "10%",
              minHeight: "100px",
              borderRadius: "0px 0px 10px 10px",
              padding: "10px",
              background: "rgb(243, 245, 246)",
              borderTop: "0.5px solid rgb(235, 235, 235)"
            }}
            spacing={2}
          >
            <Stack
              sx={{
                height: "50px",
                borderRadius: "8px",
                background: "#fff"
              }}
            >
              <input
                type="text"
                className="input_ele chat_input_ele"
                style={{ fontSize: "14px", opacity: "none" }}
                placeholder="Enter your message here..."
                // ref={messageRef}
                value={text}
                onChange={(e) => {
                  e.stopPropagation();
                  setText(e.target.value)
                }}
                onKeyDown={e => {
                  if (e.code === "Enter" && e.target.value !== "") {
                    (async () => {
                      sendUserMessage();
                      scrollChatToBottom();
                    })();
                  }
                }}
              />
            </Stack>
            <Stack
              direction={"row"}
              justifyContent="space-between"
              alignItems="center"
              spacing={2}
            >
              <Stack
                direction={"row"}
                justifyContent="center"
                alignItems="center"
                spacing={1}
              >
                <IconButton
                  size="small"
                >

                  <FaceIcon fontSize="inherit"
                    onClick={() => {
                      dispatch(
                        openModal({
                          modalName: "emojiPickerModal",
                          modalProps: {
                            setText: (event) => {
                              setText(prevInput => prevInput + event.emoji);
                            }
                          }
                        })
                      );
                    }}
                  />
                </IconButton>
                <IconButton
                  style={{ marginRight: "4%" }}
                  onClick={async () => {
                    const input = document.createElement("input");
                    input.type = "file";
                    // input.multiple = true;
                    input.accept =
                      ".jpeg, .jpg, .png, .gif, .pdf, .docx, .json";
                    input.click();
                    let url = [];
                    input.addEventListener("change", async () => {
                      let urlList = [];
                      const files = [...input.files];
                      files?.forEach(async item => {
                        const image = await getPreSignedURLForMultipleUpload(
                          item
                        );
                        url.push(image);
                        setAttachment(image);
                        sendUserMessage(image);
                      });
                      // sendUserMessage();
                    });
                  }}
                  size="small"
                >
                  <AttachFileIcon fontSize="inherit" />
                </IconButton>
              </Stack>
              <Stack
                direction={"row"}
                justifyContent="center"
                alignItems="center"
                spacing={2}
              >
                <Box
                  onClick={() => { sendUserMessage(); scrollChatToBottom(); }}
                  sx={{
                    cursor: "pointer"
                  }}
                >
                  <SendIcon
                    fontSize="small"
                  />
                </Box>
              </Stack>
            </Stack>
          </Stack>
        </div>
      </div>
    </>
  );
}

export default DemoChatMainDummy;
