import React, { useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { imageURL } from "../../../Constants/commonURLS";
import SearchIcon from "@mui/icons-material/Search";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import Select, { selectClasses } from "@mui/joy/Select";
import Option from "@mui/joy/Option";
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import Pagination from "@mui/material/Pagination";
import { Typography } from '@mui/material';
// import CustomNoRowsOverlay from "../../../ComingSoon/NoRowsFound";



const useStyles = makeStyles({
  root: {
    borderColor: "rgba(0, 0, 0, 0.3)",
    border: "0.825815px solid rgba(14, 55, 75, 0.22)",
    borderRadius: "4px !important",
    fontFamily: "Inter , sans-serif !important",
    color: "rgba(0, 0, 0, 0.5)",
    "& input::placeholder": {
      fontFamily: "Inter , sans-serif !important",
      fontWeight: 500,
      fontSize: 13.213,
      fontStyle: "normal",
      color: "rgba(0, 0, 0, 0.5)",
    },
    "& input": {
      padding: "12px 14px",
      height: 20,
      borderColor: " rgba(14, 55, 75, 0.22)",
      borderRadius: "4px",
    },
  },
  headerCellStyles: {
    fontWeight: "700",
    fontFamily: "Inter,sans-serif",
    // background:"rgba(8, 23, 83, 0.2)",
    background:"#cde2e7",
    color:"#066a4c",
    fontSize: "13.213px",
    lineHeight: "20px",
    // color: "rgba(0, 0, 0, 0.5)",
    "& .css-t89xny-MuiDataGrid-columnHeaderTitle": {
      fontWeight: "700",
      fontFamily: "Inter,sans-serif",
      fontSize: "13.213px",
      lineHeight: "20px",
      color: "rgba(0, 0, 0, 0.5)",
    },
  },
  menuItem: {
    fontSize: "16px",
    fontWeight: "bold",
  },
  menuPaper: {
    background: "red",
  },
  menuList: {
    color: "black",
  },

  ".MuiMenu-paper.MuiPopover-paper .MuiList-root .MuiMenuItem-root:hover": {
    backgroundColor: "blue !important",
  },
});

function DashBoardPage2() {
  const [rows, setRows] = useState();
  const classes = useStyles();


  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(5);


  const styleObj = {
    fontFamily: "Inter,sans-serif",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "11.5614px",
    color: "rgba(0, 0, 0, 0.8)",
  };

  const rowsData = [].map((each, index) => {
    return {
      id: index,
      each: each,
    };
  });
  

  const columns = [
    {
      field: "companyName",
      headerClassName: classes.headerCellStyles,
    //   headerClassName: "column_styles",
      style: { color: "red" },
      headerName: "Company Name",
      minWidth: 300,
      flex:1,
      //   renderCell: ({ value }) => <span style={styleObj}>{value}</span>,
    },
    {
      field: "Type",
      headerClassName: classes.headerCellStyles,
      headerName: "Type",
      minWidth: 300,
      flex:1
      //   renderCell: ({ value }) => <span style={styleObj}>{value}</span>,
    },
    {
      field: "Designation",
      headerClassName: classes.headerCellStyles,
      headerName: "Designation",
      minWidth: 300,
      flex:1
      //   renderCell: ({ value }) => <span style={styleObj}>{value}</span>,
    },

    {
      field: "InterviewerName",
      headerClassName: classes.headerCellStyles,
      headerName: "Interviewer Name",
      minWidth: 300,
      flex:1
      //   renderCell: ({ value }) => <span style={styleObj}>{value}</span>,
    },
    {
      field: "MeetLink",
      headerClassName: classes.headerCellStyles,
      headerName: "MeetLink",
      minWidth: 300,
      flex:1
      //   renderCell: ({ value }) => <span style={styleObj}>{value}</span>,
    },
  ];

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  
  };


  return (
    <>
      <div className="dashpage2_main_sec">
        <h2 className="interview_text">Upcomming Interviews </h2>

        <div className="dashboard_table">
          <div style={{ height: "40vh", width: "100%" }}>
            <DataGrid
              rows={rowsData}
              columns={columns}
            //   headerClasses={{ root: classes.headerCellStyles }}
              disableColumnSelector
              disableDensitySelector
              disableRowSelectionOnClick
              // slots={{
              //   noRowsOverlay: <CustomNoRowsOverlay />,
              // }}
              
              // disableColumnMenu

                // components={{
                //   Pagination: ({ state, api }) => (
                //     <Pagination
                //       {...state}
                //       onChangePage={(event, newPage) => api.setPage(newPage)}
                //       onChangeRowsPerPage={() => {}}
                //       style={{ display: 'none' }} // Hide the rows per page selection
                //     />
                //   ),
                // }}
                components={{
                  Pagination: ({ state, api }) => (
                    <Pagination
                      {...state}
                      onChangePage={(event, newPage) => api.setPage(newPage)}
                      onChangeRowsPerPage={() => {}}
                      style={{ display: 'none' }} // Hide the rows per page selection
                    />
                  ),
                }}
              style={{ border: "0px" }}
              pagination
              pageSize={rowsPerPage}
              page={page}
            />
          </div>

          {/* <Pagination
            // count={10}
            color="primary"
            count={Math.ceil(rowsData.length / rowsPerPage)}
            page={page}
            rowsPerPage={rowsPerPage}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={event => {
              setRowsPerPage(parseInt(event.target.value, 10));
              setPage(1);
            }}
          /> */}
        </div>
      </div>
    </>
  );
}

export default DashBoardPage2;
