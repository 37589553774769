import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { imageURL } from "../../../Constants/commonURLS";
import Box from "@mui/material/Box";
import Pagination from "@mui/material/Pagination";
import { useNavigate } from "react-router-dom";
import { styleObj } from "../../../assets/MUI/useStyles";
import { DataGridStyles } from "../../../assets/MUI/useStyles";
import { globalTheme } from "../../../assets/MUI/globalStyles";
import { ThemeProvider } from "@mui/material/styles";
import GlobalSearch from "../../../hooks/GlobalSearch";
import { formatdate } from "../../../helpers/common/FormateDate";


function MyNewReferals({
  referData,
  page,
  totalCount,
  setPage,
  loading,
  pageSize
}) {
  const classes = DataGridStyles();
  const navigate = useNavigate();

  const rowsData = referData?.map((item, index) => {
    return {
      id: `${item?.id}`,
      userId: `${item?.user_id}`,
      jobPostId: `${item?.job_post_id}`,
      applicantName: `${item?.job_applicant?.first_name} ${item?.job_applicant?.last_name}`,
      Phone: `${item?.job_applicant?.phone_number ?? "-" }`,
      referedName: `${item?.referral_user?.first_name} ${item?.referral_user?.last_name}`,
      referPhone: `${item?.referral_user?.phone_number ?? "-"}`,
      Email: `${item.job_applicant?.email ?? "-"}`,
      Image: `${imageURL}${item.company_details?.company?.company_image}`,
      companyName: `${item?.company_details?.company?.company_name ?? "-"}`,
      jobTitle: `${item?.job_title ?? "-"}`,
      Date: formatdate(`${item?.createdAt}`),
      ctc: `${item?.company_details?.max_sal ?? "N/A"}`,
      Status: `${item?.job_post_pipeline_stage?.stage_name ?? "-"}`,
    };
  });

  const columns = [
    {
      field: "Image",
      headerName: "Image",
      headerClassName: classes.headerCellStyles,
      minWidth: 100,
      flex: 1,
      renderCell: params => (
        <img
          src={params.value}
          alt={params.value}
          style={{ width: 30, borderRadius: "50%" }}
        />
      ),
    },
    {
      field: "applicantName",
      headerClassName: classes.headerCellStyles,
      headerName: "Applicant Name",
      minWidth: 150,
      flex: 1,
      renderCell: ({ value }) => <span style={styleObj}>{value}</span>,
    },
    {
      field: "companyName",
      headerClassName: classes.headerCellStyles,
      style: { color: "red" },
      headerName: "Company Name",
      minWidth: 150,
      flex: 1,
      renderCell: ({ value }) => (
          <span style={styleObj}>{value}</span>
      ),
    },
    {
      field: "jobTitle",
      headerClassName: classes.headerCellStyles,
      headerName: "Job Title",
      minWidth: 200,
      flex: 1,
      renderCell: ({ value }) => {
        return (
            <span style={styleObj}>{value}</span>
        );
      },
    },
    {
      field: "Phone",
      headerClassName: classes.headerCellStyles,
      headerName: "Applicant Number",
      minWidth: 150,
      flex: 1,
      renderCell: ({ value }) => <span style={styleObj}>{value}</span>,
    },
    {
      field: "Email",
      headerClassName: classes.headerCellStyles,
      headerName: "Email",
      minWidth: 200,
      flex: 1,
      renderCell: ({ value }) => <span style={styleObj}>{value}</span>,
    },

    {
      field: "referedName",
      headerClassName: classes.headerCellStyles,
      headerName: "Referred Name",
      minWidth: 150,
      flex: 1,
      renderCell: ({ value }) => <span style={styleObj}>{value}</span>,
    },
    {
      field: "referPhone",
      headerClassName: classes.headerCellStyles,
      headerName: "Referred Number",
      minWidth: 150,
      flex: 1,
      renderCell: ({ value }) => <span style={styleObj}>{value}</span>,
    },
    

 
    {
      field: "Date",
      headerClassName: classes.headerCellStyles,
      headerName: "Date",
      minWidth: 150,
      flex: 1,
      renderCell: ({ value }) => (
        <span style={styleObj}>{value}</span>
      ),
    },

    {
      field: "ctc",
      headerClassName: classes.headerCellStyles,
      headerName: "CTC",
      minWidth: 100,
      flex: 1,
      renderCell: ({ value }) => <span style={styleObj}>{value}</span>,
    },
    {
      field: "Status",
      headerClassName: classes.headerCellStyles,
      headerName: "Status",
      minWidth: 230,
      flex: 1,
      renderCell: ({ value }) => (
        <div
          style={{
            display: "inline-block",
            padding: "5px 10px",
            borderRadius: "8px",
            backgroundColor:
              value === "Rejected"
                ? "#ffe9e9"
                : value === "Applied"
                  ? "rgba(8, 23, 83, 0.2)"
                  : value === "On boarded"
                    ? "#d7f6dd"
                    : value === "Technical Evalutation(External)"
                      ? "#ffc6f6"
                      : value === "Screened"
                        ? "#fff8c6"
                        : "rgba(8, 23, 83, 0.2)",
            color:
              value === "Rejected"
                ? "#a10e25"
                : value === "Applied"
                  ? "#081753"
                  : value === "On boarded"
                    ? "#0f5d27"
                    : value === "Technical Evalutation(External)"
                      ? "#44004e"
                      : "",
            fontWeight: 500,
            fontSize: "12px",
            fontFamily: "Inter,sans-serif",
            textTransform: "capitalize",
          }}
        >
          {value}
        </div>
      ),
    },
  ];


  return (
    <ThemeProvider theme={globalTheme}>
      <div>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          style={{ paddingBottom: "20px" }}
          width="80%"
        >
          <GlobalSearch />
        </Box>
        <div style={{ height: "65vh", width: "98%" }}>
          <DataGrid
            loading={loading}
            rows={rowsData}
            columns={columns}
            disableColumnSelector
            disableDensitySelector
            disableRowSelectionOnClick
            onRowClick={(params, event, details) => {
              navigate(`../job?id=${params?.row?.jobPostId}&userId=${params?.row?.userId}`);
            }}
            slots={{
              // loadIcon: LoaderIcon,
              // baseButton: PrimaryButton,
              // basePopper: TablePopper,
              // baseSwitch: PrimarySwitch,
              // toolbar: GridToolbar,
              // noRowsOverlay: CustomNoRowsOverlay,
              pagination: props => (
                <Pagination
                  {...props}
                  // color="primary"
                  // count={Math.ceil(rowsData.length / rowsPerPage)}
                  count={Number(totalCount)}
                  page={Number(page)}
                  rowsPerPage={Number(pageSize)}
                  onChange={(_, newPage) => setPage(newPage)}
                  showFirstButton
                  showLastButton
                  sx={{
                    "& .MuiPaginationItem-root": {
                      "&:hover": {
                        backgroundColor: "#cde2e7",
                        color: "#066a4c",
                      },
                      "&.Mui-selected": {
                        backgroundColor: "#024430",
                        color: "#ffe7bb",
                        "&:hover": {
                          backgroundColor: "#cde2e7",
                          color: "#066a4c",
                        },
                      },
                    },
                  }}
                />
              ),
            }}
          />
        </div>
      </div>
    </ThemeProvider>
  );
}

export default MyNewReferals;
