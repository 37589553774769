import React, { useEffect, useState, useRef } from "react";
import {
  OutlinedInput,
  InputAdornment,
  FormControl,
  IconButton,
} from "@material-ui/core";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
// import Avatar from '@mui/material/Avatar';
import Typography from "@mui/material/Typography";
import ListItemButton from "@mui/material/ListItemButton";
import TextField from "@mui/material/TextField";
import SearchIcon from "@mui/icons-material/Search";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import FilterListIcon from "@mui/icons-material/FilterList";
import { makeStyles } from "@mui/styles";
import Box from "@mui/material/Box";
import { Stack, CircularProgress } from "@mui/material";
import Badge from "@mui/material/Badge";
import Avatar from "@mui/material/Avatar";
import Chip from "@mui/material/Chip";
import "../../assets/css/chat/chat.css";
import startChat from "../../assets/images/start_chat.svg";
// import startChat from '../../assets/images/startChat.png';
import { formatDistance } from "date-fns";
import { findChatBasedOnId } from "../../helpers/dataFetcher/chats/chats";
import { get_chat_active_user_list } from "../../helpers/sockets";
import { imageURL } from "../../Constants/commonURLS";
import { blue } from "@mui/material/colors";
import { useNavigate, useSearchParams } from "react-router-dom";
import { globalTheme } from "../../assets/MUI/globalStyles";
import { ThemeProvider } from "@mui/material/styles";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import { StyledInputBase } from "../../assets/MUI/globalStyles";
import { SearchContainer } from "../../assets/MUI/globalStyles";
import { SearchIconWrapper } from "../../assets/MUI/globalStyles";
import MailIcon from "@mui/icons-material/Mail";
import SocketConnection from "../../hooks/socket";
import GlobalSearch from "../../hooks/GlobalSearch";

const useStyles = makeStyles({
  root: {
    height: 48,
    width: "100% ",
    background: "#ffffff",
    border: "1px solid rgba(0, 0, 0, 0.1) !important",
    borderRadius: "12px !important",
    transitionDelay: "0.5ms",
    color: "rgba(0, 0, 0, 0.5)",
    fontFamily: "Inter , sans-serif !important",
    "& input::placeholder": {
      fontFamily: "Inter , sans-serif !important",
      fontWeight: 500,
      fontSize: 14,
      fontStyle: "normal",
      color: "rgba(0, 0, 0, 0.5)",
    },
    //   "& input": {
    //       padding: "12px 14px",
    //       borderRadius: 8,
    //     },
    "& .PrivateNotchedOutline-root-1": {
      display: "none !important",
    },
    "&.MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
    "&.PrivateNotchedOutline-root-5": {
      borderWidth: 0,
    },
    "& fieldset": {
      borderWidth: 0,
    },
  },
  fieldSet: {
    width: "88%",
    "&.MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
    "&.PrivateNotchedOutline-root-5": {
      borderWidth: 0,
    },
    "& fieldset": {
      borderWidth: 0,
    },
  },
  avatar: {
    background: "rgba(192, 223, 250, 0.4) !important",
    "& .css-2s90m6-MuiAvatar-root": {
      color: "rgba(0, 0, 0, 0.7)  !important",
      fontFamily: "Inter , sans-serif !important",
      fontSize: "20px !important",
    },
  },
  avatar1: {
    background: "rgba(192, 223, 250, 0.4) !important",
    borderRadius: "36px",
    "&.css-2s90m6-MuiAvatar-root": {
      color: "rgba(0, 0, 0, 0.7)",
      fontFamily: "Inter , sans-serif !important",
      fontSize: "20px !important",
    },
  },

  chip: {
    "&.css-w66kx-MuiChip-root": {
      height: "auto !important",
      marginLeft: "4px",
      color: "#006064",
      backgroundColor: "rgba(77, 208, 225, 0.5) !important",
      fontFamily: "Inter , sans-serif !important",
      fontStyle: "normal",
      fontWeight: "500 !important",
      fontSize: "11px !important",
    },
  },
  chip2: {
    "&.css-w66kx-MuiChip-root": {
      height: "auto !important",
      marginLeft: "4px",
      color: "#B84040",
      backgroundColor: "rgba(204, 51, 51, 0.5) !important",
      fontFamily: "Inter , sans-serif !important",
      fontStyle: "normal",
      fontWeight: "500 !important",
      fontSize: "11px !important",
    },
  },
  chip3: {
    "&.css-w66kx-MuiChip-root": {
      height: "auto !important",
      marginLeft: "4px",
      color: "#827717",
      backgroundColor: "rgba(220, 231, 117, 0.5) !important",
      fontFamily: "Inter , sans-serif !important",
      fontStyle: "normal",
      fontWeight: "500 !important",
      fontSize: "11px !important",
    },
  },
  address: {
    fontFamily: "Inter , sans-serif !important",
    fontStyle: "normal",
    fontWeight: "400 !important",
    fontSize: "12px !important",
    color: "rgba(0, 0, 0, 0.7) !important",
  },
  name: {
    fontFamily: "Inter , sans-serif !important",
    fontStyle: "normal",
    fontWeight: "700 !important",
    fontSize: "14px !important",
    color: "#000000 !important",
    letterSpacing: "-0.005em",
  },
  msg: {
    fontFamily: "Inter , sans-serif !important",
    fontStyle: "normal",
    fontWeight: "500 !important",
    fontSize: "13px !important",
    color: "rgba(0, 0, 0, 0.7) !important",
  },
  listBtn: {
    padding: "9px !important"
  },
  searchBox: {
    height: "40px"
  }
});


function JobsListChat({

  appliedJobs,

  setCurrPage,
  currPage,
  jobLoading
}) {
  const classes = useStyles();

  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const listInnerRef = useRef();

  const handleSendJobId = async data => {
    navigate(`/jobchat?user-job-post-id=${data.user_job_post_id}&job-post-id=${data.job_post_id}`);
  };

  const onScroll = () => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      if (Math.ceil(scrollTop + clientHeight) === Math.ceil(scrollHeight) - 4) {
        setCurrPage(currPage + 1);
      }
    }
  };

  return (
    <>
      <div className="demochat_side_main">
        <div className="demochat_side_inside_cont">
          <div
            className="chats_name_count pt-0 pb-3"
            style={{ background: "" }}
          >
            <div style={{ gap: '16px', alignItems: 'center' }} className="d-flex flex-row pt-2 pl-3">
              <h2 className="chats_name1">Chats</h2>
              <Badge
                badgeContent={appliedJobs ? appliedJobs.length : "0"}
                slotProps={{
                  badge: {
                    style: {
                      backgroundColor: "#066a4c",
                      width: "20px",
                      height: "20px",
                      color: "#ffe7bb",
                      fontSize: "10px",
                      fontWeight: 500,
                    },
                  },
                }}
              />
            </div>

            <div style={{ marginTop: "15px" }}>
              <ThemeProvider theme={globalTheme}>
                <GlobalSearch placeholder={"Search..."} />
              </ThemeProvider>
            </div>
          </div>
          {jobLoading ? (
            <Stack
              sx={{
                height: "100%",
              }}
              justifyContent="center"
              alignItems="center"
            >
              <CircularProgress color="success" />
            </Stack>
          ) : (
            <>
              {appliedJobs?.length > 0 ? (
                <div className="list_scroll"
                  onScroll={onScroll}
                  ref={listInnerRef}
                >
                  {appliedJobs?.map(each => (
                    <List
                      sx={{ width: "100%", bgcolor: "background.paper" }}
                      key={each?.id}
                    >
                      <ListItemButton
                        alignItems="flex-start"
                        onClick={(e) => {
                          e.preventDefault();
                          searchParams.set("job-post-id", each?.job_post_id);
                          setSearchParams(searchParams);
                        }}
                        style={{
                          backgroundColor:
                            searchParams.get("job-post-id") === each?.job_post_id
                              ? "#cde2e7"
                              : "#FFFFFF",
                          width: "100%",
                          padding: "10px !important",
                        }}
                        className={classes.listBtn}
                      >
                        <ListItemAvatar>
                          <Avatar
                            loading="lazy"
                            alt={`${each?.job_post_in_user_job_post?.company?.company_name.charAt(
                              2
                            )}`}
                            src={`${imageURL}${each?.job_post_in_user_job_post?.company?.company_image}`}
                            className={classes.avatar1}
                            sx={{ background: "#cde2e7 !important", color: "rgba(0, 0, 0, 0.7) !important" }}
                          />
                        </ListItemAvatar>
                        <div className="names_link_cont">
                          <div className="d-flex flex-column">
                            <Typography className={classes.name} noWrap>
                              {each?.job_post_in_user_job_post?.job_title}
                            </Typography>
                            <Typography className={classes.msg} noWrap>
                              {
                                each?.job_post_in_user_job_post?.company
                                  ?.company_name
                              }
                            </Typography>
                          </div>

                          <IconButton
                            onClick={(e) => {
                              e.stopPropagation();
                              handleSendJobId({
                                user_job_post_id: each.id,
                                job_post_id: each.job_post_id,
                              })
                            }}
                          >
                            <Badge
                              badgeContent={`${each?.chatChannelsCount
                                ? each.chatChannelsCount
                                : "0"
                                }`}
                              slotProps={{
                                badge: {
                                  style: {
                                    backgroundColor: "#066a4c",
                                    width: "20px",
                                    height: "20px",
                                    color: "#ffe7bb",
                                    fontSize: "10px",
                                    fontWeight: 500,
                                  },
                                },
                              }}
                            >
                              <ChatBubbleOutlineIcon
                                style={{ height: "20px", width: "20px" }}
                              />
                            </Badge>
                          </IconButton>
                        </div>
                      </ListItemButton>

                      <Divider
                        variant="inset"
                        component="li"
                        sx={{ marginLeft: "68px" }}
                      />
                    </List>
                  ))}
                </div>
              ) : (
                <div className="contact_no_show">
                  <img src={startChat} />
                  <p>
                    You haven't started a chat please head over to contacts and
                    start chat.
                    {appliedJobs.length == 0 ? "No search found" : ""}
                  </p>
                </div>
              )}
            </>
          )}

        </div>
      </div>
    </>
  );
}

export default JobsListChat;
