import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import {
  BlackTypography,
  MultipleSelectInputs,
  PrimaryAutoComplete,
  PrimaryButton,
  PrimaryNormalFormHelperText,
  PrimaryNormalInputLabel,
  PrimaryNormalInputs,
  SecondaryFilledButton,
  SecondaryFilledChip,
  autocompleteProps,
} from "../../../assets/MUI/materialUiStyles";
import { Controller, useForm } from "react-hook-form";
import {
  Checkbox,
  FormControl,
  Grid,
  Stack,
  createFilterOptions,
} from "@mui/material";
import {
  LocalizationProvider,
  MobileDatePicker,
  MobileDateTimePicker,
} from "@mui/x-date-pickers";
import {v4 as uuid} from "uuid"
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: "8px",
  boxShadow: 24,
  p: 4,
};
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import ReactSelect from "react-select";
// import {
//   createKeySkills,
//   getAllKeySkillsWithStatus,
// } from "helpers/services/jobAttributes/keyskills";
// import SearchParamHook from "hooks/searchParams";
// import { updateUserJobPost } from "helpers/userJobPost/updateUserJobPost";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import { getAllKeySkillsWithStatus,createKeySkills } from "../../../helpers/dataFetcher/profile/profile";
import { updateUserProfileDetails } from "../../../helpers/dataFetcher/profile/profile";

export default function AddEditKeySkills({
  currentData,
  open,
  toggleModel,
  onSubmit,
  wholeDetails
}) {
  const {
    register,
    handleSubmit,
    watch,
    getValues,
    setValue,
    reset,
    control,
    formState: { errors },
  } = useForm();
  const [allKeySkills, setAllKeySkills] = React.useState([]);
  const [userJobPostId, setUserJobPostId] = React.useState(null);
//   const searchParams = SearchParamHook();
  const filter = createFilterOptions();

//   React.useEffect(() => {
//     let queryParamsForUserJobPostId = searchParams.get("user_job_post_id");
//     if (queryParamsForUserJobPostId)
//       setUserJobPostId(queryParamsForUserJobPostId);
//   }, [searchParams]);
  React.useEffect(() => {
    getAllKeySkills();
  }, []);
  async function getAllKeySkills() {
    const { data } = await getAllKeySkillsWithStatus();
    const result = data?.data_payload?.map(item => ({
      value: item.id,
      name: item.name,
      isActive:item.is_active
    }));
    setAllKeySkills(result);
  }
  React.useEffect(() => {
    if (currentData) {
      setValue("key_skills", currentData);
    }
  }, [currentData]);
  const [keySkills, setKeySkills] = React.useState([]);
  return (
    <div>
      <Modal
        open={open}
        onClose={() => {
          toggleModel();
          reset();
        }}
        aria-labelledby="edit-profile-modal-title"
        aria-describedby="edit-profile-modal-description"
      >
        <Box sx={style}>
          <BlackTypography variant="h2" sx={{ marginBottom: "10px" }}>
            Edit Key Skills{" "}
          </BlackTypography>
          <form
            onSubmit={handleSubmit(async data => {
              // await updateUserProfileDetails(userJobPostId, {
              //   key_skills: data?.key_skills?.map(item => item.value),
              // });

              /*start here */
              const obj = {
                last_name: wholeDetails?.last_name,
                first_name: wholeDetails?.first_name,
                email: wholeDetails?.email,
                skills: data?.key_skills?.map(item => item.value),
                update_type_to_send_email: "email",
              };
              let o = Object.fromEntries(
                Object.entries(obj).filter(([_, v]) => v != null)
              );
          
             await updateUserProfileDetails("about_me", o);
              /*End Here */

              toggleModel();
              onSubmit(data?.key_skills);
            })}
            // onSubmit={async e => {
            //   e.preventDefault();
            //   toggleModel();
            //   await updateUserJobPost(userJobPostId, {
            //     key_skills: keySkills?.map(item => item.value),
            //   });

            //   onSubmit(keySkills);
            // }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                {/* <ReactSelect
                  isMulti={true}
                  options={allKeySkills}
                  onChange={data => {
                    setKeySkills(data);
                  }}
                  value={keySkills}
                /> */}
                <Controller
                  render={props => {
                    return (
                      <PrimaryAutoComplete
                        sx={{ width: "100%" }}
                        {...props}
                        disableCloseOnSelect
                        componentsProps={autocompleteProps}
                        multiple={true}
                        options={allKeySkills}
                        isOptionEqualToValue={(option,value)=>option.value===value.value}
                        renderTags={(tagValue, getTagProps) =>
                          tagValue.map((option, index) => (
                            <SecondaryFilledChip
                              deleteIcon={<FeatherIcon icon={"x"} size="14" />}
                              label={option.name}
                              {...getTagProps({ index })}
                            />
                          ))
                        }
                        renderOption={(props, option, { selected }) => (
                          option.isActive ?
                          <li key={option.id} {...props}>
                            <Checkbox
                              icon={<FeatherIcon icon="square" size="14" />}
                              checkedIcon={
                                <FeatherIcon icon="check-square" size="14" />
                              }
                              style={{ marginRight: 8 }}
                              checked={selected}
                            />
                            {option.name}
                          </li> : null
                        )}
                        getOptionLabel={option => option?.name}
                        renderInput={params => (
                          <FormControl
                            variant="standard"
                            sx={{ width: "100%" }}
                          >
                            <PrimaryNormalInputLabel
                              shrink
                              htmlFor="key-skills-outlined"
                              error={errors.key_skills}
                            >
                              Required Skills (select all that applies)
                            </PrimaryNormalInputLabel>
                            <MultipleSelectInputs
                              sx={{
                                ...(errors.key_skills && {
                                  borderColor: "red",
                                }),
                              }}
                              variant="standard"
                              ref={params.InputProps.ref}
                              {...params}
                            />
                          </FormControl>
                        )}
                        filterOptions={(options, params) => {
                          const filtered = filter(options, params);
                          const { inputValue } = params;
                          // // Suggest the creation of a new value
                          const isExisting = options.some(
                            option => inputValue === option.name
                          );
                          if (inputValue !== "" && !isExisting) {
                            filtered.push({
                              inputValue,
                              isActive:true,
                              name: `${inputValue}`,
                              id:uuid()
                            });
                          }

                          return filtered;
                        }}
                        value={getValues("key_skills")}
                        onChange={async (_, data) => {
                          const lastIndex = data?.length - 1  ;
                          if (data && data[lastIndex].inputValue) {
                            const { data: skill } = await createKeySkills({
                              name: data[lastIndex].inputValue,
                              is_active:true,
                            });
                            await getAllKeySkills();
                            props.field.onChange([
                              ...getValues("key_skills"),
                              {
                                name: skill.key_skill_res.name,
                                value: skill.key_skill_res.id,
                              },
                            ]);
                          } else {
                            props.field.onChange(data);
                          }
                        }}
                      />
                    );
                  }}
                  name="key_skills"
                  control={control}
                  rules={{
                    required:!true
                  }}
                />
              </Grid>
              <Grid item xs={7}></Grid>
              <Grid item xs={5}>
                <Stack spacing={1} direction="row">
                  <SecondaryFilledButton
                    onClick={() => {
                      toggleModel();
                    }}
                  >
                    cancel
                  </SecondaryFilledButton>
                  <PrimaryButton type="submit">Submit</PrimaryButton>
                </Stack>{" "}
              </Grid>
            </Grid>
          </form>
        </Box>
      </Modal>
    </div>
  );
}
