import React from 'react';
import { Grid, Stack, Typography, Box, Button, Chip } from "@mui/material";
import LayerIcon from '../../../assets/icons/About/LayerIcon';
import StormLightIcon from '../../../assets/icons/About/StormLightIcon';

function MissionSection() {

    return (
        <Grid container sx={{
            paddingTop: "40px", paddingBottom: "50px",
            "@media (max-width: 600px)": {
                paddingTop: "20px",
                paddingBottom: "30px",
            },
        }}>
            <Grid item xs={12} sm={12}
                sx={{
                    paddingTop: "20px",
                }}
            >
                <Box>
                    <Stack
                        direction="column"
                        sx={{
                            gap: "10px"
                        }}
                    >
                        <Typography
                            sx={{
                                color: "var(--text-color-one)",
                            }}
                        >Mission + Values</Typography>
                        <Typography
                            sx={{
                                fontSize: "40px",
                                fontWeight: "500",
                                "@media (max-width: 1600px)": {
                                    fontSize: "30px",
                                },
                                "@media (max-width: 1200px)": {
                                    fontSize: "25px",
                                },
                                "@media (max-width: 900px)": {
                                    fontSize: "20px",
                                },
                                "@media (max-width: 600px)": {
                                    fontSize: "18px",
                                },
                            }}
                        >
                            <span style={{ color: "var(--text-color)" }}>joinee is on a mission to make it easy for companies in a recruitment process easy-peasy.</span>
                            <span style={{ color: "#BABABA" }}>We know first-hand the challenges of selling digital goods globally and are here to shake that up for the better.</span>
                        </Typography>
                    </Stack>
                    <Box
                        sx={{
                            display: "grid",
                            gridTemplateColumns: "repeat(1, 1fr)",
                            gap: "20px",
                            marginTop: "20px",
                        }}
                    >
                        {[
                            { id: "1", icon: LayerIcon, title: "Our Mission", text: "Tortor pulvinar vestibulum eget aliquet penatibus interdum pellentesque. Diam ultrices in risus ac nunc imperdiet ultricies. Viverra est nunc condimentum aliquam pharetra ac. Aenean sit hendrerit aliquam sapien proin. Nam duis porta a vel commodo proin posuere. Curabitur cursus aenean sodales.", },
                            { id: "2", icon: StormLightIcon, title: "Our Values", text: "Tortor pulvinar vestibulum eget aliquet penatibus interdum pellentesque. Diam ultrices in risus ac nunc imperdiet ultricies. Viverra est nunc condimentum aliquam pharetra ac. Aenean sit hendrerit aliquam sapien proin. Nam duis porta a vel commodo proin posuere. Curabitur cursus aenean sodales.", },
                        ]?.map((item, index) => (
                            <Stack
                                key={index}
                                sx={{
                                    boxShadow: item?.id == "1" ? "" : "0px 4px 11px -1px #0A0A0A0A",
                                    background: item?.id == "1" ? "" : "#fff",
                                    minHeight: "150px",
                                    gap: "10px"
                                }}
                                p={4}
                                direction="column"

                            >
                                <Stack
                                    sx={{
                                        height: "50px",
                                        width: "50px",
                                        borderRadius: "50%",
                                        background: item?.id == "2" ? "var(--app-button-color1)" : "var(--app-color-one)",

                                    }}
                                    justifyContent="center"
                                    alignItems="center"
                                >
                                    {item?.icon()}
                                </Stack>
                                <Typography
                                    sx={{
                                        fontSize: "20px",
                                        fontWeight: "600",
                                        "@media (max-width: 900px)": {
                                            fontSize: "18px",
                                        },
                                        "@media (max-width: 600px)": {
                                            fontSize: "15px",
                                        },
                                    }}
                                >{item?.title}</Typography>
                                <Typography
                                    sx={{
                                        color: "var(--text-color-one)",
                                        fontSize: "15px",
                                        "@media (max-width: 600px)": {
                                            fontSize: "12px",
                                        },
                                    }}
                                >{item?.text}</Typography>
                            </Stack>
                        ))}
                    </Box>
                </Box>
            </Grid>
        </Grid>
    )
}

export default MissionSection;