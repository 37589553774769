import { Box, Stack, IconButton, CircularProgress } from "@mui/material";
import { Link } from "react-router-dom";
import React from "react";
import MyModal from "./components/Modal";
import { useState } from "react";

export default function RequestDemoOneModal({
  open,
  handleClose,
  edit,
  setOpen
}) {


  const [loading, setLoading] = useState(true);

  const handleIframeLoad = () => {
    setLoading(false);
  };

  return (
    <MyModal
      open={open}
      setOpen={setOpen}
      handleClose={handleClose}
      // showCloseIcon
      size="medium"
      sx={{
        width: "60vw",
        height: "600px",
        padding: "0px",
        position: "relative"
      }}
    >
      {/* <Box
        sx={{
          position: "absolute",
          zIndex: "999",
          marginTop: "-50px",
          marginRight: "-50px",
          borderRadius: "50%",
          height: "fit-content",
          width: "fit-content"
        }}
      >
        <IconButton aria-label="delete" size="small"
          onClick={() => {
            handleClose();
          }}
        >
          <CloseIcon fontSize="inherit" />
        </IconButton>
      </Box> */}
      {loading && (
        <Stack
          sx={{
            height: "100%",
          }}
          justifyContent="center"
          alignItems="center"
        >
          <CircularProgress color="success"/>
        </Stack>
      )}
      <Stack
        sx={{
          borderRadius: "5px",
          height: '100%',
        }}
      >
        <iframe width={"100%"} height={"100%"}
          style={{
            borderRadius: "5px",
            display: loading ? 'none' : 'block'
          }}
          onLoad={handleIframeLoad}
          src="https://www.youtube.com/embed/tgbNymZ7vqY">
        </iframe>
      </Stack>
    </MyModal>
  );
}
