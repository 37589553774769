import { io } from "socket.io-client";

// const URL = process.env.SOCKET_URL

const URL = "https://socket.joinee.com"


export const socket = io(URL, {
  autoConnect: true,
  transports: ["websocket"],
  // extraHeaders: {
  //   "ngrok-skip-browser-warning": true,
  // },
});
    