import React, { useState, useReducer } from "react";
import "./register.css";
import { signup } from "../helpers/dataFetcher/profile/profile";
import signUpImage from "../assets/images/signUp.png";
import loginPage from "../assets/images/loginPage.png";
// import Button from "@mui/joy/Button";
import Button from "@mui/material/Button";
import { Col, Form, FormFeedback, FormGroup, Label, Row } from "reactstrap";
import OtpVerificationModal from "./OtpVerificationModal";
import { MuiTelInput } from "mui-tel-input";
import { Input, OutlinedInput } from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import styled from "@mui/material/styles/styled";
import Popover from "@mui/material/Popover";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";
import { globalTheme } from "../assets/MUI/globalStyles";
import { ThemeProvider } from "@mui/material/styles";

import { Box, Grid, Stack, Typography, FormHelperText } from "@mui/material";
import MuiCustomTelInput from "../components/formElements/PhoneNumber/PhoneNumber";

/* Mui styles and design start for tabs API and Pop over API Start */
const useStyles = makeStyles({
  root: {
    height: 48,
    width: "100% ",
    borderColor: "rgba(0, 0, 0, 0.3)",
    borderRadius: "8px !important",
    fontFamily: "Inter , sans-serif !important",
    color: "rgba(0, 0, 0, 0.5)",
    "& input::placeholder": {
      fontFamily: "Inter , sans-serif !important",
      fontWeight: 600,
      fontSize: 16,
      fontStyle: "normal",
      color: "rgba(0, 0, 0, 0.5)",
    },
    "& input": {
      padding: "12px 14px",
    },
  },
});

const StyledTab = styled(Tab)(({ theme }) => ({
  fontSize: 16,
  textTransform: "none",
  "&:first-letter": {
    textTransform: "capitalize",
  },
}));

const StyledPopover = styled(Popover)({
  "& .MuiPaper-root.MuiPopover-paper": {
    backgroundColor: "transparent",
    boxShadow: "none",
  },
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

/* Mui styles and design start for tabs API and Pop over API End */

function SignUpForm() {
  const classes = useStyles();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [selectedButton, setSelectedButton] = useState(null);

  const [showPassword, setShowPassword] = React.useState(false);
  const [value, setValue] = React.useState(0);
  const [phone, setPhone] = React.useState("");
  const [age, setAge] = React.useState("");

  const [userType, setUserType] = useState("US");

  const handleDescChange = event => {
    setAge(event.target.value);
  };

  /* Sign up for candidates functionality OR Integration start */
  const initState = {
    email: "",
    firstName: "",
    lastName: "",
    password: "",
    phoneNumber: "",
    error: "",
    jsonError: "",
    status: 200,
    otpModal: false,
  };

  const {
    register,
    handleSubmit,
    // setValue,
    watch,
    getValues,
    formState: { errors },
  } = useForm();

  const reducer = (state, action) => {
    switch (action.type) {
      case "email":
        return { ...state, email: action.payload };
      case "firstName":
        return { ...state, firstName: action.payload };
      case "lastName":
        return { ...state, lastName: action.payload };
      case "password":
        return { ...state, password: action.payload };
      case "phoneNumber":
        return { ...state, phoneNumber: action.payload };
      case "otpModal":
        return { ...state, otpModal: action.payload };
      case "error":
        return { ...state, error: action.payload };
      case "status":
        return { ...state, status: action.payload };
      case "jsonError":
        return { ...state, jsonError: action.payload };
      default:
        return state;
    }
  };

  const [state, dispatch] = useReducer(reducer, initState);

  const handleLogin = async e => {
    e.preventDefault();
    const { status, data } = await signup({
      email: state.email,
      first_name: state.firstName,
      last_name: state.lastName,
      user_password: state.password,
      usertype: "REFERAL_PARTNER",
      phone_number: `${state.phoneNumber}`,
    });
    if (status === 201) {
      dispatch({ type: "otpModal", payload: true });
    }
    if (status === 422) {
      dispatch({ type: "error", payload: data });
      dispatch({ type: "status", payload: status });
    }
    if (status === 400) {
      dispatch({ type: "jsonError", payload: data?.message });
    }
    if (status === 200) {
      toast.success("User Successfully registered");
    }
  };

  const handleChangePhn = newPhone => {
    setPhone(newPhone);
    var mobile = "";
    if (newPhone.slice(0, 1) == "+" || newPhone.slice(0, 1) == "0") {
      mobile = newPhone.replace(/[^a-zA-Z0-9+]/g, "");
    } else {
      mobile = newPhone.replace(/[^a-zA-Z0-9]/g, "");
    }
    dispatch({ type: "phoneNumber", payload: mobile });
    if(state?.error?.includes("phone_number")) {
      dispatch({ type: "error", payload: "" });
    }
  };


  /* Sign up for candidates functionality OR Integration End */

  /* Pop over functionality start mui */
  const handleChangeTabs = (_, newValue) => {
    setUserType(newValue);
    setValue(newValue);
  };
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorElCandidates, setAnchorElCandidates] = useState(null);
  const [anchorElPartner, setAnchorElPartner] = useState(null);

  const handleCandidatesPopoverOpen = event => {
    setAnchorElCandidates(event.currentTarget);
  };

  const handleCandidatesPopoverClose = () => {
    setAnchorElCandidates(null);
  };

  const handlePartnerPopoverOpen = event => {
    setAnchorElPartner(event.currentTarget);
  };

  const handlePartnerPopoverClose = () => {
    setAnchorElPartner(null);
  };

  const handlePopoverOpen = event => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  //    const open = Boolean(anchorEl);
  const openCandidates = Boolean(anchorElCandidates);
  const openPartner = Boolean(anchorElPartner);

  /* Pop over functionality End mui */

  const handleClickShowPassword = () => setShowPassword(show => !show);

  const handleButtonClick = buttonValue => {
    setSelectedButton(buttonValue === selectedButton ? null : buttonValue);
  };

  return (
    <>
      <ThemeProvider theme={globalTheme}>
        <Grid container>
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            lg={6}
            sx={{
              padding: "0 0",
              "@media (max-width: 900px)": {
                display: "none",
              },
            }}
          >
            <Box
              sx={{
                height: "100vh",
                position: "relative",
                display: "flex",
                justifyContent: "center",
                alignItems: "start",
              }}
            >
              <Stack
                spacing={2}
                alignItems="center"
                justifyContent="center"
                sx={{}}
              >
                <div className="signup_main_section">
                  <div className="signup_part1">
                    <div>
                      <h2 className="signup_heading_section mb-0">
                        The most efficient platform to easily
                      </h2>
                      <span className="signup_span_section">
                        Apply jobs, Refer candidates,
                      </span>
                      <h2 className="signup_heading_section">
                        Hire talented people
                      </h2>
                    </div>
                    <img src={loginPage} className="signup_img" />
                  </div>
                </div>
              </Stack>
            </Box>
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Box
              sx={{
                backgroundColor: "rgba(255,255,255,0.7)",
                padding: "50px 50px",
              }}
            >
              <div className="sign_head_txt" style={{textAlign: "center"}}>
                Signup
                <span className="sign_head_txt2">{"  "}Here</span>
              </div>

              <Box
                sx={{
                  "@media (max-width: 576px)": {
                    width: "95%",
                  },
                  margin: "auto",
                }}
              >
                <Form onSubmit={handleLogin}>
                  <Box
                    sx={{
                      border: "1px solid rgba(0, 0, 0, 0.15)",
                      borderRadius: "8px",
                      width: "100%",
                      // marginLeft: "3%",
                      marginTop: "5%",
                      height: "50px",
                      "@media (max-width: 576px)": {
                        width: "100%",
                      },
                    }}
                  >
                    <Tabs
                      value={userType}
                      onChange={handleChangeTabs}
                      className={classes.root}
                      aria-label="basic tabs example"
                      sx={{
                        ".MuiTabs-flexContainer": {
                          justifyContent: "space-between",
                        },
                        ".MuiTabs-indicator": {
                          // backgroundColor:"rgba(0,0,0,0)"
                          backgroundColor: "#cde2e7",
                        },
                        ".MuiTab-root.Mui-selected ": {
                          // color:"#FFFFFF"
                          color: "#066a4c",
                        },
                      }}
                    >
                      <StyledTab
                        label="Candidates"
                        value="US"
                        sx={{
                          // color: userType === "US" ? "#FFFFFF" : "#000000",
                          // background: userType === "US" ? "#081753" : "#FFFFFF",
                          color: userType === "US" ? "#066a4c" : "#000000",
                          background: userType === "US" ? "#cde2e7" : "#FFFFFF",
                          height: 48,
                          fontSize: 16,
                          borderRadius: 2,
                          border: "0px solid rgba(0, 0, 0, 0.15)",
                          transitionDelay: "0.3s",
                        }}
                        {...a11yProps(0)}
                        aria-owns={open ? "candidates-over-popover" : undefined}
                        aria-haspopup="true"
                        onMouseEnter={handleCandidatesPopoverOpen}
                        onMouseLeave={handleCandidatesPopoverClose}
                      />

                      <StyledTab
                        label="Company"
                        value="CA"
                        sx={{
                          color: userType === "CA" ? "#066a4c" : "#000000",
                          background: userType === "CA" ? "#cde2e7" : "#FFFFFF",
                          height: 48,
                          fontSize: 16,
                          borderRadius: 2,
                          border: "0px solid rgba(0, 0, 0, 0.15)",
                          transitionDelay: "0.3s",
                        }}
                        {...a11yProps(1)}
                        onClick={() => {
                          window.location.href =
                            "https://app.joinee.com/signup?user_type=CA";
                        }}
                      />

                      <StyledTab
                        label="Partner"
                        value="PL"
                        sx={{
                          color: userType === "PL" ? "#066a4c" : "#000000",
                          background: userType === "PL" ? "#cde2e7" : "#FFFFFF",
                          height: 48,
                          fontSize: 16,
                          borderRadius: 2,
                          border: "0px solid rgba(0, 0, 0, 0.15)",
                          transitionDelay: "0.3s",
                        }}
                        {...a11yProps(2)}
                        onClick={() => {
                          window.location.href =
                            "https://app.joinee.com/signup?user_type=PL";
                        }}
                        aria-owns={open ? "partner-over-popover" : undefined}
                        aria-haspopup="true"
                        onMouseEnter={handlePartnerPopoverOpen}
                        onMouseLeave={handlePartnerPopoverClose}
                      />

                      <StyledPopover
                        id="candidates-over-popover"
                        sx={{
                          pointerEvents: "none",
                        }}
                        open={openCandidates}
                        anchorEl={anchorElCandidates}
                        anchorOrigin={{
                          vertical: "top",
                          horizontal: "center",
                        }}
                        transformOrigin={{
                          vertical: "bottom",
                          horizontal: "center",
                        }}
                        onClose={handlePopoverClose}
                        disableRestoreFocus
                      >
                        <div className="popup_main_cont">
                          <div className="popup_child_cont"></div>
                          <ul className="links_txt">
                            <li>&#x2022; Job Seeker</li>
                            <li>&#x2022; Referral Candidate</li>
                          </ul>
                        </div>
                      </StyledPopover>

                      <StyledPopover
                        id="partner-over-popover"
                        sx={{
                          pointerEvents: "none",
                        }}
                        open={openPartner}
                        anchorEl={anchorElPartner}
                        anchorOrigin={{
                          vertical: "top",
                          horizontal: "center",
                        }}
                        transformOrigin={{
                          vertical: "bottom",
                          horizontal: "center",
                        }}
                        onClose={handlePopoverClose}
                        disableRestoreFocus
                      >
                        <div className="popup_main_cont2">
                          <div className="popup_child_cont2"></div>
                          <ul className="links_txt">
                            <li>&#x2022; Sreening Partner</li>
                            <li>&#x2022; Technical Evaluator</li>
                            <li>&#x2022; Recruitment {""}Companies</li>
                            <li>&#x2022; BGV Verification</li>
                          </ul>
                        </div>
                      </StyledPopover>
                    </Tabs>
                  </Box>

                  <Stack mt={3}>
                    <Stack
                      direction="row"
                      sx={{ width: "100%", marginBottom: "3%" }}
                      spacing={2}
                    >
                      <Box
                        sx={{
                          width: "50%",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-start",
                          justifyContent: "flex-start",
                        }}
                      >
                        <Label className="signup_label_txt">First Name</Label>
                        <OutlinedInput
                          placeholder="First Name"
                          className={classes.root}
                          required
                          fullWidth
                          type="text"
                          autoFocus
                          invalid={state?.error?.includes("first_name")}
                          onChange={e => {
                            dispatch({
                              type: "firstName",
                              payload: e.target.value,
                            });
                          }}
                        />
                      </Box>
                      <Box
                        sx={{
                          width: "50%",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-start",
                          justifyContent: "flex-start",
                        }}
                      >
                        <Label className="signup_label_txt">Last Name</Label>
                        <OutlinedInput
                          placeholder="Last Name"
                          className={classes.root}
                          required
                          fullWidth
                          type="text"
                          invalid={state?.error?.includes("last_name")}
                          onChange={e => {
                            dispatch({
                              type: "lastName",
                              payload: e.target.value,
                            });
                          }}
                        />
                      </Box>
                    </Stack>

                    <div className="names_cont">
                      <div className="col-sm-12 col-md-12 col-lg-12">
                        {userType == "US" ? (
                          <div className="names_inside_cont2">
                            <Label className="signup_label_txt">
                              Email Address
                            </Label>
                            <OutlinedInput
                              placeholder="Email"
                              className={classes.root}
                              required
                              fullWidth
                              type="email"
                              //   autoComplete="email"
                              invalid={
                                state?.error?.includes("email") ||
                                state?.jsonError?.includes("email")
                              }
                              onChange={e => {
                                dispatch({
                                  type: "email",
                                  payload: e.target.value,
                                });
                              }}
                            />
                          </div>
                        ) : null}
                      </div>
                    </div>

                    {userType == "CA" ? (
                      <div className="names_cont">
                        <div className="col-sm-12 col-md-6 col-lg-6">
                          <div className="names_inside_cont">
                            <Label className="signup_label_txt">Email</Label>
                            <OutlinedInput
                              placeholder="Email"
                              className={classes.root}
                            />
                          </div>
                        </div>

                        <div className="col-sm-12 col-md-6 col-lg-6">
                          <div className="names_inside_cont">
                            <Label className="signup_label_txt">Company</Label>
                            <OutlinedInput
                              placeholder="Company"
                              className={classes.root}
                            />
                          </div>
                        </div>
                      </div>
                    ) : null}

                    {userType == "PL" ? (
                      <div className="names_cont">
                        <div className="col-sm-12 col-md-6 col-lg-6">
                          <div className="names_inside_cont">
                            <Label className="signup_label_txt">Email</Label>
                            <OutlinedInput
                              placeholder="Email"
                              className={classes.root}
                            />
                          </div>
                        </div>

                        <div className="col-sm-12 col-md-6 col-lg-6">
                          <div className="names_inside_cont">
                            <Label className="signup_label_txt">
                              Describe Yourself
                            </Label>

                            <FormControl fullWidth>
                              <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={age}
                                onChange={handleDescChange}
                                className={classes.root}
                              >
                                <MenuItem value={10}>
                                  Screening Partner
                                </MenuItem>
                                <MenuItem value={20}>Moderator</MenuItem>
                                <MenuItem value={30}>
                                  Technical Evaluator
                                </MenuItem>
                                <MenuItem value={40}>
                                  Recruitment Companies
                                </MenuItem>
                                <MenuItem value={50}>BGV Verification</MenuItem>
                              </Select>
                            </FormControl>
                          </div>
                        </div>
                      </div>
                    ) : null}

                    <FormGroup>
                      <div className="names_inside_cont2">
                        <Label className="signup_label_txt">Mobile No.</Label>
                        <Box sx={{ width: "100%"}}>
                          <MuiCustomTelInput
                            defaultCountry="IN"
                            fullWidth
                            countryCodeEditable={true}
                            value={phone}
                            error={state?.error?.includes("phone_number") ? true : false}
                            helperText={phone === "" ? "Phone Number is Required" : "Enter valid phone number" }
                            onChange={e => handleChangePhn(e)}
                            InputProps={{
                              style: {
                                borderRadius: "8px",
                                height: "48px",
                              },
                            }}
                            sx={{
                              width: "100%"
                            }}
                          />
                        </Box>
                      </div>
                    </FormGroup>

                    <div className="names_cont">
                      <div className="col-sm-12 col-md-12 col-lg-12">
                        <div className="names_inside_cont2">
                          <Label className="signup_label_txt">Password</Label>
                          <FormControl
                            sx={{ width: "100%" }}
                            variant="outlined"
                          >
                            <OutlinedInput
                              id="outlined-adornment-password"
                              className={classes.root}
                              type={showPassword ? "text" : "password"}
                              endAdornment={
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    edge="end"
                                  >
                                    {showPassword ? (
                                      <Visibility />
                                    ) : (
                                      <VisibilityOff />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              }
                              placeholder="Password"
                              required
                              fullWidth
                              // autoComplete="current-password"
                              invalid={state?.error?.includes("user_password")}
                              // inputProps={{
                              //   pattern:
                              //     "^(?=.*[A-Za-z])(?=.*\\d)(?=.*[@$!%*#?&])[A-Za-z\\d@$!%*#?&]{5,10}$",
                              // }}
                              onChange={e => {
                                dispatch({
                                  type: "password",
                                  payload: e.target.value,
                                });
                              }}
                            />
                            {/* <FormHelperText error>
                            
                              {errors?.password?.type === "pattern"
                                ? "Password must contain at least one letter, one number, and one special character"
                                : ""}
                         
                            </FormHelperText> */}
                          </FormControl>
                        </div>
                      </div>
                    </div>

                    <Button
                      variant="outlined"
                      type="submit"
                      disabled={
                        userType == "CA" || userType == "PL" ? true : false
                      }
                      style={{
                        // background: "#081753",
                        borderRadius: "8px",
                        fontFamily: "Inter,sans-serif",
                        fontWeight: "500",
                        fontSize: "16px",
                        color: "#066a4c",
                        fontStyle: "normal",
                        border: "0px",
                        background: `${
                          userType == "CA" || userType == "PL"
                            ? "#cdddf7"
                            : "#cde2e7"
                        }`,
                      }}
                    >
                      Sign Up
                    </Button>
                  </Stack>
                </Form>
              </Box>

              <div className="terms_txt"  style={{textAlign: "center"}}>
                <p>By clicking Sign Up you agree to Terms and Conditions</p>
                <br />
                <p>
                  Already have an account{" "}
                  <Link href="#" className="signin_txt" to="../login">
                    Sign In
                  </Link>
                </p>
              </div>
            </Box>
          </Grid>
        </Grid>
        <OtpVerificationModal
          show={state.otpModal}
          onHide={() => dispatch({ type: "otpModal", payload: false })}
          email={state.email}
        />
      </ThemeProvider>
    </>
  );
}

export default SignUpForm;
