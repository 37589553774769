import React, { useState, useEffect } from "react";
import { ThemeProvider } from "@mui/material/styles";
import {
  globalTheme,
  GreyTypography,
  PrimaryAvatar,
  BlackTypography,
  ProfileAvatar,
  ProfileSectionBox,
  SmallSizeChip,
  MiddleDivider,
  ColorTypography,
  SubTypography,
  HeadingTypography,
  SubHeadingTypography,
  ParaTypography,
  SecondaryHeadingTypography,
} from "../../../../assets/MUI/materialUiStyles";
import { Box, Grid, Stack, typographyClasses } from "@mui/material";
import ProfileBanner from "../../../../assets/images/ProfileBanner.svg";
import profileImage from "../../../../assets/images/profileImage.svg";
import star from "../../../../assets/images/star.svg";
import syoft from "../../../../assets/images/syoft.svg";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import FeatherIcon from "feather-icons-react";
import {
  getProfile,
  getProfileById,
} from "../../../../helpers/dataFetcher/profile/profile";
import JoineeLoader from "../../../../Loader/JoineeLoader";
import { format } from "date-fns";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

export default function UserProfile() {
  const [userDetails, setUserDetails] = useState();
  const [loading, setLoading] = useState(true);

  const searchParams = new URLSearchParams(window.location.search);
  const id = searchParams.get("user_id");

  useEffect(() => {
    setLoading(true);
    dataFetch();
  }, []);

  const dataFetch = async () => {
    const { data, status } = await getProfileById(id);
    console.log(data, "linkkkk");
    setUserDetails(data);
    if (status === 200) {
      setLoading(false);
    }
  };

  if (loading) {
    return <JoineeLoader />; // Show the loader component while data is being fetched
  }

  console.log(userDetails, "userDetails");

  return (
    <>
      <ThemeProvider theme={globalTheme}>
        <Box
          sx={{
            width: "88%",
            marginLeft: "80px",
            height: "100vh",
          }}
        >
          <Box sx={{ width: "100%" }}>
            <Box
              sx={{
                backgroundImage: `url(${ProfileBanner})`,
                backgroundSize: "cover",
                width: "100%",
                height: "150px",
              }}
            ></Box>
            <Box>
              <ProfileAvatar
                // src={`${profileImage}`}
                src={`${userDetails?.profile_image}`}
                alt={`${userDetails?.first_name?.charAt(0, 2)}`}
                sx={{ position: "absolute", top: "190px", left: "100px" }}
              />
              <Grid container spacing={3} sx={{ paddingBottom: "15px" }}>
                <Grid item xs={6} md={8}>
                  <Stack
                    sx={{ paddingLeft: "28px", paddingTop: "44px" }}
                    spacing={2}
                  >
                    <HeadingTypography sx={{ marginBottom: "1px" }}>
                      {userDetails?.first_name} {userDetails?.last_name}
                    </HeadingTypography>
                    <SubHeadingTypography sx={{ marginBottom: "1px" }}>
                      {userDetails?.current_postition
                        ? userDetails.current_position
                        : "N/A"}
                    </SubHeadingTypography>
                    <ParaTypography sx={{ marginBottom: "1px" }}>
                      {userDetails?.gender ? userDetails.gender : "N/A"}
                    </ParaTypography>
                    <ParaTypography sx={{ marginBottom: "1px" }}>
                      {userDetails?.dob
                        ? format(new Date(userDetails.dob), "dd-MM-yyyy")
                        : "N/A"}
                    </ParaTypography>
                    <ParaTypography sx={{ marginBottom: "1px" }}>
                      <FeatherIcon icon="map-pin" fontSize="small" />{" "}
                      {userDetails?.location ? userDetails.location : "N/A"}
                    </ParaTypography>
                    <ParaTypography sx={{ marginBottom: "1px" }}>
                      {userDetails?.email ? userDetails.email : "N/A"}
                    </ParaTypography>
                  </Stack>
                </Grid>

                <Grid item xs={6} md={4}>
                  <Stack
                    direction="row"
                    // justifyContent={"center"}
                    alignItems={"center"}
                    sx={{ paddingTop: "20%" }}
                    spacing={2}
                  >
                    <PrimaryAvatar
                      variant="square"
                      sx={{ border: "none" }}
                      //   src={`${syoft}`}
                      src={`${
                        userDetails?.work_exp_details?.image
                          ? userDetails?.work_exp_details[0]?.image
                          : ""
                      }`}
                      alt={`${
                        userDetails?.work_exp_details?.company
                          ? userDetails?.work_exp_details[0]?.company?.charAt(
                              0,
                              2
                            )
                          : "C"
                      }`}
                    />
                    <SubHeadingTypography sx={{ paddingLeft: "8px" }}>
                      {userDetails?.current_company
                        ? userDetails.current_company
                        : "N/A"}
                    </SubHeadingTypography>
                  </Stack>
                </Grid>

                <Grid item sx={12} md={12} lg={12}>
                  <ProfileSectionBox
                    sx={{
                      wordBreak: "break-word",
                      height: "auto",
                      maxHeight: "200px",
                      overflowY: "scroll",
                    }}
                  >
                    <SecondaryHeadingTypography sx={{ paddingBottom: "8px" }}>
                      About
                    </SecondaryHeadingTypography>
                    {userDetails?.about?.length > 0 ? (
                      <ParaTypography sx={{ paddingTop: "8px" }}>
                        {userDetails?.about}
                      </ParaTypography>
                    ) : (
                      <GreyTypography>No Data</GreyTypography>
                    )}
                  </ProfileSectionBox>
                </Grid>

                <Grid item sx={12} md={12} lg={12}>
                  <ProfileSectionBox
                    sx={{
                      wordBreak: "break-word",
                      height: "auto",
                      maxHeight: "500px",
                      overflowY: "scroll",
                    }}
                  >
                    <SecondaryHeadingTypography>
                      Experience
                    </SecondaryHeadingTypography>
                    <Stack
                      direction={"column"}
                      spacing={2}
                      sx={{ paddingTop: "8px" }}
                    >
                      {userDetails?.work_exp?.length > 0 ? (
                        userDetails?.work_exp?.map((each, index) => {
                          const isLastItem =
                            index === userDetails?.work_exp?.length - 1;
                          return (
                            <>
                              <Stack direction={"row"} spacing={1}>
                                <Stack>
                                  <PrimaryAvatar
                                    variant="square"
                                    sx={{ border: "none" }}
                                    //   src={`${syoft}`}
                                    src={`${each?.image}`}
                                    alt={`${each?.company?.charAt(0, 2)}`}
                                  />
                                </Stack>
                                <Stack direction="column">
                                  <SecondaryHeadingTypography varient="h2">
                                    {each?.title}
                                  </SecondaryHeadingTypography>
                                  <ParaTypography varient="h4">
                                    {each?.company}
                                  </ParaTypography>

                                  {!each?.is_currently_working ? (
                                    <ParaTypography>
                                      {format(
                                        new Date(each?.start_date),
                                        "LLLL"
                                      )}
                                      .{" "}
                                      {new Date(each?.start_date).getFullYear()}{" "}
                                      -{" "}
                                      {format(new Date(each?.end_date), "LLLL")}
                                      . {new Date(each?.end_date).getFullYear()}
                                    </ParaTypography>
                                  ) : (
                                    <ParaTypography>
                                      {format(
                                        new Date(each?.start_date),
                                        "LLLL"
                                      )}
                                      .{" "}
                                      {new Date(each?.start_date).getFullYear()}{" "}
                                      -{"present"}
                                    </ParaTypography>
                                  )}

                                  <ParaTypography>
                                    {each?.location}
                                  </ParaTypography>
                                </Stack>
                              </Stack>
                              {!isLastItem && <MiddleDivider />}
                            </>
                          );
                        })
                      ) : (
                        <GreyTypography>No data </GreyTypography>
                      )}
                    </Stack>
                  </ProfileSectionBox>
                </Grid>

                <Grid item sx={12} md={12} lg={12}>
                  <ProfileSectionBox
                    sx={{
                      wordBreak: "break-word",
                      height: "auto",
                      maxHeight: "400px",
                      overflowY: "scroll",
                    }}
                  >
                    <SecondaryHeadingTypography>
                      Education
                    </SecondaryHeadingTypography>
                    <Stack
                      direction={"column"}
                      spacing={2}
                      sx={{ paddingTop: "8px" }}
                    >
                      {userDetails?.courses?.length > 0 ? (
                        userDetails?.courses?.map(
                          (each, index) => {
                            const isLastItem =
                              index ===
                              userDetails?.courses?.length -
                                1;
                            return (
                              <>
                                <Stack direction={"row"} spacing={1}>
                                  <Stack>
                                    <PrimaryAvatar
                                      variant="square"
                                      sx={{ border: "none" }}
                                      //   src={`${syoft}`}
                                      src={`${each?.institute_image}`}
                                      alt={`${each?.institute?.charAt(
                                        0,
                                        2
                                      )}`}
                                    />
                                  </Stack>
                                  <Stack direction="column">
                                    <SecondaryHeadingTypography varient="h2">
                                      {each?.institute}
                                    </SecondaryHeadingTypography>
                                    <ParaTypography varient="h4">
                                      {each?.certificate}
                                    </ParaTypography>

                                    <ParaTypography>
                                      {format(
                                        new Date(each?.start_date),
                                        "LLLL"
                                      )}
                                      -{" "}
                                      {new Date(each?.start_date).getFullYear()}{" "}
                                      -{"present"}
                                    </ParaTypography>

                                    <ParaTypography>
                                      {each?.location}
                                    </ParaTypography>
                                  </Stack>
                                </Stack>
                                {!isLastItem && <MiddleDivider />}
                              </>
                            );
                          }
                        )
                      ) : (
                        <GreyTypography>No data </GreyTypography>
                      )}
                    </Stack>
                  </ProfileSectionBox>
                </Grid>

                <Grid item sx={12} md={12} lg={12}>
                  <ProfileSectionBox
                    sx={{
                      wordBreak: "break-word",
                      height: "auto",
                      maxHeight: "200px",
                      overflowY: "scroll",
                    }}
                  >
                    <SecondaryHeadingTypography sx={{ paddingBottom: "8px" }}>
                      Skills
                    </SecondaryHeadingTypography>
                    {/* {["Python", "Devops", "Angular", "PowerBI"].map(each => {
                      return (
                        <>
                          <SmallSizeChip label={`${each}`} />{" "}
                        </>
                      );
                    })} */}
                    {userDetails?.skill_details?.length > 0 ? (
                      userDetails?.skill_details?.map(each => {
                        return (
                          <>
                            <SmallSizeChip label={`${each?.name}`} />{" "}
                          </>
                        );
                      })
                    ) : (
                      <GreyTypography>No data </GreyTypography>
                    )}
                  </ProfileSectionBox>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>
      </ThemeProvider>
    </>
  );
}
