import { POST,PUT } from "../../../Constants/methods";
import callApi from "../../callApi";
import { imageURL } from "../../../Constants/commonURLS";
import moment from "moment";

function jobPost(data) {

  const jobpostDetails = data?.map(each => {
    const formattedTime = moment(each?.updatedAt).format("ddd, DD YYYY");
    // if(!each?.is_job_post_verified){
    return {
      id: each?.id,
      role: each?.info?.job_post_details?.job_title,
      title: each?.info?.company_details?.company_name,
      image: `${imageURL}${each?.info?.company_details?.company_image}`,
      // time:each?.updatedAt,
      time: formattedTime,
      key: "to be approve",
      message: `${each?.info?.message ?? ""}`,
    };
    // }
  });
  return jobpostDetails;
}

export const getAllNotifications = async (page, rowsPerPage, search) => {
  return await callApi(`common/notifications_list?pageNo=${page}&pageSize=${rowsPerPage}&searchParam=${search}`);
};

export const getUnreadNotificationCount = async () => {
  return await callApi(`common/unread_notifications_list?searchParam&pageNo=1&pageSize=10`)
}

export const getAllNotificationsSettings = async () => {
  const response = await callApi(`common/notification_settings`);
  return response?.data?.data_payload;
};

export const updateNotifyInAppSettings = async (data, userId) => {
 
  await callApi(`common/update_all_notification_settings/${userId}`, data, PUT);
};

export const updateIndividualNotifyInAppSettings = async (data, id) => {
  await callApi(`common/notification_settings/${id}`, data, PUT);
};
