import React from "react";
import { Box, CircularProgress, Grid } from "@mui/material";
import { Suspense } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import GlobalNavbar from "../layouts/Navabar/GlobalNavbar";
export default function PublicOutlet() {

    return (
        <React.Fragment>
            <GlobalNavbar />
            <main className="">
                <Suspense
                    fallback={
                        <Box
                            sx={{
                                display: "flex",
                                height: "100vh",
                                width: "100vw",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                        >
                            <CircularProgress />
                        </Box>
                    }
                >
                    <Outlet />
                </Suspense>
            </main>
        </React.Fragment>
    )

}
