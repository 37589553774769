import React from 'react';
import { Grid, Stack, Typography, Box } from "@mui/material";
import CompaniesCarousal from '../../LandingPages/CompaniesCarousal';

function CompanyHired() {
    return (
        <Grid container sx={{
            paddingTop: "40px", paddingBottom: "100px",
            "@media (max-width: 600px)": {
                paddingTop: "20px", paddingBottom: "50px",
            },

        }}>
            <Grid item xs={12} sm={12}
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <Stack
                    sx={{
                        position: "relative",
                    }}
                    direction="row"
                    p={1}
                >

                    <Stack
                        sx={{
                            fontSize: "30px",
                            textAlign: "center",
                            fontWeight: "700",
                            width: "80%",
                            margin: "auto",
                            "@media (max-width: 1200px)": {
                                fontSize: "25px",
                            },
                            "@media (max-width: 600px)": {
                                fontSize: "15px",
                                width: "90%",
                            },

                        }}
                    >
                        <Box> Companies hired outstanding talent through <span style={{color: "var(--text-color)"}}>joinee.com</span></Box>
                    </Stack>
                </Stack>
            </Grid>
            <Grid
                item
                xs={12}
                sm={12}
            >
                <Stack
                    sx={{
                        width: "80%",
                        margin: "auto",
                        "@media (max-width: 600px)": {
                            width: "95%",
                        },
                    }}
                >
                    <CompaniesCarousal />
                </Stack>
                <Stack
                    sx={{
                        width: "70%",
                        margin: "auto",
                        "@media (max-width: 600px)": {
                            width: "90%",
                        },
                    }}
                >
                    <CompaniesCarousal />
                </Stack>

            </Grid>
        </Grid>
    )
}

export default CompanyHired;