export default function JetPlaneIcon() {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.6802 16.93L15.7502 21L17.5402 19.21C17.6444 19.1062 17.7244 18.9807 17.7745 18.8424C17.8246 18.7042 17.8436 18.5565 17.8302 18.41L17.1802 11.3" stroke="#1D1E25" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M9.36994 4.71L7.99994 6.13M9.36994 17.46C9.4629 17.5537 9.5735 17.6281 9.69536 17.6789C9.81722 17.7297 9.94793 17.7558 10.0799 17.7558C10.212 17.7558 10.3427 17.7297 10.4645 17.6789C10.5864 17.6281 10.697 17.5537 10.7899 17.46L18.6999 9.55C20.1713 8.07754 20.9984 6.08157 20.9999 4C20.9999 3.73478 20.8946 3.48043 20.707 3.29289C20.5195 3.10536 20.2652 3 19.9999 3C17.9184 3.00151 15.9224 3.82867 14.4499 5.3L6.53994 13.21C6.44621 13.303 6.37182 13.4136 6.32105 13.5354C6.27028 13.6573 6.24414 13.788 6.24414 13.92C6.24414 14.052 6.27028 14.1827 6.32105 14.3046C6.37182 14.4264 6.44621 14.537 6.53994 14.63L9.36994 17.46ZM17.8699 16L19.2899 14.59L17.8699 16Z" stroke="#1D1E25" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M12.75 6.81997L5.59 6.16997C5.44353 6.1565 5.2959 6.17551 5.15761 6.22564C5.01933 6.27576 4.89381 6.35578 4.79 6.45997L3 8.24997L7.09 12.34" stroke="#1D1E25" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>

    );
}
