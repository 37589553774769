import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./login.css";
import { Col, Form, Row, Label } from "reactstrap";
import { Input, OutlinedInput } from "@mui/material";
import { useDispatch } from "react-redux";
import { addUserAction } from "../store/auth/actions";
import { useForm } from "react-hook-form";
import { useRef } from "react";
import Button from "@mui/material/Button";
import { forgetPassword } from "../helpers/dataFetcher/auth";
import { toast } from "react-hot-toast";
import loginPage from '../assets/images/loginPage.png';
import { makeStyles } from "@mui/styles";
import { globalTheme } from '../assets/MUI/globalStyles';
import { ThemeProvider } from "@mui/material/styles";

import { Box, Grid, Stack } from "@mui/material";

const useStyles = makeStyles({
  root: {
    height: 48,
    width: "100% ",
    borderColor: "rgba(0, 0, 0, 0.3)",
    borderRadius: "8px !important",
    transitionDelay: "0.5ms",
    color: "rgba(0, 0, 0, 0.5)",
    fontFamily: "Inter , sans-serif !important",
    "& input::placeholder": {
      fontFamily: "Inter , sans-serif !important",
      fontWeight: 600,
      fontSize: 16,
      fontStyle: "normal",
      color: "rgba(0, 0, 0, 0.5)",
    },
    "& input": {
      padding: "12px 14px",
      borderRadius: 8,
    },
  },
});

function ForgetPassword() {
  const [loginStatus, setLoginStatus] = useState(false);
  const classes = useStyles();
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    getValues,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();
  const [error, setError] = useState(false);
  const dispatch = useDispatch();
  const [password, setPassword] = useState("");
  const [divClick, setDivClick] = useState(false);
  const [passwordClick, setPasswordClick] = useState(false);
  const usePasswordRef = useRef(null);
  const ref = useRef(null);
  useEffect(() => {
    const handleClickOutside = event => {
      if (ref.current && !ref.current.contains(event.target)) {
        // setDivClick(false);
      } else {
        setError(false);
        setDivClick(true);
      }
      if (
        usePasswordRef.current &&
        !usePasswordRef.current.contains(event.target)
      ) {
        // setPasswordClick(false);
      } else {
        setError(false);
        setPasswordClick(true);
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);
  const loginFunc = async data => {
    setLoginStatus(true);
    const { status, data: loginObj } = await forgetPassword(data.email);
    if (status !== 200) {
      setError(true);
      setLoginStatus(false);
      return;
    }
    setLoginStatus(false);
    toast.success(loginObj?.data);
    localStorage.setItem("token", loginObj.access);
    localStorage.setItem("user_id", loginObj.id);
    localStorage.setItem("user_type", loginObj.usertype);
    dispatch(addUserAction(loginObj));
    return;
  };

  return (
    <>
      <ThemeProvider theme={globalTheme}>

        <Grid container>
          <Grid item xs={12} sm={12} md={6} lg={6}
            sx={{
              padding: "0 0",
              "@media (max-width: 900px)": {
                display: "none"
              },
            }}
          >
            <Box
              sx={{
                height: "100vh",
                position: "relative",
                display: "flex",
                justifyContent: "center",
                alignItems: "start",
              }}
            >
              <Stack
                spacing={2}
                alignItems="center"
                justifyContent="center"
                sx={{}}
              >
                <div className="signup_main_section">
                  <div className="signup_part1">
                    <div>
                      <h2 className="signup_heading_section mb-0">
                        The most efficient platform to easily
                      </h2>
                      <span className="signup_span_section">
                        Apply jobs, Refer candidates,
                      </span>
                      <h2 className="signup_heading_section">Hire talented people</h2>
                    </div>
                    <img src={loginPage} className="signup_img" />
                  </div>
                </div>
              </Stack>
            </Box>
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            lg={6}
          >
            <Box
              sx={{
                backgroundColor: "rgba(255,255,255,0.7)",
                padding: "50px 50px",
                textAlign: "center"
              }}
            >
              <div className="sign_head_txt">
                Change Password{" "}
                <span className="sign_head_txt2">{"  "}Here</span>
              </div>

              <Box
                sx={{
                  "@media (max-width: 576px)": {
                    width: "95%",
                  },
                  margin: "auto",
                }}
              >
                <Form onSubmit={handleSubmit(loginFunc)}>
                  <div className="form_main_container">
                    <div className="names_cont">
                      <div className="col-sm-12 col-md-12 col-lg-12">
                        <div className="names_inside_cont2">
                          <Label className="signup_label_txt">
                            Email Address
                          </Label>
                          <OutlinedInput
                            placeholder="Email"
                            className={classes.root}
                            //   style={{borderRadius:"8px"}}
                            fullWidth
                            id="email"
                            name="email"
                            type="email"
                            autoComplete="email"
                            autoFocus
                            error={errors.email ? true : false}
                            {...register("email", { required: true })}
                          />
                          <p style={{ color: "red" }}>
                            {errors.email ? "Email required" : null}
                          </p>
                        </div>
                      </div>
                    </div>

                    <Button
                      variant="outlined"
                      type="submit"
                      style={{
                        // background: "#081753",
                        borderRadius: "8px",
                        fontFamily: "Inter,sans-serif",
                        fontWeight: "500",
                        fontSize: "16px",
                        color: "#066a4c",
                        fontStyle: "normal",
                        background: "#cde2e7",
                        border: "0px"
                      }}
                    >
                      Submit
                    </Button>
                  </div>
                </Form>
              </Box>

              <div className="terms_txt">
                <p>
                  Go back to login?{" "}
                  <Link href="#" className="signin_txt" to="../login">
                    Login
                  </Link>
                </p>
                <br />
              </div>

            </Box>
          </Grid>
        </Grid>
      </ThemeProvider>
    </>
  );
}

export default ForgetPassword;
