import { styled } from "@mui/material/styles";
import {
  Autocomplete,
  Avatar,
  Badge,
  Box,
  Button,
  Card,
  CardActionArea,
  CardContent,
  Checkbox,
  Chip,
  CircularProgress,
  Divider,
  FormHelperText,
  IconButton,
  InputBase,
  InputLabel,
  ListItemButton,
  ListItemText,
  Menu,
  OutlinedInput,
  Paper,
  Popover,
  Slider,
  Step,
  StepConnector,
  StepLabel,
  Switch,
  Tab,
  TableCell,
  TableRow,
  Tabs,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
  Rating,
  avatarClasses,
  checkboxClasses,
  buttonBaseClasses,
  buttonClasses,
  cardActionAreaClasses,
  cardClasses,
  cardContentClasses,
  chipClasses,
  circularProgressClasses,
  iconButtonClasses,
  inputBaseClasses,
  listItemButtonClasses,
  listItemTextClasses,
  menuClasses,
  outlinedInputClasses,
  paperClasses,
  popoverClasses,
  sliderClasses,
  stepLabelClasses,
  tabClasses,
  tableCellClasses,
  tableRowClasses,
  tabsClasses,
  textFieldClasses,
  toggleButtonClasses,
  toggleButtonGroupClasses,
  tooltipClasses,
  typographyClasses,
  ratingClasses,
  dividerClasses
} from "@mui/material";
import { grey } from "@mui/material/colors";
import { createTheme } from "@mui/material/styles";
import { DataGrid } from "@mui/x-data-grid";

export const globalTheme = createTheme({
  overrides: {
    MuiCssBaseline: {
      "@global": {
        html: {
          WebkitFontSmoothing: "auto",
        },
      },
    },
  },
  palette: {
    primary: {
      main: "#024430",
    },
    error: {
      main: "#ff5630",
    },
  },
  typography: {
    h1: {
      fontSize: 28,
      fontWeight: 800,
      color: "#024430",
    },
    fontFamily: ["Inter"].join(","),
  },
  transitions: {
    duration: {
      shortest: 150,
      shorter: 200,
      short: 250,
      standard: 300,
      complex: 375,
      enteringScreen: 225,
      leavingScreen: 195,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...(ownerState.variant === "solid" &&
            ownerState.color === "primary" && {
              fontSize: "1rem",
              padding: "10px 0px",
              background: "#0549ff",
              textTransform: "capitalize",
              fontFamily: "Inter",
              color: "#fff",
              borderRadius: "10px",
              fontFamily: "",
              fontWeight: "800",
              transition:
                "transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;",
              width: "100%",
              ":hover": {
                boxShadow: "rgb(0 85 171 / 24%) 0px 8px 16px 0px",
                background: "rgb(0, 85, 123)",
              },
            }),
          ...(ownerState.variant === "solid" &&
            ownerState.color === "success" && {
              fontSize: "1rem",
              padding: "10px 0px",
              background: "rgb(0, 171, 85)",
              textTransform: "capitalize",
              fontFamily: "Inter",
              color: "#fff",
              borderRadius: "10px",
              fontFamily: "",
              transition:
                "transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;",
              fontWeight: "800",
              width: "100%",
              ":hover": {
                boxShadow: "rgb(0 171 85 / 24%) 0px 8px 16px 0px",
                background: "rgb(0, 123, 85)",
              },
            }),

          ...(ownerState.variant === "solid" &&
            ownerState.color === "danger" && {
              fontSize: "1rem",
              padding: "10px 0px",
              background: "rgb( 171,15,0)",
              textTransform: "capitalize",
              fontFamily: "Inter",
              color: "#fff",
              borderRadius: "10px",
              fontFamily: "",
              transition:
                "transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;",
              fontWeight: "800",
              width: "100%",
              ":hover": {
                boxShadow: "rgb( 171 15,0 / 24%) 0px 8px 16px 0px",
                background: "rgb( 123, 15,0)",
              },
            }),
          ...(ownerState.variant === "solid" &&
            ownerState.purpose === "icons" && {
              width: "10px",
              height: "47px",
            }),
          ...(ownerState.purpose === "high_content" && {
            width: "200px",
            fontWeight: "normal",
            fontSize: "14px",
          }),
          ...(ownerState.purpose === "cancel" && {
            width: "100px",
            fontWeight: "normal",
            fontSize: "14px",
          }),
          ...(ownerState.purpose === "approve" && {
            padding: "6px 0px",
            fontSize: "14px",
            background: "rgb(54, 179, 126)",
            textTransform: "capitalize",
            fontFamily: "Inter",
            color: "#fff",
            borderRadius: "8px",
            maxWidth: "100px",
            fontFamily: "",
            // fontWeight: "800",
            transition:
              "transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;",
            width: "100%",
            ":hover": {
              boxShadow: "rgb(0 171 85 / 24%) 0px 8px 16px 0px",
              background: "rgb(0, 123, 85)",
            },
          }),
          ...(ownerState.purpose === "delete" && {
            padding: "6px 0px",
            fontSize: "14px",
            background: "#D60D0D",
            textTransform: "capitalize",
            fontFamily: "Inter",
            color: "#fff",
            borderRadius: "5px",
            maxWidth: "100px",
            fontFamily: "",
            transition:
              "transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;",
            fontWeight: "800",
            width: "100%",
            ":hover": {
              boxShadow: "rgb( 171 15,0 / 24%) 0px 8px 16px 0px",
              background: "rgb( 123, 15,0)",
            },
          }),
          ...(ownerState.purpose === "view_more" && {
            padding: "6px 0px",
            fontSize: "14px",
            background: "#0549ff",
            textTransform: "capitalize",
            fontFamily: "Inter",
            color: "#fff",
            borderRadius: "5px",
            maxWidth: "100px",
            fontFamily: "",
            transition:
              "transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;",
            // width: "100%",
            ":hover": {
              boxShadow: "rgb(0 85 171 / 24%) 0px 8px 16px 0px",
              background: "rgb(0, 85, 123)",
            },
          }),

          ...(ownerState.purpose === "error_state_btn" && {
            padding: "3px 2px",
            // width: "200px",
            width: "100%",
            fontSize: "12px",
            background: "#D14520",
            fontWeight: "normal",
            textTransform: "capitalize",
            fontFamily: "Inter",
            color: "#fff",
            borderRadius: "10px",
            border: "1px solid #d14520",
            maxWidth: "100px",
            fontFamily: "",
            transition:
              "transition: border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
            ":hover": {
              color: "#d14520",
              background: "white",
              borderColor: "#d14520",
            },
          }),
          ...(ownerState.purpose === "mod_button" && {
            padding: "3px 2px",
            width: "100%",
            fontSize: "12px",
            background: "#34988D",
            fontWeight: "normal",
            textTransform: "capitalize",
            fontFamily: "Inter",
            color: "#fff",
            borderRadius: "10px",
            maxWidth: "100px",
            fontFamily: "",
            border: "1px solid #34988D",
            maxWidth: "100px",
            fontFamily: "",
            transition:
              "transition: border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
            ":hover": {
              color: "#34988D",
              background: "white",
              borderColor: "#34988D",
            },
          }),
          ...(ownerState.purpose === "te_button" && {
            padding: "3px 2px",
            width: "100%",
            fontSize: "12px",
            background: "#00205B",
            fontWeight: "normal",
            textTransform: "capitalize",
            fontFamily: "Inter",
            color: "#fff",
            borderRadius: "10px",
            maxWidth: "100px",
            border: "1px solid #00205B",
            maxWidth: "100px",
            fontFamily: "",
            transition:
              "transition: border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
            ":hover": {
              color: "#00205B",
              background: "white",
              borderColor: "#00205B",
            },
          }),
        }),
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          [`& fieldset`]: {
            borderRadius: "8px",
          },
          [`&:hover fieldset`]: {
            borderColor: "black",
          },
          [`&:active fieldset`]: {
            borderColor: "black",
          },
          borderRadius: "8px",
          width: "100%",
          fontFamily: "Inter",
          "& .MuiInputBase-root": {
            borderRadius: "8px",
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          [`& fieldset`]: {
            borderRadius: "8px",
          },
          [`&:hover fieldset`]: {
            borderColor: "black",
          },
          [`&:active fieldset`]: {
            borderColor: "black",
          },
          borderRadius: "8px",
          width: "100%",
          fontFamily: "Inter",
          "& .MuiInputBase-root": {
            borderRadius: "8px",
          },
        },
      },
    },
    MuiInputAdornment: {
      styleOverrides: {
        root: {
          cursor: "pointer",
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...(ownerState.type === "soft" &&
            ownerState.color === "success" && {
              color: "black",
              borderRadius: "8px",
              overflow: "hidden",
              height: "auto",
              wordWrap: "break-word",
              background: "rgb(200, 250, 205)",
              padding: "12px",
            }),
          ...(ownerState.type === "soft" &&
            ownerState.color === "info" && {
              color: "black",
              borderRadius: "8px",
              overflow: "hidden",
              wordWrap: "break-word",
              height: "auto",
              background: "rgb(244, 246, 248)",
              padding: "12px",
            }),
        }),
      },
    },
    MuiCircularProgress: {
      styleOverrides: {
        root: {},
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          padding: "8px",
          borderRadius: "8px",
          margin: "2px 4px",
          "&:hover": {
            background: "rgba(145, 158, 171, 0.08)",
          },
          "&:active": {
            background: "rgba(145, 158, 171, 0.08)",
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          borderRadius: "8px",
          color: "rgb(33, 43, 54)",
          boxShadow:
            "rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) -20px 20px 40px -4px",
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          "& .MuiMenu-list": {
            borderRadius: "8px",
            padding: "6px",
          },
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: "capitalize",
          background: "trasparent",
          color: "rgb(99, 115, 129)",
          display: "flex",
          justifyContent: "center",
          height: "20px",
          alignItems: "center",
        },
        "&:focus": {
          color: "black",
        },
      },
    },
    MuiModal: {
      root: {},
    },
    MuiInputLabel:{
      styleOverrides:{
        root:{
          color:"#066a4c"
        }
      }
    },
  },
});
export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    color: grey[700],
    borderBottom: "1.5px solid #eeeff3",
    fontWeight: "bold",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

export const StyledTableLeft = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    color: grey[700],
    borderTopLeftRadius: "10px",
    fontWeight: "bold",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

export const StyledTableRight = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    color: grey[700],
    borderTopRightRadius: "10px",
    fontWeight: "bold",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));
export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  [`&.${tableRowClasses.selected}`]: {
    backgroundColor: "white",
  },
  [`&.${tableRowClasses.root}`]: {},
}));
export const StyledTabs = styled(Tabs)(({ theme }) => ({
  [`&.${tabsClasses.root}`]: {
    backgroundColor: grey[100],
    borderRadius: "10px",
  },
  [`&.${tabsClasses.indicator}`]: {
    backgroundColor: "red",
    color: "white",
    borderRadius: "10px",
    display: "none",
  },
}));

export const StyledTabComponents = styled(Tab)(({ theme }) => ({
  [`&.${tabClasses.root}`]: {
    backgroundColor: grey[100],
  },
  [`&.${tabClasses.selected}`]: {
    color: "white",
    borderRadius: "10px",
    backgroundColor: theme.palette.primary.main,
  },
}));

export const StyledSecondaryTypography = styled(Typography)(
  ({ theme, purpose }) => ({
    [`&.${typographyClasses.h6}`]: {
      fontWeight: "400",
      fontSize: "12px",
      overflow: "hidden",
      maxWidth: "100%",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      color: "grey[800]",
      textTransform: "capitalize",
    },
    ...(purpose === "skills" && {
      ":not(h6:nth-last-of-type(1)) ": {
        "&:after": {
          content: '""',
          display: "inline-block",
          margin: "0px 3px",
          width: "6px !important",
          height: "6px  !important",
          borderRadius: "50%",
          background: "rgba(0,0,0,0.5)",
        },
      },
    }),
  })
);
export const GrayDivider = styled(Divider)(({ theme }) => ({
  background: "rgba(0,0,0,0.5)",
}));
export const GrayLiniearDivider = styled(Divider)(({ theme }) => ({
  background: "rgba(0,0,0,0.2)",
}));
export const PrimaryDivider = styled(Divider)(({ theme }) => ({
  background: "#024430",
  opacity: 1,
}));
export const BorderTypography = styled(Typography)(({ theme }) => ({
  [`&.${typographyClasses.h1}`]: {
    fontWeight: "900",
    textTransform: "capitalize",
    color: "#081753",
    fontSize: "28px",
    borderBottom: "1px solid #081753",
  },
  [`&.${typographyClasses.h2}`]: {
    fontWeight: "700",
    textTransform: "capitalize",
    color: "#081753",
    fontSize: "20px",
    padding: "10px",
    borderBottom: "1px solid rgba(0,0,0,0.1)",
  },
}));
//this is primary typography
export const PrimaryTypography = styled(Typography)(({ theme }) => ({
  [`&.${typographyClasses.h1}`]: {
    fontWeight: "800",
    textTransform: "capitalize",
    color: "#024430",
    fontSize: "28px",
  },
  [`&.${typographyClasses.h2}`]: {
    fontWeight: "700",
    textTransform: "capitalize",
    color: "#024430",
    fontSize: "20px",
  },
  [`&.${typographyClasses.h3}`]: {
    fontWeight: "600",
    color: "#024430",
    fontSize: "16px",
  },
  [`&.${typographyClasses.h4}`]: {
    fontWeight: "500",
    color: "#024430",
    fontSize: "16px",
  },
  //ellipsis maxwidth 200px
  [`&.${typographyClasses.h5}`]: {
    fontWeight: "400",
    fontSize: "15px",
    overflow: "hidden",
    maxWidth: "200px",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    color: "#066a4c",
    textTransform: "capitalize",
  },
  [`&.${typographyClasses.h6}`]: {
    fontWeight: "400",
    fontSize: "10px",
    color: "#024430",
    textTransform: "capitalize",
  },
}));

export const SecondaryTypography = styled(Typography)(({ theme }) => ({
  [`&.${typographyClasses.h1}`]: {
    fontWeight: "800",
    textTransform: "capitalize",
    color: "#024430",
    fontSize: "28px",
  },
  [`&.${typographyClasses.h2}`]: {
    fontWeight: "700",
    textTransform: "capitalize",
    color: "#024430",
    fontSize: "20px",
  },
  [`&.${typographyClasses.h3}`]: {
    fontWeight: "600",
    color: "#024430",
    fontSize: "18px",
    letterSpacing:"-0.2px"
  },
  [`&.${typographyClasses.h4}`]: {
    fontWeight: "500",
    color: "#024430",
    fontSize: "16px",
  },
  //ellipsis maxwidth 200px
  [`&.${typographyClasses.h5}`]: {
    fontWeight: "400",
    fontSize: "15px",
    overflow: "hidden",
    maxWidth: "200px",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    color: "#066a4c",
    textTransform: "capitalize",
  },
  [`&.${typographyClasses.h6}`]: {
    fontWeight: "400",
    fontSize: "10px",
    color: "#024430",
    textTransform: "capitalize",
  },
}));


export const HeadingTypography = styled(Typography)(({ theme }) => ({
  fontWeight: "600",
  color: "#000000",
  fontSize: "16px",
  lineHeight:"20px",
  letterSpacing:"-0.2px"
}));

export const SubHeadingTypography = styled(Typography)(({ theme }) => ({
  fontWeight: "500",
  color: "#000000",
  fontSize: "15px",
  lineHeight:"20px",
  letterSpacing:"-0.2px"
}));

export const SecondaryHeadingTypography = styled(Typography)(({ theme }) => ({
  fontWeight: "600",
  color: "#000000",
  fontSize: "15px",
  lineHeight:"20px",
  letterSpacing:"-0.2px"
}));

export const ParaTypography = styled(Typography)(({ theme }) => ({
  fontWeight: "500",
  color: "#263238",
  fontSize: "15px",
  lineHeight:"20px",
  letterSpacing:"-0.2px"
}));



//! dont use this styledh2Typography
export const Styledh2Typography = styled(Typography)(({ theme }) => ({
  [`&.${typographyClasses.h2}`]: {
    fontWeight: "700",
    textTransform: "capitalize",
    color: "#021a39",
    fontSize: "20px",
  },
  [`&.${typographyClasses.h3}`]: {
    fontWeight: "600",
    color: "black",
    fontSize: "16px",
  },
  [`&.${typographyClasses.h4}`]: {
    fontWeight: "600",
    color: grey[800],
    fontSize: "16px",
  },
  [`&.${typographyClasses.h5}`]: {
    fontWeight: "600",
    fontSize: "15px",
    overflow: "hidden",
    maxWidth: "200px",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    color: "#081753",
    textTransform: "capitalize",
  },
  [`&.${typographyClasses.h6}`]: {
    fontWeight: "400",
    fontSize: "10px",
    color: grey[800],
    textTransform: "capitalize",
  },
}));
export const tertiaryCustomStyle = {
  fontWeight: "600",
  fontSize: "15px",
  color: "black",
  textTransform: "capitalize",
};
export const BlackTypography = styled(Typography)(({ theme }) => ({
  [`&.${typographyClasses.h1}`]: {
    fontWeight: "800",
    color: "black",
    fontSize: "20px",
    textOverflow:"ellipsis",
    wordWrap:"break-word",
    textTransform: "capitalize",
  },
  [`&.${typographyClasses.h2}`]: {
    fontWeight: "700",
    color: "rgba(33, 33, 33, 1)",
    fontSize: "25px",
    textOverflow:"ellipsis",
    wordWrap:"break-word",
    textTransform: "capitalize",
  
  },
  [`&.${typographyClasses.h3}`]: {
    fontWeight: "600",
    color: "#024430",
    fontSize: "16px",
    textOverflow:"ellipsis",
    wordWrap:"break-word",
    textTransform: "capitalize",
  },
  [`&.${typographyClasses.h4}`]: {
    fontWeight: "500",
    color: "rgba(33, 33, 33, 1)",
    fontSize: "16px",
    textOverflow:"ellipsis",
    wordWrap:"break-word",
    textTransform: "capitalize",
  },
  [`&.${typographyClasses.h5}`]: {
    fontWeight: "400",
    fontSize: "15px",
    overflow: "hidden",
    maxWidth: "200px",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    color: "#024430",
    textTransform: "capitalize",
  },
  [`&.${typographyClasses.h6}`]: {
    fontWeight: "400",
    fontSize: "14px",
    color: "#024430",
    textTransform: "capitalize",
  },
}));



export const ColorTypography = styled(Typography)(({ theme }) => ({
  [`&.${typographyClasses.h1}`]: {
    fontWeight: "800",
    color: "black",
    fontSize: "20px",
  },
  [`&.${typographyClasses.h2}`]: {
    fontWeight: "700",
    color: "#024430",
    fontSize: "20px",
  },
  [`&.${typographyClasses.h3}`]: {
    fontWeight: "600",
    color: "#000000",
    fontSize: "16px",
    letterSpacing:"-0.2px"
  },
  [`&.${typographyClasses.h4}`]: {
    fontWeight: "500",
    color: "#000000",
    fontSize: "15px",
    letterSpacing:"-0.2px"
  },
  [`&.${typographyClasses.h5}`]: {
    fontWeight: "600",
    fontSize: "15px",
    overflow: "hidden",
    maxWidth: "200px",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    color: "#024430",
    textTransform: "capitalize",
  },
  [`&.${typographyClasses.h6}`]: {
    fontWeight: "500",
    color: "#024430",
    fontSize: "15px",
    letterSpacing:"-0.2px",
    textTransform: "capitalize",
    textOverflow: "ellipsis",
  },
}));

export const SubTypography = styled(Typography)(({ theme }) => ({
  [`&.${typographyClasses.h1}`]: {
    fontWeight: "800",
    color: "black",
    fontSize: "20px",
  },
  [`&.${typographyClasses.h2}`]: {
    fontWeight: "700",
    color: "#024430",
    fontSize: "20px",
  },
  [`&.${typographyClasses.h3}`]: {
    fontWeight: "500",
    color: "#000000",
    fontSize: "14px",
  },
  [`&.${typographyClasses.h4}`]: {
    fontWeight: "500",
    color: "#024430",
    fontSize: "16px",
  },
  [`&.${typographyClasses.h5}`]: {
    fontWeight: "400",
    fontSize: "15px",
    overflow: "hidden",
    maxWidth: "200px",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    color: "#024430",
    textTransform: "capitalize",
  },
  [`&.${typographyClasses.h6}`]: {
    fontWeight: "400",
    fontSize: "14px",
    color: "#024430",
    textTransform: "capitalize",
  },
}));


//needs to be redefinded from h1 to h5
//TODO
export const GreyTypography = styled(Typography)(
  ({ theme, additionalProps }) => ({
    [`&.${typographyClasses.h1}`]: {
      fontWeight: "900",
      textTransform: "capitalize",
      color: "rgba(0,0,0,0.5)",
      fontSize: "28px",
    },
    [`&.${typographyClasses.h2}`]: {
      fontWeight: "700",
      color: "rgba(0,0,0,0.5)",
      fontSize: "20px",
    },
    [`&.${typographyClasses.h3}`]: {
      fontWeight: "600",
      color: "rgba(0,0,0,0.5)",
      fontSize: "16px",
    },
    [`&.${typographyClasses.h4}`]: {
      fontWeight: "600",
      fontSize: "16px",
      color: "rgba(0,0,0,0.5)",
      textTransform: "capitalize",
    },
    [`&.${typographyClasses.h3}`]: {
      fontWeight: "900",
      fontSize: "16px",
      color: "rgba(0,0,0,0.5)",
      textTransform: "capitalize",
    },
    [`&.${typographyClasses.h6}`]: {
      fontWeight: "400",
      fontSize: "14px",
      color: "rgba(0,0,0,0.5)",
      textTransform: "capitalize",
    },
    ...additionalProps,
  })
);
export const ExtraSmallTypography = styled(Typography)(({ theme }) => ({
  [`&.${typographyClasses.h6}`]: {
    fontWeight: "400",
    fontSize: "14px",
    color: "grey",
  },
}));

export const LogoutButton = styled(Button)(({ theme }) => ({
  // [`$.${buttonClasses.outlined}`]: {
  border: theme.palette.error.main,
  borderWidth: "1px",
  borderStyle: "solid",
  width: "160px",
  marginTop: "10px",
  borderRadius: "10px",
  curor: "pointer",
  color: theme.palette.error.main,
  ":hover": {
    backgroundColor: theme.palette.error.main,
    color: "white",
  },
  // },
}));

export const StyledPaperForKanban = styled(Paper)(({ theme }) => ({
  backgroundColor: "rgb(8,23,83,0.1)",
  width: "350px",
  height: "100vh",
  padding: "10px",
  borderRadius: "10px",
  overflowY: "scroll",
}));

export const UserDetailCard = styled(Card)(({ theme }) => ({
  border: "0.5px solid rgba(0,0,0,0.3)",
  marginBottom: "10px",
  borderRadius: "14px",
  boxShadow: "none",
}));
export const OutlinedInputForFilter = styled(OutlinedInput)(({ theme }) => ({
  [`&.${outlinedInputClasses.root}`]: {
    background: "#F2F2F2",
    color: "black",
    height: "40px",
    outline: "none",
    border: "none",
  },
  [`&.${outlinedInputClasses.focused}`]: {
    border: "none",
    outline: "none",
  },
}));

export const StyledParagraph = styled(Typography)(({ theme }) => ({
  backgroundColor: grey[100],
  borderRadius: "5px",
  border: `1px solid ${grey[800]}`,
  padding: "3px 9px",
  fontWeight: "600",
  fontSize: "14px",
}));
export const SecondaryButton = styled(Button)(({ theme }) => ({
  border: `1.5px solid ${grey[400]}`,
  color: grey[600],
  width: "100%",
  borderRadius: "8px",
  "&:hover": {
    background: "white",
    color: "#081753",
    border: `1.5px solid #081753`,
  },
}));
export const PrimaryButton = styled(Button)(({ theme, sx }) => ({
  [`&.${buttonBaseClasses.root}`]: {
    border: `1.5px solid #024430`,
    backgroundColor: "#024430",
    color: "#ffe7bb",
    fontWeight: "400",
    width: "100%",
    textTransform: "capitalize",
    borderRadius: "8px",
    "&:hover": {
      background: "#cde2e7",
      color: "#066a4c",
      borderColor:"#cde2e7"
    },
    ...sx,
  },
  [`&.${buttonBaseClasses.disabled}`]: {
    background: "rgba(2, 69, 49,0.5)",
    border: "rgba(2, 69, 49,0.5)",
    color: "rgba(255, 231, 187,0.5)",
  },
}));
export const PrimaryButtonForTable = styled(Button)(({ theme }) => ({
  border: `1.5px solid #024430`,
  backgroundColor: "#024430",
  color: "#ffe7bb",
  fontWeight: "400",
  width: "100px",
  textTransform: "capitalize",
  borderRadius: "8px",
  "&:hover": {
    background: "#cde2e7",
    color: "#024430",
    borderColor:"#cde2e7"
  },
}));
export const PrimaryJobButton = styled(Button)(({ theme }) => ({
  border: `.5px solid #024430`,
  backgroundColor: "#024430",
  color: "#ffe7bb",
  width: "100%",
  textTransform: "capitalize",
  borderRadius: "8px",
  "&:hover": {
    background: "#cde2e7",
    color: "#024430",
    borderColor:"#cde2e7"
  },
}));
export const PrimaryTextButton = styled(Button)(({ theme }) => ({
  border: `1.5px solid transparent`,
  backgroundColor: "transparent",
  color: "black",
  width: "100%",
  textTransform: "capitalize",
  borderRadius: "8px",
}));
export const SuccessBadge = styled(Badge)(({ theme }) => ({
  color: "#219653",
  background: "rgba(39,174,96,0.36)",
}));
export const PrimaryBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    color: "rgb(3,23,83)",
    background: "rgba(3,27,87,0.1)",
    color: "black",
  },
}));
export const SuccessTextButton = styled(Button)(({ theme }) => ({
  borderRadius: "8px",
  textTransform: "capitalize",
}));
export const PrimaryOpacitedButton = styled(Button)(({ theme }) => ({
  // border: `.5px solid rgba(3,33,83,0.3)`,
  border: "none",
  backgroundColor: "#cde2e7",
  padding: "6px 3px",
  textTransform: "capitalize",
  borderRadius: "8px",
  fontSize: "12px",
  color: "#024430",
}));

export const PrimarySaveButton = styled(Button)(({ theme }) => ({
  // border: `.5px solid rgba(3,33,83,0.3)`,
  border: "none",
  backgroundColor: "#cde2e7",
  padding: "6px 3px",
  textTransform: "capitalize",
  borderRadius: "8px",
  fontSize: "12px",
  color: "#066a4c",
}));


export const PrimaryOutlinedButton = styled(Button)(({ theme }) => ({
  border: `.5px solid #024430`,
  color: "#024430",
  width: "100%",
  borderRadius: "8px",
  fontWeight: "500",
  textTransform: "capitalize",
  "&:hover": {
    background: "#024430",
    color: "#ffe7bb",
    border: `.5px solid #081753`,
  },
}));
export const ErrorSolidButton = styled(Button)(({ theme }) => ({
  border: `.5px solid rgba(180,0,0,0.1)`,
  color: "#E44171",
  width: "100%",
  borderRadius: "8px",
  background: "#FDEDF2",
  textTransform: "capitalize",
  "&:hover": {
    // background: "rgba(180,0,0,0.2)",
    // color: "white",
  },
}));
export const SuccessSolidButton = styled(Button)(({ theme }) => ({
  border: `.5px solid rgba(0,39,20,0.1)`,
  color: "#54B78B",
  width: "100%",
  background: "rgba(84,183,143,0.1)",
  borderRadius: "8px",
  textTransform: "capitalize",
  "&:hover": {
    // background: "rgba(84,183,143,0.2)",
    // color: "white",
  },
}));
export const PrimaryOutlinedOpacitedButton = styled(Button)(({ theme }) => ({
  border: `.5px solid rgba(3,33,83,0.3)`,
  color: "#024430",
  width: "100%",
  borderRadius: "8px",
  textTransform: "capitalize",
  "&:hover": {
    background: "#cde2e7",
    borderColor: `#eeeeee`,
  },
}));
export const SecondaryFilledButton = styled(Button)(({ theme }) => ({
  border: `1.5px solid #cde2e7`,
  backgroundColor: "#cde2e7",
  color: "#024430",
  width: "100%",
  textTransform: "capitalize",
  borderRadius: "8px",
  "&:hover": {
    opacity: 0.8,
  },
}));
export const PrimarySearchButton = styled(InputBase)(({ theme }) => ({
  [`&.${inputBaseClasses.root}`]: {},
}));
export const KanbanSearchInput = styled(TextField)(({ theme }) => ({
  [`&.${textFieldClasses.root}`]: {
    backgroundColor: grey[100],
    borderRadius: "8px",
    border: `1px solid ${grey[800]}`,
    padding: "3px 9px",
    fontWeight: "600",
    height: "37px",
    minWidth: "250px",
    color: grey[800],
    fontSize: "14px",
    "&:hover": {
      border: `1px solid #081753`,
    },
    "&:focus": {
      border: `1px solid #081753`,
    },
  },
  [`&.${outlinedInputClasses.focused}`]: {
    border: `1.5px solid ${grey[800]}`,
  },
}));

export const SearchPrimaryInput = styled(InputBase)(({ theme }) => ({
  "& .MuiInputBase-input": {
    borderRadius: "8px",
    position: "relative",
    backgroundColor: "#fcfcfb",
    border: `1.5px solid ${grey[400]}`,
    fontSize: 16,
    minWidth: "200px",
    padding: "7px 12px",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),

    "&:focus": {
      border: `1.5px solid #081753`,
    },
  },
}));

export const StageSearchInput = styled(InputBase)(({ theme }) => ({
  "& .MuiInputBase-input": {
    borderRadius: "8px",
    position: "relative",
    backgroundColor: "#fcfcfb",
    border: `1.5px solid ${grey[400]}`,
    fontSize: 16,
    minWidth: "200px",
    padding: "7px 12px",
    placeholder:"Search...",
    marginBottom:"5px",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),

    "&:focus": {
      border: `1.5px solid #024430`,
    },
  },
}));

export const PrimaryNormalInputs = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  [`&.${inputBaseClasses.error}`]: {
    "& .MuiInputBase-input": {
      border: "1px solid #D60D0D !important",
      "::placeholder": {
        color: "#D60D0D",
      },
    },
  },
  [`&.${inputBaseClasses.adornedEnd}`]: {
  },


  "& .MuiInputBase-input": {
    borderRadius: "8px",
    position: "relative",
    "::placeholder": {
      color: "rgba(0,0,0,0.5)",
      fontSize: "16px",
    },
    width: "100%",
    backgroundColor: "#fcfcfb",
    border: `1px solid ${grey[400]}`,
    fontSize: 16,
    padding: "12px 12px",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),

    "&:focus": {
      border: `1px solid #024430`,
    },
  },
}));


export const DescriptionInputs = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  [`&.${inputBaseClasses.error}`]: {
    "& .MuiInputBase-input": {
      border: "1px solid #D60D0D !important",
      "::placeholder": {
        color: "#D60D0D",
      },
    },
  },
  [`&.${inputBaseClasses.adornedEnd}`]: {
  },


  "& .MuiInputBase-input": {
    borderRadius: "8px",
    position: "relative",
    "::placeholder": {
      color: "rgba(0,0,0,0.5)",
      fontSize: "16px",
    },
    width: "100%",
    minHeight:"40px",
    overflowY:"scroll",
    wordWrap:"break-word",
    backgroundColor: "#fcfcfb",
    border: `1px solid ${grey[400]}`,
    fontSize: 16,
    padding: "12px 12px",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),

    "&:focus": {
      border: `1px solid #024430`,
    },
  },
}));

export const PrimaryCurrencyInputs = styled(TextField)(({ theme }) => ({
  
  borderRadius: "8px",
  padding: "5px",
  border: "1px solid rgba(0,0,0,.5)",
  
  "& .MuiInputBase-root": {
    outline:"none",
    padding:"0px",
  
    [`& fieldset`]: {
      border:"none"
    },
    "&:before": {
      border: "none !important",
    },
    "&:after": {
      border: "none !important",
    },
    "&:hover": {
      boxShadow: "none",
    },
    "&:focus": {
      boxShadow: "none",
    },
  },

  "label + &": {
    marginTop: theme.spacing(3),
  },
  [`&.${inputBaseClasses.error}`]: {
    "& .MuiInputBase-input": {
      border: "none",
      outline:"none",
      "::placeholder": {
        color: "#D60D0D",
      },
      "&:focus": {
        border: `none`,
      },
    },
  },


  "& .MuiInputBase-input": {
    borderRadius: "8px",
    background :'transparent !important',
    "::placeholder": {
      color: "rgba(0,0,0,0.5)",
      fontSize: "16px",
    },
    width: "30% !important",

    fontSize: 16,
  
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),

    '&:focus': {
      border:"none"
    },
  },
  
 

}));


export const PrimaryNormalInputLabel = styled(InputLabel)(({ theme }) => ({
  color: "#066a4c !important",
  fontSize: "20px",
  fontWeight: "500",
  textTransform: "capitalize",
  // "& .Mui-error": {
  //   color: "#D60D0D !important",
  // },
}));
export const PrimaryNormalFormHelperText = styled(FormHelperText)(
  ({ theme }) => ({
    "& .Mui-error": {
      color: "#D60D0D !important",
    },
    "& .MuiFormHelperText-root": {
      color: "rgb(3,27,87) !important",
    },
  })
);
// export const GrayDivider = styled(Divider)(({ theme }) => ({
//   height: "3px",
//   background: grey,
// }));
export const StyledCard = styled(Card)(({ theme }) => ({
  [`&.${cardClasses.root}`]: {
    paddingBottom: "15px",
    border: "0.5px solid rgba(0,0,0,0.15)",
    // padding: "20px 15px",
    boxShadow: "none",
    borderRadius: "16px",
    transition: theme.transitions.create(["border-color"]),
    "&:hover": {
      borderColor: "#024430",
    },
  },
}));
export const StyledCardContent = styled(CardContent)(({ theme }) => ({
  [`&.${cardContentClasses.root}`]: {
    paddingBottom: "0px",
  },
}));
export const PrimaryPopUp = styled(Popover)(({ theme }) => ({
  [`&.MuiPopover-paper`]: {
    width: "500px",
    background: "red",
    padding: "20px",
    borderRadius: "8px",
    fontSize: "14px",
  },
}));
export const PrimaryPopUpForTable = styled(Popover)(({ theme }) => ({
  [`&.MuiPopover-paper`]: {
    width: "500px",
    height: "500px",
    padding: "20px",
    borderRadius: "8px",
    fontSize: "14px",
  },
}));

export const StyledProfilePopText = styled(ListItemText)(({ theme }) => ({
  fontSize: "12px !important",
  color: "black",
  fontWeight: "400",
  [`&.${listItemTextClasses.primary}`]: {
    color: "black",
    fontSize: "12px",
    fontWeight: "400",
  },
}));
export const StyledListItemButton = styled(ListItemButton)(({ theme }) => ({
  [`&.${listItemButtonClasses.root}`]: {
    fontSize: "12px",
    color: "black",
    fontWeight: "400",
  },
}));
export const PrimaryTab = styled(Tab)(({ theme }) => ({
  [`&.${tabClasses.root}`]: {
    height: "30px",
  },
  [`&.Mui-selected`]: {
    // background: "#081753",
  },
  ["&.Mui-selected"]: {
    color: "#024430",
  },
  // height: "20px",
  ":hover": {
    background: "#cde2e7",
    borderRadius: "8px",
    color:"#024430 !important"
  },
}));
export const SolidTab = styled(Tab)(({ theme }) => ({
  [`&.${tabClasses.root}`]: {
    height: "20px",
    width: "33.33333%",
    padding: "6px 10px",
    zIndex: 999,
  },
  [`&.${tabClasses.selected}`]: {
    color: "white !important",
  },
  "& .Mui-selected": {
    color: "#024430 !important",
  },

  ["&.Mui-selected"]: {
    color: "#ffe7bb !important",
  },
  // height: "20px",
  ":hover": {
    background: "#cde2e7",
    borderRadius: "8px",
    color:"#024430 !important"
  },
}));
export const Solid2Tab = styled(Tab)(({ theme }) => ({
  [`&.${tabClasses.root}`]: {
    height: "20px",
    width: "50%",
    padding: "6px 10px",
    zIndex: 999,
  },
  [`&.${tabClasses.selected}`]: {
    color: "white !important",
  },
  "& .Mui-selected": {
    color: "#ffe7bb !important",
  },

  ["&.Mui-selected"]: {
    color: "#ffe7bb !important",
  },
  // height: "20px",
  ":hover": {
    background: "#cde2e7",
    borderRadius: "8px",
    color:"#024430 !important"
  },
}));
export const StyledTabsObject = {
  style: {
    background: "#024430",
    height: "5px",
    borderTopLeftRadius: "14px",
    borderTopRightRadius: "14px",
  },
};
export const StyledLoginTabsObject = {
  style: {
    background: "#024430",
    height: "47px",
    borderRadius: "8px",
    // color:"#ffe7bb"
  },
};
export const StyledTabScrollButtonObject = {
  style: {
    height: "47px",
    zIndex: 1,
    borderTopLeftRadius: "8px",
    borderTopRightRadius: "8px",
  },
};
export const MediumPrimaryAvatar = styled(Avatar)(({ theme }) => ({
  [`&.${avatarClasses.root}`]: {
    backgroundColor: grey["A200"],
    height: "44px",
    width: "44px",
    color: "rgb(0,0,0)",
  },
}));
export const PrimaryAvatar = styled(Avatar)(({ theme }) => ({
  [`&.${avatarClasses.root}`]: {
    backgroundColor: "#cde2e7",
    height: "48px",
    width: "48px",
    color: "#024430",
  },
}));

export const ProfileAvatar = styled(Avatar)(({ theme }) => ({
  [`&.${avatarClasses.root}`]: {
    backgroundColor: "#cde2e7",
    height: "80px",
    width: "80px",
    color: "#024430",
  },
}));

export const SmallPrimaryAvatar = styled(Avatar)(({ theme }) => ({
  [`&.${avatarClasses.root}`]: {
    backgroundColor: "#cde2e7",
    height: "24px",
    fontSize: "10px",
    width: "24px",
    textTransform: "capitailze",
    color: "#024430",
  },
}));
export const CustomAvatar = styled(Avatar)(
  ({ theme, bgHeight, bgColor, bgWidth }) => ({
    [`&.${avatarClasses.root}`]: {
      backgroundColor: bgColor,
      color: "white",
      textTransform: "uppercase",
      height: bgHeight,
      fontSize: "12px",
      width: bgWidth,
    },
  })
);

export const BorderCard = styled(Card)(({ theme }) => ({
  [`&.${cardClasses.root}`]: {
    boxShadow: "none",
    width: "98%",
    borderRadius: "10px",
    border: "0.5px solid rgba(3,23,83,0.2)",
    padding:"10px",

  },
}));

export const BorderCardWork = styled(Card)(({ theme }) => ({
  [`&.${cardClasses.root}`]: {
    boxShadow: "none",
    width: "98%",
    borderRadius: "10px",
    border: "0.5px solid rgba(3,23,83,0.2)",
    padding:"10px",
    maxHeight:"400px",
    overflowY:"scroll"
  },
}));

export const PrimaryChip = styled(Chip)(({ theme }) => ({
  [`&.${chipClasses.root}`]: {
    color: "#024430",
    background: "#cde2e7",
  },
  [`&.${chipClasses.deleteIcon}`]: {
    background: "rgba(3,23,83)",
  },
  [`&.${chipClasses.deleteIconColorPrimary}`]: {
    background: "rgba(3,23,83)",
  },
  [`&.${chipClasses.iconColorPrimary}`]: {
    background: "rgba(3,23,83)",
  },
}));

export const SmallSizeChip = styled(Chip)(({theme}) =>({

  [`&.${chipClasses.root}`]: {
    color: "#024430",
    background: "#cde2e7",
  },
  [`&.${chipClasses.deleteIcon}`]: {
    background: "rgba(3,23,83)",
  },
  [`&.${chipClasses.deleteIconColorPrimary}`]: {
    background: "rgba(3,23,83)",
  },
  [`&.${chipClasses.iconColorPrimary}`]: {
    background: "rgba(3,23,83)",
  },
}))
export const HoldChip = styled(Chip)(({ theme }) => ({
  [`&.${chipClasses.root}`]: {
    color: "rgb(198, 40, 40)",
    background: "#cde2e7",
  },
  [`&.${chipClasses.deleteIcon}`]: {
    background: "rgba(3,23,83)",
  },
  [`&.${chipClasses.deleteIconColorPrimary}`]: {
    background: "rgba(3,23,83)",
  },
  [`&.${chipClasses.iconColorPrimary}`]: {
    background: "rgba(3,23,83)",
  },
}));

export const SecondaryFilledChip = styled(Chip)(({ theme }) => ({
  [`&.${chipClasses.root}`]: {
    color: "#024430",
    background: "#cde2e7",
    borderRadius: "8px",
  },
}));
export const deleteIconContainer = {
  display: "grid",
  placeItems: "center",
  padding: "2px",
  cursor: "pointer",
  background: "rgba(0,0,0,0.1)",
  borderRadius: "50%",
  color: "rgba(0,23,82)",
};
export const BorderedCardActionArea = styled(CardActionArea)(({ theme }) => ({
  [`&.${cardActionAreaClasses.root}`]: {
    border: "1px dashed rgba(3,23,83,0.3)",
    padding: "10px",
    background: "rgba(252,253,254,1)",
    height: "200px",
    display: "grid",
    width: "100%",
    placeItems: "center",
    borderRadius: "8px",
  },
}));
export const PrimaryIconButton = styled(IconButton)(({ theme }) => ({
  [`&.${iconButtonClasses.root}`]: {
    color: "rgb(3,23,83)",
  },
}));
export const Primarymenu = styled(Menu)(({ theme }) => ({
  "& .MuiPaper-root": {
    width: 250,
    background: "white",
    marginTop: "10px",
    fontSize: "14px",
    padding: "10px 0px",
    borderRadius: "10px",
    boxShadow: "rgba(17, 12, 46, 0.15) 0px 48px 100px 0px",
  },
  [`&.${menuClasses.root}`]: {
    width: 250,
    background: "white",
    marginTop: "10px",
    fontSize: "14px",
    padding: "10px 0px",
    borderRadius: "10px",
    boxShadow: "rgba(17, 12, 46, 0.15) 0px 48px 100px 0px",
  },
  [`&.${menuClasses.list}`]: {
    width: 250,
    background: "white",
    marginTop: "10px",
    fontSize: "14px",
    padding: "10px 0px",
    borderRadius: "10px",
    boxShadow: "rgba(17, 12, 46, 0.15) 0px 48px 100px 0px",
  },
}));
export const SuccessSlider = styled(Slider)(({ theme }) => ({
  "& .MuiSlider-mark": {
    background: "transparent",
    "&.MuiSlider-markActive": {
      background: "transparent",
    },
  },
  "& .MuiSlider-markLabel": {
    color: "grey",
    "&.MuiSlider-markLabelActive": {
      color: "green",
    },
  },
  "& .MuiSlider-rail": {
    height: "7px",
    background: "#455A64",
  },
  "& .MuiSlider-track": {
    border: "none",
    height: "7px",
    background: "#455A64",
  },
  "& .MuiSlider-thumb": {
    background: "white",
    border: "2px solid #455A64",
    transition: "boxShadow 500ms 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    "&:hover": {
      boxShadow: "0 0 0 8px rgba(58, 133, 137, 0.16)",
    },
    "&:active": {
      boxShadow: "0 0 0 12px rgba(58, 133, 137, 0.16)",
    },
    "&.visited": {
      boxShadow: "none",
    },
  },
  "& .MuiSlider-valueLabel": {
    lineHeight: 1.2,
    fontSize: 12,
    background: "unset",
    padding: 0,
    width: 0,
    display: "none",
    height: 0,
    "&:before": { display: "none" },
    "&.MuiSlider-valueLabelOpen": {
      transform: "translate(50%, -100%) rotate(-45deg) scale(1)",
    },
    "& > *": {
      transform: "rotate(45deg)",
    },
  },
}));

export const CustomSlider = styled(Slider)(
  ({ theme, backgrounds, thumb__hover_color }) => ({
    "& .MuiSlider-mark": {
      background: "transparent",
      "&.MuiSlider-markActive": {
        background: "transparent",
      },
    },
    "& .MuiSlider-markLabel": {
      color: "grey",
      "&.MuiSlider-markLabelActive": {
        color: backgrounds,
      },
    },
    ...(backgrounds && {
      "& .MuiSlider-rail": {
        height: "7px",
        background: backgrounds,
      },
    }),
    ...(backgrounds && {
      "& .MuiSlider-track": {
        border: "none",
        height: "7px",
        background: backgrounds,
      },
    }),
    ...(backgrounds && {
      "& .MuiSlider-thumb": {
        background: "white",
        border: `2px solid ${backgrounds}`,
        transition: "boxShadow 500ms 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        "&:hover": {
          boxShadow: `0 0 0 8px ${thumb__hover_color}`,
        },
        "&:active": {
          boxShadow: `0 0 0 12px ${thumb__hover_color}`,
        },
        "&.visited": {
          boxShadow: "none",
        },
      },
    }),
    "& .MuiSlider-valueLabel": {
      lineHeight: 1.2,
      fontSize: 12,
      background: backgrounds,
      padding: 0,
      width: "40px",
      // display: "",
      height: "40px",
      "&:before": { display: "none" },
      "&.MuiSlider-valueLabelOpen": {
        transform: "translate(50%, -100%) rotate(-0deg) scale(1)",
      },
      "& > *": {
        transform: "rotate(0deg)",
      },
    },
  })
);

export const SuccessCircularProgress = styled(CircularProgress)(
  ({ theme,strokeColor }) => ({
    [`&.${circularProgressClasses.root}`]: {
      transform: "rotate(180deg) !important",
      width: "200px !important",
      height: "200px !important",
    },
    "& .MuiCircularProgress-circle": {
      stokeWidth: "19px",
      stroke: `${strokeColor?strokeColor:"#2DA13F"}`,
      strokeLinecap: "round",
    },
    "& .MuiCircularProgress-svg": {
      width: "200px",
      height: "200px",
    },
  })
);
export const FetchingCircularLoader = styled(CircularProgress)`
color:"#081753",
height:"500px",
width:"500px"
`

export const SecondaryCircularProgress = styled(CircularProgress)(
  ({ theme }) => ({
    [`&.${circularProgressClasses.root}`]: {
      transform: "rotate(180deg) !important",
      width: "200px !important",
      height: "200px !important",
      // background: "rgba(58, 133, 137, 0.16)",
    },
    [`&.${circularProgressClasses.circle}`]: {
      stroke: "red",
    },
    "& .MuiCircularProgress-circle": {
      stroke: "rgba(218,218,218,0.5)",
      strokeLinecap: "round",
      stokeWidth: "19px",
    },
    "& .MuiCircularProgress-svg": {
      width: "200px",
      height: "200px",
    },
  })
);
export const StyledTootlTip = styled(Tooltip)(({ theme }) => ({
  "&.MuiTooltip-tooltip": {
    background: "red !important",
    "&.MuiTooltip-tooltipPlacementBottom": {
      backgroundColor: "red !important",
    },
  },
  "& .MuiTooltip-popper": {
    background: "red !important",
  },
}));

export const PrimaryTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} arrow />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: "rgba(3,27,87,0.2)",
    marginRight: "30px",
    right: "80px",
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "rgba(3,27,87,0.2)",
    color: "rgb(3,27,87)",
    maxWidth: 220,
    borderRadius: "20px",
    padding: "10px",
    fontSize: theme.typography.pxToRem(12),
  },
}));
export const ColorlibStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  zIndex: 1,
  color: "#024430",
  background: "transparent",
  width: 20,
  height: 20,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  ...(ownerState.active && {
    background: "#ffe7bb",
    color: "#066a4c",
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  }),
  ...(ownerState.completed && {
    background: "#024430",
    color: "#ffe7bb",
  }),
}));

export const PrimaryStepLabel = styled(StepLabel)(({ theme }) => ({
  "& .MuiStepLabel-iconContainer": {
    padding: "20px !important",
    color: "#024430",
    background: "#cde2e7",
    margin: "0px 4px",
    fontWeight: "bolder",
    borderRadius: "50%",
  },
  // [`&.${stepLabelClasses.completed}`]: {
  //   background: "red !important",
  //   "& .MuiStepLabel-iconContainer": {
  //     background: "rgb(3,23,83) !important",
  //     color: "white !important",
  //   },
  // },
  "&.Mui-completed>.MuiStepLabel-root>.MuiStepLabel-iconContainer": {
    background: "rgb(3,23,83) !important",
    color: "white !important",
  },
}));
export const QontoStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  color: theme.palette.mode === "dark" ? theme.palette.grey[700] : "#eaeaf0",
  display: "flex",
  height: 22,
  alignItems: "center",
  ...(ownerState.active && {
    color: "#0A6738",
  }),
  "& .QontoStepIcon-completedIcon": {
    color: "white",
    zIndex: 1,
    borderRadius: "50%",
    background: "#0A6738",
    fontSize: 18,
  },
  "& .QontoStepIcon-circle": {
    width: 8,
    height: 8,
    borderRadius: "50%",
    backgroundColor: "currentColor",
  },
}));

export const SuccessStepLabel = styled(StepLabel)(({ theme }) => ({
  "& .MuiStepLabel-iconContainer": {
    padding: "10px !important",
    color: "white",
    background: "#0A6738",
    fontWeight: "bolder",
    borderRadius: "50%",
  },
  "& .MuiStepLabel-root": {
    width: "164px",
  },
  "& .Mui-completed>&.MuiStepLabel-root> &.MuiStepLabel-iconContainer": {
    background: "#0A6738 !important",
  },
  "& .Mui-Active>&.MuiStepLabel-root> &.MuiStepLabel-iconContainer": {
    background: "#0A6738 !important",
  },
}));
export const SuccessStepConnector = styled(StepConnector)(({ theme }) => ({
  // "& .MuiStepConnector-root": {
  //   width: "200px",
  // },
  "& .MuiStepConnector-line": {
    borderColor: "#CED1DD",
    marginTop: "2px",
    borderRadius: "8px",
    borderWidth: "3px",
    marginLeft: "10px",
    marginRight: "10px",
  },
  "&.Mui-completed>.MuiStepConnector-line": {
    borderColor: "#0A6738 !important",
  },
  "&.Mui-active>.MuiStepConnector-line": {
    borderColor: "#0A6738 !important",
  },
}));

export const PrimaryStepConnector = styled(StepConnector)(({ theme }) => ({
  "& .MuiStepConnector-line": {
    borderColor: "#CED1DD",
    marginTop: "14px",
    marginLeft: "10px",
    marginRight: "10px",
  },
  "&.Mui-completed>.MuiStepConnector-line": {
    borderColor: "green !important",
  },
  "&.Mui-active>.MuiStepConnector-line": {
    borderColor: "green !important",
  },
}));
export const PrimaryStep = styled(Step)(({ theme }) => ({
  "& .Mui-completed": {
    borderColor: "red",
  },
}));
export const LargeChips = styled(Chip)(({ theme }) => ({
  [`&.${chipClasses.root}`]: {
    display: "flex",
    flexDirection: "column",
    width: "100px",
    height: "100px",
    borderRadius: "8px",
  },
  [`&.${chipClasses.deleteIcon}`]: {
    background: "green",
    borderRadius: "50%",
  },
}));
export const SecondaryToggleButtonGroup = styled(ToggleButtonGroup)(
  ({ theme }) => ({
    "& .MuiToggleButtonGroup-grouped": {
      "&:first-of-type": {
        borderTopLeftRadius: "8px",
        borderBottomLeftRadius: "8px",
      },
      "&:last-of-type": {
        borderTopRightRadius: "8px",
        borderBottomRightRadius: "8px",
      },
    },
  })
);
export const PrimaryToggleButtonGroup = styled(ToggleButtonGroup)(
  ({ theme }) => ({
    "&.MuiToggleButtonGroup-root": {},
    "& .MuiToggleButtonGroup-grouped": {
      margin: "10px",
      border: "0.5px solid rgba(0,0,0,0.5) !important",
      borderRadius: "8px !important",
    },
  })
);

export const PrimaryToggleButton = styled(ToggleButton)(({ theme }) => ({
  [`&.${toggleButtonClasses.root}`]: {
    // color: "rgb(3,27,87)",
  },
  [`&.${toggleButtonClasses.selected}`]: {
    color: "#ffe7bb ",
    background: "#024430",
  },
}));
export const PrimaryContrastedToggleButton = styled(ToggleButton)(
  ({ theme }) => ({
    [`&.${toggleButtonClasses.root}`]: {
      // color: "rgb(3,27,87)",
    },
    [`&.${toggleButtonClasses.selected}`]: {
      color: "white",
      background: "rgb(3,27,87)",
      "&:hover": {
        background: "rgba(3,27,87,0.9)",
      },
    },
  })
);
export const PrimaryContrastedSmallToggleButton = styled(ToggleButton)(
  ({ theme }) => ({
    [`&.${toggleButtonClasses.root}`]: {
      // color: "rgb(3,27,87)",
      width: "auto",
      height: "30px",
      textTransform: "capitalize",
    },
    [`&.${toggleButtonClasses.selected}`]: {
      color: "#ffe7bb",
      background: "#024430",
      "&:hover": {
        background: "#cde2e7",
        color: "#066a4c"
      },
    },
  })
);

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
export const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
      marginLeft: "30px",
      borderRadius: "10px",
      boxShadow: "rgba(17, 12, 46, 0.15) 0px 48px 100px 0px",
    },
  },
};
export const autocompleteProps = {
  popper: {
    style: {
      borderRadius: "8px",
      width: "100%",
      maxWidth: "400px",
      // boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.2)",
    },
  },
  paper: {
    style: {
      borderRadius: "8px",
      width: "100%",
      maxWidth: "400px",
      boxShadow: "rgba(17, 12, 46, 0.15) 0px 48px 100px 0px",
    },
  },
};
export const PrimaryAutoComplete = styled(Autocomplete)(({ theme }) => ({
  "& .MuiAutocomplete-paper": {
    borderRadius: "10px",
    boxShadow: "rgba(17, 12, 46, 0.15) 0px 48px 100px 0px",
  },
  "& .MuiAutocomplete-popper": {
    borderRadius: "10px",
    boxShadow: "rgba(17, 12, 46, 0.15) 0px 48px 100px 0px",
  },
}));
export const MultipleSelectInputs = styled(TextField)(({ theme }) => ({
  marginTop: "20px !important",
  borderRadius: "8px",
  padding: "10px",
  border: "1px solid rgba(0,0,0,.5)",
  "& .MuiInputBase-root": {
    "&:before": {
      border: "none !important",
    },
    "&:after": {
      border: "none !important",
    },
  },
}));
export const MultipleSelectInputsForTable = styled(TextField)(({ theme }) => ({
  borderRadius: "8px",
  "label + &": {
    display: "none",
  },
  "& >label": {
    display: "none",
  },
  padding: "0px !important",
  border: "1px solid rgba(0,0,0,.5)",
  "& .MuiInputBase-root": {
    "&:before": {
      border: "none !important",
    },
    "&:after": {
      border: "none !important",
    },
  },
}));
export const SecondaryFilledBox = styled(Box)(({ theme }) => ({
  background: "#ffe7bb",
  borderRadius: "8px",
  minWidth: "80px",
  maxWidth: "200px",
  width: "auto",

  color: "#024430",
  padding: "5px",
  // marginBottom: "10px",
}));

export const PrimaryBox = styled(Box)(({ theme }) => ({
  background: "#024430",
  color: "white",
  borderRadius: "8px",
  // marginBottom: "10px",
  minWidth: "80px",
  maxWidth: "200px",
  width: "auto",
  padding: "5px",
}));

export const ProfileSectionBox = styled(Box)(({ theme }) => ({
  // background: "#024430",
  // color: "white",
  border:"0.2px solid #BABABA",
  borderRadius: "4px",
  width:"100%",
  wordWrap:"break-word",
  padding: "12px",
  
}));


export const TimeTypography = styled(Typography)(({ theme }) => ({
  color: "rgba(0,0,0,0.5)",
  fontSize: "10px",
  width: "50px",
}));

export const LargeSemiboldTypography = {
  fontWeight: "500",
  textTransform: "capitalize",
  fontSize: "28px",
};
export const successNotification = {
  color: "#219653",
  background: "rgba(39,174,96,0.36)",
  borderRadius: "50%",
  width: "18px",
  display: "grid",
  placeItems: "center",
  height: "18px",
};

export const PrimarySwitch = styled(Switch)(({ theme }) => ({
  width: 52,
  height: 34,
  padding: 7,
  "& .MuiSwitch-switchBase": {
    margin: 1,
    padding: 0,
    transform: "translateX(6px)",
    "&.Mui-checked": {
      color: "#fff",
      transform: "translateX(22px)",

      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: "#cde2e7",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    marginTop: "5px",
    backgroundColor: "#024430",
    width: 22,
    height: 22,
    "&:before": {
      content: "''",
      position: "absolute",
      width: "100%",
      height: "100%",
      left: 0,
      top: "10px",
    },
  },
  "& .MuiSwitch-track": {
    opacity: 1,
    backgroundColor: "#ffe7bb",
    borderRadius: 20 / 2,
  },

  // "& .MuiSwitch-switchBase.Mui-checked": {
  //   color: "rgba(3,27,87,0.6)",
  //   "&:hover": {
  //     backgroundColor: alpha("rgba(3,27,87,0.6)", "rgba(3,27,87,0.8)"),
  //   },
  // },
  // "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
  //   backgroundColor: "rgba(3,27,87,0.2)",
  // },
}));

export const PrimaryDataGrid = styled(DataGrid)(({ theme }) => ({
  border: "none",
  "div:first-of-type": {
    // display: "flex ",
    // justifyContent: "flex-end",
  },
  "& .MuiDataGrid-toolbarContainer": {
    marginBottom: "20px",
  },
  "&	.MuiDataGrid-main": {
    marginBottom: "20px",
  },
  "& .MuiTablePagination-root *":{
    marginBottom:0
  },
  "& .MuiDataGrid-columnHeader":{
    color:"#024430 !important"
  }
}));

export const PrimaryPaperComponent = styled(Paper)(({ theme }) => ({
  [`&.${paperClasses.rounded}`]: {
    borderRadius: "8px",
    width: "100%",
    padding: "20px",
    height: "280px",
    background: "rgba(3,23,83,0.3)",
    color: "rgb(3,23,83)",
  },
}));

export const SecondaryCard = styled(Card)(({ theme, sx }) => ({
  [`&.${cardClasses.root}`]: {
    background: "white",
    borderRadius: "8px",
    minHeight: "150px",
    height: "100%",
    boxShadow: "rgba(17, 12, 46, 0.15) 0px 48px 100px 0px",
    padding: "10px",
    ...sx,
  },
}));

export const ScoreDisplayCard = styled(Card)(({ theme, sx }) => ({
  [`&.${cardClasses.root}`]: {
    backgroundColor: "#f5f5f5",
    borderRadius: 8,
    padding: 16,
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    margin: "8px",
    [theme.breakpoints.down("sm")]: {
      padding: 12,
    },
  },
}));

export const ScoreDisplayCardContent = styled(CardContent)(({ theme, sx }) => ({
  [`&.${cardContentClasses.root}`]: {
    textAlign: "center",
  },
}));

export const SkillTypography = styled(Typography)(({ theme, sx }) => ({
  [`&.${typographyClasses.root}`]: {
    fontSize: 16,
    fontWeight: 600,
    marginBottom: 8,
    [theme.breakpoints.down("sm")]: {
      fontSize: 18,
    },
  },
}));

export const CommentTypography = styled(Typography)(({ theme, sx }) => ({
  [`&.${typographyClasses.root}`]: {
    fontSize: 14,
    marginBottom: 16,
    [theme.breakpoints.down("sm")]: {
      fontSize: 12,
    },
  },
}));

export const SkillRating = styled(Rating)(({ theme, sx }) => ({
  [`&.${ratingClasses.root}`]: {
    color: "#ffd700",
    fontSize: 24,
    marginBottom: 8,
    [theme.breakpoints.down("sm")]: {
      fontSize: 20,
    },
  },
}));


/* Notifications Page(settings) css styles start here */

export const NotificationTypography = styled(Typography)(({ theme, sx }) => ({
  [`&.${typographyClasses.root}`]: {
    fontSize: 16,
    fontWeight: 500,
    marginBottom: 8,
    color:"#024430",
    [theme.breakpoints.down("sm")]: {
      fontSize: 18,
    },
  },
}));

export const MiddleDivider = styled(Divider)(({ theme }) => ({
  [`&.${dividerClasses.root}`]: {
    borderWidth:"0.1px",
    borderColor:"#081753",
    width:"100%",
    marginTop:"10px"
  },
}));

export const NotificationTitleTypography = styled(Typography)(({ theme }) => ({
  [`&.${typographyClasses.root}`]: {
   color:"#024430",
   fontWeight:500,
   fontFamily:"Inter",
   fontSize:"16px"
  },
}));

export const NotificationDescTypography = styled(Typography)(({ theme }) => ({
  [`&.${typographyClasses.root}`]: {
   color:"#024430",
   fontWeight:450,
   fontFamily:"Inter",
   fontSize:"14px"
  },
}));

export const NotificationDescTextTypography = styled(Typography)(({ theme }) => ({
  [`&.${typographyClasses.root}`]: {
   color:"black",
   fontWeight:400,
   fontFamily:"Inter",
   fontSize:"13px",
   opacity:"50%",
   padding:"5px"
  },
}));

/* Notifications Page(settings) css styles end here */

/* This is used for No rows overlay in datagrid start*/
export const StyledGridOverlay = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
  '& .ant-empty-img-1': {
    fill: theme.palette.mode === 'light' ? '#aeb8c2' : '#262626',
  },
  '& .ant-empty-img-2': {
    fill: theme.palette.mode === 'light' ? '#f5f5f7' : '#595959',
  },
  '& .ant-empty-img-3': {
    fill: theme.palette.mode === 'light' ? '#dce0e6' : '#434343',
  },
  '& .ant-empty-img-4': {
    fill: theme.palette.mode === 'light' ? '#fff' : '#1c1c1c',
  },
  '& .ant-empty-img-5': {
    fillOpacity: theme.palette.mode === 'light' ? '0.8' : '0.08',
    fill: theme.palette.mode === 'light' ? '#f5f5f5' : '#fff',
  },
}));

/* This is used for No rows overlay in datagrid End*/

export const PrimaryCheckbox = styled(Checkbox)(({theme})=>({
  [`&.${checkboxClasses.root}`]:{
    '&.Mui-checked': {
      color: "success",
    },
  }


}))

export const NotificationPopUp = styled(Popover)(({ theme }) => ({
  '.MuiPopover-root':{
    height:"500px",
    width:"400px !important",
    padding:"0px !important",
    boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px !important"
  },
  '.MuiPopover-paper':{
    height:"500px",
    marginTop: "30px",
    width:"400px !important",
    padding:"0px !important",
    boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px !important"
    // '&::-webkit-scrollbar': {
    //   // width: '0.4em', // Width of the scrollbar
    //   background: 'transparent', // Set the scrollbar background color to transparent
    // },
    // '&::-webkit-scrollbar-thumb': {
    //   background: 'transparent', // Set the scrollbar thumb color to transparent
    // },
  }
}));


export const NotificationHeader = styled(Box)({
  height:"50px",
  width:"100%",
  padding:"12px",
  // top:"51px !important",
  // position:"fixed",
  backgroundColor:"rgba(234, 242, 245, 1)",
  borderRadius:'4px 4px 0px 0px'
})


export const StyledListCard = styled(Card)(({ theme }) => ({
  [`&.${cardClasses.root}`]: {
    // paddingBottom: "15px",
    border: "0.5px solid rgba(0, 0, 0, 0.15)",
    padding: "8px",
    boxShadow: "none",
    marginBottom:"8px",
    borderRadius: "4px",
    transition: theme.transitions.create(["border-color"]),
    "&:hover": {
      borderColor: "rgba(234, 242, 245, 1)",
      backgroundColor:"#cde2e7 !important"
    },
  },
}));

/*New Joinee styles */ 

export const PrimaryJoineeBox = styled(Box)(({ theme }) => ({
  background: "rgba(178, 209, 200, 0.14)",
  // color: "white",
  borderRadius: "8px",
  // border:"1px solid red",
  // marginBottom: "10px",
  // minWidth: "80px",
  // maxWidth: "200px",
  height:"300px",
  width:"100%",
  textAlign:"center",
  // width: "auto",
  padding: "5px",
}));

export const SubscribeInputEle = styled(InputBase)(({ theme }) => ({
  "& .MuiInputBase-input": {
    borderRadius: "24px",
    position: "relative",
    backgroundColor: "rgba(255, 255, 255, 1)",
    // border: `0.8px solid ${grey[400]}`,
    border:`0.8px solid rgba(178, 209, 200, 0.14)`,
    fontSize: 16,
    // minWidth: "400px",
    // width:"100%",
    padding: "7px 12px",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),

    "&:focus": {
      // border: `1.5px solid #081753`,
      border:`0.3px solid rgba(233, 233, 233, 1)`
    },
  },
}));

export const NewsletterInput = styled(InputBase)(({ theme }) => ({
  "& .MuiInputBase-input": {
    borderRadius: "24px",
    position: "relative",
    backgroundColor: "rgba(255, 255, 255, 1)",
    border:`none`,
    fontSize: 16, 
    padding: "7px 12px",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),

    "&:focus": {
      border:`none`,
    },
  },
}));

export const PrimaryJoineeButton = styled(Button)(({ theme, sx }) => ({
  [`&.${buttonBaseClasses.root}`]: {
    // border: `1.5px solid #024430`,
    backgroundColor: "rgba(5, 96, 69, 1)",
    color: "rgba(233, 235, 235, 1)",
    fontWeight: "400",
    // width: "20%",
    // width:"auto",
    textTransform: "capitalize",
    borderRadius: "24px",
    "&:hover": {
      background: "rgba(178, 209, 200, 0.14)",
      color: "#066a4c",
      // borderColor:"#cde2e7"
    },
    ...sx,
  },
  [`&.${buttonBaseClasses.disabled}`]: {
    background: "rgba(2, 69, 49,0.5)",
    border: "rgba(2, 69, 49,0.5)",
    color: "rgba(255, 231, 187,0.5)",
  },
}));

