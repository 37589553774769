import React, { useState, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import SearchIcon from "@mui/icons-material/Search";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
// import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import Option from "@mui/joy/Option";
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import Pagination from "@mui/material/Pagination";
import {styleObj} from '../../assets/MUI/useStyles';
import {DataGridStyles} from '../../assets/MUI/useStyles';



function WithDrawTable1(props) {
  const [page, setPage] = useState(1);
  const classes = DataGridStyles()
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const rowsData = props.withDrawTableData?.map((item, index) => {
    return {
      id: index,
      Amount: `${item?.finalCommission}`,
      Date: `${item?.date}`,
      Time: `${item?.date}`,
      PaymentStatus: "Pending",
      company: `${item?.email}`,
      Date: `${item?.date}`,
      Amount: `${item?.finalCommission}`,
    };
  });
  

  const columns = [
    {
      field: "Amount",
      headerClassName: classes.headerCellStyles,
      headerName: "Amount",
      minWidth: 250,
      flex:1,
      renderCell: ({ value }) => <span style={styleObj}>{value}</span>,
    },
    {
      field: "Date",
      headerClassName: classes.headerCellStyles,
      headerName: "Date",
      minWidth: 250,
      flex:1,
      renderCell: ({ value }) => (
        <span style={styleObj}>{value.slice(0, 10)}</span>
      ),
    },
    {
      field: "Time",
      headerClassName: classes.headerCellStyles,
      headerName: "Time",
      minWidth: 250,
      flex:1,
      renderCell: ({ value }) => (
        <span style={styleObj}>{value.slice(0, 10)}</span>
      ),
    },
    
    {
      field: "PaymentStatus",
      headerClassName: classes.headerCellStyles,
      style: { color: "red" },
      headerName: "Payment Status",
      minWidth: 250,
      flex:1,
      renderCell: ({ value }) => <span style={styleObj}>{value}</span>,
    },
  ];

  return (
    <>
      <div style={{ height: "60vh", width: "98%" }}>
        {props.withDrawTableData && (
          <DataGrid
            rows={rowsData}
            columns={columns}
            disableColumnSelector
            disableDensitySelector
            disableRowSelectionOnClick
            // style={{ border: "0px" }}
            components={{
              Pagination: ({ state, api }) => (
                <Pagination
                  {...state}
                  onChangePage={(event, newPage) => api.setPage(newPage)}
                  onChangeRowsPerPage={() => {}}
                  style={{ display: 'none' }} // Hide the rows per page selection
                />
              ),
            }}
          />
        )}
      </div>
    </>
  );
}

export default WithDrawTable1;
