import React, { useEffect } from "react";
import "../../../assets/css/index.css";
import AppStore from "../../../../src/assets/images/infoImages/AppStore.png";
import GooglePlay from "../../../../src/assets/images/infoImages/GooglePlay.png";
import GooglePlay2 from "../../../../src/assets/images/infoImages/googlepalyq.png";
import AppStore2 from "../../../../src/assets/images/infoImages/appstorew.png";
import FooterJobsPic from '../../../assets/images/infoImages/footerJobs.jpg';
import TrendingJobsImage from '../../../assets/images/infoImages/trendingJobsImage.png';
function FooterSection() {
  const trendingJobs = [
    "Software Engineer",
    "Data Analyst",
    "Marketing Manager",
    "Product Designer",
    "Sales Representative",
    "HR Generalist",
    "Operations Manager",
    "Accountant",
    "Customer Support Specialist",
    "Project Manager",
  ];
  // useEffect(() => {
  //   callJobs();
  // }, []);
  // function callJobs() {
  //   const container = document.querySelector(".trending-jobs");
  //   trendingJobs.forEach(job => {
  //     const listing = document.createElement("div");
  //     listing.classList.add("job_listing");
  //     listing.textContent = job;
  //     container.appendChild(listing);
  //   });
  // }

  return (
    <>
      <div>

        <footer
          className="footer_area section_padding_130_0"
          style={{ backgroundColor: "aliceblue" }}
        >
          <div className="container">
            <div className="row">
              <div className="col-12 col-sm-6 col-lg">
                <div className="single-footer-widget section_padding_0_130">
                  <h5
                    className="widget-title"
                    style={{ fontFamily: "sans-serif", fontWeight: "550", color: "" }}
                  >
                    Trending Jobs
                  </h5>
                  <div className="footer_menu">
                    <ul
                      style={{
                        fontFamily: " Bold ,sans-serif, Arial, Helvetica",
                      }}
                    >
                      <li>
                        <a href="#">React Developer</a>
                      </li>
                      <li>
                        <a href="#">Angular Developer</a>
                      </li>
                      <li>
                        <a href="#">Python Developer</a>
                      </li>
                      <li>
                        <a href="#">IOT Developer</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-lg">
                <div className="single-footer-widget section_padding_0_130">
                  <h5
                    className="widget-title"
                    style={{ fontFamily: "sans-serif", fontWeight: "550", color: "" }}
                  >
                    Trending Jobs
                  </h5>
                  <div className="footer_menu">
                    <ul
                      style={{
                        fontFamily: " Bold ,sans-serif, Arial, Helvetica",
                      }}
                    >
                      <li>
                        <a href="#">React Developer</a>
                      </li>
                      <li>
                        <a href="#">Angular Developer</a>
                      </li>
                      <li>
                        <a href="#">Python Developer</a>
                      </li>
                      <li>
                        <a href="#">IOT Developer</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-lg">
                <div className="single-footer-widget section_padding_0_130">
                  <h5
                    className="widget-title"
                    style={{ fontFamily: "sans-serif", fontWeight: "550", color: "" }}
                  >
                    Trending JobsTypes
                  </h5>
                  <div className="footer_menu">
                    <ul
                      style={{
                        fontFamily: " Bold ,sans-serif, Arial, Helvetica",
                      }}
                    >
                      <li>
                        <a href="#">Work from home</a>
                      </li>
                      <li>
                        <a href="#">Remote Jobs</a>
                      </li>
                      <li>
                        <a href="#">Hybrid Model</a>
                      </li>
                      <li>
                        <a href="#">Freelancer</a>
                      </li>

                      {/* <li>
                    <a href="#">Help &amp; Support</a>
                  </li> */}
                    </ul>
                  </div>
                </div>
              </div>

              <div className="col-12 col-sm-6 col-lg">
                <div className="single-footer-widget section_padding_0_130">
                  <h5
                    className="widget-title"
                    style={{ fontFamily: "sans-serif", fontWeight: "550", color: "" }}
                  >
                    Trending Categories
                  </h5>
                  <div className="footer_menu">
                    <ul
                      style={{
                        fontFamily: " Bold ,sans-serif, Arial, Helvetica",
                      }}
                    >
                      <li>
                        <a href="#">IT & Information Technology</a>
                      </li>
                      <li>
                        <a href="#">Embedded Services</a>
                      </li>
                      <li>
                        <a href="#">Design & Manufacturing</a>
                      </li>
                      <li>
                        <a href="#">Sales</a>
                      </li>
                      {/* <li>
                    <a href="#">Help &amp; Support</a>
                  </li> */}
                    </ul>
                  </div>
                </div>
              </div>




              <div className="col-12 col-sm-6 col-lg">
                <div className="single-footer-widget section_padding_0_130">
                  <h5
                    className="widget-title"
                    style={{ fontFamily: "sans-serif", fontWeight: "550", color: "" }}
                  >
                    Top Hiring Companies
                  </h5>
                  <div className="footer_menu">
                    <ul
                      style={{
                        fontFamily: " Bold ,sans-serif, Arial, Helvetica",
                      }}
                    >
                      <li>
                        <a href="#">
                          TCS
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          Shifyt
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          Cognizant
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          Virtusa
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <hr style={{ height: "1px", width: "100%" }} />


        </footer>

        <hr />

        <footer
          className="footer_area section_padding_130_0"
          style={{ backgroundColor: "#081753" }}
        >
          <div className="container">
            <div className="row">
              <div className="col-12 col-sm-6 col-lg-4">
                <div className="single-footer-widget section_padding_0_130">
                  <div className="footer-logo"></div>
                  <a href="">
                    {/* Stay updated on your professional world with{" "} */}
                    <img
                      src="https://res.cloudinary.com/dt6u679fs/image/upload/v1663336534/MicrosoftTeams-image_5_ngznfx.png"
                      alt="Shifyt"
                      className="pb-3"
                      width="200px"
                    />
                  </a>
                  <p
                    className=""
                    style={{ fontFamily: " Bold ,sans-serif, Arial, Helvetica", color: "#ffffff" }}
                  >
                    Found perfect job<i className="lni-heart mr-1"></i> with{" "}
                    <a
                      className="ml-1"
                      href="https://wrapbootstrap.com/user/DesigningWorld"
                    >
                      Shifyt
                    </a>
                  </p>
                  <div className="copywrite-text mb-4"></div>
                  <div className="footer_social_area">
                    <a
                      href="https://www.linkedin.com/company/Shifyt/"
                      data-toggle="tooltip"
                      target="_blank"
                      data-placement="top"
                      title=""
                      data-original-title="Pinterest"
                      style={{ backgroundColor: "#bad9f7" }}
                    >
                      <i className="fa fa-linkedin"></i>
                    </a>
                    <a
                      href="https://twitter.com/tryShifyt"
                      data-toggle="tooltip"
                      target="_blank"
                      data-placement="top"
                      title=""
                      data-original-title="Skype"
                      style={{ backgroundColor: "#bad9f7" }}
                    >
                      <i className="fa fa-twitter"></i>
                    </a>
                    <a
                      href="https://www.facebook.com/tryShifyt"
                      data-toggle="tooltip"
                      target="_blank"
                      data-placement="top"
                      title=""
                      data-original-title="Twitter"
                      style={{ backgroundColor: "#bad9f7" }}
                    >
                      <i className="fa fa-facebook"></i>
                    </a>
                    <a
                      href="https://www.instagram.com/tryShifyt/"
                      data-toggle="tooltip"
                      target="_blank"
                      data-placement="top"
                      title=""
                      data-original-title="Facebook"
                      style={{ backgroundColor: "#bad9f7" }}
                    >
                      <i className="fa fa-instagram"></i>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-lg">
                <div className="single-footer-widget section_padding_0_130">
                  <h5
                    className="widget-title"
                    style={{ fontFamily: "sans-serif", fontWeight: "550", color: "#ffffff" }}
                  >
                    About
                  </h5>
                  <div className="footer_menu">
                    <ul
                      style={{
                        fontFamily: " Bold ,sans-serif, Arial, Helvetica",
                      }}
                    >
                      <li>
                        <a href="#">About Us</a>
                      </li>
                      <li>
                        <a href="#">Our Company</a>
                      </li>
                      <li>
                        <a href="#">Our Team</a>
                      </li>
                      <li>
                        <a href="#">Privacy Policy</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-lg">
                <div className="single-footer-widget section_padding_0_130">
                  <h5
                    className="widget-title"
                    style={{ fontFamily: "sans-serif", fontWeight: "550", color: "#ffffff" }}
                  >
                    Trending Jobs
                  </h5>
                  <div className="footer_menu">
                    <ul
                      style={{
                        fontFamily: " Bold ,sans-serif, Arial, Helvetica",
                      }}
                    >
                      <li>
                        <a href="#">React Developer</a>
                      </li>
                      <li>
                        <a href="#">Angular Developer</a>
                      </li>
                      <li>
                        <a href="#">Fullstack Developer</a>
                      </li>
                      <li>
                        <a href="#">Site Engineers</a>
                      </li>

                      {/* <li>
                    <a href="#">Help &amp; Support</a>
                  </li> */}
                    </ul>
                  </div>
                </div>
              </div>

              <div className="col-12 col-sm-6 col-lg">
                <div className="single-footer-widget section_padding_0_130">
                  <h5
                    className="widget-title"
                    style={{ fontFamily: "sans-serif", fontWeight: "550", color: "#ffffff" }}
                  >
                    Quick Links
                  </h5>
                  <div className="footer_menu">
                    <ul
                      style={{
                        fontFamily: " Bold ,sans-serif, Arial, Helvetica",
                      }}
                    >
                      <li>
                        <a href="#">Home</a>
                      </li>
                      <li>
                        <a href="#">Jobs</a>
                      </li>
                      <li>
                        <a href="#">Companies</a>
                      </li>
                      <li>
                        <a href="#">Contact Us</a>
                      </li>
                      {/* <li>
                    <a href="#">Help &amp; Support</a>
                  </li> */}
                    </ul>
                  </div>
                </div>
              </div>




              <div className="col-12 col-sm-6 col-lg">
                <div className="single-footer-widget section_padding_0_130">
                  <h5
                    className="widget-title"
                    style={{ fontFamily: "sans-serif", fontWeight: "550", color: "#ffffff" }}
                  >
                    Download App
                  </h5>
                  <div className="footer_menu">
                    <ul
                      style={{
                        fontFamily: " Bold ,sans-serif, Arial, Helvetica",
                      }}
                    >
                      <li>
                        <a href="#">
                          Download our Apps and get all updates from Shifyt
                        </a>
                      </li>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "flex-start",
                          alignContent: "flex-start",
                        }}
                      >
                        <li>
                          <img
                            src={AppStore2}
                            style={{ height: "30px", width: "" }}
                          />
                        </li>
                        <li>
                          <img
                            src={GooglePlay2}
                            style={{
                              height: "30px",
                              width: "",
                              paddingLeft: "20px",
                            }}
                          />
                        </li>
                      </div>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <hr style={{ height: "1px", width: "100%" }} />
          {/* <div className="trending_jobs_main_cont">
          <div className="jobs_heading">
            <img src={TrendingJobsImage}/>
          </div>
        <div className="footer_trend_jobs">
        <div className="trending-jobs"></div>
        </div>
        </div>
        <hr /> */}

          <div className="d-flex flex-column flex-md-row   justify-content-between py-4 px-4 px-xl-5">
            <div
              className="pt-2 mb-md-0"
              style={{ fontFamily: " Bold ,sans-serif, Arial, Helvetica" }}
            >
              <a href="#!" className="terms me-4">
                Copyright © {new Date().getFullYear()}. All rights reserved.
              </a>
            </div>
            <div style={{ fontFamily: " Bold ,sans-serif, Arial, Helvetica" }}>
              <a href="#!" className="terms me-4">
                {/* <i className="fab fa-facebook-f"></i> */}
                Privacy policy
              </a>
              <a href="#!" className="terms me-4">
                {/* <i className="fab fa-twitter"></i> */}
                Terms & Conditions
              </a>
              <a href="#!" className="terms me-4">
                {/* <i className="fab fa-google"></i> */}
                Security policy
              </a>
              <a href="#!" className="text-white">
                {/* <i className="fab fa-linkedin-in"></i> */}
              </a>
            </div>
          </div>
        </footer>
      </div>
    </>
  );
}

export default FooterSection;
