export default function StarDarkIcons() {
    return (
        <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_1233_41342)">
                <path d="M14.1527 15.6044C13.6082 15.9923 10.1142 13.5337 9.44507 13.5283C8.7759 13.5229 5.2426 15.925 4.70442 15.5284C4.16624 15.1318 5.43147 11.0585 5.22984 10.4223C5.02821 9.78606 1.6453 7.17748 1.85721 6.54454C2.06916 5.9116 6.34508 5.85281 6.88961 5.46495C7.43414 5.07714 8.87671 1.06289 9.54593 1.06824C10.2151 1.07365 11.5925 5.11059 12.1306 5.50716C12.6688 5.90368 16.9433 6.03132 17.145 6.66757C17.3466 7.30382 13.922 9.85761 13.71 10.4905C13.4981 11.1235 14.6973 15.2166 14.1527 15.6044Z" fill="#212121" />
            </g>
            <defs>
                <clipPath id="clip0_1233_41342">
                    <rect width="16.3417" height="16.3417" fill="white" transform="translate(0.827148 0.329132)" />
                </clipPath>
            </defs>
        </svg>
    );
}
