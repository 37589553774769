export default function India() {
    return (
        <svg width="20" height="20" viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="artwork">
                <g clip-path="url(#clip0_103_1134)">
                    <rect width="22" height="16" rx="2" fill="white" />
                    <path id="vector" fill-rule="evenodd" clip-rule="evenodd" d="M11 10.6667C12.4457 10.6667 13.619 9.472 13.619 8C13.619 6.528 12.4457 5.33334 11 5.33334C9.55428 5.33334 8.38095 6.528 8.38095 8C8.38095 9.472 9.55428 10.6667 11 10.6667ZM11 9.6C11.8674 9.6 12.5714 8.8832 12.5714 8C12.5714 7.1168 11.8674 6.4 11 6.4C10.1326 6.4 9.42857 7.1168 9.42857 8C9.42857 8.8832 10.1326 9.6 11 9.6Z" fill="#1A47B8" />
                    <path id="vector_2" d="M11 8.53333C11.2893 8.53333 11.5238 8.29455 11.5238 8C11.5238 7.70545 11.2893 7.46667 11 7.46667C10.7107 7.46667 10.4762 7.70545 10.4762 8C10.4762 8.29455 10.7107 8.53333 11 8.53333Z" fill="#1A47B8" />
                    <path id="vector_3" fill-rule="evenodd" clip-rule="evenodd" d="M0 11H22V16H0V11Z" fill="#249F58" />
                    <path id="vector_4" fill-rule="evenodd" clip-rule="evenodd" d="M0 0H22V5H0V0Z" fill="#FF6C2D" />
                </g>
            </g>
            <defs>
                <clipPath id="clip0_103_1134">
                    <rect width="22" height="16" rx="0" fill="white" />
                </clipPath>
            </defs>
        </svg>

    );
}
