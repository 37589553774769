import React from 'react';
import { Grid, Stack, Typography, Box, Button, Chip } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import Banner from "../../../assets/icons/About/banner.png";



function AboutBannerSection() {

    /**
     *
     * redux dispatch
     *
     */
    const dispatch = useDispatch();


    /**
     *
     * navigate
     *
     */
    const navigate = useNavigate();


    return (
        <Grid container sx={{
            paddingTop: "80px", paddingBottom: "100px",
            "@media (max-width: 600px)": {
                paddingTop: "50px",
                paddingBottom: "60px",
            },
        }}>
            <Grid item xs={12} sm={12}>
                <Stack sx={{ width: "100%", margin: "auto" }} spacing={2}>
                    <Stack
                        direction="column"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <Box
                            sx={{
                                width: "60%",
                                margin: "auto",
                                "@media (max-width: 900px)": {
                                    width: "100%",
                                },
                            }}
                        >
                            <Typography
                                sx={{
                                    fontSize: "45px",
                                    fontWeight: "500",
                                    textAlign: "center",
                                    "@media (max-width: 900px)": {
                                        fontSize: "20px",
                                    },
                                }}
                            >Setting the new standard in Recruitment</Typography>
                            <Typography
                                sx={{
                                    fontSize: "18px",
                                    fontWeight: "400",
                                    color: "#737373",
                                    textAlign: "center",
                                    "@media (max-width: 900px)": {
                                        fontSize: "13px",
                                    },
                                }}
                            >
                                Seamless recruitment from shortlisting to onboarding.
                            </Typography>
                        </Box>
                    </Stack>
                    <Stack pt={4}>
                        <Box
                            sx={{
                                width: "100%",
                                height: "100%"
                            }}
                        >
                            <img src={Banner} alt="career banner" style={{ width: "100%", height: "auto" }} />
                        </Box>
                    </Stack>
                </Stack>
            </Grid>
        </Grid >
    )
}

export default AboutBannerSection;