import React from 'react';
import { Grid, Stack, Typography, Box, Button, Chip } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import tickOne from '../../../assets/icons/Careers/tickOne.svg';
import bannerTwo from '../../../assets/icons/Careers/bannerTwo.png';
import SpeedoMeterIcon from '../../../assets/icons/Careers/SpeedoMeterIcon';
import HeartHandIcon from '../../../assets/icons/Careers/HeartHandIcon';
import ServerIcon from '../../../assets/icons/Careers/ServerIcon';
function BenifitSection() {

    /**
 *
 * redux dispatch
 *
 */
    const dispatch = useDispatch();


    /**
 *
 * navigate
 *
 */
    const navigate = useNavigate();


    return (
        <Grid container sx={{
            paddingTop: "60px", paddingBottom: "80px",
            "@media (max-width: 600px)": {
                paddingTop: "30px",
                paddingBottom: "40px",
            },
        }}>
            <Grid item xs={12} sm={12}>
                <Grid container
                    sx={{
                        "@media (max-width: 600px)": {
                            flexDirection: "column-reverse"
                        },
                    }}
                >
                    <Grid item xs={12} sm={6} md={8}
                        sx={{
                            paddingTop: "20px",
                        }}
                    >
                        <Box>
                            <Stack
                                direction="column"
                                sx={{
                                    gap: "10px"
                                }}
                            >
                                <Typography
                                    sx={{
                                        color: "var(--text-color-one)",
                                    }}
                                >Perks and Benefits</Typography>
                                <Typography
                                    sx={{
                                        fontSize: "52px",
                                        fontWeight: "600",
                                        "@media (max-width: 1600px)": {
                                            fontSize: "40px",
                                        },
                                        "@media (max-width: 1200px)": {
                                            fontSize: "30px",
                                        },
                                        "@media (max-width: 900px)": {
                                            fontSize: "25px",
                                        },
                                        "@media (max-width: 600px)": {
                                            fontSize: "25px",
                                        },
                                    }}
                                >Perks and Benefit</Typography>
                                <Typography
                                    sx={{
                                        color: "var(--text-color-one)",
                                        width: "80%",
                                        "@media (max-width: 900px)": {
                                            width: "100%",
                                        },
                                    }}
                                >We provide a collaborative and inclusive workplace with competitive benfits such as affordable health insurance, unlimited paid time off, and a strong startup company culture.</Typography>
                            </Stack>
                            <Stack
                                direction="column"
                                mt={5}
                                sx={{
                                    gap: "10px"
                                }}
                            >
                                {[
                                    { id: "1", text: "Competitive compensation" },
                                    { id: "2", text: "Flexible and hybrid working hours" },
                                    { id: "3", text: "Health and wellness benefits" },
                                    { id: "4", text: "Medical, dental & vision insurance" },
                                    { id: "5", text: "New York-based + remote friendly" },
                                ]?.map((item, index) => (
                                    <Typography
                                        sx={{
                                            "&:before ": {
                                                content: "''",
                                                display: "block",
                                                background: `url("${tickOne}") no-repeat`,
                                                width: "20px",
                                                height: "24px",
                                                float: "left",
                                                margin: "0 6px 0 0",
                                                marginRight: "15px"
                                            }
                                        }}
                                    >
                                        {item?.text}
                                    </Typography>
                                ))}
                            </Stack>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} >
                        <Stack
                            sx={{
                                maxHeight: "100%"
                            }}
                        >
                            <Box>
                                <img src={bannerTwo} alt="alt"
                                    style={{
                                        maxHeight: "100%"
                                    }}
                                />
                            </Box>
                        </Stack>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} sm={12} mt={3} >
                <Box
                    sx={{
                        display: "grid",
                        gridTemplateColumns: "repeat(3, 1fr)",
                        "@media (max-width: 900px)": {
                            gridTemplateColumns: "repeat(2, 1fr)",
                        },
                        "@media (max-width: 600px)": {
                            gridTemplateColumns: "repeat(1, 1fr)",
                        },
                        gap: "20px"
                    }}
                >
                    <Stack
                        sx={{
                            boxShadow: "0px 4px 11px -1px #0A0A0A0A",
                            border: "1px solid #E5E5E5",
                            background: "linear-gradient(0deg, #FFFFFF, #FFFFFF), linear-gradient(0deg, #E5E5E5, #E5E5E5)",
                            minHeight: "150px",
                            gap: "10px"
                        }}
                        p={3}
                        direction="column"

                    >
                        <Stack
                            sx={{
                                height: "50px",
                                width: "50px",
                                borderRadius: "50%",
                                background: "var(--app-color-one)",

                            }}
                            justifyContent="center"
                            alignItems="center"
                        >
                            <HeartHandIcon />
                        </Stack>
                        <Typography
                            sx={{
                                fontSize: "20px",
                                fontWeight: "600",
                                "@media (max-width: 900px)": {
                                    fontSize: "18px",
                                },
                                "@media (max-width: 600px)": {
                                    fontSize: "15px",
                                },
                            }}
                        >Build Trust</Typography>
                        <Typography
                            sx={{
                                color: "var(--text-color-one)",
                                fontSize: "15px",
                                "@media (max-width: 600px)": {
                                    fontSize: "12px",
                                },
                            }}
                        >Test your product idea by launching payments with little to no code.</Typography>
                    </Stack>
                    <Stack
                        sx={{
                            boxShadow: "0px 4px 11px -1px #0A0A0A0A",
                            border: "1px solid #E5E5E5",
                            background: "linear-gradient(0deg, #FFFFFF, #FFFFFF), linear-gradient(0deg, #E5E5E5, #E5E5E5)",
                            minHeight: "150px",
                            gap: "10px"
                        }}
                        p={3}
                        direction="column"

                    >
                        <Stack
                            sx={{
                                height: "50px",
                                width: "50px",
                                borderRadius: "50%",
                                background: "var(--app-color-one)",

                            }}
                            justifyContent="center"
                            alignItems="center"
                        >
                            <ServerIcon />
                        </Stack>
                        <Typography
                            sx={{
                                fontSize: "20px",
                                fontWeight: "600",
                                "@media (max-width: 900px)": {
                                    fontSize: "18px",
                                },
                                "@media (max-width: 600px)": {
                                    fontSize: "15px",
                                },
                            }}
                        >Drive Client Joy</Typography>
                        <Typography
                            sx={{
                                color: "var(--text-color-one)",
                                fontSize: "15px",
                                "@media (max-width: 600px)": {
                                    fontSize: "12px",
                                },
                            }}
                        >Test your product idea by launching payments with little to no code.</Typography>
                    </Stack>
                    <Stack
                        sx={{
                            boxShadow: "0px 4px 11px -1px #0A0A0A0A",
                            border: "1px solid #E5E5E5",
                            background: "linear-gradient(0deg, #FFFFFF, #FFFFFF), linear-gradient(0deg, #E5E5E5, #E5E5E5)",
                            minHeight: "150px",
                            gap: "10px"
                        }}
                        p={3}
                        direction="column"

                    >
                        <Stack
                            sx={{
                                height: "50px",
                                width: "50px",
                                borderRadius: "50%",
                                background: "var(--app-color-one)",

                            }}
                            justifyContent="center"
                            alignItems="center"
                        >
                            <SpeedoMeterIcon />
                        </Stack>
                        <Typography
                            sx={{
                                fontSize: "20px",
                                fontWeight: "600",
                                "@media (max-width: 900px)": {
                                    fontSize: "18px",
                                },
                                "@media (max-width: 600px)": {
                                    fontSize: "15px",
                                },
                            }}
                        >Move with Urgency</Typography>
                        <Typography
                            sx={{
                                color: "var(--text-color-one)",
                                fontSize: "15px",
                                "@media (max-width: 600px)": {
                                    fontSize: "12px",
                                },
                            }}
                        >Test your product idea by launching payments with little to no code.</Typography>
                    </Stack>
                </Box>

            </Grid>
        </Grid>
    )
}

export default BenifitSection;