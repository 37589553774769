import React from 'react';
import { Grid, Stack, Typography, Box, Chip, Button } from "@mui/material";
import fireIcon from "../../../../assets/icons/LandingPage/fireIcon.svg";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
    arrow: {
        zIndex: 0,
        background: "#fff",
        borderRadius: "10px",
        overflow: "hidden",
    },

});

function CompanyHiredSection() {

    const classes = useStyles();
    let array1 = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15];
    const responsive = {
        superLargeDesktop: {
            breakpoint: { max: 4000, min: 3001 },
            items: 5,
        },
        desktop2: {
            breakpoint: { max: 3000, min: 1601 },
            items: 4,
        },
        desktop1: {
            breakpoint: { max: 1600, min: 1024 },
            items: 3,
            // gap: 2,
        },
        tablet2: {
            breakpoint: { max: 1024, min: 773 },
            items: 2,
        },
        tablet1: {
            breakpoint: { max: 772, min: 464 },
            items: 2,
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
        },
    };

    const data = [
        {
            id: "1",
            img: "https://qa.joinee.com/static/media/clutchIcon.239b8883c6d4b37f175fa2d6ea9a2950.svg",
            jobType: "full time",
            title: "Email Marketing",
            desc: "Joinee is a career changer! The platform connected me with my dream Joinee is a career changer! The platform connected me with my dreamJoinee is a career changer! The platform connected me with my dream",
            types: ["Marketing", "Design"],
            locations: ["Span", "America, usa"],
        },
        {
            id: "2",
            img: "https://qa.joinee.com/static/media/clutchIcon.239b8883c6d4b37f175fa2d6ea9a2950.svg",
            jobType: "full time",
            title: "Email Marketing",
            desc: "Joinee is a career changer! The platform connected me with my dream Joinee is a career changer! The platform connected me with my dreamJoinee is a career changer! The platform connected me with my dream",
            types: ["Marketing", "Design"],
            locations: ["Span", "America, usa"],
        },
        {
            id: "3",
            img: "https://qa.joinee.com/static/media/clutchIcon.239b8883c6d4b37f175fa2d6ea9a2950.svg",
            jobType: "full time",
            title: "Email Marketing",
            desc: "Joinee is a career changer! The platform connected me with my dream Joinee is a career changer! The platform connected me with my dreamJoinee is a career changer! The platform connected me with my dream",
            types: ["Marketing", "Design"],
            locations: ["Span", "America, usa"],
        },
        {
            id: "4",
            img: "https://qa.joinee.com/static/media/clutchIcon.239b8883c6d4b37f175fa2d6ea9a2950.svg",
            jobType: "full time",
            title: "Email Marketing",
            desc: "Joinee is a career changer! The platform connected me with my dream Joinee is a career changer! The platform connected me with my dreamJoinee is a career changer! The platform connected me with my dream",
            types: ["Marketing", "Design"],
            locations: ["Span", "America, usa"],
        },
        {
            id: "5",
            img: "https://qa.joinee.com/static/media/clutchIcon.239b8883c6d4b37f175fa2d6ea9a2950.svg",
            jobType: "full time",
            title: "Email Marketing",
            desc: "Joinee is a career changer! The platform connected me with my dream Joinee is a career changer! The platform connected me with my dreamJoinee is a career changer! The platform connected me with my dream",
            types: ["Marketing", "Design"],
            locations: ["Span", "America, usa"],
        },
        {
            id: "6",
            img: "https://qa.joinee.com/static/media/clutchIcon.239b8883c6d4b37f175fa2d6ea9a2950.svg",
            jobType: "full time",
            title: "Email Marketing",
            desc: "Joinee is a career changer! The platform connected me with my dream Joinee is a career changer! The platform connected me with my dreamJoinee is a career changer! The platform connected me with my dream",
            types: ["Marketing", "Design"],
            locations: ["Span", "America, usa"],
        },
        {
            id: "7",
            img: "https://qa.joinee.com/static/media/clutchIcon.239b8883c6d4b37f175fa2d6ea9a2950.svg",
            jobType: "full time",
            title: "Email Marketing",
            desc: "Joinee is a career changer! The platform connected me with my dream Joinee is a career changer! The platform connected me with my dreamJoinee is a career changer! The platform connected me with my dream",
            types: ["Marketing", "Design"],
            locations: ["Span", "America, usa"],
        },
    ];



    return (
        <Grid container sx={{ paddingTop: "40px", paddingBottom: "100px" }}>
            <Grid item xs={12} sm={12}>
                <Stack
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    spacing={1}
                >
                    <Box sx={{ marginBottom: "10px" }}><img src={fireIcon} /> </Box><Box
                        sx={{
                            fontSize: "36px",
                            textAlign: "center",
                            fontWeight: "400",
                            margin: "auto",
                            color: "#183B56",
                            "@media (max-width: 600px)": {
                                fontSize: "20px",
                            },
                        }}
                    >Companies hiring now</Box>
                </Stack>
            </Grid>
            <Grid
                item
                xs={12}
                sm={12}
                pt={5}
                pb={3}
            >
                <Stack
                    sx={{
                        borderRadius: "10px",
                        overflow: "hidden",
                        boxShadow: "rgba(17, 12, 46, 0.15) 0px 48px 100px 0px",
                        width: "85%",
                        margin: "auto",
                        "@media (max-width: 600px)": {
                            width: "100%",
                        },
                    }}
                >
                    <Carousel responsive={responsive} className={classes.arrow}>
                        {data?.map((item, index) => (
                            <Stack
                                key={index}
                                pt={2}
                                pb={1}
                                sx={{
                                    borderRight: "1px solid #E9E9E97A"
                                }}
                            >
                                <Stack
                                    direction="row"
                                    justifyContent="space-between"
                                    alignItems="center"
                                    p={2}
                                >
                                    <Box
                                        sx={{
                                            width: "50%",
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                width: "100px",
                                            }}
                                        >
                                            <img
                                                src={item?.img}
                                                style={{
                                                    width: "100%",
                                                    height: "auto",
                                                }}
                                            />
                                        </Box>
                                    </Box>
                                    <Box
                                        sx={{
                                            minWidth: "50px",
                                            width: "50%",
                                            display: "flex",
                                            justifyContent: "flex-end",
                                            alignItems: "center",
                                        }}
                                    >
                                        <Chip label={item?.jobType} variant="outlined"
                                            sx={{
                                                border: "1px solid var(--app-button-color1)",
                                                paddin: "5px 10px",
                                                '& .MuiChip-label': {
                                                    fontWeight: "500",
                                                    color: "var(--app-button-color1)",
                                                },
                                            }}
                                        />
                                    </Box>
                                </Stack>
                                <Stack p={2}>
                                    <Typography
                                        sx={{
                                            fontSize: "16px",
                                            fontWeight: "600",
                                        }}
                                    >
                                        {item?.title}
                                    </Typography>
                                    <Box
                                        sx={{
                                            fontSize: "15px",
                                            color: "#515B6F",
                                            fontWeight: "300",
                                        }}
                                        mt={2}
                                        mb={1}
                                    >
                                        {item?.types?.map((el, index) => (
                                            <span key={index}>{el} &#x2022; </span>
                                        ))}
                                    </Box>
                                    <Box
                                        sx={{
                                            display: "-webkit-box",
                                            WebkitBoxOrient: "vertical",
                                            WebkitLineClamp: 3,
                                            overflow: "hidden",
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                fontSize: "15px",
                                                wordWrap: "break-word",
                                                overflowWrap: "break-word",
                                                color: "#515B6F",
                                                fontWeight: "300",
                                            }}
                                        >
                                            {item?.desc}
                                        </Typography>
                                    </Box>
                                    <Box
                                        mt={2}
                                        sx={{
                                            display: "flex",
                                            gap: "10px",
                                        }}
                                    >
                                        {item?.types?.map((el, index) => (
                                            <Chip key={index} label={el} variant="outlined"
                                                sx={{
                                                    background: "#E9E9E97A",
                                                    border: "none",
                                                    paddin: "5px 10px",
                                                    '& .MuiChip-label': {
                                                        fontWeight: "400",
                                                        fontSize: "12px"
                                                    },
                                                }}
                                            />
                                        ))}
                                    </Box>
                                </Stack>
                                <Stack p={2}>
                                    <Box
                                        sx={{
                                            minWidth: "50px",
                                            width: "50%",
                                        }}
                                    >
                                        <Chip label="Apply Now" variant="outlined" onClick={() => { }}
                                            sx={{
                                                border: "1px solid var(--app-button-color1)",
                                                padding: "10px 15px",
                                                '& .MuiChip-label': {
                                                    fontWeight: "600",
                                                    color: "var(--app-button-color1)",
                                                },
                                            }}
                                        />
                                    </Box>
                                </Stack>
                            </Stack>
                        ))}
                    </Carousel>
                </Stack>

                <Stack
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    mt={3}
                >
                    <Button
                        type="submit"
                        size="large"
                        onClick={() => { console.log("Fired...") }}
                        loading={false}
                        loadingPosition="end"
                        variant="contained"
                        sx={{
                            borderRadius: "50px",
                            boxShadow: "none",
                            background: "var(--app-button-color1)",
                            padding: "8px 30px",
                            "&:hover": {
                                boxShadow: "none",
                                background: "var(--app-button-color1)",
                            }
                        }}
                    >
                        <span style={{ textTransform: "capitalize", fontWeight: "700" }}>See All jobs</span>
                    </Button>

                </Stack>

            </Grid>
        </Grid>
    )
}

export default CompanyHiredSection;