import * as React from "react";
import { useEffect, useState, useRef } from "react";
import {
  BlackTypography,
  NotificationHeader,
  StyledListCard,
  GreyTypography
} from "../../assets/MUI/materialUiStyles";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import Badge from "@mui/material/Badge";
import { getAllNotifications, getUnreadNotificationCount } from "../../helpers/dataFetcher/Notifications/notifications";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import {
  IconButton, Box, ListItemText
} from "@mui/material";
import { socket } from "../../Sockets/Socket";
import { Link } from "react-router-dom";
import { Stack } from "@mui/material";
import PopoverPopupModel from "../../components/popover/components/Popover";

import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import moment from "moment";
import { imageURL } from "../../Constants/commonURLS";


export default function NotificationDrawer(props) {


  const userId = localStorage.getItem("user_id");
  const [notificationEl, setNotificationEl] = React.useState(null);
  const open = Boolean(notificationEl);
  const [notificationsData, setNotificationData] = React.useState([]);
  const [unreadNotificationCount, setUnreadNotificationCount] = useState(null);
  const listInnerRef = useRef();
  const [currPage, setCurrPage] = useState(1);

  const handleBellClick = event => {
    setNotificationEl(event.currentTarget);
  };

  function FormatedNotifictionData(data) {

    const jobpostDetails = data?.map(each => {
      const formattedTime = moment(each?.updatedAt).format("ddd, DD YYYY");
      return {
        id: each?.id,
        role: each?.info?.job_post_details?.job_title,
        title: each?.info?.company_details?.company_name,
        image: `${imageURL}${each?.info?.company_details?.company_image}`,
        time: formattedTime,
        key: "to be approve",
        message: `${each?.info?.message ?? ""}`,
      };
    });
    return jobpostDetails;
  }

  useEffect(() => {
    if(currPage > 1 && open) {
      getNotificationData();
    }
  }, [currPage])

  const getNotificationData = async () => {
    const { data, status } = await getAllNotifications(currPage, 15, "");
    setNotificationData(FormatedNotifictionData(data?.data_payload));
  }

  async function getAllUnreadNotificationCount() {
    const { data, status } = await getUnreadNotificationCount();
    if (status === 200) {
      setUnreadNotificationCount(data?.overall_count);
    }
  }

  useEffect(() => {
    if (open) {
      getNotificationData();
    }
    getAllUnreadNotificationCount();
  }, [open])


  const handleListnerEvent = (data) => {
    if (data?.type === "notification") {
      if (open) {
        getNotificationData();
      }
      getAllUnreadNotificationCount();
    }
  };

  useEffect(() => {

    const handleReconnect = () => {
      socket.on("listnerEvent", handleListnerEvent);
    };

    socket.on("listnerEvent", handleListnerEvent);
    socket.on("reconnect", handleReconnect);

    return () => {
      socket.off("listnerEvent", handleListnerEvent);
      socket.off("reconnect", handleReconnect);
    };
  }, [userId]);

  const onScroll = () => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      if (Math.ceil(scrollTop + clientHeight) === Math.ceil(scrollHeight) - 4 ) {
        setCurrPage(currPage + 1);
      }
    }
  };
  return (
    <div>
      <IconButton
        id="basic-demo-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        variant="outlined"
        // color="neutral"
        sx={{ color: "#066a4c", borderColor: "#066a4c !important" }}
        onClick={handleBellClick}
      >
        {unreadNotificationCount > 0 ? (
          <Badge badgeContent={unreadNotificationCount > 0 && unreadNotificationCount} color="success"
            sx={{
              fontSize: "8px"
            }}
          >
            <NotificationsNoneIcon color="action" />
          </Badge>
        ) : (
          <NotificationsNoneIcon color="action" />
        )}
      </IconButton>

      <PopoverPopupModel
        anchorEl={notificationEl}
        setAnchorEl={setNotificationEl}
        sx={{ maxWidth: "40vw", minWidth: "150px" }}
        popupAdjust={{ marginTop: "15px" }}
      >
        <Stack
          direction="column"
          sx={{
            borderRadius: "5px",
            overflow: "hidden",
            height: "500px",
            width: "400px",
          }}
        >
          <NotificationHeader>
            <Stack
              direction={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <BlackTypography varient="h5">All Notifications</BlackTypography>
              <Link to="/settings">
                <FeatherIcon
                  icon="settings"
                  size="16"
                  style={{ color: "#024430" }}
                />
              </Link>
            </Stack>
          </NotificationHeader>
          <Box 
          onScroll={onScroll}
          ref={listInnerRef}
          sx={{ overflow: "auto", height: "100%" }}
          >
            {notificationsData?.length > 0 ? (
              <>
                <Box sx={{ padding: "10px" }}>
                  {notificationsData?.map((each, i) => (
                    <>
                      <StyledListCard>
                        <ListItem key={i} sx={{ padding: 0 }}>
                          <ListItemAvatar>
                            <Avatar
                              src={each.image}
                              style={{ border: "1px solid #EFF7FF" }}
                            ></Avatar>
                          </ListItemAvatar>

                          <div
                            style={{
                              display: "flex",
                              width: "100%",
                              flexDirection: "row",
                              justifyContent: "space-between",
                              alignItems: "flex-start",
                            }}
                          >
                            <ListItemText
                              primary={`${each.role}`}
                              secondary={`${each.title}`}
                              primaryTypographyProps={{
                                style: {
                                  fontFamily: "Inter",
                                  fontSize: "14px",
                                  fontWeight: 600,
                                  letterSpacing: "-1%",
                                },
                              }}
                              secondaryTypographyProps={{
                                style: {
                                  fontFamily: "Inter",
                                  fontSize: "13px",
                                  fontWeight: 500,
                                  letterSpacing: "-1%",
                                  color: "rgba(0, 0, 0, 0.53)",
                                },
                              }}
                            />
                            <ListItemText
                              secondary={`${each.time}`}
                              className="time_stamp"
                              secondaryTypographyProps={{
                                style: {
                                  fontFamily: "Inter",
                                  fontSize: "13px",
                                  fontWeight: "500",
                                  letterSpacing: "-1%",
                                  color: "rgba(0, 0, 0, 0.53)",
                                  opacity: "60%",
                                  display: "flex",
                                  justifyContent: "flex-end",
                                },
                              }}
                            />
                          </div>
                        </ListItem>
                        {each?.message && (
                          <p
                            className="notification_msg "
                            style={{ fontSize: "13px" }}
                          >
                            {each.message}

                          </p>
                        )}
                      </StyledListCard>
                    </>
                  ))}
                </Box>
              </>
            ) : (
              <GreyTypography varient="h2" component="h2" sx={{ fontSize: "20px", marginTop: "50%", textAlign: "center" }}>No notifications</GreyTypography>
            )}
          </Box>
        </Stack>
      </PopoverPopupModel>
    </div>
  );
}

