import Picker from "emoji-picker-react";
import React from "react";
import MyModal from "./components/Modal";

export default function EmojiPickerModal({ open, handleClose, setText}) {
  const onEmojiClick = (event) => {
   
    setText(event);
  };
  return (
    <MyModal
      open={open}
      handleClose={() => {
        handleClose();
      }}
      sx={{ height: "fit-content" }}
    >
      <Picker
        // searchDisabled={true}
        skinTonePickerLocation="PREVIEW"
        height={500}
        // style={{ display: "block", position: "absolute", zIndex: 1 }}
        pickerStyle={{ width: "100%" }}
        onEmojiClick={onEmojiClick}
      />
    </MyModal>
  );
}
