import React from 'react';
import { Grid, Stack, Typography, Box } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import Frame1 from '../../../../assets/icons/Frame1';
import Frame2 from '../../../../assets/icons/Frame2';
import ApplyIcon from '../../../../assets/icons/LandingPage/ApplyIcon';
import HireIcon from '../../../../assets/icons/LandingPage/HireIcon';
import ReferIcon from '../../../../assets/icons/LandingPage/ReferIcon';
import ArrowIcon from '../../../../assets/icons/LandingPage/ArrowIcon';
import { openModal } from '../../../../components/modals/modalsSlice';
import VideoPauseIcon from '../../../../assets/icons/LandingPage/VideoPauseIcon';

function BannerSection() {

    /**
    *
    * redux dispatch
    *
    */
    const dispatch = useDispatch();


    /**
     *
     * navigate
     *
     */
    const navigate = useNavigate();


    return (
        <Grid container sx={{ paddingTop: "100px", paddingBottom: "100px" }}>
            <Grid item xs={12} sm={12}
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <Stack
                    sx={{
                        position: "relative",
                    }}
                    direction="row"
                    p={4}
                >
                    <Stack
                        sx={{
                            fontSize: "80px",
                            textAlign: "center",
                            position: "relative",
                            "@media (max-width: 600px)": {
                                fontSize: "15px",
                            },
                            "@media (max-width: 900px)": {
                                fontSize: "20px",
                            },
                            "@media (max-width: 1200px)": {
                                fontSize: "50px",
                            },
                        }}
                        justifyContent="center"
                        alignItems="center"
                    >
                        <Box
                            sx={{
                                position: "absolute",
                                bottom: "0",
                                left: "0",
                                width: "fit-content",
                                height: "fit-content",
                                marginLeft: "-55px",
                                marginBottom: "-33px"
                            }}
                        ><Frame1 /></Box>
                        <Box>Hire people 10x faster</Box>
                        <Box>through  <span
                            style={{
                                background: "#FFCA6C36"
                            }}
                        >Joinee</span></Box>
                        <Box sx={{
                            position: "absolute",
                            top: "0",
                            right: "0",
                            width: "fit-content",
                            height: "fit-content",
                            marginRight: "-41px",
                            marginTop: "-45px",
                            "@media (max-width: 1200px)": {
                                width: "100px",
                                marginTop: "-50px",
                            },
                        }}><Frame2 /></Box>
                    </Stack>
                </Stack>
                <Stack>
                    <Typography sx={{ fontSize: "15px" }}>Simplifying hiring processes for Seamless Company-Joinee Matches.</Typography>
                </Stack>
                <Stack
                    direction="row"
                    spacing={2}
                    mt={3}
                >
                    <Box
                        sx={{
                            border: "1px solid #066a4c",
                            color: "#fff",
                            padding: "8px 15px",
                            fontSize: "15px",
                            borderRadius: "50px",
                            background: "#066a4c",
                            cursor: "pointer",
                            "&:hover": {
                                background: "#fff",
                                color: "#000",
                            },
                            "@media (max-width: 900px)": {
                                fontSize: "12px",
                            },

                        }}
                        onClick={() => {
                            navigate("/registration")
                        }}
                    >Hire now</Box>
                    <Stack direction="row" spacing={1}
                        sx={{
                            border: "1px solid black",
                            color: "black",
                            padding: "8px 15px",
                            fontSize: "15px",
                            borderRadius: "50px",
                            cursor: "pointer",
                            "&:hover": {
                                background: "#066a4c",
                                color: "#fff",
                            },
                            "@media (max-width: 900px)": {
                                fontSize: "12px",
                            },
                        }}
                        onClick={() => {
                            dispatch(
                                openModal({
                                    modalName: "requestDemoOneModal",
                                    modalProps: {
                                    },
                                })
                            );
                        }}
                    >
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center"
                            }}
                        >
                            <VideoPauseIcon />
                        </Box>
                        <Box>Request for demo</Box>

                    </Stack>
                </Stack>
            </Grid>
            {/* <Grid
                item
                xs={12}
                sm={12}
                mt={3}
            >
                <Grid container sx={{ width: "90%", margin: "auto" }}>
                    <Grid
                        item
                        xs={12}
                        sm={6}
                        md={4}
                    >
                        <Stack
                            sx={{
                                borderRadius: "10px",
                                boxShadow:
                                    "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px",
                                minHeight: "300px"
                            }}
                            p={2}
                            m={1}
                            direction="column"
                            justifyContent="center"
                            alignItems="center"
                        >
                            <ApplyIcon />
                            <Box mt={1} mb={2}>
                                <Typography
                                    sx={{ fontSize: "13px", color: "gray" }}
                                >Top Companies are hiring now </Typography>
                            </Box>
                            <Link
                                to="#"
                                style={{
                                    color: "var(--text-color)",
                                    fontSize: "16px",
                                    fontWeight: "700",
                                    marginTop: "10px"
                                }}
                            >Apply for a job <ArrowIcon /> </Link>
                        </Stack>

                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sm={6}
                        md={4}
                    >
                        <Stack
                            sx={{
                                borderRadius: "10px",
                                boxShadow:
                                    "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px",
                                minHeight: "300px"
                            }}
                            p={2}
                            m={1}
                            direction="column"
                            justifyContent="center"
                            alignItems="center"
                        >
                            <HireIcon />
                            <Box mt={1} mb={2}>
                                <Typography
                                    sx={{ fontSize: "13px", color: "gray" }}
                                >Hire the top 1% candidates through joinee.com </Typography>
                            </Box>
                            <Link
                                to="#"
                                style={{
                                    color: "var(--text-color)",
                                    fontSize: "16px",
                                    fontWeight: "700",
                                    marginTop: "10px"
                                }}
                            >Hire Candidates <ArrowIcon /> </Link>
                        </Stack>

                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sm={6}
                        md={4}
                    >
                        <Stack
                            sx={{
                                borderRadius: "10px",
                                boxShadow:
                                    "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px",
                                minHeight: "300px"
                            }}
                            p={2}
                            m={1}
                            direction="column"
                            justifyContent="center"
                            alignItems="center"
                        >
                            <ReferIcon />
                            <Box mt={1} mb={2}>
                                <Typography
                                    sx={{ fontSize: "13px", color: "gray" }}
                                >Refer a job with your connection and earn money</Typography>
                            </Box>
                            <Link
                                to="#"
                                style={{
                                    color: "var(--text-color)",
                                    fontSize: "16px",
                                    fontWeight: "700",
                                    marginTop: "10px"
                                }}
                            >Refer & Earn <ArrowIcon /> </Link>
                        </Stack>
                    </Grid>
                </Grid>
            </Grid> */}

        </Grid>
    )
}

export default BannerSection;