import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import OwlCarousel from "react-owl-carousel3";
import combinator from "../../assets/images/combinator.png";
import stripe from "../../assets/images/stripe.png";
import openSea from "../../assets/images/openSea.png";
import doorDarsh from "../../assets/images/doorDarsh.png";
import razor from "../../assets/images/razor.png";
import america from "../../assets/images/america.png";
import morgan from "../../assets/images/morgan.png";
import dummy1 from "../../assets/images/dummy1.png";
import Button from '@mui/joy/Button';
import Stack from "@mui/material/Stack";

/**/
import algolia from '../../assets/images/algolia.jpeg';
import Eazyrooms_logo from '../../assets/images/Eazyrooms_logo.png'
import novig from '../../assets/images/novig.jpeg';
import onFront from '../../assets/images/onfrontiers.jpeg';
import prelude from '../../assets/images/prelude.svg';
import sritech from '../../assets/images/SRItech_logo.png'
import worke from '../../assets/images/Worke_logo.png';
import swim from '../../assets/images/swimply.jpeg';





function CompanyCarousal() {
  // const companyImages = [
  //     { id: 1, src: "https://example.com/image1.jpg", alt: "Company 1" },
  //     { id: 2, src: "https://example.com/image2.jpg", alt: "Company 2" },
  //     { id: 3, src: "https://example.com/image3.jpg", alt: "Company 3" },
  //     // add more images here
  //   ];
  const slidesData = [
    {
      id: 1,
      // image:combinator, 
      image: worke,
      title: "Senior Full Stack",
      type: "FUll time",
      date: "04 sep",
      description:
        "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium, totam rem aperiam",
    },
    {
      id: 1,
      // image:combinator, 
      image: sritech,
      title: "Senior Full Stack",
      type: "FUll time",
      date: "04 sep",
      description:
        "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium, totam rem aperiam",
    },
    {
      id: 2,
      // image: razor,
      image: Eazyrooms_logo,
      title: "Senior Full Stack",
      type: "FUll time",
      date: "04 sep",
      description:
        "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium, totam rem aperiam",
    },
    {
      id: 3,
      image: combinator,
      // image:novig, 
      title: "Senior Full Stack",
      type: "FUll time",
      date: "04 sep",
      description:
        "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium, totam rem aperiam",
    },
    {
      id: 4,
      image: morgan,
      // image:onFront, 
      title: "Senior Full Stack",
      type: "FUll time",
      date: "04 sep",
      description:
        "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium, totam rem aperiam",
    },
    {
      id: 5,
      // image:morgan,
      image: prelude,
      title: "Senior Full Stack",
      type: "FUll time",
      date: "04 sep",
      description:
        "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium, totam rem aperiam",
    },
    {
      id: 6,
      image: razor,
      // image:swim, 
      title: "Senior Full Stack",
      type: "FUll time",
      date: "04 sep",
      description:
        "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium, totam rem aperiam",
    },
  ];

  const options = {
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 8,
      },
      1000: {
        items: 8,
      },
      1200: {
        items: 8,
      },
      1400: {
        items: 8,
      },
    },
    loop: true,
    lazyLoad: true,
    autoplay: true,
    dots: false,
    autoplaySpeed: 1000,
    autoplayTimeout: 2000,
    autoplayHoverPause: true,
    nav: true,
    navText: [
      "<i className='fa fa-chevron-left'></i>",
      "<i className='fa fa-chevron-right'></i>",
    ],
  };

  return (
    <>
      <div className="company_carousal_main_card_sec">
        <div className="row align-items-center">
          <div className="col-sm-12 col-md-12 col-lg-12">
            <div className="">
              <OwlCarousel
                nav
                loop
                {...options}
              // className="owl-carousel-category owl-theme"
              >
                {slidesData?.map(item => (
                  // <div style={{height:"80px"}} key={item.id}>
                  //   <img
                  //     src={item?.image}
                  //     style={{ height: "100%", width: "90%", padding: "1px" }}
                  //   />
                  // </div>
                  <div key={item.id}>
                    <img
                      src={item?.image}
                      style={{ padding: "18px" }}
                    />
                  </div>
                ))}
              </OwlCarousel>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CompanyCarousal;
