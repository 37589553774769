import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { imageURL } from "../../../Constants/commonURLS";
import Box from "@mui/material/Box";
import { styleObj } from '../../../assets/MUI/useStyles';
import { DataGridStyles } from '../../../assets/MUI/useStyles';
import Pagination from "@mui/material/Pagination";
import { globalTheme } from '../../../assets/MUI/globalStyles';
import { ThemeProvider } from "@mui/material/styles";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import "./table.css";
import CustomNoRowsFound from "../../../Nodatafound/customNoRowsFound";
import GlobalSearch from "../../../hooks/GlobalSearch";
import { formatdate } from "../../../helpers/common/FormateDate";



function ReferMeTable({ referData, page, setPage, loading, totalCount, pageSize }) {
  const classes = DataGridStyles();
  const navigate = useNavigate();


  const rowsData = referData?.map((item, index) => {
    return {
      id: `${item?.id}`,
      userId: `${item?.user_id}`,
      jobPostId: `${item?.job_post_id}`,
      Image: `${imageURL}${item?.company_details?.company?.company_image}`,
      companyName: `${item?.company_details?.company?.company_name ?? "-"}`,
      jobTitle: `${item?.job_title ?? "-"}`,
      location: `${item?.location ?? "-"}`,
      Date: formatdate(`${item?.createdAt}`),
      referedBy: `${item?.referral_user?.email ?? "-"}`,
      ctc: `${item?.job_applicant.ctc ?? "N/A"}`,
      status: `${item?.job_post_pipeline_stage?.stage_name ?? "-"}`,
    };
  });


  const columns = [
    {
      field: "Image",
      headerName: "Image",
      headerClassName: classes.headerCellStyles,
      flex: 1,
      minWidth: 100,
      renderCell: params => (
        <img
          src={params.value}
          alt="avatar"
          style={{ width: 30, borderRadius: "50%" }}
        />
      ),
    },
    {
      field: "companyName",
      headerClassName: classes.headerCellStyles,
      style: { color: "red" },
      headerName: "Company Name",
      minWidth: 150,
      flex: 1,
      renderCell: ({ value }) => (
        <span style={styleObj}>{value}</span>
      ),
    },
    {
      field: "jobTitle",
      headerClassName: classes.headerCellStyles,
      headerName: "Job Title",
      minWidth: 200,
      flex: 1,
      renderCell: ({ value }) => {
        return (
          <span style={styleObj}>{value}</span>
        );
      },
    },


    {
      field: "location",
      headerClassName: classes.headerCellStyles,
      headerName: "Location",
      minWidth: 200,
      flex: 1,
      renderCell: ({ value }) => <span style={styleObj}>{value}</span>,
    },
    {
      field: "Date",
      headerClassName: classes.headerCellStyles,
      headerName: "Date",
      minWidth: 150,
      flex: 1,
      renderCell: ({ value }) => (
        <span style={styleObj}>{value}</span>
      ),
    },
    {
      field: "referedBy",
      headerClassName: classes.headerCellStyles,
      headerName: "Referred By",
      minWidth: 150,
      flex: 1,
      renderCell: ({ value }) => <span style={styleObj}>{value}</span>,
    },
    {
      field: "ctc",
      headerClassName: classes.headerCellStyles,
      headerName: "CTC",
      minWidth: 100,
      flex: 1,
      renderCell: ({ value }) => <span style={styleObj}>{value}</span>,
    },
    {
      field: "status",
      headerClassName: classes.headerCellStyles,
      headerName: "Status",
      minWidth: 150,
      flex: 1,
      renderCell: ({ value }) => (
        <div
          style={{
            display: "inline-block",
            padding: "5px 10px",
            borderRadius: "8px",
            backgroundColor:
              value === "Rejected"
                ? "#ffe9e9"
                : value === "Applied"
                  ? "#cde2e7"
                  : value === "On boarded"
                    ? "#d7f6dd"
                    : value === "Technical Evalutation(External)"
                      ? "#ffc6f6"
                      : "rgba(8, 23, 83, 0.2)",
            color:
              value === "Rejected"
                ? "#a10e25"
                : value === "Applied"
                  ? "#066a4c"
                  : value === "On boarded"
                    ? "#066a4c"
                    : value === "Technical Evalutation(External)"
                      ? "#066a4c"
                      : "#066a4c",
            fontWeight: 500,
            fontSize: "12px",
            fontFamily: "Inter,sans-serif",
            textTransform: "capitalize",
          }}
        >
          {value}
        </div>

      ),
    },
  ];

  return (
    <ThemeProvider theme={globalTheme}>
      <div>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          style={{ paddingBottom: "20px" }}
          width="80%"
        >
         <GlobalSearch />
        </Box>
        <div style={{ height: "65vh", width: "98%" }}>
          <DataGrid
            rows={rowsData}
            columns={columns}
            disableColumnSelector
            disableDensitySelector
            disableRowSelectionOnClick
            loading={loading}
            onRowClick={(params, event, details) => {
              navigate(`../job?id=${params?.row?.jobPostId}&userId=${params?.row?.userId}`);
            }}
            slots={{
              // loadIcon: LoaderIcon,
              // baseButton: PrimaryButton,
              // basePopper: TablePopper,
              // baseSwitch: PrimarySwitch,
              // toolbar: GridToolbar,
              // noRowsOverlay: CustomNoRowsOverlay,
              pagination: props => (
                <Pagination
                  {...props}
                  // color="primary"
                  count={Number(totalCount)}
                  page={Number(page)}
                  rowsPerPage={Number(pageSize)}
                  onChange={(_, newPage) => setPage(newPage)}
                  showFirstButton
                  showLastButton
                  sx={{
                    "& .MuiPaginationItem-root": {
                      "&:hover": {
                        backgroundColor: "#cde2e7",
                        color: "#066a4c",
                      },
                      "&.Mui-selected": {
                        backgroundColor: "#024430",
                        color: "#ffe7bb",
                        "&:hover": {
                          backgroundColor: "#cde2e7",
                          color: "#066a4c",
                        },
                      },
                    },
                  }}
                />
              ),
            }}

          />
        </div>
      </div>
    </ThemeProvider>
  );
}

export default ReferMeTable;
