import React, { useState, useEffect } from "react";
import { BrowserRouter as Router } from "react-router-dom";
// import { Toaster } from "react-hot-toast";
import toast, { Toaster, ToastBar } from "react-hot-toast";
import { useDispatch } from "react-redux";
import SocketConnection from "./hooks/socket";
import Modals from "./components/modals/modals";

import Cookies from 'js-cookie';
import { openModal } from "./components/modals/modalsSlice";
import AppRoutes from "./routes/routes";
import { socket } from "./Sockets/Socket";



function App() {
  const data = SocketConnection();
  const cookieCountryCode = Cookies.get('cookieCountryCode');
  const userId = (localStorage.getItem("user_id"))

  /**
   *
   * redux dispatch
   *
   */
  const dispatch = useDispatch();

  useEffect(() => {
    if (!cookieCountryCode) {
      dispatch(
        openModal({
          modalName: "countrySelectionModal",
          modalProps: {
          },
        })
      );
    }
  }, []);


  window.addEventListener('beforeunload', function (event) {
    if (userId) {
      socket.emit("logout", {
        userId: userId,
      });
    }
  });



  // Handle Event when user is cloding the tab or window
  // async function getOnlineUsersList() {
  //   if (document.visibilityState === 'hidden') {
  //     if (userId) {
  //       socket.emit("logout", {
  //         userId: userId,
  //       });
  //     }
  //   }
  // }

  // useEffect(() => {
  //   document.addEventListener('visibilitychange', getOnlineUsersList);

  //   return () => {
  //     document.removeEventListener('visibilitychange', getOnlineUsersList);
  //   };
  // }, []);

  // Handle Event when user is cloding the tab or window

  function connectToSocket() {
    socket.connect();
    socket.emit("join", {
      userId: userId,
    });
  }

  useEffect(() => {
    if (userId) {
      connectToSocket();
      socket.on("disconnect", (data) => {
        // setTimeout(() => {
        console.log("socket disconnected", data)
        connectToSocket();
        // }, 3000);
      });

      socket.on('connect_error', (error) => {
        console.log("socket connect_error", error)
        connectToSocket();
      });

      socket.on('connect_timeout', (data) => {
        console.log("socket connect_timeout", data)
        connectToSocket();
      });

      return () => {
        socket.disconnect();
      };
    }
  }, [userId]);
  /*Socket Connection root level end here */

  return (
    <Router>
      <AppRoutes />
      <Toaster
        toastOptions={{
          duration: 2000,
        }}
        position="top-right"
        reverseOrder={false}
      >
        {t => (
          <ToastBar toast={t}>
            {({ icon, message }) => (
              <div style={{ display: 'flex', gap: "8px" }} onClick={() => toast.dismiss()}>
                {icon}
                {message}
              </div>
            )}
          </ToastBar>
        )}
      </Toaster>
      <Modals />
    </Router>
  );
}

export default App;
