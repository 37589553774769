import { POST } from "../../../Constants/methods";
import callApi from "../../callApi";
import axios from "axios";

export const getAllChats = async () => {
  return await callApi("chats/all_chats");
};
export const findChatBasedOnId = async id => {
  return await callApi(`chats/chat_details/${id}`);
};
export const initializeChat = async id => {
  return await callApi("/chats/initiate_chat", { user_id: id }, POST);
};
export const sendMessage = async (id, message, type) => {
  return await callApi(
    "/chats/send_message",
    { user_id: id, message, type },
    POST
  );
};

const findAllContactsModal = async (item) => {
  return item.map((each) => {
    return {
      id: each.id,
      name: each.first_name,
      lastName: each.last_name,
      recents_msgs: "Hey this is dummy msg",
      avatar: each.image,
      status: each.isActive

    }
  })
}

export const findAllChatContacts = async () => {
  const { data, status } = await callApi("common/get_contacts")
  return { data: await findAllContactsModal(data.data) }
}


export const getOnlineUsers = async () => {
  return await axios.get('https://socket.joinee.com/users').then((res) => res);
}

// function removeDuplicates(aarr) {
//   var unique = [];
//   aarr.forEach(function (item) {
//     if (!unique.includes(item.id)) {
//       unique.push(item.id);
//     }
//   });
//   return unique;
// }


export const getAllAppliedJobsList = async (page, rowsPerPage, search) => {
  return await callApi(`/chats/get_all_applied_job_posts?pageNo=${page}&pageSize=${rowsPerPage}&searchParam=${search}`)
}

export const getAllCompanyMenList = async (userJobDetails, page, rowsPerPage, search) => {
  // return await callApi(`chats/get_all_job_post_chat_channels?user_job_post_id=${userJobDetails?.user_job_post_id}&job_post_id=${userJobDetails?.job_post_id}`)
  return await callApi(`chats/get_all_job_post_chat_channels?user_job_post_id=${userJobDetails?.user_job_post_id}&job_post_id=${userJobDetails?.job_post_id}&pageNo=${page}&pageSize=${rowsPerPage}&searchParam=${search}`)
}


export const getAllChatsByChannel = async (channelId, page, rowsPerPage, search) => {

  const params = new URL(location.href).searchParams;
  const channelIds = params.get('channel');

  return await callApi(`chats/all_chats?channel_id=${channelIds}&pageNo=${page}&pageSize=${rowsPerPage}&searchParam=${search}`)
}

export const sendUserMessages = async (data) => {
  return await callApi(`chats/send_message`, data, POST)
}