import React from 'react';
import { Grid, Stack, Typography, Box, Button, Chip } from "@mui/material";
import EmojIcon from '../../../assets/icons/Careers/EmojIcon';
import UserIcon from '../../../assets/icons/Careers/UserIcon';
import TrophyIcon from '../../../assets/icons/Careers/TrophyIcon';
import MedalIcon from '../../../assets/icons/Careers/MedalIcon';
function JoineeReasonTypeSection() {

    return (
        <Grid container sx={{
            paddingTop: "40px", paddingBottom: "50px",
            "@media (max-width: 600px)": {
                paddingTop: "20px",
                paddingBottom: "30px",
            },
        }}>
            <Grid item xs={12} sm={12}  
                sx={{
                    paddingTop: "20px",
                }}
            >
                <Box>
                    <Stack
                        direction="column"
                        sx={{
                            gap: "10px"
                        }}
                    >
                        <Typography
                            sx={{
                                color: "var(--text-color-one)",
                            }}
                        >Why joinee</Typography>
                        <Typography
                            sx={{
                                fontSize: "40px",
                                fontWeight: "500",
                                width: "70%",
                                "@media (max-width: 1600px)": {
                                    fontSize: "30px",
                                },
                                "@media (max-width: 1200px)": {
                                    fontSize: "25px",
                                },
                                "@media (max-width: 900px)": {
                                    fontSize: "20px",
                                    width: "100%",
                                },
                                "@media (max-width: 600px)": {
                                    fontSize: "18px",
                                    width: "100%",
                                },
                            }}
                        >Joinee is a Place Where Everyone Can Be The Best Version of Themselves</Typography>
                    </Stack>
                    <Box
                        sx={{
                            display: "grid",
                            gridTemplateColumns: "repeat(2, 1fr)",
                            "@media (max-width: 900px)": {
                                gridTemplateColumns: "repeat(2, 1fr)",
                            },
                            "@media (max-width: 600px)": {
                                gridTemplateColumns: "repeat(1, 1fr)",
                            },
                            gap: "20px",
                            marginTop: "20px",
                        }}
                    >
                        {[
                            { id: "1", icon: UserIcon, title: "Powered by People", text: "Tortor pulvinar vestibulum eget aliquet penatibus interdum pellentesque. Diam ultrices in risus ac nunc imperdiet ultricies. Viverra est nunc condimentum aliquam pharetra ac.", },
                            { id: "2", icon: TrophyIcon, title: "Inspire and Provoke", text: "Tortor pulvinar vestibulum eget aliquet penatibus interdum pellentesque. Diam ultrices in risus ac nunc imperdiet ultricies. Viverra est nunc condimentum aliquam pharetra ac.", },
                            { id: "3", icon: MedalIcon, title: "Make it Right", text: "Tortor pulvinar vestibulum eget aliquet penatibus interdum pellentesque. Diam ultrices in risus ac nunc imperdiet ultricies. Viverra est nunc condimentum aliquam pharetra ac.", },
                            { id: "4", icon: EmojIcon, title: "Be Transparent", text: "Tortor pulvinar vestibulum eget aliquet penatibus interdum pellentesque. Diam ultrices in risus ac nunc imperdiet ultricies. Viverra est nunc condimentum aliquam pharetra ac.", },
                        ]?.map((item, index) => (
                            <Stack
                                sx={{
                                    boxShadow: "0px 4px 11px -1px #0A0A0A0A",
                                    border: "1px solid #E5E5E5",
                                    background: "linear-gradient(0deg, #FFFFFF, #FFFFFF), linear-gradient(0deg, #E5E5E5, #E5E5E5)",
                                    minHeight: "150px",
                                    gap: "10px"
                                }}
                                p={4}
                                direction="column"

                            >
                                <Stack
                                    sx={{
                                        height: "50px",
                                        width: "50px",
                                        borderRadius: "50%",
                                        background: "var(--app-color-one)",

                                    }}
                                    justifyContent="center"
                                    alignItems="center"
                                >
                                    {item?.icon()}
                                </Stack>
                                <Typography
                                    sx={{
                                        fontSize: "20px",
                                        fontWeight: "600",
                                        "@media (max-width: 900px)": {
                                            fontSize: "18px",
                                        },
                                        "@media (max-width: 600px)": {
                                            fontSize: "15px",
                                        },
                                    }}
                                >{item?.title}</Typography>
                                <Typography
                                    sx={{
                                        color: "var(--text-color-one)",
                                        fontSize: "15px",
                                        "@media (max-width: 600px)": {
                                            fontSize: "12px",
                                        },
                                    }}
                                >{item?.text}</Typography>
                            </Stack>
                        ))}
                    </Box>
                </Box>
            </Grid>
        </Grid>
    )
}

export default JoineeReasonTypeSection;