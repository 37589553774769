import React from 'react';
import { Grid, Stack, Typography, Box, Button, Chip } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import Banner from "../../../assets/icons/Careers/banner.png";
import airtable from "../../../assets/icons/Careers/airtable.svg";
import gumroad from "../../../assets/icons/Careers/gumroad.svg";
import mailChimp from "../../../assets/icons/Careers/mailChimp.svg";
import notion from "../../../assets/icons/Careers/notion.svg";



function CareerBannerSection() {

    /**
 *
 * redux dispatch
 *
 */
    const dispatch = useDispatch();


    /**
 *
 * navigate
 *
 */
    const navigate = useNavigate();


    return (
        <Grid container sx={{
            paddingTop: "80px", paddingBottom: "100px",
            "@media (max-width: 600px)": {
                paddingTop: "50px", 
                paddingBottom: "60px",
            },
        }}>
            <Grid item xs={12} sm={12}>
                <Stack sx={{ width: "100%", margin: "auto" }} spacing={2}>
                    <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="flex-start"
                    >
                        <Chip label="Career"
                            sx={{
                                background: "#ECFAE2",
                                '& .MuiChip-label': {
                                    fontWeight: "700",
                                },
                            }}
                        />
                    </Stack>
                    <Stack
                        direction="column"
                        sx={{
                            width: "70%",
                            "@media (max-width: 900px)": {
                                width: "100%",
                            },
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: "45px",
                                fontWeight: "500",
                                "@media (max-width: 900px)": {
                                    fontSize: "20px",
                                },
                            }}
                        >Our Company Are Building The Future</Typography>
                        <Typography
                            sx={{
                                fontSize: "16px",
                                fontWeight: "500",
                                color: "#737373",
                                "@media (max-width: 900px)": {
                                    fontSize: "13px",
                                },
                            }}
                        >
                            Keep your business account and all your finance needs safely organized under one roof. Manage money quickly, easily & efficiently. Whether you’re alone or leading a team.
                        </Typography>
                        <Button
                            type="submit"
                            size="large"
                            onClick={() => { console.log("Fired...") }}
                            loading={false}
                            loadingPosition="end"
                            variant="contained"
                            sx={{
                                borderRadius: "50px",
                                boxShadow: "none",
                                width: "fit-content",
                                background: "var(--app-button-color1)",
                                padding: "8px 30px",
                                "&:hover": {
                                    boxShadow: "none",
                                    background: "var(--app-button-color1)",
                                },
                                marginTop: "30px",
                            }}
                        >
                            <span style={{ textTransform: "capitalize", fontWeight: "600" }}>See Open Positions</span>
                        </Button>
                    </Stack>
                    <Stack pt={4}>
                        <Box
                            sx={{
                                width: "100%",
                                height: "100%"
                            }}
                        >
                            <img src={Banner} alt="career banner" style={{ width: "100%", height: "auto" }} />
                        </Box>
                    </Stack>
                </Stack>
            </Grid>
            <Grid
                item
                xs={12}
                sm={12}
            >
                <Stack
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        flexWrap: "wrap",
                        gap: "20px",
                        justifyContent: "space-between",
                        marginTop: "50px"
                    }}
                >
                    <Box>
                        <Typography>Trusted by thousand companies in the world</Typography>
                    </Box>
                    <Box>
                        <img src={mailChimp} alt="" />
                    </Box>
                    <Box>
                        <img src={airtable} alt="" />
                    </Box>
                    <Box>
                        <img src={gumroad} alt="" />
                    </Box>
                    <Box>
                        <img src={notion} alt="" />
                    </Box>
                </Stack>
            </Grid>


        </Grid >
    )
}

export default CareerBannerSection;