import { POST } from "../../../Constants/methods";
import callApi from "../../callApi";
import { applyForJobModel } from "./models/applyForJob";
import Cookies from 'js-cookie';
import {
  findAllJobsModel,
  findAllFunctionalAreas,
  findAllJobTypes,
  findAllKeySkills,
  findBlogsModel,
  findSalaryRanageModel,
  findAllWorkModes
} from "./models/findAllJobs";
import { isAvailableInDB } from "./models/userData";


let userId = localStorage.getItem("user_id");

const cookieCountryCode = Cookies.get('cookieCountryCode');

export const findAllJobs = async ({
  job_post_title,
  location,
  job_type,
  experience,
  salary,
  functional_area,
  key_skills,
  work_mode,
  offset,
  limit,
  candidate_id,
  pageNo,
  pageSize,
  searchParam
}) => {

  
  const formatter = (
    job_post_title,
    location,
    job_type,
    experience,
    salary,
    functional_area,
    key_skills,
    work_mode,
    candidate_id,
    offset,
    limit,
    pageNo,
    pageSize,
    searchParam
  ) => ({
    job_post_title: job_post_title === null ? "" : job_post_title,
    location: location === null ? "" : location,
    job_type: job_type === null ? [] : job_type,
    experience: experience === null ? "" : experience,
    salary: salary === null ? [] : salary,
    key_skills: key_skills === null ? [] : key_skills,
    functional_area: functional_area === null ? [] : functional_area,
    work_mode: work_mode === null ? "" : work_mode,
    candidate_id: candidate_id === null ? "" : candidate_id,
    offset,
    limit,
    pageNo,
    pageSize,
    searchParam
  });

  const formatter2 = (
    job_post_title,
    location,
    job_type,
    experience,
    salary,
    functional_area,
    key_skills,
    work_mode,
    offset,
    limit,
    pageNo,
    pageSize,
    searchParam
  ) => 

  ({
    job_post_title: job_post_title === null ? "" : job_post_title,
    location: location === null ? "" : location,
    job_type: job_type === null ? [] : job_type,
    experience: experience === null ? "" : experience,
    salary: salary === null ? [] : salary,
    key_skills: key_skills === null ? [] : key_skills,
    functional_area: functional_area === null ? [] : functional_area,
    work_mode: work_mode === null ? "" : work_mode,
    // offset,
    // limit,
    pageNo:pageNo,
    pageSize,
    searchParam
  });

  let formatted
  if (localStorage.getItem("token") !== null) {
    formatted = formatter(
      job_post_title,
      location,
      job_type,
      experience,
      salary,
      functional_area,
      key_skills,
      work_mode,
      candidate_id,
      offset,
      limit,
      pageNo,
      pageSize,
      searchParam
    );
  } else {
    console.log(pageNo,"nummm")
    formatted = formatter2(
      job_post_title,
      location,
      job_type,
      experience,
      salary,
      functional_area,
      key_skills,
      work_mode,
      offset,
      limit,
      pageNo,
      pageSize,
      searchParam
    );
  }

  console.log(formatted,"formatted")
  const { data, status } = await callApi(
    "referral_partner_job_posts",
    {
      search_type: `country-${cookieCountryCode || ""}`,
      ...formatted
    },
    POST
  );

  return { data: await findAllJobsModel(data?.data_payload), status, totalCount: data?.overall_count, totalPageSize: data?.total_page_size };
};

export const findAllFuncArea = async (search) => {
  const { data, status } = await callApi(`/common/get_functional_areas?search_type=country-${cookieCountryCode || ""}&searchParam=${search}`);

  return { data: await findAllFunctionalAreas(data?.data_payload), status };
};

export const findJobTypes = async () => {
  const { data, status } = await callApi(`/common/get_job_types?search_type=country-${cookieCountryCode || ""}`);
  return { data: await findAllJobTypes(data?.data_payload), status };
};

export const findSalaryRangeTypes = async () => {
  const { data, status } = await callApi("common/get_all_salary_ranges");
  return { data: await findSalaryRanageModel(data), status };
};

export const findKeySkills = async (search) => {
  const { data, status } = await callApi(`/common/get_key_skills?search_type=country-${cookieCountryCode || ""}&searchParam=${search}`);
  return { data: await findAllKeySkills(data?.data_payload), status };
};

export const findWorkMode = async () => {
  const { data, status } = await callApi(`/get_work_modes?search_type=country-${cookieCountryCode || ""}`);
  return { data: await findAllWorkModes(data?.data_payload), status };
};

export const fetchFilteredJobs = async (text, location, exp) => {
  let propsObject = {
    name: text,
    locationn: location,
    experiencee: exp,
  };

  const { data, status } = await callApi(
    `referral_partner_job_posts?${text ? `&job_post_title=${text}` : ""}${location ? `&location=${location}` : ""
    }${exp ? `&experience=0,${exp}` : ""}`
  );

  return { data: await findAllJobsModel(data?.data_payload), status };
};

export const applyFilteredJobs = async exp => {
  const { data, status } = await callApi(
    `referral_partner_job_posts?${exp ? `&experience=0,${exp}` : ""}`
  );

  return { data: await findAllJobsModel(data?.data_payload), status };
};

export const findJobsOnFuns = async id => {
  const { data, status } = await callApi(
    `common/get_job_posts_based_on_functional_area?functional_area_id=${id}`
  );
  const dataM = await findAllJobsModel(data);
  return { data: await findAllJobsModel(data), status };
};

export const findBlogsList = async () => {
  const { data, status } = await callApi("blogs/blogs");
  return { data: await findBlogsModel(data.data), status };
};

export const referSelf = async () => {
  return await callApi("/referral_partner_job_posts/check_refer_self");
};

export const applyForJob = async (data, id) => {
  return await callApi(
    `/referral_partner_job_posts/apply_or_refer_now`,
    { ...data, job_post_id: id, address: "no_preview" },
    POST
  );
};

export const updateScreeningQuestion = async (data, id) => {
  return await callApi(
    `/referral_partner_job_posts/answer_the_questions`,
    data,
    POST
  );
};

export const checkIfEmailExists = async email => {
  const { data, status } = await callApi(
    `referral_partner_job_posts/check_refer_now_candidate_for_job_post?email=${email}`
  );
  return { data: await isAvailableInDB(data), status };
};
export const getAllReferedJobs = async (page, rowsPerPage, search) => {
  return await callApi(`referral_partner_job_posts/get_all_referred_jobs?pageNo=${page}&pageSize=${rowsPerPage}&searchParam=${search}`);
};

export const getAllMyReferals = async (page, rowsPerPage, search) => {
  return await callApi(`referral_partner_job_posts/get_all_my_referrals?pageNo=${page}&pageSize=${rowsPerPage}&searchParam=${search}`);
};
export const getAllSelfReferedJobs = async (page, rowsPerPage, search) => {
  return await callApi(`referral_partner_job_posts/get_all_self_referred_jobs?pageNo=${page}&pageSize=${rowsPerPage}&searchParam=${search}`);
};
export const getJobPostById = async id => {
  // return await callApi(`company/job_post/${id}`);
  return await callApi(`company/job_post/${id}?user_id=${userId}`);
};
// export const getJobPostById = async (id,userId) => {
//   return await callApi(`company/job_post/${id}?userId=${userId}`);
// };

export const getJobDetailsById = async id => {
  const result = await callApi(`company/job_post/${id}?user_id=${userId}`);
  return result
};

export const getAllInterviewsList = async () => {
  return await callApi(`schedule_interview_for_job_post`);
};


export const getScreeningQuestions = async (id) => {
  // return await callApi(`company/job_post/${id}`)
  return await callApi(`company/job_post/${id}?user_id=${userId}`);
}

export const savedJobById = async (data) => {
  return await callApi(`candidate/saved_jobs`, data, POST)
}

export const getSavedJobsList = async (page, rowsPerPage, search) => {
  return await callApi(`candidate/saved_jobs?pageNo=${page}&pageSize=${rowsPerPage}&searchParam=${search}`)
}

/*Search with pagination API start here testing @seshu */

/*Applied Jobs API GET Method with search and pagination start here @seshu */
export const getAllSelfReferedAppliedJobs = async (text) => {
  return await callApi(`referral_partner_job_posts/get_all_self_referred_jobs?search=${text !== "" ? text : ""}`);
};
/*Applied Jobs API GET Method with search and pagination end here @seshu */

/* My referred Me page search API Start here @seshu */
export const getAllReferJobsWithSearch = async (text) => {
  // return await callApi(`?search=${text !== ""?text:""}`)
}
/* My referred Me page search API End here @seshu */

/* My referrals page search API Start here @seshu */
export const getAllReferralsSearch = async (text) => {
  // return await callApi(`?search=${text !== ""?text:""}`)
}
/* My referrals page search API Start here @seshu */

/*Search with pagination API End here testing @seshu */