import React from 'react';
import { Grid, Stack, Typography, Box, Chip } from "@mui/material";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import Artboard1 from "../../../../assets/icons/LandingPage/Artboard1.svg";
import Artboard2 from "../../../../assets/icons/LandingPage/Artboard2.svg";
import Artboard3 from "../../../../assets/icons/LandingPage/Artboard3.svg";
import Artboard4 from "../../../../assets/icons/LandingPage/Artboard4.svg";
import Artboard5 from "../../../../assets/icons/LandingPage/Artboard5.svg";
import Artboard6 from "../../../../assets/icons/LandingPage/Artboard6.svg";

import { makeStyles } from "@mui/styles";
import { openModal } from '../../../../components/modals/modalsSlice';

const useStyles = makeStyles({
    mainElement: {
        position: "relative",
    },
    line: {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "0.5px",
        height: "100%",
        // backgroundColor: "#7b7b7b",
        borderLeft: "dotted 2px #7b7b7b",
        borderSpacing: "10px",
    },

    dot: {
        position: "absolute",
        left: "50%",
        backgroundColor: "#7b7b7b",
        borderRadius: "50%",
        width: "5px",
        height: "5px",
    },
    dotTop: {
        top: "0",
        transform: "translateX(-50%)"
    },
    dotBottom: {
        bottom: "0",
        transform: "translateX(-50%)",
    },
    "@media (max-width: 600px)": {
        line: {
            display: "none",
        },
        dot: {
            display: "none"
        }
    },

});


function WorkingTypeSection() {

    const classes = useStyles();


      /**
   *
   * redux dispatch
   *
   */
  const dispatch = useDispatch();




    return (
        <Stack>
            <Stack pb={3} sx={{
                width: "50vw", margin: "auto",
                "@media (max-width: 900px)": {
                    paddingBottom: "10px",
                },
            }}>
                <Typography
                    sx={{
                        color: "#263238",
                        textAlign: "center",
                        fontSize: "37px",
                        fontWeight: "500",
                        "@media (max-width: 1200px)": {
                            fontSize: "30px",
                        },
                        "@media (max-width: 900px)": {
                            fontSize: "25px",
                        },
                        "@media (max-width: 600px)": {
                            fontSize: "20px",
                            width: "90%",
                        },
                    }}
                >Joinee, The new face of hiring</Typography>
            </Stack>
            <Grid container sx={{
                paddingTop: "0px",
                paddingBottom: "50px",
                position: "relative",
                width: "70%",
                margin: "auto",
                "@media (max-width: 900px)": {
                    width: "95%",
                },
            }}
            >

                <Grid item xs={12} sm={12}
                    className={`${classes.mainElement}`}
                >
                    <span className={`${classes.line}`}></span>
                    <span className={`${classes.dot} ${classes.dotTop}`}></span>
                    <span className={`${classes.dot} ${classes.dotBottom}`}></span>
                    <Grid container mt={3}>
                        <Grid item xs={6} sm={5} p={2}>
                            <Stack
                                direction="column"
                                alignItems="center"
                                justifyContent="center"
                            >
                                <Typography
                                    sx={{
                                        fontWeight: "600",
                                        fontSize: "20px"
                                    }}
                                    mb={2}
                                    noWrap
                                >Traditional way</Typography>
                            </Stack>
                        </Grid>
                        <Grid item xs={0} sm={2}></Grid>
                        <Grid item xs={6} sm={5} p={2}>
                            <Stack
                                direction="column"
                                alignItems="center"
                                justifyContent="center"
                            >
                                <Typography
                                    sx={{
                                        fontWeight: "600",
                                        fontSize: "20px",
                                        color: "#056045"
                                    }}
                                    noWrap
                                    mb={2}
                                >Joinee way</Typography>
                            </Stack>
                        </Grid>
                    </Grid>
                    <Grid container mt={3}>
                        <Grid item xs={12} sm={5} p={2}>
                            <Stack
                                direction="column"
                                alignItems="center"
                                justifyContent="center"
                            >
                                <Box mb={2}>
                                    <img src={Artboard1} />
                                </Box>
                                <Box mb={2}>
                                    <Chip label="10+ Days"
                                        sx={{
                                            background: "#E6F0ED",
                                            padding: "10px 15px",
                                            '& .MuiChip-label': {
                                                fontWeight: "600",
                                            },
                                        }}
                                    />
                                </Box>

                                <Typography
                                    sx={{
                                        fontWeight: "500",
                                        fontSize: "15px",
                                        textAlign: "center"
                                    }}
                                ><span style={{ fontWeight: "700" }}>Sourcing Hassles: </span> You sign-up & subscribe to multiple job boards.</Typography>
                            </Stack>

                        </Grid>
                        <Grid item xs={12} sm={2}
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                zIndex: "1"
                            }}
                        >
                            {/* <Box
                            sx={{
                                zIndex: "1",
                                paddingTop: "15px",
                                paddingBottom: "15px",
                                background: "#fff"
                            }}
                            > */}
                            <Chip label="Sourcing"
                                sx={{
                                    zIndex: "2",
                                    background: "#ffca6c",
                                    boxShadow: "0 0 20px 30px rgb(252 251 243)",
                                    '& .MuiChip-label': {
                                        fontWeight: "600",
                                        display: 'block',
                                        whiteSpace: 'normal',
                                        textAlign: "center"
                                    },
                                }}
                            />
                            {/* </Box> */}
                        </Grid>
                        <Grid item xs={12} sm={5} p={2}>
                            <Stack
                                direction="column"
                                alignItems="center"
                                justifyContent="center"
                            >
                                <Box mb={2}>
                                    <img src={Artboard2} />
                                </Box>
                                <Box mb={2}>
                                    <Chip label="48 Hours"
                                        sx={{
                                            background: "#E6F0ED",
                                            padding: "10px 15px",
                                            '& .MuiChip-label': {
                                                fontWeight: "600",
                                            },
                                        }}
                                    />
                                </Box>

                                <Typography
                                    sx={{
                                        fontWeight: "500",
                                        fontSize: "15px",
                                        textAlign: "center"
                                    }}
                                ><span style={{ fontWeight: "700" }}>Efficient Sign-Up: </span> Share job specifics for quicker and more precise talent matching.</Typography>
                            </Stack>
                        </Grid>
                    </Grid>

                    <Grid container mt={3}>
                        <Grid item xs={12} sm={5} p={2}>
                            <Stack
                                direction="column"
                                alignItems="center"
                                justifyContent="center"
                            >
                                <Box mb={2}>
                                    <img src={Artboard3} />
                                </Box>
                                <Box mb={2}>
                                    <Chip label="20 Days"
                                        sx={{
                                            background: "#E6F0ED",
                                            padding: "10px 15px",
                                            '& .MuiChip-label': {
                                                fontWeight: "600",
                                            },
                                        }}
                                    />
                                </Box>

                                <Typography
                                    sx={{
                                        fontWeight: "500",
                                        fontSize: "15px",
                                        textAlign: "center"
                                    }}
                                ><span style={{ fontWeight: "700" }}>Time-Consuming Interviews: </span> Sorting through resumes and conducting multiple interviews leads to delays and inefficiencies.</Typography>
                            </Stack>

                        </Grid>
                        <Grid item xs={12} sm={2}
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                zIndex: "1"
                            }}
                        >
                            <Chip label="Screening Assesment"
                                sx={{
                                    zIndex: "2",
                                    background: "#ffca6c",
                                    boxShadow: "0 0 20px 30px rgb(252 251 243)",
                                    '& .MuiChip-label': {
                                        fontWeight: "600",
                                        display: 'block',
                                        whiteSpace: 'normal',
                                        textAlign: "center",
                                    },
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={5} p={2}>
                            <Stack
                                direction="column"
                                alignItems="center"
                                justifyContent="center"
                            >
                                <Box mb={2}>
                                    <img src={Artboard4} />
                                </Box>
                                <Box mb={2}>
                                    <Chip label="2 Days"
                                        sx={{
                                            background: "#E6F0ED",
                                            padding: "10px 15px",
                                            '& .MuiChip-label': {
                                                fontWeight: "600",
                                            },
                                        }}
                                    />
                                </Box>

                                <Typography
                                    sx={{
                                        fontWeight: "500",
                                        fontSize: "15px",
                                        textAlign: "center"
                                    }}
                                ><span style={{ fontWeight: "700" }}>Streamlined Recruitment: </span> 100% profile matching minimises multiple interview rounds, ensuring rapid talent selection.</Typography>
                            </Stack>
                        </Grid>
                    </Grid>

                    <Grid container mt={3}>
                        <Grid item xs={12} sm={5} p={2}>
                            <Stack
                                direction="column"
                                alignItems="center"
                                justifyContent="center"
                            >
                                <Box mb={2}>
                                    <img src={Artboard5} />
                                </Box>
                                <Box mb={2}>
                                    <Chip label="10 Days"
                                        sx={{
                                            background: "#E6F0ED",
                                            padding: "10px 15px",
                                            '& .MuiChip-label': {
                                                fontWeight: "600",
                                            },
                                        }}
                                    />
                                </Box>

                                <Typography
                                    sx={{
                                        fontWeight: "500",
                                        fontSize: "15px",
                                        textAlign: "center"
                                    }}
                                ><span style={{ fontWeight: "700" }}>Uncertainty Post-Offer: </span>There's no guarantee of timely candidate acceptance, causing potential delays.</Typography>
                            </Stack>

                        </Grid>
                        <Grid item xs={12} sm={2}
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                zIndex: "1"
                            }}
                        >
                            <Chip label="Placement"
                                sx={{
                                    zIndex: "2",
                                    background: "#ffca6c",
                                    boxShadow: "0 0 20px 30px rgb(252 251 243)",
                                    '& .MuiChip-label': {
                                        fontWeight: "600",
                                        display: 'block',
                                        whiteSpace: 'normal',
                                        textAlign: "center"
                                    },
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={5} p={2}>
                            <Stack
                                direction="column"
                                alignItems="center"
                                justifyContent="center"
                            >
                                <Box mb={2}>
                                    <img src={Artboard6} />
                                </Box>
                                <Box mb={2}>
                                    <Chip label="2 Days"
                                        sx={{
                                            background: "#E6F0ED",
                                            padding: "10px 15px",
                                            '& .MuiChip-label': {
                                                fontWeight: "600",
                                            },
                                        }}
                                    />
                                </Box>

                                <Typography
                                    sx={{
                                        fontWeight: "500",
                                        fontSize: "15px",
                                        textAlign: "center"
                                    }}
                                ><span style={{ fontWeight: "700" }}>Post-Hire Ease: </span>Our team manages onboarding, productivity, and post-hire processes, ensuring a smooth transition.</Typography>
                            </Stack>
                        </Grid>
                    </Grid>


                </Grid>
            </Grid>
            <Stack mb={2} mt={2}
                alignItems="center"
                justifyContent="center"
            >
                <Box
                    sx={{
                        border: "1px solid var(--app-button-color1)",
                        color: "#fff",
                        padding: "12px 30px 12px 30px",
                        fontSize: "15px",
                        borderRadius: "50px",
                        fontWeight: "700",
                        background: "var(--app-button-color1)",
                        width: "fit-content",
                        cursor: "pointer",
                        "&:hover": {
                            background: "#fff",
                            color: "#000",
                        }
                    }}
                    onClick={() => {
                        dispatch(
                            openModal({
                                modalName: "requestDemoOneModal",
                                modalProps: {
                                },
                            })
                        );
                    }}
                >Request a demo</Box>
            </Stack>
        </Stack>
    )
}

export default WorkingTypeSection;