import React, { useEffect, useState, useRef } from "react";
import {
  OutlinedInput,
  InputAdornment,
  FormControl,
  IconButton,
  Box,
  Stack,
  TextField,
  ListItemButton,
  Typography,
  ListItemAvatar,
  ListItemText,
  ListItem,
  List,
  Divider,
  Badge,
  Avatar
} from "@mui/material";
import { getAllCompanyMenList } from "../../helpers/dataFetcher/chats/chats";
import SearchIcon from "@mui/icons-material/Search";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { makeStyles } from "@mui/styles";
import "../../assets/css/chat/chat.css";
import { get_chat_active_user_list } from "../../helpers/sockets";
import { imageURL } from "../../Constants/commonURLS";
import { useParams } from "react-router-dom";
import { useNavigate, useSearchParams } from "react-router-dom";
import { globalTheme } from "../../assets/MUI/globalStyles";
import { ThemeProvider } from "@mui/material/styles";
import EyeJobDrawer from "../Notifications/EyeJobDrawer";
// import chatImg from "../../assets/images/chat.svg";
import chatImg from "../../assets/images/chatShiyft2.png";
import moment from 'moment';
import { socket } from "../../Sockets/Socket";
import GlobalSearch from "../../hooks/GlobalSearch";
import { getJobPostById } from "../../helpers/dataFetcher/jobs/jobs";

const useStyles = makeStyles({
  root: {
    height: 48,
    width: "100% ",
    background: "#ffffff",
    border: "1px solid rgba(0, 0, 0, 0.1) !important",
    borderRadius: "12px !important",
    transitionDelay: "0.5ms",
    color: "rgba(0, 0, 0, 0.5)",
    fontFamily: "Inter , sans-serif !important",
    "& input::placeholder": {
      fontFamily: "Inter , sans-serif !important",
      fontWeight: 500,
      fontSize: 14,
      fontStyle: "normal",
      color: "rgba(0, 0, 0, 0.5)",
    },
    //   "& input": {
    //       padding: "12px 14px",
    //       borderRadius: 8,
    //     },
    "& .PrivateNotchedOutline-root-1": {
      display: "none !important",
    },
    "& fieldset": {
      borderWidth: 0,
    },
  },
  fieldSet: {
    width: "88%",
    "&.MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
    "&.PrivateNotchedOutline-root-5": {
      borderWidth: 0,
    },
    "& fieldset": {
      borderWidth: 0,
    },
  },
  avatar: {
    background: "#cde2e7 !important",
    "& .css-2s90m6-MuiAvatar-root": {
      color: "rgba(0, 0, 0, 0.7)  !important",
      fontFamily: "Inter , sans-serif !important",
      fontSize: "20px !important",
    },
  },
  avatar1: {
    background: "#cde2e7 !important",
    borderRadius: "36px",
    "&.css-2s90m6-MuiAvatar-root": {
      color: "rgba(0, 0, 0, 0.7)",
      fontFamily: "Inter , sans-serif !important",
      fontSize: "20px !important",
    },
  },
  chip: {
    "&.css-w66kx-MuiChip-root": {
      height: "auto !important",
      marginLeft: "4px",
      color: "#006064",
      backgroundColor: "rgba(77, 208, 225, 0.5) !important",
      fontFamily: "Inter , sans-serif !important",
      fontStyle: "normal",
      fontWeight: "500 !important",
      fontSize: "11px !important",
      textTransform: "capitalize",
    },
  },
  chip2: {
    "&.css-w66kx-MuiChip-root": {
      height: "auto !important",
      marginLeft: "4px",
      color: "#B84040",
      backgroundColor: "rgba(204, 51, 51, 0.5) !important",
      fontFamily: "Inter , sans-serif !important",
      fontStyle: "normal",
      fontWeight: "500 !important",
      fontSize: "11px !important",
    },
  },
  chip3: {
    "&.css-w66kx-MuiChip-root": {
      height: "auto !important",
      marginLeft: "4px",
      color: "#827717",
      backgroundColor: "rgba(220, 231, 117, 0.5) !important",
      fontFamily: "Inter , sans-serif !important",
      fontStyle: "normal",
      fontWeight: "500 !important",
      fontSize: "11px !important",
    },
  },
  address: {
    fontFamily: "Inter , sans-serif !important",
    fontStyle: "normal",
    fontWeight: "400 !important",
    fontSize: "12px !important",
    color: "rgba(0, 0, 0, 0.7) !important",
  },
  name: {
    display: "inline-block",
    fontFamily: "Inter , sans-serif !important",
    fontStyle: "normal",
    fontWeight: "700 !important",
    fontSize: "14px !important",
    color: "#000000 !important",
  },
  msg: {
    fontFamily: "Inter , sans-serif !important",
    fontStyle: "normal",
    fontWeight: "400 !important",
    fontSize: "13px !important",
    color: "rgba(0, 0, 0, 0.7) !important",
  },
});

function DemoChatSidebarDummy({
  setReceiverId,
  setChannelsPage,
  channelsPage,
}) {
  const classes = useStyles();
  const navigate = useNavigate();
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const [read, setRead] = useState({ id: '' })
  const [searchParams, setSearchParams] = useSearchParams();
  const [sideChatLoading, setSideChatLoading] = useState(true);

  const [jobDetails, setJobDetails] = useState({});
  const [companyMembers, setCompanyMembers] = useState(null);
  const [currPage, setCurrPage] = useState(1);
  const [prevPage, setPrevPage] = useState(0);
  const [wasLastList, setWasLastList] = useState(false);
  const userJobPostIds = searchParams.get("user-job-post-id") ?? "";
  const jobPostIds = searchParams.get("job-post-id") ?? "";
  const channelIDs = searchParams.get("channel") ?? "";
  const searchFilter = searchParams.get("q");
  const pageSize = 10;

  const peopleListRef = useRef();

  const userJobDetails = {
    user_job_post_id: userJobPostIds,
    job_post_id: jobPostIds,
  };


  const handleListnerEvent = (data) => {
    if (data?.type === "chats") {
      getCompanyMembers();
    }
  };


  useEffect(() => {
    const handleReconnect = () => {
      socket.on("listnerEvent", handleListnerEvent);
    };

    socket.on("listnerEvent", handleListnerEvent);
    socket.on("reconnect", handleReconnect);

    return () => {
      socket.off("listnerEvent", handleListnerEvent);
      socket.off("reconnect", handleReconnect);
    };
  }, []);

  useEffect(() => {
    getjobById();
  }, [jobPostIds])

  const getjobById = async () => {
    const { data, status } = await getJobPostById(jobPostIds);
    if (status === 200) {
      setJobDetails(data?.data_payload);
    }
  };

  useEffect(() => {
    getCompanyMembers();
  }, [read]);

  useEffect(() => {
    getCompanyMembers();
    setCurrPage(1);
    setCompanyMembers([]);
  }, [searchFilter])


  async function getCompanyMembers() {
    setSideChatLoading(true);
    const { data, status } = await getAllCompanyMenList(userJobDetails, 1, pageSize * currPage, searchFilter ?? "");

    if (!data?.data_payload?.length) {
      setSideChatLoading(false);
      return;
    }

    if(data?.overall_count >= data?.pageSize) {
      setCurrPage(currPage + 1);
    } 
  
    setCompanyMembers(data?.data_payload);

    // setCurrPage((prevPage) => prevPage + 1);
    // setCompanyMembers((prevItems) => [...prevItems, ...data?.data_payload]);
    setChannelsPage(false);
    setSideChatLoading(false);
    return;
  }


  const handleSendChannelId = async each => {

    setRead({ id: each?.id })
    searchParams.set("channel", each?.id);
    searchParams.set("sender_id", each?.ucc_sender_id?.id);
    setSearchParams(searchParams);
  };


  useEffect(() => {
    getCompanyMembers();
  }, [channelsPage]);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);


  const onPeopleListScroll = () => {
    if (peopleListRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = peopleListRef.current;

      if (Math.ceil(scrollTop + clientHeight) === Math.ceil(scrollHeight) - 2 ) {
        getCompanyMembers();
      }
    }
  };


  return (
    <>
      <div className="demochat_side_main">
        <div className="demochat_side_inside_cont"
          style={{
            height: "100%"
          }}
        >
          <div style={{ position: "sticky", minHeight: "120px", height: "125px"}}>
            <div
              className="selected_job"
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                padding: "10px"
              }}
            >

              <Stack
                direction="row"
                alignItems="center"
                justifyContent="flex-start"
                sx={{
                  background: "#cde2e7",
                  borderRadius: "8px",
                  width: "100%",
                  padding: "8px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginRight: "5px",
                  }}
                >
                  <IconButton
                    size="small"
                    onClick={(e) => {
                      e.stopPropagation();
                      navigate("../demochat");
                    }}
                  >
                    <KeyboardArrowLeftIcon fontSize="medium" />
                  </IconButton>
                </Box>
                <Avatar
                  alt={jobDetails?.job_title ? jobDetails?.job_title?.charAt(0) : "U"}
                  src={`${imageURL}${jobDetails?.company_details?.company_image}`}
                  className={classes.avatar1}
                  loading="lazy"
                  sx={{
                    background: "#cde2e7 !important",
                    color: "rgba(0, 0, 0, 0.7) !important",
                    marginRight: "5px"
                  }}
                />
                <div className="names_link_cont">
                  <div className="d-flex flex-column">
                    <Typography className={classes.name}>
                      {jobDetails?.job_title}
                    </Typography>
                    <Typography className={classes.msg}>
                      {jobDetails?.company_details?.company_name}
                    </Typography>
                  </div>
                  <div className="d-flex flex-column align-items-end">
                    <EyeJobDrawer
                      isDrawerOpen={isDrawerOpen}
                      setDrawerOpen={setDrawerOpen}
                      jobDetailsData={jobDetails}
                    />
                  </div>
                </div>
              </Stack>
            </div>
            <div className="chats_name_count">
              <div
                style={{ gap: "16px", alignItems: "center" }}
                className="d-flex flex-row pt-1 pl-3"
              >
                <h2 className="chats_name1">Chats</h2>
                <Badge
                  badgeContent={companyMembers?.length}
                  slotProps={{
                    badge: {
                      style: {
                        backgroundColor: "#066a4c",
                        width: "20px",
                        height: "20px",
                        color: "#ffe7bb",
                        fontSize: "10px",
                        fontWeight: 500,
                      },
                    },
                  }}
                />
              </div>

              <ThemeProvider theme={globalTheme}>
                <GlobalSearch placeholder={"Search..."} />
              </ThemeProvider>
            </div>
          </div>

          <div
            className="list_scroll2"
            style={{
              minHeight: "calc(100% - 125px)",
              height: "calc(100% - 125px)",
            }}
            onScroll={onPeopleListScroll}
            ref={peopleListRef}
          >
            <List sx={{ width: "100%", bgcolor: "background.paper"}}

            >
              {sideChatLoading && companyMembers === null ? (
                <Typography sx={{ color: "gray", padding: "10px", marginTop: "20px", textAlign: "center" }}>Loading...</Typography>
              ) : (
                <>
                  {companyMembers?.length > 0 ? (
                    companyMembers?.map(each => (
                      <>
                        <div
                          className="company_chat_list"
                          key={each.id}
                        >
                          <a
                            href="#"
                            onClick={e => {
                              e.preventDefault();
                              handleSendChannelId(each);
                              $(".chatbox").addClass("showbox");
                              if (windowWidth <= 992) {
                                $(".jobbox").removeClass("hideJobBox");
                              }
                            }}
                          >
                            <ListItemButton
                              alignItems="flex-start"
                              style={{ display: "flex", alignItems: "center" }}
                              sx={{ width: "100%", padding: "10px !important" }}
                              selected={each?.id === channelIDs}

                            >
                              <ListItemAvatar>
                                <Avatar
                                  // alt={`${each?.sender_type == "REFERAL_PARTNER"
                                  //   ? each?.ucc_receiver_id?.first_name?.charAt(0)
                                  //   : each?.ucc_sender_id?.first_name?.charAt(0)
                                  //   }`}
                                  alt={`${each?.ucc_sender_id?.first_name?.charAt(0)}`}
                                  // src={
                                  //   each?.sender_type == "REFERAL_PARTNER"
                                  //     ? `${imageURL}${each?.ucc_receiver_id?.image}`
                                  //     : `${imageURL}${each?.ucc_sender_id?.image}`
                                  // }
                                  src={`${imageURL}${each?.ucc_sender_id?.image}`}
                                  className={classes.avatar1}
                                  sx={{
                                    background:
                                      "rgba(192, 223, 250, 0.4) !important",
                                    color: "rgba(0, 0, 0, 0.7) !important",
                                    marginTop: "-8px"
                                  }}
                                />
                              </ListItemAvatar>
                              <div
                                className="names_link_cont"
                                style={{ display: "flex", alignItems: "center" }}
                              >
                                <div className="d-flex flex-column">
                                  <span className={classes.name}>
                                    {/* {each.sender_type == "REFERAL_PARTNER"
                                      ? each?.ucc_receiver_id?.first_name +
                                      each?.ucc_receiver_id?.last_name
                                      : each?.ucc_sender_id?.first_name +
                                      each?.ucc_sender_id?.last_name} */}
                                    {each?.ucc_sender_id?.first_name + " " + each?.ucc_sender_id?.last_name}
                                  </span>
                                  <Typography className={classes.msg}>
                                    {each?.last_message?.last_message
                                      ? each.last_message.last_message.slice(
                                        0,
                                        15
                                      ) + "..."
                                      : ""}
                                  </Typography>
                                </div>
                                <div className="d-flex flex-column align-items-end">
                                  {each?.last_message?.sender_details?.usertype !== "REFERAL_PARTNER" ?
                                    <Badge
                                      sx={{ mr: 1 }}
                                      badgeContent={each?.unread_messages_count || 0}
                                      slotProps={{
                                        badge: {
                                          style: {
                                            backgroundColor: "#066a4c",
                                            width: "20px",
                                            height: "20px",
                                            color: "#ffe7bb",
                                            fontSize: "10px",
                                            fontWeight: 600,
                                            // backdropFilter:"opacity(0.2)"
                                          },
                                        },
                                      }}
                                    />
                                    : ""}
                                </div>
                              </div>
                            </ListItemButton>
                          </a>
                        </div>

                        <Divider variant="inset" component="li" />
                      </>
                    ))
                  ) : (
                    <div className="">
                      <div
                        style={{
                          display: "grid",
                          placeItems: "center",
                          height: "100%",
                          width: "100%",
                        }}
                      >
                        <div className="d-flex flex-column justify-content-center align-items-center gap-2 pt-4 mt-3">
                          <div className="contact_no_show">
                            <img src={chatImg} alt="chat" />
                            <p>No Chats Found</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </>
              )}
            </List>
          </div>
        </div>
      </div>
    </>
  );
}

export default DemoChatSidebarDummy;
