import React from "react";
import EmpDummy1 from "../../assets/images/EmpDummy1.png";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

function EmployerPage6() {
  return (
    <>
      <section className="Employerpage6_sec">
        <div className="Emp6_main_cont">
          <div className="col-sm-2 col-md-2 col-lg-1">
            <div className="EmpDummy_Cont">
              <img src={EmpDummy1} />
            </div>
          </div>

          <div className="col-sm-10 col-md-10 col-lg-11">
            <div className="Emptxt_Cont">
              <h2 className="emptxt_head">
                Advantages with <span>Shifyt</span>
              </h2>

              <Box sx={{ flexGrow: 0 }}>
                <Grid container spacing={1}>
                  <Grid item xs={12} md={6} lg={4}>
                    <div className="grid1">
                      <div className="adv_card_main">
                        <div className="advantages_card">
                          <div className="profile_card_dummy"></div>

                          <h2 className="easycash_txt">
                            Unlimited Job Posting
                          </h2>
                          <p>
                          Free job postings on the platform absolutely free
                          </p>
                        </div>

                        <div className="advantages_card">
                          <div className="profile_card_dummy"></div>

                          <h2 className="easycash_txt">Flexible Pricing</h2>
                          <p>
                          You choose the commission to be paid as per your requirement
                          </p>
                        </div>
                      </div>
                      <div className="separate_grid_card">
                        <div className="advantages_card">
                          <div className="profile_card_dummy"></div>

                          <h2 className="easycash_txt">Faster Hiring</h2>
                          <p>
                          Cut short the time required to hire and onboard
                          </p>
                        </div>
                      </div>
                    </div>
                  </Grid>

                  <Grid item xs={12} md={6} lg={4}>
                    <div className="grid1">
                      <div className="adv_card_main">
                        <div className="advantages_card">
                          <div className="profile_card_dummy"></div>

                          <h2 className="easycash_txt">Cost Saving</h2>
                          <p>
                          Leverage on Joinee to save around 70% of the hiring cost
                          </p>
                        </div>

                        <div className="advantages_card">
                          <div className="profile_card_dummy"></div>

                          <h2 className="easycash_txt">Zero Obligations</h2>
                          <p>
                          Hire with no obligations and be stress free
                          </p>
                        </div>
                      </div>
                      <div className="separate_grid_card">
                        <div className="advantages_card">
                          <div className="profile_card_dummy"></div>

                          <h2 className="easycash_txt">Free ATS</h2>
                          <p>
                          Get free access to advanced ATS at zero cost

                          </p>
                        </div>
                      </div>
                    </div>
                  </Grid>

                  <Grid item xs={12} md={6} lg={4}>
                    <div className="grid1">
                      <div className="adv_card_main">
                        <div className="advantages_card">
                          <div className="profile_card_dummy"></div>

                          <h2 className="easycash_txt">Easy Encashment</h2>
                          <p>
                          Encashing is as easy as 1-2-3, directly to your bank account
                          </p>
                        </div>

                        <div className="advantages_card">
                          <div className="profile_card_dummy"></div>

                          <h2 className="easycash_txt">Vetted Profiles</h2>
                          <p>
                          Personally vetted profiles by domain experts
                          </p>
                        </div>
                      </div>
                      <div className="separate_grid_card">
                        <div className="advantages_card">
                          <div className="profile_card_dummy"></div>

                          <h2 className="easycash_txt">Network</h2>
                          <p>
                          Reach out to 1000s of partner network to simplify your hiring process
                          </p>
                        </div>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </Box>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default EmployerPage6;
