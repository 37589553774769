import React, { useState } from "react";
import StepOne from "./StepOne";
import StepTwo from "./StepTwo";
import StepThree from "./StepThree";
import "./refernow.css";

function ReferNow(props) {
  const [step, setStep] = useState(1);
  const [skills, setSkills] = useState();

  switch (step) {
    case 1: {
      return (
        <>
          <div className="container">
            <div className="row">
              <div className="col-sm-12 col-md-6 col-lg-6 border-right">
                <div className="block-banner">
                  <div className="refer_now_img_div">
                    <img src="https://png.pngtree.com/png-vector/20220520/ourmid/pngtree-refer-friend-loudspeaker-badge-png-image_4684700.png" alt="" />
                  </div>

                  <h3 className="heading-banne p-3">
                    The Easiest <br />
                    Way to Get Your
                    <br />
                    New Job
                  </h3>
                  <div className="banner-description mt-50 p-3">
                    Each month, more than 3 million job seekers turn to website
                    in their search for work, making over 140,000 applications
                    every single day
                  </div>
                </div>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-6">
                <StepOne
                  setSkills={setSkills}
                  skills={skills}
                  step={step}
                  setStep={setStep}
                />
              </div>
            </div>
          </div>
        </>
      );
    }

    case 2: {
      return (
        <>
          <div className="container" style={{ marginTop: "120px" }}>
            <div className="row">
              <div className="col-sm-12 col-md-6 col-lg-6 border-right">
                <div className="block-banner">
                  <h3 className="heading-banne" style={{ marginTop: "45px" }}>
                    The Easiest <br />
                    Way to Get Your
                    <br />
                    New Job
                  </h3>
                  <div className="banner-description mt-50">
                    Each month, more than 3 million job seekers turn to website
                    in their search for work, making over 140,000 applications
                    every single day
                  </div>
                </div>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-6">
                <StepTwo step={step} setStep={setStep} />
              </div>
            </div>
          </div>
        </>
      );
    }

    case 3: {
      return (
        <>
          <div className="container" style={{ marginTop: "120px" }}>
            <div className="row">
              <div className="col-sm-12 col-md-6 col-lg-6 border-right">
                <div className="block-banner">
                  <h3 className="heading-banne" style={{ marginTop: "45px" }}>
                    The Easiest <br />
                    Way to Get Your
                    <br />
                    New Job
                  </h3>
                  <div className="banner-description mt-50">
                    Each month, more than 3 million job seekers turn to website
                    in their search for work, making over 140,000 applications
                    every single day
                  </div>
                </div>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-6">
                <StepThree step={step} setStep={setStep} />
              </div>
            </div>
          </div>
        </>
      );
    }

    default:
      return (
        <div className="App">
          <div className="custom-card form-bg custom-sell-form p-5 mt-2"></div>
        </div>
      );
  }
}

export default ReferNow;
