import React from 'react';
import { Grid, Stack, Typography, Box, Chip, Button, IconButton } from "@mui/material";
import fireIcon from "../../../../assets/icons/LandingPage/fireIcon.svg";
import { makeStyles } from "@mui/styles";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import AppleLogoIcon from '../../../../assets/icons/LandingPage/AppleLogoIcon';
import DropboxLogoIcon from '../../../../assets/icons/LandingPage/DropboxLogoIcon';
import GoogleIcon from '../../../../assets/icons/LandingPage/GoogleIcon';
import InvisionStudioSmallIcon from '../../../../assets/icons/LandingPage/InvisionStudioSmallIcon';
import MailchimpWhiteIcon from '../../../../assets/icons/LandingPage/MailchimpWhiteIcon';
import NetflixIcon from '../../../../assets/icons/LandingPage/NetflixIcon';
import ReferJobCardImage1 from '../../../../assets/icons/LandingPage/ReferJobCardImage1.png';
import ReferJobCardImage from '../../../../assets/icons/LandingPage/ReferJobCardImage.svg';



const useStyles = makeStyles({
    container: {
        width: "85%",
        margin: "auto",
        display: "grid",
        gap: "20px",
        gridTemplateColumns: "repeat(3, 1fr)",
        // gridTemplateRows: "1fr 1fr 1fr 1fr",
        gridTemplateAreas: `
        "header1 header2 header3"
        "sidebar main1 main2"
        "sidebar section content"
      `,
    },
    testDiv: {
        border: "0.5px solid #E5EAF4",
        padding: "30px",
        borderRadius: "8px",
        overflow: "hidden"
    },
    header1: {
        gridArea: "header1",
        background: "#F3FAF7AD",
    },
    header2: {
        gridArea: "header2",
        background: "#F3FAF7AD",
    },
    header3: {
        gridArea: "header3",
        background: "#F3FAF7AD",
    },
    sidebar: {
        gridArea: "sidebar",
        border: "0px",
        padding: "0px",
    },
    main1: {
        gridArea: "main1",
        background: "#F3FAF7AD",
    },
    main2: {
        gridArea: "main2",
        background: "#F3FAF7AD",
    },
    section: {
        gridArea: "section",
        background: "#F3FAF7AD",
    },
    content: {
        gridArea: "content",
        border: "0.5px dashed #E5EAF4",
    },
});

const useMediaStyles = makeStyles({
    "@media (max-width: 900px)": {
        container: {
            width: "90%",
            margin: "auto",
            gap: "10px",
            gridTemplateColumns: "repeat(2, 1fr)",
            // gridTemplateRows: "10% 10% 10% 10%",
            gridTemplateAreas: `
          "header1 header2"
          "header3 main1"
          "main2 section"
          "sidebar content"
        `,
        },
    },
    "@media (max-width: 600px)": {
        container: {
            width: "100%",
            margin: "auto",
            gap: "10px",
            gridTemplateColumns: "repeat(1, 1fr)",
            // gridTemplateRows: "10% 10% 10% 10%",
            gridTemplateAreas: `
          "header1"
          "header2"
          "header3"
          "main1"
          "main2"
          "section"
          "sidebar"
          "content"
        `,
        },
    },
});



function OpenedJobSection() {

    const classes = useStyles();
    const mediaClasses = useMediaStyles();

    const dataFormat = [
        {
            id: "1",
            type: "Marketing",
            typeColor: "#e95432",
            typeBackground: "#f6ebe6",
            title: "Associate Product Marketing Consumer Marketing",
            location: "Amsterdam",
            jobType: "Full Time",
            icon: MailchimpWhiteIcon,
            iconBackground: "#FAAD13",
            iconName: "Mailchimp",
            time: "13 Hours ago",
            className: "header1"

        },
        {
            id: "2",
            type: "Design",
            typeColor: "#00b8d9",
            typeBackground: "#def5f7",
            title: "Associate Product Marketing Consumer Marketing",
            location: "New York",
            jobType: "Part Time",
            icon: DropboxLogoIcon,
            iconBackground: "#f0f5f5",
            iconName: "Dropbox",
            time: "17 Hours ago",
            className: "header2"
        },
        {
            id: "3",
            type: "Development",
            typeColor: "#36b37e",
            typeBackground: "#e4f5ee",
            title: "Associate Product Marketing Consumer Marketing",
            location: "Tokyo",
            jobType: "1 year - Contract",
            icon: InvisionStudioSmallIcon,
            iconBackground: "#f0f5f5",
            iconName: "Invision",
            time: "1 Day ago",
            className: "header3"
        },
        {
            id: "4",
            type: "Business",
            typeColor: "#faad12",
            typeBackground: "#f7f4e3",
            title: "Associate Product Marketing Consumer Marketing",
            location: "Stockholm",
            jobType: "Full Time",
            icon: AppleLogoIcon,
            iconBackground: "#f0f5f5",
            iconName: "Apple",
            time: "2 Days ago",
            className: "main1"
        },
        {
            id: "5",
            type: "Sales",
            typeColor: "#b0bdcf",
            typeBackground: "#e5ecef",
            title: "Associate Product Marketing Consumer Marketing",
            location: "Marseille",
            jobType: "Freelance",
            icon: GoogleIcon,
            iconBackground: "#f0f5f5",
            iconName: "Google",
            time: "1 Weeks ago",
            className: "main2"
        },
        {
            id: "6",
            type: "Customer Support",
            typeColor: "#e95432",
            typeBackground: "#f6ebe6",
            title: "Associate Product Marketing Consumer Marketing",
            location: "Silicon Valley",
            jobType: "Full Time",
            icon: NetflixIcon,
            iconBackground: "#f0f5f5",
            iconName: "Netflix",
            time: "1 Months ago",
            className: "section"
        },
    ]

    return (
        <Grid container sx={{ paddingTop: "40px", paddingBottom: "50px" }}>
            <Grid item xs={12} sm={12}>
                <Stack
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    spacing={1}
                >
                    <Typography
                        sx={{
                            fontSize: "36px",
                            textAlign: "center",
                            fontWeight: "500",
                            color: "#183B56",
                            "@media (max-width: 600px)": {
                                fontSize: "20px",
                            },
                        }}
                    >Some opened jobs for you</Typography>
                </Stack>
                <Stack
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    spacing={1}
                >
                    <Typography
                        sx={{
                            fontSize: "18px",
                            fontWeight: "400",
                            color: "#5A7184",
                        }}
                    >Show Items by : </Typography>
                    <Box>
                    <select
                    style={{
                        background: "transparant",
                        border: "none",
                        fontSize: "16px",
                        fontWeight: "600",
                        color: "#5A7184",
                    }}
                    >
                        <option>Opened Position</option>
                        <option>Opened Jobs</option>
                        <option>Full Time</option>
                        <option>Freelance</option>
                        <option>Part Time</option>
                    </select>
                    </Box>
                </Stack>
            </Grid>
            <Grid
                item
                xs={12}
                sm={12}
                pt={5}
                pb={3}
            >
                <Box className={`${classes.container} ${mediaClasses.container}`}>
                    {dataFormat?.map((item, index) => (
                        <Stack className={`${classes[item?.className]} ${classes.testDiv}`} key={index}>
                            <Box sx={{ marginBottom: "25px" }}>
                                <Chip
                                    icon={
                                        <span
                                            style={{
                                                backgroundColor: item?.typeColor,
                                                borderRadius: "50%",
                                                width: "10px",
                                                height: "10px",
                                            }}
                                        ></span>
                                    }
                                    label={item?.type}
                                    sx={{
                                        background: item?.typeBackground,
                                        padding: "15px 5px",
                                        '& .MuiChip-label': {
                                            fontWeight: "400",
                                            fontSize: "12px"
                                        },
                                    }}
                                />
                            </Box>
                            <Typography
                                sx={{
                                    color: "#183B56",
                                    fontWeight: "600",
                                    fontSize: "18px"
                                }}
                            >
                                {item?.title}
                            </Typography>
                            <Stack direction="row" spacing={2} sx={{ marginTop: "15px" }}>
                                <Stack direction="row" spacing={1}
                                    sx={{
                                        color: "#5A7184",
                                    }}
                                >
                                    <LocationOnOutlinedIcon fontSize="small" color="inherit" />
                                    <Typography
                                        sx={{
                                            color: "#5A7184",
                                            fontSize: "14px",
                                        }}
                                    >{item?.location}</Typography>
                                </Stack>
                                <Stack direction="row" spacing={1}
                                    sx={{
                                        color: "#5A7184",
                                    }}
                                >
                                    <AccessTimeOutlinedIcon fontSize="small" color="inherit" />
                                    <Typography
                                        sx={{
                                            color: "#5A7184",
                                            fontSize: "14px",
                                        }}
                                    > {item?.jobType}</Typography>
                                </Stack>
                            </Stack>
                            <Stack
                                direction="row"
                                spacing={2}
                                mt={4}
                                justifyContent="flex-start"
                                alignItems="center"
                            >
                                <Stack
                                    direction="row"
                                    sx={{ gap: "10px" }}
                                    justifyContent="center"
                                    alignItems="center"
                                >
                                    <Box
                                        sx={{
                                            borderRadius: "5px",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            height: "35px",
                                            width: "35px",
                                            background: item?.iconBackground
                                        }}
                                    >
                                        {item?.icon()}
                                    </Box>
                                    <Typography
                                        sx={{
                                            color: "#183B56",
                                            fontWeight: "600",
                                            fontSize: "14px",
                                        }}
                                    >
                                        {item?.iconName}
                                    </Typography>
                                </Stack>
                                <span
                                    style={{
                                        backgroundColor: "rgb(213 213 213)",
                                        borderRadius: "50%",
                                        width: "5px",
                                        height: "5px",
                                    }}
                                ></span>
                                <Box>
                                    <Typography
                                        sx={{
                                            color: "#5A7184",
                                            fontSize: "12px",
                                            fontWeight: "400"
                                        }}
                                    >{item?.time}</Typography>
                                </Box>
                            </Stack>
                        </Stack>
                    ))}
                    <Stack className={`${classes.sidebar} ${classes.testDiv}`}>
                        <Stack>
                            <Box>
                                <img src={ReferJobCardImage1} alt={"Refer Card Image"} style={{ width: "100%" }} />
                            </Box>
                            <Box mt={2} mb={3}>
                                <Typography
                                    sx={{
                                        color: "#183B56",
                                        fontSize: "19px",
                                        fontWeight: "400"
                                    }}
                                >Tips From Pro : How apply a jobs on a Startup for Fresh Graduate like you  |  JobBlog ep;04 </Typography>
                            </Box>
                            <Stack
                                direction="row"
                                spacing={2}
                                justifyContent="flex-start"
                                alignItems="center"
                            >
                                <Stack
                                    direction="row"
                                    sx={{ gap: "10px" }}
                                    justifyContent="center"
                                    alignItems="center"
                                >
                                    <Box
                                        sx={{
                                            borderRadius: "5px",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            height: "35px",
                                            width: "35px",
                                            background: "#f0f5f5",
                                        }}
                                    >
                                        <img src={ReferJobCardImage} />
                                    </Box>
                                    <Typography
                                        sx={{
                                            color: "#183B56",
                                            fontWeight: "600",
                                            fontSize: "14px",
                                        }}
                                    >
                                        Nerte Gronw
                                    </Typography>
                                </Stack>
                                <span
                                    style={{
                                        backgroundColor: "rgb(213 213 213)",
                                        borderRadius: "50%",
                                        width: "5px",
                                        height: "5px",
                                    }}
                                ></span>
                                <Box>
                                    <Typography
                                        sx={{
                                            color: "#5A7184",
                                            fontSize: "12px",
                                            fontWeight: "400"
                                        }}
                                    >13 Hours ago</Typography>
                                </Box>
                            </Stack>
                        </Stack>
                    </Stack>

                    <Stack className={`${classes.content} ${classes.testDiv}`}>
                        <Stack
                            direction="column"
                            sx={{
                                gap: "15px",
                                height: "100%",
                            }}
                            justifyContent="center"
                            alignItems="center"
                        >
                            <Box>
                                <Typography
                                    sx={{
                                        color: "var(--text-color)",
                                        fontSize: "49px",
                                        fontWeight: "600",
                                    }}
                                >328+</Typography>
                                <Typography sx={{
                                    color: "#5A7184",
                                    fontSize: "12px",
                                    fontWeight: "400"
                                }}>Total Jobs Opened</Typography>
                            </Box>
                            <Box>
                                <Chip label="EXPLORE JOBS" variant="outlined" onClick={() => { }}
                                    sx={{
                                        border: "1px solid var(--app-button-color1)",
                                        padding: "10px 15px",
                                        '& .MuiChip-label': {
                                            fontWeight: "600",
                                            color: "var(--app-button-color1)",
                                        },
                                    }}
                                />
                            </Box>
                        </Stack>
                    </Stack>
                </Box>
            </Grid>
        </Grid>
    )
}

export default OpenedJobSection;