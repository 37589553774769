export default function ReferIcon() {
    return (
        <svg  width="224" height="169" viewBox="0 0 254 255" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M239.267 83.9686C239.267 83.1284 238.937 82.324 238.347 81.7342C237.748 81.1443 236.943 80.8046 236.112 80.8046H183.343C182.512 80.8046 181.708 81.1353 181.109 81.7342C180.519 82.324 180.188 83.1284 180.188 83.9686C180.188 88.6788 180.188 97.4736 180.188 102.184C180.188 103.024 180.528 103.828 181.109 104.418C181.708 105.008 182.512 105.348 183.343 105.348C193.604 105.348 225.861 105.348 236.112 105.348C236.943 105.348 237.748 105.017 238.347 104.418C238.945 103.828 239.267 103.024 239.267 102.184C239.267 97.4736 239.267 88.6699 239.267 83.9686Z" fill="#C6ECFF" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M73.8185 131.538C73.8185 130.698 73.4878 129.893 72.8979 129.304C72.2991 128.714 71.4947 128.374 70.6635 128.374H17.8948C17.0636 128.374 16.2592 128.705 15.6603 129.304C15.0704 129.893 14.7397 130.698 14.7397 131.538C14.7397 136.248 14.7397 145.043 14.7397 149.753C14.7397 150.593 15.0794 151.398 15.6603 151.988C16.2592 152.578 17.0636 152.917 17.8948 152.917C28.1554 152.917 60.4118 152.917 70.6635 152.917C71.4947 152.917 72.2991 152.586 72.8979 151.988C73.4967 151.398 73.8185 150.593 73.8185 149.753V131.538Z" fill="#FFC6C6" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M100.349 41.2288C100.349 40.3855 100.014 39.5828 99.4244 38.9936C98.8351 38.4043 98.0223 38.069 97.1891 38.069C85.8912 38.069 47.6388 38.069 36.3409 38.069C35.4976 38.069 34.695 38.4043 34.1057 38.9936C33.5164 39.5828 33.1812 40.3956 33.1812 41.2288V62.7984C33.1812 63.6316 33.5164 64.4444 34.1057 65.0336C34.695 65.6229 35.5078 65.9582 36.3409 65.9582H97.1891C98.0324 65.9582 98.8351 65.6229 99.4244 65.0336C100.014 64.4444 100.349 63.6316 100.349 62.7984C100.349 57.4238 100.349 46.5932 100.349 41.2288Z" fill="#FFEFC6" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M93.1442 66.0997H97.1878C98.0616 66.0997 98.8947 65.7543 99.5246 65.1345C100.144 64.5148 100.49 63.6715 100.49 62.7977C100.49 57.4332 100.49 46.5925 100.49 41.228C100.49 40.3543 100.144 39.511 99.5246 38.8912C98.8947 38.2715 98.0616 37.926 97.1878 37.926C85.8899 37.926 47.6375 37.926 36.3396 37.926C35.4658 37.926 34.6327 38.2715 34.0028 38.8912C33.383 39.511 33.0376 40.3543 33.0376 41.228C33.0376 45.1803 33.0376 51.4287 33.0376 51.4287C33.0376 51.51 33.1087 51.5709 33.1798 51.5709C33.2611 51.5709 33.3119 51.51 33.3119 51.4287C33.3119 51.4287 33.3119 45.1803 33.3119 41.228C33.3119 40.4254 33.637 39.6532 34.206 39.0944C34.775 38.5255 35.5471 38.2105 36.3396 38.2105C47.6375 38.2105 85.9001 38.2105 97.1878 38.2105C97.9803 38.2105 98.7525 38.5255 99.3214 39.0944C99.8904 39.6634 100.216 40.4254 100.216 41.228C100.216 46.5925 100.216 57.4332 100.216 62.7977C100.216 63.6004 99.8904 64.3725 99.3214 64.9313C98.7525 65.5003 97.9803 65.8152 97.1878 65.8152H93.1543C93.073 65.8152 93.0121 65.8762 93.0121 65.9575C93.0019 66.0388 93.073 66.0997 93.1442 66.0997Z" fill="black" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M35.4719 128.229H17.8913C17.0243 128.229 16.1753 128.578 15.5585 129.195C14.9418 129.811 14.5933 130.652 14.5933 131.528V149.743C14.5933 150.619 14.9418 151.459 15.5585 152.075C16.1753 152.692 17.0154 153.041 17.8913 153.041C28.2591 153.041 60.5513 153.041 60.5513 153.041C60.6228 153.041 60.6943 152.978 60.6943 152.898C60.6943 152.817 60.6318 152.755 60.5513 152.755C60.5513 152.755 28.2591 152.755 17.8913 152.755C17.0958 152.755 16.3272 152.433 15.7641 151.87C15.1921 151.307 14.8793 150.538 14.8793 149.734V131.519C14.8793 130.714 15.1921 129.946 15.7641 129.382C16.3272 128.819 17.0958 128.498 17.8913 128.498H35.4719C35.5523 128.498 35.6149 128.435 35.6149 128.355C35.606 128.292 35.5523 128.229 35.4719 128.229Z" fill="black" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M206.448 80.6616C206.448 80.6616 190.333 80.6616 183.343 80.6616C182.476 80.6616 181.627 81.0102 181.011 81.6269C180.394 82.2436 180.045 83.0837 180.045 83.9596C180.045 88.6698 180.045 97.4646 180.045 102.175C180.045 103.051 180.394 103.891 181.011 104.508C181.627 105.124 182.468 105.473 183.343 105.473C193.604 105.473 225.86 105.473 236.112 105.473C237.935 105.473 239.41 103.989 239.41 102.166V91.9232C239.41 91.8428 239.348 91.7802 239.267 91.7802C239.196 91.7802 239.124 91.8428 239.124 91.9232V102.175C239.124 103.846 237.775 105.196 236.103 105.196C225.852 105.196 193.586 105.196 183.335 105.196C182.539 105.196 181.77 104.874 181.207 104.311C180.635 103.748 180.322 102.979 180.322 102.175C180.322 97.4646 180.322 88.6698 180.322 83.9596C180.322 83.1552 180.635 82.3866 181.207 81.8235C181.77 81.2604 182.539 80.9387 183.335 80.9387C190.324 80.9387 206.439 80.9387 206.439 80.9387C206.51 80.9387 206.582 80.8761 206.582 80.7957C206.582 80.7242 206.519 80.6616 206.448 80.6616Z" fill="black" />
            <path d="M48.1137 60.5746C52.844 60.5746 56.6786 56.74 56.6786 52.0097C56.6786 47.2795 52.844 43.4448 48.1137 43.4448C43.3835 43.4448 39.5488 47.2795 39.5488 52.0097C39.5488 56.74 43.3835 60.5746 48.1137 60.5746Z" fill="#FE9F3A" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M60.0641 48.5026H91.7836C91.8547 48.5026 91.9259 48.4417 91.9259 48.3604C91.9259 48.2791 91.8547 48.2181 91.7836 48.2181H60.0641C59.993 48.2181 59.9219 48.2791 59.9219 48.3604C59.9117 48.4417 59.9828 48.5026 60.0641 48.5026Z" fill="black" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M60.0598 56.1985H83.6536C83.7291 56.1985 83.7896 56.1375 83.7896 56.0562C83.7896 55.975 83.7291 55.914 83.6536 55.914H60.0598C59.9843 55.914 59.9238 55.975 59.9238 56.0562C59.9238 56.1375 59.9843 56.1985 60.0598 56.1985Z" fill="black" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M124.303 183.682L129.912 187.747L135.122 188.923L140.535 185.261L140.436 180.98L139.401 176.812L133.028 172.193L124.303 183.682Z" fill="#FFB7E7" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M122.192 111.799C122.192 111.799 99.1995 117.997 87.0583 128.878C74.9273 139.76 81.7853 151.454 88.0743 157.154C88.0743 157.154 86.469 164.194 96.944 167.314C96.944 167.314 102.359 183.427 120.83 185.246C120.83 185.246 119.52 188.985 125.88 185.409C132.23 181.832 140.764 164.855 131.935 166.135C131.935 166.135 130.279 158.495 117.335 153.313C117.335 153.313 112.743 144.779 109.685 143.742C109.685 143.742 128.572 136.173 140.713 124.296L122.192 111.799Z" fill="#3471D2" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M128.292 124.857C128.292 124.857 104.832 131.085 97.4156 157.867C97.4156 157.867 93.0671 161.981 92.9351 165.639L95.9424 166.98L114.576 154.239L128.749 128.25L128.292 124.857Z" fill="#1B3E77" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M116.117 184.425C116.117 184.425 121.878 177.221 123.402 169.113C123.402 169.113 125.261 168.26 125.525 165.822L129.65 161.88L128.268 160.284L118.779 160.904L113.343 177.506L114.349 183.896L116.117 184.425Z" fill="#1B3E77" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M88.4873 193.052L85.5462 199.324L85.3682 204.662L89.9803 209.292L94.1557 208.387L98.0566 206.589L101.398 199.463L88.4873 193.052Z" fill="#FFB7E7" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M156.989 132.264C156.989 132.264 155.801 141.479 145.844 148.327C135.887 155.174 124.315 163.953 124.315 163.953C124.315 163.953 123.929 166.554 121.663 167.356C121.663 167.356 116.421 184.191 108.221 192.025C108.221 192.025 109.867 196.759 105.549 197.725C105.549 197.725 107.459 200.976 100.439 201.474C93.4183 201.971 78.1986 190.602 87.7592 187.646C87.7592 187.646 87.241 180.849 91.498 176.917C91.498 176.917 93.1643 165.914 99.2298 160.478C99.2298 160.478 104.3 134.367 133.875 122.276L149.339 124.319L156.989 132.264Z" fill="#3471D2" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M123.157 115.986C123.157 115.986 126.55 119.654 130.594 121.655C130.594 121.655 133.012 127.67 138.315 128.331C138.315 128.331 142.136 135.331 150.548 135.453C150.548 135.453 153.098 137.993 155.069 137.871C155.069 137.871 156.288 135.382 156.746 133.431L146.179 120.274L128.735 115.356L123.157 115.986Z" fill="#1B3E77" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M173.695 155.642C173.695 155.642 170.718 159.015 169.854 162.622C169.854 162.622 168.269 163.942 167.406 165.578C166.542 167.224 166.857 169.398 168.767 168.078C170.677 166.757 171.581 165.548 171.581 165.548L174.924 160.986L173.695 155.642Z" fill="#EC78C6" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M167.81 139.152C167.81 139.152 163.878 146.03 163.817 147.961C163.746 149.891 163.269 156.729 163.482 158.029C163.706 159.319 167.363 158.872 167.13 154.189L167.77 151.303L169.771 148.052C169.771 148.052 171.722 148.448 172.291 150.897C172.87 153.345 173.866 158.537 173.866 158.537C173.866 158.537 172.606 161.382 172.23 163.729C172.23 163.729 170.046 168.646 171.305 171.115C172.555 173.584 174.465 169.967 175.532 167.783C176.599 165.588 178.387 162.571 178.387 162.571L180.053 155.835L180.917 140.605L173.622 138.857L167.81 139.152Z" fill="#FFB7E7" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M81.6133 73.1219L76.1168 70.5921L70.4272 66.6195C70.4272 66.6195 68.5781 62.9721 67.938 61.9256C67.2979 60.8893 70.9047 60.0968 72.6827 64.049C72.6827 64.049 76.0863 64.9126 76.7975 65.4917L75.2837 60.3914C75.2837 60.3914 73.3329 56.7948 72.8453 55.1793C72.8453 55.1793 71.24 53.8585 70.8641 52.4666C70.8641 52.4666 68.7914 50.8105 68.6085 49.0732C68.4257 47.3358 69.3909 46.8278 70.4881 47.2342C71.5753 47.6406 72.9875 49.0935 74.0746 50.2619C74.0746 50.2619 76.4521 49.8758 77.4071 50.2314C78.3621 50.5769 79.1343 51.0849 79.1343 51.0849C79.1343 51.0849 80.384 50.7293 81.654 50.7902C82.924 50.8512 83.4929 52.1009 83.4929 52.1009C83.4929 52.1009 84.8849 51.7249 85.6773 52.2939C86.4597 52.873 87.3537 56.9472 87.3537 56.9472C87.3537 56.9472 89.4569 63.1753 89.8429 66.6703L81.6133 73.1219Z" fill="#FFB7E7" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M79.9282 72.3491C79.9282 72.3491 83.2607 65.9483 88.5642 66.0194L85.7905 73.1314H81.6148L79.9282 72.3491Z" fill="black" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M163.48 155.002C163.48 155.002 163.958 154.962 164.405 155.013C164.578 155.033 164.74 155.063 164.872 155.124C164.954 155.155 165.004 155.196 165.045 155.236C165.126 155.338 165.137 155.521 165.126 155.734C165.106 156.303 164.883 157.014 164.883 157.014C164.852 157.085 164.903 157.167 164.974 157.187C165.055 157.207 165.126 157.167 165.147 157.095C165.147 157.095 165.37 156.344 165.401 155.744C165.411 155.45 165.37 155.196 165.258 155.053C165.137 154.911 164.923 154.809 164.669 154.759C164.131 154.647 163.45 154.718 163.45 154.718C163.369 154.728 163.318 154.799 163.318 154.87C163.338 154.952 163.399 155.013 163.48 155.002Z" fill="black" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M175.379 168.137C175.379 168.137 174.851 167.843 174.302 167.772C173.896 167.721 173.479 167.772 173.205 168.087C172.575 168.808 172.443 169.987 172.443 169.987C172.433 170.068 172.483 170.129 172.565 170.139C172.646 170.149 172.717 170.088 172.727 170.017C172.727 170.017 172.849 168.93 173.418 168.269C173.631 168.026 173.957 168.005 174.261 168.046C174.769 168.117 175.247 168.381 175.247 168.381C175.318 168.422 175.399 168.391 175.44 168.33C175.47 168.269 175.44 168.178 175.379 168.137Z" fill="black" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M170.505 166.705C170.505 166.705 170.261 166.359 169.936 166.146C169.692 165.984 169.408 165.892 169.164 166.014C168.625 166.278 168.219 166.949 168.219 166.949C168.188 167.02 168.199 167.101 168.27 167.142C168.341 167.182 168.422 167.162 168.463 167.091C168.463 167.091 168.808 166.492 169.286 166.268C169.448 166.187 169.621 166.278 169.773 166.38C170.058 166.573 170.271 166.878 170.271 166.878C170.322 166.939 170.403 166.949 170.474 166.908C170.545 166.857 170.566 166.776 170.505 166.705Z" fill="black" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M73.9837 50.3446C73.9837 50.3446 76.6355 53.5044 77.2146 54.2257C77.7937 54.9369 78.5049 57.04 78.5049 57.04C78.5354 57.1112 78.6065 57.1518 78.6776 57.1315C78.7589 57.1112 78.7894 57.0299 78.7589 56.9486C78.7589 56.9486 78.0274 54.7744 77.4279 54.0428C76.8387 53.3215 74.1869 50.1617 74.1869 50.1617C74.1361 50.1008 74.0548 50.0906 73.9939 50.1414C73.9329 50.1922 73.9329 50.2837 73.9837 50.3446Z" fill="black" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M79.0207 51.1473C79.0207 51.1473 79.9656 52.5697 80.4228 53.3013C80.8698 54.0226 81.1238 55.699 81.1238 55.699C81.134 55.7803 81.2051 55.8311 81.2864 55.8209C81.3677 55.8108 81.4185 55.7397 81.4083 55.6584C81.4083 55.6584 81.1238 53.9109 80.6666 53.1489C80.2094 52.4072 79.2645 50.9848 79.2645 50.9848C79.2239 50.9238 79.1325 50.9035 79.0715 50.9441C79.0004 50.9949 78.9801 51.0864 79.0207 51.1473Z" fill="black" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M83.3815 52.1408C83.3815 52.1408 83.8488 53.2787 83.9403 53.4412C83.9606 53.4717 83.9708 53.5632 84.0114 53.6851C84.1435 54.2439 84.3873 55.4326 84.3873 55.4326C84.4076 55.5037 84.4686 55.5545 84.5499 55.5444C84.621 55.5342 84.682 55.4529 84.6718 55.3818C84.6718 55.3818 84.4381 54.1829 84.2857 53.614C84.2552 53.4616 84.2146 53.3396 84.1943 53.3092C84.113 53.1466 83.6558 52.029 83.6558 52.029C83.6253 51.9579 83.5339 51.9172 83.4729 51.9477C83.3815 51.9782 83.3408 52.0595 83.3815 52.1408Z" fill="black" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M69.299 64.4043C69.299 64.4043 69.5734 64.2621 69.7969 64.0081C69.9493 63.8354 70.0814 63.6017 70.1017 63.3172C70.122 63.0226 70.0204 62.6771 69.6648 62.291C69.6038 62.2301 69.5226 62.2301 69.4616 62.2809C69.4006 62.3317 69.4006 62.4231 69.4514 62.4841C69.7359 62.799 69.8375 63.0734 69.8274 63.307C69.807 63.5204 69.7054 63.6931 69.5937 63.8252C69.4006 64.0386 69.167 64.1503 69.167 64.1503C69.0958 64.1808 69.0755 64.2722 69.106 64.3434C69.1365 64.4145 69.2178 64.445 69.299 64.4043Z" fill="black" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M72.3182 48.4854C72.3182 48.4854 71.7899 49.1662 71.0685 49.156C70.7536 49.156 70.4183 49.0239 70.0728 48.6582C70.022 48.5972 69.9306 48.5972 69.8696 48.6582C69.8188 48.709 69.8188 48.8004 69.8696 48.8614C70.2964 49.2881 70.7028 49.4405 71.0584 49.4405C71.9016 49.4506 72.5316 48.6683 72.5316 48.6683C72.5722 48.6074 72.562 48.5159 72.5011 48.4651C72.4503 48.4042 72.3588 48.4245 72.3182 48.4854Z" fill="black" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M127.23 71.708C127.23 71.708 120.951 74.0244 119.162 76.2393C119.162 76.2393 110.415 80.1509 107.255 84.7737C107.255 84.7737 104.156 84.5705 102.906 88.0351C102.906 88.0351 102.622 86.7752 101.2 87.649C101.2 87.649 97.8874 78.6269 95.6929 75.7415C95.6929 75.7415 95.3576 72.5106 93.478 70.7224C93.478 70.7224 93.539 67.2579 89.8814 66.0996C86.2339 64.9516 80.7983 71.7791 79.8534 75.1725C78.9086 78.566 81.3876 79.3483 81.3876 79.3483C81.3876 79.3483 81.6721 86.6533 84.6591 90.128C84.6591 90.128 90.3182 109.036 102.978 107.481C112.304 106.333 116.216 100.532 116.216 100.532C116.216 100.532 119.853 101.67 126.244 98.2256L132.33 89.9553L130.044 78.5964L127.23 71.708Z" fill="#FE5E3A" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M130.748 72.3993C130.748 72.3993 129.701 68.2743 132.262 66.2118C134.822 64.1392 135.99 63.0216 135.482 58.3886C134.974 53.7557 135.127 43.87 143.56 43.4128C151.992 42.9556 153.598 49.7425 154.888 54.1011C156.178 58.4598 158.017 59.4961 164.642 60.1768C171.266 60.8474 172.505 62.7473 173.024 64.129C173.532 65.5108 172.384 67.492 170.148 67.8781C170.148 67.8781 175.757 69.5138 170.738 78.6172L151.718 73.8014L130.748 72.3993Z" fill="#FE9F3A" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M137.028 67.8377C137.028 67.8377 129.692 70.1136 127.244 71.7087C127.244 71.7087 127.122 84.8151 127.203 86.2984C127.203 86.2984 123.871 92.0388 126.888 100.329C126.888 100.329 125.547 102.707 125.456 105.277C125.456 105.277 121.006 106.07 122.784 109.992C122.784 109.992 119.329 110.053 119.654 112.054C119.99 114.045 126.787 119.217 131.521 120.792C131.521 120.792 133.005 125.811 139.212 126.644C139.212 126.644 143.022 133.451 151.435 132.557C151.435 132.557 155.001 136.265 158.466 134.416C161.92 132.567 160.924 127.995 160.924 127.995C160.924 127.995 163.495 125.872 162.763 122.692L166.004 113.72L170.028 76.2502C170.028 76.2502 167.853 71.6884 155.418 68.0511L146.03 70.2558L140.462 69.7478L137.028 67.8377Z" fill="#FE5E3A" />
            <path d="M100.649 45.4705C104.065 45.4705 106.834 42.7013 106.834 39.2854C106.834 35.8694 104.065 33.1003 100.649 33.1003C97.2335 33.1003 94.4644 35.8694 94.4644 39.2854C94.4644 42.7013 97.2335 45.4705 100.649 45.4705Z" fill="#7BC53E" />
            <path d="M27.8695 148.178C32.0307 148.178 35.4041 144.805 35.4041 140.644C35.4041 136.483 32.0307 133.109 27.8695 133.109C23.7083 133.109 20.335 136.483 20.335 140.644C20.335 144.805 23.7083 148.178 27.8695 148.178Z" fill="#FE5E3A" />
            <path d="M193.318 100.603C197.479 100.603 200.853 97.2293 200.853 93.0681C200.853 88.9069 197.479 85.5336 193.318 85.5336C189.157 85.5336 185.784 88.9069 185.784 93.0681C185.784 97.2293 189.157 100.603 193.318 100.603Z" fill="#019CEF" />
            <path d="M204.454 149.699C211.813 149.699 217.779 143.733 217.779 136.374C217.779 129.015 211.813 123.049 204.454 123.049C197.095 123.049 191.129 129.015 191.129 136.374C191.129 143.733 197.095 149.699 204.454 149.699Z" fill="#FFC6C6" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M204.454 123.19C211.725 123.19 217.621 129.102 217.621 136.373C217.621 143.644 211.725 149.556 204.454 149.556C201.483 149.556 198.748 148.576 196.551 146.932C196.488 146.884 196.409 146.9 196.345 146.963C196.298 147.027 196.314 147.121 196.377 147.169C198.637 148.86 201.419 149.856 204.454 149.856C211.883 149.856 217.921 143.818 217.921 136.389C217.921 128.96 211.883 122.922 204.454 122.922C204.375 122.922 204.312 122.985 204.312 123.064C204.312 123.127 204.375 123.19 204.454 123.19Z" fill="black" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M38.3788 137.578H66.2826C66.363 137.578 66.4256 137.516 66.4256 137.435C66.4256 137.355 66.363 137.292 66.2826 137.292H38.3788C38.2984 137.292 38.2358 137.355 38.2358 137.435C38.2358 137.516 38.2895 137.578 38.3788 137.578Z" fill="black" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M203.829 90.0012H231.733C231.813 90.0012 231.876 89.9386 231.876 89.8582C231.876 89.7777 231.813 89.7152 231.733 89.7152H203.829C203.749 89.7152 203.686 89.7777 203.686 89.8582C203.686 89.9386 203.749 90.0012 203.829 90.0012Z" fill="black" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M38.3772 144.34H59.1327C59.2124 144.34 59.2789 144.277 59.2789 144.197C59.2789 144.116 59.2124 144.054 59.1327 144.054H38.3772C38.2974 144.054 38.231 144.116 38.231 144.197C38.2376 144.277 38.304 144.34 38.3772 144.34Z" fill="black" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M203.834 96.7715H224.589C224.669 96.7715 224.735 96.7089 224.735 96.6285C224.735 96.5481 224.669 96.4855 224.589 96.4855H203.834C203.754 96.4855 203.688 96.5481 203.688 96.6285C203.688 96.7089 203.754 96.7715 203.834 96.7715Z" fill="black" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M70.1757 94.4217L71.4989 105.196C71.5619 105.717 71.772 106.191 72.1081 106.57C72.4441 106.949 72.8641 107.185 73.3262 107.256L82.8827 108.748L73.3262 110.24C72.8641 110.311 72.4441 110.548 72.1081 110.927C71.772 111.306 71.5619 111.779 71.4989 112.3L70.1757 123.075L68.8526 112.3C68.7895 111.779 68.5794 111.306 68.2434 110.927C67.9073 110.548 67.4873 110.311 67.0252 110.24L57.4688 108.748L67.0252 107.256C67.4873 107.185 67.9073 106.949 68.2434 106.57C68.5794 106.191 68.7895 105.717 68.8526 105.196L70.1757 94.4217Z" fill="#FFDB3B" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M188.685 30.6055L190.328 43.9819C190.406 44.6287 190.666 45.2167 191.084 45.6871C191.501 46.1575 192.022 46.4514 192.596 46.5396L204.46 48.3918L192.596 50.2439C192.022 50.3321 191.501 50.6261 191.084 51.0965C190.666 51.5669 190.406 52.1548 190.328 52.8016L188.685 66.1781L187.042 52.8016C186.964 52.1548 186.703 51.5669 186.286 51.0965C185.869 50.6261 185.347 50.3321 184.773 50.2439L172.909 48.3918L184.773 46.5396C185.347 46.4514 185.869 46.1575 186.286 45.6871C186.703 45.2167 186.964 44.6287 187.042 43.9819L188.685 30.6055Z" fill="#FFDB3B" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M52.4358 172.63L59.69 174.794C59.7611 174.814 59.8322 174.774 59.8526 174.702C59.883 174.631 59.8322 174.56 59.7713 174.54L52.517 172.376C52.4459 172.355 52.3748 172.396 52.3545 172.467C52.324 172.538 52.3646 172.609 52.4358 172.63Z" fill="black" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M32.4586 27.6553L33.5051 33.1315C33.5153 33.2026 33.5864 33.2534 33.6575 33.2331C33.7286 33.2229 33.7794 33.1518 33.7591 33.0807L32.7228 27.6045C32.7025 27.5333 32.6415 27.4825 32.5602 27.5029C32.4891 27.513 32.4383 27.574 32.4586 27.6553Z" fill="black" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M21.263 30.471L29.3402 36.2114C29.4011 36.2521 29.4722 36.2419 29.5129 36.1809C29.5535 36.12 29.5434 36.0387 29.4824 35.9981L21.4052 30.2577C21.3442 30.217 21.263 30.2272 21.2223 30.2881C21.1817 30.3491 21.202 30.4304 21.263 30.471Z" fill="black" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M24.5344 42.1744L29.4417 40.7419C29.5128 40.7215 29.5534 40.6504 29.5331 40.5793C29.5128 40.5082 29.4417 40.4675 29.3604 40.4879L24.4633 41.9204C24.382 41.9407 24.3515 42.0119 24.3718 42.083C24.3921 42.1541 24.4633 42.1947 24.5344 42.1744Z" fill="black" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M97.536 39.8262L99.9033 41.8683C100.046 42.0004 100.239 42.0512 100.432 42.0309C100.625 42.0106 100.797 41.909 100.919 41.7566L104.191 37.5097C104.425 37.2049 104.364 36.7579 104.059 36.5242C103.744 36.2905 103.307 36.3413 103.063 36.6563L100.249 40.3139L98.4403 38.7594C98.1456 38.5054 97.7087 38.5359 97.4547 38.8305C97.2007 39.1251 97.2414 39.5722 97.536 39.8262Z" fill="white" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M204.559 133.541C206.114 130.92 209.225 130.92 210.78 132.23C212.335 133.541 212.335 136.162 210.78 138.784C209.689 140.755 206.887 142.716 204.559 144.026C202.221 142.716 199.42 140.745 198.329 138.784C196.773 136.162 196.773 133.541 198.329 132.23C199.893 130.92 203.003 130.92 204.559 133.541Z" fill="#FE5E3A" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M190.374 177.99C190.476 177.391 190.455 176.873 190.333 176.426C190.161 175.745 189.744 175.257 189.216 175.024C188.698 174.79 188.068 174.821 187.448 175.176C187.174 175.329 187.031 175.583 187.001 175.877C186.971 176.284 187.164 176.792 187.58 177.228C188.088 177.767 188.931 178.214 190.049 178.255C189.927 178.732 189.714 179.25 189.409 179.799C187.499 183.172 182.673 183.67 182.673 183.67C182.602 183.68 182.541 183.741 182.551 183.812C182.561 183.883 182.622 183.934 182.693 183.934C182.693 183.934 187.651 183.406 189.632 179.931C189.968 179.332 190.191 178.773 190.313 178.255C190.476 178.255 190.648 178.234 190.811 178.214C193.016 177.94 196.094 176.121 199.671 170.899C199.711 170.838 199.701 170.757 199.64 170.716C199.579 170.675 199.498 170.685 199.457 170.746C196.937 174.414 194.692 176.385 192.823 177.31C191.908 177.757 191.085 177.96 190.374 177.99ZM190.11 177.99C190.527 175.796 189.053 174.567 187.58 175.4C187.326 175.552 187.235 175.816 187.275 176.121C187.357 176.639 187.814 177.239 188.555 177.615C188.982 177.838 189.5 177.98 190.11 177.99Z" fill="black" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M33.9026 100.993C34.0347 101.511 34.2481 102.07 34.5935 102.669C36.5646 106.144 41.5325 106.672 41.5325 106.672C41.6138 106.682 41.6648 106.631 41.675 106.55C41.6851 106.479 41.6344 106.408 41.5531 106.408C41.5531 106.408 36.7372 105.91 34.817 102.537C34.502 101.988 34.2886 101.47 34.1768 100.993C35.2944 100.952 36.1378 100.505 36.6458 99.9665C37.0623 99.5296 37.2553 99.0216 37.2248 98.6152C37.1943 98.3206 37.0421 98.0666 36.7779 97.9142C36.1582 97.5586 35.528 97.5383 35.0099 97.7618C34.4815 97.9955 34.065 98.473 33.8923 99.1639C33.7602 99.6109 33.7502 100.129 33.8518 100.728C33.1406 100.698 32.3176 100.505 31.4134 100.048C29.5439 99.1232 27.2883 97.1522 24.7788 93.4844C24.7382 93.4235 24.6467 93.4133 24.5959 93.4539C24.5349 93.4946 24.5145 93.5759 24.5653 93.6368C28.1416 98.8591 31.2103 100.678 33.4251 100.952C33.5775 100.972 33.7502 100.993 33.9026 100.993ZM34.1157 100.728C34.7253 100.718 35.2435 100.576 35.6703 100.363C36.4119 99.9868 36.8693 99.3874 36.9506 98.8692C36.9912 98.5644 36.8998 98.2901 36.6458 98.1479C35.1726 97.3046 33.6991 98.5339 34.1157 100.728Z" fill="black" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M137.757 51.6671C137.757 51.6671 137.134 57.1356 137.486 59.359C137.838 61.5824 139.794 62.635 140.913 62.9068L140.945 68.5591L144.344 70.0968L150.617 68.1723L150.966 58.3733C150.966 58.3733 154.903 58.4199 155.922 56.1438C156.942 53.8676 154.097 52.1217 150.112 53.7783C146.571 53.1016 144.545 50.9048 144.157 47.0823C140.278 47.9181 138.96 49.7688 137.757 51.6671Z" fill="#FFB7E7" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M150.225 55.2062C150.123 55.847 150.28 56.3978 150.697 56.8588C150.697 56.8588 153.197 56.4867 153.256 55.4326C153.307 54.4021 151.329 54.7115 150.225 55.2062Z" fill="black" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M145.288 62.4893C145.288 62.4893 144.385 65.0201 140.942 64.7474L140.914 62.9056C140.914 62.9056 143.399 63.5747 145.288 62.4893Z" fill="black" />
            <path d="M143.097 54.194C142.858 54.1989 142.672 54.5589 142.681 54.9981C142.69 55.4373 142.891 55.7893 143.13 55.7844C143.369 55.7795 143.555 55.4195 143.546 54.9803C143.537 54.5411 143.336 54.1891 143.097 54.194Z" fill="black" />
            <path d="M138.67 53.6043C138.431 53.6092 138.245 53.9692 138.254 54.4084C138.263 54.8476 138.464 55.1996 138.703 55.1947C138.942 55.1898 139.128 54.8298 139.119 54.3906C139.11 53.9514 138.909 53.5994 138.67 53.6043Z" fill="black" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M139.966 59.8318C139.966 59.8318 140.038 58.8203 140.527 58.6962C141.008 58.5566 141.265 59.1631 141.265 59.1631C141.265 59.1631 141.394 58.5177 141.961 58.5203C142.529 58.5229 142.864 59.5144 142.864 59.5144C142.864 59.5144 141.851 60.0494 139.966 59.8318Z" fill="#FE5E3A" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M140.454 59.8772C140.454 59.8772 140.728 60.8119 141.616 60.6523C142.312 60.5259 142.424 59.6814 142.424 59.6814C142.424 59.6814 141.403 59.9427 140.454 59.8772Z" fill="#FE5E3A" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M140.369 53.6134C140.369 53.6134 139.912 55.9299 140.227 57.4539C140.237 57.4945 140.257 57.525 140.298 57.5453C140.318 57.5656 140.369 57.5758 140.4 57.5656L141.08 57.4031C141.162 57.3828 141.202 57.3116 141.182 57.2304C141.162 57.1491 141.091 57.1084 141.009 57.1288L140.471 57.2507C140.217 55.7673 140.633 53.6642 140.633 53.6642C140.654 53.5931 140.603 53.5118 140.522 53.5016C140.45 53.4915 140.379 53.5423 140.369 53.6134Z" fill="black" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M142.471 53.2372C142.471 53.2372 143.517 52.3736 144.574 53.3692C144.635 53.42 144.716 53.42 144.777 53.3591C144.828 53.2981 144.828 53.2168 144.767 53.1559C143.527 51.9773 142.298 53.0136 142.298 53.0136C142.237 53.0644 142.227 53.1559 142.278 53.2168C142.328 53.2778 142.42 53.288 142.471 53.2372Z" fill="black" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M137.646 52.7007C137.646 52.7007 138.631 52.1114 139.475 52.9648C139.525 53.0156 139.617 53.0258 139.678 52.9648C139.729 52.914 139.729 52.8226 139.678 52.7616C138.672 51.7456 137.504 52.4467 137.504 52.4467C137.433 52.4873 137.412 52.5686 137.453 52.6397C137.493 52.721 137.585 52.7413 137.646 52.7007Z" fill="black" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M137.078 102.931C137.078 102.931 142.188 111.191 149.768 112.898C149.849 112.918 149.92 112.867 149.94 112.796C149.961 112.715 149.91 112.644 149.829 112.624C142.361 110.937 137.322 102.779 137.322 102.779C137.281 102.718 137.19 102.687 137.129 102.728C137.058 102.779 137.037 102.86 137.078 102.931Z" fill="black" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M144.351 70.023L151.087 65.898C152.997 66.4365 154.307 67.2493 155.445 68.1536C153.342 70.1348 150.894 71.7908 147.896 72.9186C147.673 73.01 147.419 72.9897 147.195 72.878C145.864 72.1972 144.909 71.2524 144.351 70.023Z" fill="white" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M140.594 65.7022L144.364 70.0202C143.775 70.8432 142.891 71.463 141.763 71.9202C141.468 72.0421 141.133 72.0116 140.869 71.8389C139.172 70.7619 137.892 69.431 137.028 67.8257C137.028 67.8358 139.385 65.9156 140.594 65.7022Z" fill="white" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M144.209 70.0015L143.304 77.3065C143.294 77.3675 143.335 77.4386 143.396 77.4589L144.513 77.8755C144.595 77.906 144.666 77.8653 144.696 77.7942C144.717 77.7231 144.676 77.6418 144.615 77.6113L143.599 77.2354L144.493 70.0421C144.503 69.9609 144.442 69.8897 144.361 69.8897C144.29 69.8694 144.229 69.9304 144.209 70.0015Z" fill="black" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M144.899 63.0115C144.899 63.0115 147.235 61.9447 148.14 60.4512C148.18 60.3801 148.16 60.2988 148.099 60.2581C148.028 60.2175 147.936 60.2378 147.906 60.3089C147.032 61.7415 144.777 62.7575 144.777 62.7575C144.706 62.788 144.675 62.8693 144.706 62.9404C144.736 63.0115 144.817 63.042 144.899 63.0115Z" fill="black" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M139.179 59.8413C139.179 59.8413 140.287 60.1054 141.557 59.9632C142.41 59.8616 143.335 59.5771 144.046 58.9167C144.107 58.8659 144.107 58.7744 144.056 58.7135C144.005 58.6525 143.914 58.6525 143.853 58.7033C143.193 59.3231 142.319 59.5872 141.526 59.6787C140.307 59.8209 139.25 59.5669 139.25 59.5669C139.169 59.5466 139.098 59.5974 139.078 59.6685C139.068 59.7498 139.108 59.8209 139.179 59.8413Z" fill="black" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M143.489 57.8536C143.489 57.8536 143.468 59.2456 144.789 59.398C144.871 59.4081 144.932 59.3472 144.942 59.276C144.952 59.1948 144.891 59.1338 144.81 59.1236C143.743 59.0017 143.773 57.8638 143.773 57.8638C143.773 57.7825 143.702 57.7216 143.631 57.7216C143.56 57.7114 143.489 57.7724 143.489 57.8536Z" fill="black" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M102.797 87.9548C102.797 87.9548 101.588 89.5905 101.426 92.4658C101.426 92.5471 101.487 92.608 101.558 92.6182C101.629 92.6182 101.7 92.5674 101.71 92.4861C101.863 89.7125 103.021 88.1275 103.021 88.1275C103.062 88.0665 103.051 87.9751 102.99 87.9345C102.929 87.8735 102.838 87.8938 102.797 87.9548Z" fill="black" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M84.8043 90.1599L84.977 88.3311C84.9872 88.2498 84.9262 88.1888 84.845 88.1787C84.7637 88.1685 84.7027 88.2295 84.6926 88.3108L84.5198 90.1396C84.5097 90.2208 84.5605 90.2818 84.6519 90.292C84.723 90.292 84.7942 90.231 84.8043 90.1599Z" fill="black" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M93.3785 70.6219L92.4742 71.3636C92.4133 71.4144 92.4031 71.5058 92.4539 71.5668C92.5047 71.6277 92.586 71.6379 92.647 71.5871L93.5512 70.8454C93.6122 70.7946 93.6223 70.7032 93.5715 70.6524C93.5309 70.5812 93.4394 70.5711 93.3785 70.6219Z" fill="black" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M116.34 100.602L117.305 98.2751C117.325 98.204 117.295 98.1227 117.224 98.0922C117.142 98.0617 117.061 98.1024 117.041 98.1735L116.076 100.5C116.045 100.571 116.086 100.653 116.157 100.683C116.228 100.713 116.309 100.673 116.34 100.602Z" fill="black" />
            <path d="M145.342 74.2742C145.643 74.2742 145.887 74.03 145.887 73.7289C145.887 73.4278 145.643 73.1837 145.342 73.1837C145.041 73.1837 144.797 73.4278 144.797 73.7289C144.797 74.03 145.041 74.2742 145.342 74.2742Z" fill="black" />
            <path d="M144.909 76.7418C145.21 76.7418 145.454 76.4977 145.454 76.1966C145.454 75.8954 145.21 75.6513 144.909 75.6513C144.607 75.6513 144.363 75.8954 144.363 76.1966C144.363 76.4977 144.607 76.7418 144.909 76.7418Z" fill="black" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M73.8183 217.521L87.3475 222.891L95.3322 218.866C95.3322 218.866 96.9717 215.681 98.6873 212.08C100.398 208.489 98.3311 205.955 98.3311 205.955C90.0902 207.183 85.7442 199.284 85.7442 199.284C82.7193 199.389 72.7855 202.047 66.0427 202.262C66.6124 203.257 66.2428 204.47 66.072 206.449C65.8581 208.888 70.4071 209.045 72.9535 210.98C74.8659 212.426 74.2637 215.863 73.8183 217.521Z" fill="#FE5E3A" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M145.599 202.668L153.411 190.386L150.954 181.789C150.954 181.789 148.134 179.581 144.917 177.22C141.711 174.867 138.694 176.32 138.694 176.32C138.355 184.645 129.919 187.517 129.919 187.517C129.455 190.508 130.203 200.764 129.149 207.428C130.233 207.055 131.355 207.645 133.267 208.184C135.623 208.852 136.63 204.412 139.009 202.274C140.79 200.684 144.054 201.92 145.599 202.668Z" fill="#FE5E3A" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M81.7628 199.393C81.6351 199.128 81.3515 198.967 81.0651 199.011C79.5573 199.224 74.8885 199.917 71.6763 200.648C71.4872 200.683 71.3369 200.805 71.2469 200.969C71.146 201.127 71.1322 201.327 71.1939 201.507C71.6812 202.847 73.0407 206.01 76.0348 209.597C76.7378 210.421 77.8252 210.793 78.8832 210.548C80.589 210.14 83.2289 209.525 84.87 209.141C85.2154 209.056 85.5262 208.818 85.6792 208.491C85.8322 208.165 85.8387 207.784 85.6668 207.442C84.6286 205.322 82.636 201.299 81.7628 199.393Z" fill="#019CEF" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M129.273 191.46C128.988 191.536 128.777 191.784 128.767 192.074C128.693 193.595 128.499 198.311 128.614 201.603C128.613 201.795 128.704 201.966 128.848 202.085C128.985 202.214 129.178 202.265 129.367 202.238C130.775 202.01 134.137 201.268 138.221 199C139.172 198.471 139.732 197.466 139.69 196.38C139.609 194.628 139.499 191.92 139.431 190.236C139.412 189.881 139.236 189.531 138.944 189.319C138.652 189.108 138.279 189.03 137.911 189.135C135.644 189.765 131.309 190.96 129.273 191.46Z" fill="#019CEF" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M66.0583 202.257C64.8081 202.289 63.6707 202.25 62.7105 202.088C56.5208 201.038 55.0311 205.858 55.0311 205.858L59.054 211.67L73.8291 217.528C74.2636 215.865 74.8659 212.427 72.9806 210.98C70.4286 209.029 65.8901 208.877 66.0987 206.449C66.2533 204.476 66.628 203.252 66.0583 202.257Z" fill="#FFDB3B" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M129.148 207.428C128.944 208.662 128.692 209.772 128.353 210.684C126.161 216.568 130.616 218.934 130.616 218.934L137.08 216.073L145.604 202.659C144.052 201.92 140.788 200.684 139.014 202.265C136.628 204.413 135.618 208.834 133.273 208.174C131.354 207.645 130.231 207.055 129.148 207.428Z" fill="#FFDB3B" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M83.3675 207.587C83.4391 207.689 83.4568 207.821 83.4149 207.938C83.373 208.056 83.2767 208.148 83.1471 208.17C81.9864 208.435 79.3088 209.046 78.4341 209.24C78.2721 209.274 78.0978 209.192 78.0202 209.046C77.8984 208.824 77.7321 208.499 77.6043 208.233C77.5489 208.125 77.5365 208.01 77.5893 207.897C77.6368 207.796 77.7388 207.72 77.8523 207.705C78.9374 207.459 81.621 206.864 82.4363 206.683C82.5821 206.656 82.7288 206.711 82.8169 206.834C82.9655 207.027 83.1804 207.334 83.3675 207.587Z" fill="white" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M137.628 191.427C137.742 191.376 137.875 191.384 137.982 191.447C138.09 191.51 138.162 191.622 138.159 191.753C138.202 192.943 138.301 195.688 138.327 196.583C138.33 196.749 138.217 196.905 138.059 196.953C137.818 197.031 137.468 197.134 137.183 197.21C137.066 197.244 136.95 197.234 136.849 197.161C136.759 197.096 136.703 196.981 136.709 196.867C136.671 195.755 136.59 193.008 136.566 192.173C136.566 192.024 136.648 191.89 136.786 191.827C137.003 191.717 137.335 191.556 137.628 191.427Z" fill="white" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M86.7897 226.378L59.2176 213.449C59.2176 213.449 51.8603 209.875 55.3484 205.128C55.3484 205.128 54.9906 208.186 62.0671 211.367C69.1545 214.553 84.5144 219.107 84.5144 219.107C84.5144 219.107 90.215 213.777 95.7621 218.067C97.1066 219.109 93.6411 225.281 92.2428 226.478C90.8173 227.676 89.3309 227.446 86.7897 226.378Z" fill="#CBDAF3" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M156.731 191.589L138.861 216.248C138.861 216.248 133.971 222.804 129.962 218.488C129.962 218.488 132.899 219.413 137.351 213.059C141.799 206.687 149.162 192.461 149.162 192.461C149.162 192.461 144.996 185.862 150.25 181.218C151.526 180.093 156.938 184.654 157.852 186.252C158.752 187.87 158.257 189.294 156.731 191.589Z" fill="#CBDAF3" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M121.714 167.224L118.473 165.852C118.392 165.821 118.31 165.852 118.29 165.923C118.259 165.994 118.29 166.075 118.371 166.106L121.612 167.478C121.683 167.508 121.765 167.478 121.795 167.406C121.815 167.335 121.785 167.254 121.714 167.224Z" fill="black" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M124.336 163.81L122.599 163.393C122.518 163.373 122.447 163.423 122.426 163.495C122.406 163.566 122.457 163.647 122.538 163.667L124.265 164.084C124.347 164.104 124.418 164.053 124.438 163.982C124.458 163.911 124.418 163.83 124.336 163.81Z" fill="black" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M91.3683 176.946L91.795 178.49C91.8154 178.561 91.8865 178.612 91.9678 178.592C92.049 178.571 92.0897 178.49 92.0694 178.419L91.6426 176.875C91.6223 176.804 91.541 176.753 91.4699 176.773C91.3886 176.793 91.3378 176.875 91.3683 176.946Z" fill="black" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M108.183 191.881L106.354 192.166C106.273 192.176 106.222 192.247 106.232 192.329C106.253 192.41 106.314 192.461 106.395 192.45L108.224 192.166C108.305 192.156 108.356 192.085 108.346 192.003C108.335 191.922 108.264 191.871 108.183 191.881Z" fill="black" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M88.0845 157.307L89.9844 157.185C90.0657 157.185 90.1165 157.114 90.1165 157.033C90.1064 156.951 90.0454 156.89 89.9641 156.9L88.0642 157.012C87.9829 157.022 87.9321 157.083 87.9321 157.165C87.9423 157.246 88.0134 157.307 88.0845 157.307Z" fill="black" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M131.858 166.025L130.161 166.99C130.09 167.031 130.069 167.112 130.11 167.183C130.141 167.254 130.232 167.274 130.293 167.234L132 166.269C132.071 166.228 132.091 166.147 132.051 166.076C132.02 166.004 131.929 165.984 131.858 166.025Z" fill="black" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M127.2 75.8168L124.965 85.6314C124.965 85.6314 119.519 92.3573 123.319 99.6014L126.235 98.2501C126.235 98.2501 124.122 92.1134 127.19 86.3019L127.2 75.8168Z" fill="#E73D17" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M127.059 75.8245L127.049 86.2588C126.805 86.7058 124.235 91.6131 126.104 98.2781C126.124 98.3492 126.206 98.4 126.277 98.3797C126.358 98.3594 126.409 98.2781 126.378 98.207C124.468 91.3388 127.313 86.3604 127.313 86.3604C127.323 86.3401 127.333 86.3198 127.333 86.2893L127.354 75.8143C127.354 75.733 127.283 75.6721 127.211 75.6721C127.13 75.6822 127.059 75.7534 127.059 75.8245Z" fill="black" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M159.57 97.7893C159.57 97.7893 158.787 102.219 160.647 105.968C160.647 105.968 160.494 113.761 164.609 117.581L166.011 113.71L165.605 93.4916L159.57 97.7893Z" fill="#E73D17" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M166.705 141.162C166.705 141.162 173.492 144.748 180.848 142.158C188.183 139.577 180.929 142.158 180.929 142.158V140.603L167.802 139.14L166.705 141.162Z" fill="black" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M170.026 76.2395C170.026 76.2395 160.527 82.0815 159.572 97.7888C159.572 97.7888 159.948 100.969 162.569 104.291C162.569 104.291 162.803 109.828 165.597 112.602C165.597 112.602 165.373 122.539 167.202 127.974C167.202 127.974 165.973 129.325 166.663 132.099C166.663 132.099 162.193 135.94 168.066 140.065C173.928 144.2 188.02 141.477 183.031 138.266C183.031 138.266 186.668 133.511 183.61 132.252C183.61 132.252 185.988 117.103 183.112 109.239C183.112 109.239 183.255 104.596 180.339 101.792C180.319 101.792 175.889 83.5953 170.026 76.2395Z" fill="#FE5E3A" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M183.518 132.141L182.106 133.015C182.035 133.055 182.025 133.147 182.055 133.208C182.096 133.279 182.187 133.299 182.248 133.258L183.671 132.385C183.742 132.344 183.752 132.253 183.711 132.192C183.661 132.121 183.579 132.1 183.518 132.141Z" fill="black" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M167.103 128.086L168.241 129.061C168.302 129.112 168.394 129.102 168.445 129.051C168.495 128.99 168.485 128.898 168.424 128.848L167.286 127.872C167.225 127.822 167.134 127.832 167.083 127.893C167.043 127.943 167.053 128.035 167.103 128.086Z" fill="black" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M165.556 112.745L167.162 113.172C167.233 113.192 167.314 113.151 167.334 113.07C167.355 112.999 167.314 112.918 167.233 112.897L165.627 112.471C165.546 112.45 165.475 112.491 165.455 112.572C165.434 112.654 165.485 112.725 165.556 112.745Z" fill="black" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M165.049 81.0373C165.049 81.0373 160.426 86.4322 159.431 97.7809C159.431 97.8012 159.431 97.8114 159.431 97.8317C159.431 97.8317 160.365 101.591 162.428 104.354C162.458 104.659 162.662 106.59 163.291 108.632C163.759 110.105 164.44 111.639 165.456 112.675V114.484C165.456 114.565 165.517 114.626 165.588 114.626C165.669 114.626 165.73 114.565 165.73 114.484V112.615C165.73 112.574 165.72 112.543 165.699 112.513C164.694 111.507 164.013 109.993 163.556 108.54C162.875 106.346 162.702 104.283 162.702 104.283C162.702 104.253 162.692 104.233 162.672 104.212C160.711 101.611 159.786 98.0959 159.705 97.7809C160.701 86.5541 165.273 81.2201 165.273 81.2201C165.323 81.1592 165.313 81.0677 165.252 81.0169C165.191 80.9661 165.1 80.9763 165.049 81.0373Z" fill="black" />
            <path d="M30.6262 138.392H30.1974V137.964C30.1974 136.999 29.3937 136.195 28.4293 136.195H27.5183C26.5538 136.195 25.7501 136.999 25.7501 137.964V138.392H25.3213C24.3569 138.392 23.5532 139.196 23.5532 140.16V143.268C23.5532 144.233 24.3569 145.036 25.3213 145.036H30.6796C31.644 145.036 32.4479 144.233 32.4479 143.268V140.16C32.3943 139.196 31.5907 138.392 30.6262 138.392ZM27.0361 137.964C27.0361 137.696 27.1967 137.535 27.4646 137.535H28.3756C28.6435 137.535 28.8042 137.696 28.8042 137.964V138.392H27.0361V137.964ZM28.3756 143.75H27.4646C27.1967 143.75 27.0361 143.59 27.0361 143.322C27.0361 143.054 27.1967 142.893 27.4646 142.893H28.3756C28.6435 142.893 28.8042 143.054 28.8042 143.322C28.8042 143.59 28.6435 143.75 28.3756 143.75Z" fill="white" />
            <path d="M50.7863 49.2053H50.3575V48.7766C50.3575 47.8121 49.5539 47.0084 48.5894 47.0084H47.6784C46.7139 47.0084 45.9103 47.8121 45.9103 48.7766V49.2053H45.4817C44.5173 49.2053 43.7134 50.009 43.7134 50.9735V54.0813C43.7134 55.0457 44.5173 55.8495 45.4817 55.8495H50.84C51.8045 55.8495 52.6081 55.0457 52.6081 54.0813V50.9735C52.5545 50.009 51.7508 49.2053 50.7863 49.2053ZM47.1962 48.7766C47.1962 48.5087 47.3571 48.3479 47.625 48.3479H48.5358C48.8037 48.3479 48.9646 48.5087 48.9646 48.7766V49.2053H47.1962V48.7766ZM48.5358 54.5635H47.625C47.3571 54.5635 47.1962 54.4027 47.1962 54.1348C47.1962 53.8669 47.3571 53.7062 47.625 53.7062H48.5358C48.8037 53.7062 48.9646 53.8669 48.9646 54.1348C48.9646 54.4027 48.8037 54.5635 48.5358 54.5635Z" fill="white" />
            <path d="M195.986 90.8134H195.557V90.3848C195.557 89.4203 194.753 88.6166 193.789 88.6166H192.878C191.914 88.6166 191.11 89.4203 191.11 90.3848V90.8134H190.681C189.717 90.8134 188.913 91.6171 188.913 92.5816V95.6894C188.913 96.6539 189.717 97.4576 190.681 97.4576H196.039C197.004 97.4576 197.808 96.6539 197.808 95.6894V92.5816C197.754 91.6171 196.95 90.8134 195.986 90.8134ZM192.396 90.3848C192.396 90.1169 192.557 89.9561 192.824 89.9561H193.735C194.003 89.9561 194.164 90.1169 194.164 90.3848V90.8134H192.396V90.3848ZM193.735 96.1716H192.824C192.557 96.1716 192.396 96.0109 192.396 95.743C192.396 95.4751 192.557 95.3143 192.824 95.3143H193.735C194.003 95.3143 194.164 95.4751 194.164 95.743C194.164 96.0109 194.003 96.1716 193.735 96.1716Z" fill="white" />
        </svg>
    );
}
