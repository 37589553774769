import React from 'react';
import { Grid, Stack, Typography, Box, Button, Chip } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import EmojIcon from '../../../assets/icons/Careers/EmojIcon';
import UserIcon from '../../../assets/icons/Careers/UserIcon';
import TrophyIcon from '../../../assets/icons/Careers/TrophyIcon';
import MedalIcon from '../../../assets/icons/Careers/MedalIcon';
import ArrowTopRight from '../../../assets/icons/Careers/ArrowTopRight';
function JobOpeningSection() {
    /**
     *
     * navigate
     *
     */
    const navigate = useNavigate();


    return (
        <Grid container sx={{
            paddingTop: "40px", paddingBottom: "50px",
            "@media (max-width: 600px)": {
                paddingTop: "20px",
                paddingBottom: "30px",
            },
        }}>
            <Grid item xs={12} sm={12}
                sx={{
                    paddingTop: "20px",
                }}
            >
                <Box>
                    <Stack
                        direction="column"
                        sx={{
                            gap: "10px"
                        }}
                    >
                        <Typography
                            sx={{
                                color: "var(--text-color-one)",
                            }}
                        >Jobs</Typography>
                        <Typography
                            sx={{
                                fontSize: "40px",
                                fontWeight: "500",
                                width: "70%",
                                "@media (max-width: 1600px)": {
                                    fontSize: "30px",
                                },
                                "@media (max-width: 1200px)": {
                                    fontSize: "25px",
                                },
                                "@media (max-width: 900px)": {
                                    fontSize: "20px",
                                    width: "100%",
                                },
                                "@media (max-width: 600px)": {
                                    fontSize: "18px",
                                    width: "100%",
                                },
                            }}
                        >Jobs Opening</Typography>
                        <Typography
                            sx={{
                                color: "var(--text-color-one)",
                                width: "50%",
                                "@media (max-width: 900px)": {
                                    width: "100%",
                                },
                            }}
                        >Team will take care of onboarding, timesheets, productivity reports,& post-hiring support.</Typography>
                    </Stack>
                    <Box
                        sx={{
                            display: "grid",
                            gridTemplateColumns: "repeat(1, 1fr)",
                            gap: "20px",
                            marginTop: "20px",
                        }}
                    >
                        {[
                            { id: "1", title: "Product Designer", jobType: "Full-time", jobTime: "Remote" },
                            { id: "2", title: "Backend Engineer", jobType: "Full-time", jobTime: "Remote" },
                            { id: "3", title: "Data Analyst", jobType: "Full-time", jobTime: "Remote" },
                        ]?.map((item, index) => (
                            <Stack
                                sx={{
                                    boxShadow: "rgba(0, 0, 0, 0.16) 0px 0px 0px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px",
                                    background: "#fff",
                                    gap: "10px",
                                    cursor: "pointer"
                                }}
                                p={2}
                                direction="row"
                                justifyContent="space-between"
                                onClick={() => {
                                    navigate("/jobs")
                                }}
                            >
                                <Stack
                                    sx={{
                                        gap: "10px",
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            fontSize: "20px",
                                            fontWeight: "600",
                                            "@media (max-width: 900px)": {
                                                fontSize: "18px",
                                            },
                                            "@media (max-width: 600px)": {
                                                fontSize: "15px",
                                            },
                                        }}
                                    >{item?.title}</Typography>
                                    <Typography
                                        sx={{
                                            color: "var(--text-color-one)",
                                            fontSize: "13px",
                                            "@media (max-width: 600px)": {
                                                fontSize: "11px",
                                            },
                                        }}
                                    >{item?.jobType} &#x2022; {item?.jobTime} </Typography>
                                </Stack>
                                <Stack
                                    justifyContent="center"
                                    alignItems="center"
                                >
                                    <ArrowTopRight />
                                </Stack>

                            </Stack>
                        ))}
                    </Box>
                    <Stack
                        sx={{ marginTop: "35px" }}
                    >
                        <Typography
                            sx={{
                                fontWeight: "400",
                                fontSize: "16px",
                                color: "var(--text-color-one)"
                            }}
                        >
                            Don’s see opportunity that suits you?
                            <Link to="#"
                                style={{
                                    color: "var(--text-color)",
                                    fontWeight: "600",
                                    textDecoration: "underline",
                                }}
                            >
                                Get notified when new roles added
                            </Link>
                        </Typography>
                    </Stack>
                </Box>
            </Grid>
        </Grid>
    )
}

export default JobOpeningSection;