import { Box, Stack, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import React from "react";
import MyModal from "./components/Modal";
import Cookies from 'js-cookie';
import { countryData } from "../../data/countryData";

export default function CountrySelectionModal({
  open,
  handleClose,
  edit,
  setOpen
}) {

  const cookieCountryCode = Cookies.get('cookieCountryCode');

  return (
    <MyModal
      open={open}
      setOpen={setOpen}
      handleClose={null}
      // showCloseIcon
      size="medium"
      sx={{ width: "600px" }}
    >
      <Stack>
        <Typography sx={{ fontSize: "1.4rem", fontWeight: "600" }} mb={1}>Select your country</Typography>
        <Typography sx={{ color: "var(--text-color-0)", fontSize: "13px" }} mb={3} >
          You are currently on <span style={{ textTransform: "uppercase", fontWeight: "900" }}>{cookieCountryCode ?? "US"}</span> version of our site, please select the region of the site you would like to enter.
        </Typography>
        <Stack
          sx={{
            width: "100%",
            display: "grid",
            gridTemplateColumns: "repeat(2, 1fr)",
            flexWrap: "wrap",
            "& > div": {
              borderTop: "1px solid var(--border-color-2)",
              height: "60px",
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              paddingLeft: "10px"
            },
            "& > div > div": {
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              alignItems: "center",
              fontSize: "15px",
              width: "100%",
              height: "100%",
              gap: "10px",
              cursor: "pointer"
            },
            "& > div:nth-of-type(odd)": {
              borderRight: "1px solid var(--border-color-2)",
            },
          }}
          mt={3}
          mb={3}
        >
          {countryData?.map((item) => (
            <Box
              onClick={() => {
                Cookies.set('cookieCountryCode', item?.ccode);
                Cookies.set('cookieCountryCodeTriggered', true);
                handleClose();
                window.location.reload();
              }}

              key={item?.id}
            >
              <Box>
                {item?.logo()}
                <span style={{ textTransform: "capatilize", fontWeight: "900" }}>{item?.ccode}</span>
              </Box>
            </Box>
          ))}

          {/* <Box>

          </Box> */}
        </Stack>
      </Stack>
    </MyModal>
  );
}
