import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import "./login.css";
import OtpInput from "react18-input-otp";
import CountdownTimer from "./CountDown/CountdownTimer";
import { verifyOTP } from "../helpers/dataFetcher/otpVerifiy/verify";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";


function OtpVerificationModal(props) {
  const { email, onHide } = props;
  const navigate = useNavigate();
  const [otp, setOtp] = useState("");
  const [hasError, setHasError] = useState(false);
  const handleChange = code => setOtp(code);
  const handleOTP = async () => {
    const { status } = await verifyOTP(email, otp);
    if (status !== 201) {
      setHasError(true);
      toast.error("invalid otp");
      return;
    }
    onHide();
    navigate("/login");
    setHasError(false);
  };
  return (
    <>
      <Modal
        {...props}
        backdrop="static"
        className="custom-delete-modal mt-5 pt-5"
        keyboard={false}
      >
        <Modal.Header className="border-0 px-4 bg-transparent otp_text" closeButton>
          Enter OTP
        </Modal.Header>
        <Modal.Body
          className="pt-0"
          style={{ padding: "30px", backgroundColor: "#bebebe" }}
        >
          <div className="d-flex justify-content-center pb-0 py-4">
            <OtpInput
              value={otp}
              onChange={handleChange}
              numInputs={6}
              shouldAutoFocus
              isInputNum={true}
              inputStyle="otp_input_items"
              errorStyle={"otp_input_items_error"}
              hasErrored={hasError}
              focusStyle={"otp_input_items_focus"}
              separator={<span> </span>}
              onSubmit={handleOTP}
            />
          </div>

          <CountdownTimer email={email} totalSec={20 * 10000} />

          <div className="d-flex justify-content-end">
            <button
              type="button"
              className="btn btn-primary"
              onClick={handleOTP}
              style={{
                background: "#cde2e7",
                color: "#024430",
                border: "none",
                fontWeight: "bold"
              }}
            >
              Verify OTP
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default OtpVerificationModal;
