import React from 'react';
import { Grid, Stack, Typography, Box, Button, Chip, InputAdornment, IconButton, Divider } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import BlogCatImg1 from "../../../assets/icons/Blogs/BlogCatImg1.svg";
import Banner from "../../../assets/icons/Blogs/BlogBanner.svg";
import airtable from "../../../assets/icons/Careers/airtable.svg";
import gumroad from "../../../assets/icons/Careers/gumroad.svg";
import mailChimp from "../../../assets/icons/Careers/mailChimp.svg";
import notion from "../../../assets/icons/Careers/notion.svg";
import ArrowRight from '../../../assets/icons/LandingPage/ArrowRight';
import UserIcon from '../../../assets/icons/Careers/UserIcon';
import LinkedinIcon from '../../../assets/icons/LandingPage/LinkedinIcon';
import InstagramIcon from '../../../assets/icons/LandingPage/InstagramIcon';
import TwitterIcon from '../../../assets/icons/LandingPage/TwitterIcon';
import YouTubeIcon from '../../../assets/icons/LandingPage/YouTubeIcon';
import FacebookIcon from '../../../assets/icons/LandingPage/FacebookIcon';
function BlogContentSection() {
    /**
    *
    * redux dispatch
    *
    */
    const dispatch = useDispatch();


    /**
     *
     * navigate
     *
     */
    const navigate = useNavigate();

    const datailItem = {
        id: "1",
        headImage: BlogCatImg1,
        category: "Engineering",
        date: "October 23, 2023",
        headTitle: "Introducing The Joinee Refer & Earn",
        blogPoster: {
            avatar: UserIcon,
            name: "Dileep Kumar",
            designation: "CEO at Syoft",
            socialMedia: [
                {
                    id: "1",
                    type: "linkedin",
                    link: "https://joinee.com/",
                },
                {
                    id: "2",
                    type: "facebook",
                    link: "https://joinee.com/",
                },
                {
                    id: "3",
                    type: "twitter",
                    link: "https://joinee.com/",
                },
                {
                    id: "4",
                    type: "instagram",
                    link: "https://joinee.com/",
                },
                {
                    id: "5",
                    type: "youtube",
                    link: "https://joinee.com/",
                }
            ]
        },
        headText: "Egestas tellus viverra sed nulla. Quisque venenatis quis odio aliquet tellus dui pellentesque. Sed dictum id venenatis sed viverra aliquam sapien.",
        headDesc: "The CFO is ascending. Earlier this year, Fortune reported that in the first half of 2022, 8.1% of CFOs at Fortune 500 and S&P 500 companies were promoted to CEO up from 5.6% a decade earlier. The jump reflects how business success and financial agility have become inseparable. \n\n It’s also consistent with our own recently released CFO insights report, which found that 68% of finance leaders believe that streamlining financial operations is an essential part of their company’s strategic growth. They see it as a prerequisite for entering new markets and launching new business models. \n\n Those kinds of opportunities are why we built our revenue and finance automation suite, a set of modular products that help finance teams automate, adapt, and scale their work. The core products Billing; Revenue Recognition; Data Pipeline; Invoicing; Tax; and Sigma, our data analytics product integrate effortlessly with Stripe Payments, allowing businesses to reduce operating costs while growing revenue. \n\n This week we’re excited to announce new features that help businesses grow revenue, streamline the back office, and improve insights and decision-making.",
        details: [
            {
                id: "1",
                title: "Tools to grow revenue",
                description: "The returns from improving back-office systems can be huge. Eighty-seven percent of Stripe invoices are paid within 24 hours, and Billing’s revenue recovery tools alone helped users recover $2.5 billion of revenue that would have been lost to churn in the first half of 2023. \n\n We’ve added new features to the revenue and finance automation suite that directly translate back-office performance into revenue growth. \n\n",
                dataTypes: [
                    {
                        id: "1",
                        type: "text",
                        heading: "New revenue recovery features",
                        description: "Revenue recovery is a key part of how Stripe Billing maximizes revenue: we use machine learning to retry failed recurring payments at the optimal time, based on hundreds of dynamic signals. Our Smart Retries algorithm continuously gets better, and we’ve improved the recovery rate by 15.5% in 2023. \n\n Deliveroo has recovered more than £100 million in revenue with Billing’s revenue recovery features. “The work Stripe has done to improve payment success rates, from the use of Stripe Billing to rapidly enabling Deliveroo Plus, makes it clear why Stripe is embedded in some of our largest initiatives,” said Will Shu, cofounder and CEO of Deliveroo.",
                        image: ""
                    },
                    {
                        id: "2",
                        type: "text",
                        heading: "Tax in more markets for faster market expansion",
                        description: "One of the best ways for businesses to grow revenue is to enter new markets, but they’re often stymied by the tax complexity that comes with it. We built Stripe Tax to solve that problem. Businesses can sell cross-border, and Tax takes care of tax collection for them, automatically. Now, Tax just got better, with support for 10 new countries (bringing the total number of countries supported to 50, including all of the EU).",
                        image: ""
                    },
                    {
                        id: "3",
                        type: "text",
                        heading: "Tax for platforms",
                        description: "For a long time, platforms have generated revenue—and value for their customers—by offering and monetizing Stripe Payments. Increasingly, we’re making it easy for platforms to offer other Stripe products in the same way, allowing them to differentiate their offerings, open new revenue streams, and provide added value to their users. \n\n That now includes Tax. Platforms using Benefiq Connect can offer Tax as a service to their connected accounts, providing them with a more unified solution, and making it easier for platform users to meet their sales tax, VAT, and GST obligations automatically. \n\n “Benefiq allows us to turn on new markets instantly and move at the pace that meets our customers’ growing and changing needs. I’d estimate we move twice as fast as we would with any other platform,” said Peter Fitzpatrick, vice president of commerce at Thinkific.",
                        image: ""
                    },
                ]
            },
            {
                id: "2",
                title: "Tools to streamline the back office",
                description: "If software is not implemented carefully, it can easily create new burdens of its own. In the CFO insights report, 63% of finance teams said they now use more than 10 different systems to get a unified view of their company’s financials, and 55% said they want to consolidate the number of software programs they use within the next two years. To help, we’re adding new features to the revenue and finance automation suite that allow businesses to operate across multiple systems more easily, while accessing real-time data insights faster.",
                dataTypes: [
                    {
                        id: "1",
                        type: "text",
                        heading: "More visibility into Benefiq-originated emails",
                        description: "Revenue recovery is a key part of how Stripe Billing maximizes revenue: we use machine learning to retry failed recurring payments at the optimal time, based on hundreds of dynamic signals. Our Smart Retries algorithm continuously gets better, and we’ve improved the recovery rate by 15.5% in 2023. \n\n Deliveroo has recovered more than £100 million in revenue with Billing’s revenue recovery features. “The work Stripe has done to improve payment success rates, from the use of Stripe Billing to rapidly enabling Deliveroo Plus, makes it clear why Stripe is embedded in some of our largest initiatives,” said Will Shu, cofounder and CEO of Deliveroo.",
                        image: ""
                    },
                    {
                        id: "2",
                        type: "text",
                        heading: "New Revenue Recognition features",
                        description: "One of the best ways for businesses to grow revenue is to enter new markets, but they’re often stymied by the tax complexity that comes with it. We built Stripe Tax to solve that problem. Businesses can sell cross-border, and Tax takes care of tax collection for them, automatically. Now, Tax just got better, with support for 10 new countries (bringing the total number of countries supported to 50, including all of the EU). \n\n One of the most time-consuming back-office tasks is accurately recognizing revenue across accounting periods. Benefiq Revenue Recognition streamlines this process and now has new beta features that help to automate accounting with more precision and customization. You can gain a more complete picture of your revenue by importing and managing revenue contracts (see image below) via the Stripe Billing Connector for Salesforce CPQ. You can also customize your chart of accounts to streamline bookkeeping across systems. Finally, additional platform-based businesses on Stripe Connect can now use Revenue Recognition.",
                        image: BlogCatImg1,
                    },
                    {
                        id: "3",
                        type: "text",
                        heading: "Deeper Salesforce integration",
                        description: "Revenue recovery is a key part of how Stripe Billing maximizes revenue: we use machine learning to retry failed recurring payments at the optimal time, based on hundreds of dynamic signals. Our Smart Retries algorithm continuously gets better, and we’ve improved the recovery rate by 15.5% in 2023. \n\n Deliveroo has recovered more than £100 million in revenue with Billing’s revenue recovery features. “The work Stripe has done to improve payment success rates, from the use of Stripe Billing to rapidly enabling Deliveroo Plus, makes it clear why Stripe is embedded in some of our largest initiatives,” said Will Shu, cofounder and CEO of Deliveroo.",
                        image: ""
                    },
                    {
                        id: "4",
                        type: "text",
                        heading: "Improved Billing migrations",
                        description: "One of the best ways for businesses to grow revenue is to enter new markets, but they’re often stymied by the tax complexity that comes with it. We built Stripe Tax to solve that problem. Businesses can sell cross-border, and Tax takes care of tax collection for them, automatically. Now, Tax just got better, with support for 10 new countries (bringing the total number of countries supported to 50, including all of the EU). \n\n One of the best ways for businesses to grow revenue is to enter new markets, but they’re often stymied by the tax complexity that comes with it. We built Stripe Tax to solve that problem. Businesses can sell cross-border, and Tax takes care of tax collection for them, automatically. Now, Tax just got better, with support for 10 new countries (bringing the total number of countries supported to 50, including all of the EU).",
                        image: ""
                    },

                ]
            },
            {
                id: "3",
                title: "Tools to improve insights and decision-making",
                description: "If software is not implemented carefully, it can easily create new burdens of its own. In the CFO insights report, 63% of finance teams said they now use more than 10 different systems to get a unified view of their company’s financials, and 55% said they want to consolidate the number of software programs they use within the next two years. To help, we’re adding new features to the revenue and finance automation suite that allow businesses to operate across multiple systems more easily, while accessing real-time data insights faster. \n\n If software is not implemented carefully, it can easily create new burdens of its own. In the CFO insights report, 63% of finance teams said they now use more than 10 different systems to get a unified view of their company’s financials, and 55% said they want to consolidate the number of software programs they use within the next two years. To help, we’re adding new features to the revenue and finance automation suite that allow businesses to operate across multiple systems more easily, while accessing real-time data insights faster.",
            }
        ]
    }


    return (
        <Grid container sx={{
            paddingTop: "50px",
            paddingBottom: "60px",
            width: "70%",
            margin: "auto",
            "@media (max-width: 600px)": {
                paddingTop: "40px",
                paddingBottom: "40px",
                width: "100%",
            },
        }}>
            <Grid item xs={12} sm={12}>
                <Stack
                    sx={{ width: "100%", margin: "auto" }}
                    spacing={4}
                    direction="column"
                >
                    <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="center"
                        spacing={1}
                    >
                        <Chip label={datailItem?.category}
                            sx={{
                                background: "#ECFAE2",
                                '& .MuiChip-label': {
                                    fontWeight: "700",
                                },
                            }}
                        />
                        <Chip label={datailItem?.date} size="medium"
                            sx={{
                                backgroundColor: "transparent"
                            }}
                        />
                    </Stack>
                    <Stack direction="column">
                        <Typography
                            sx={{
                                fontSize: "45px",
                                fontWeight: "600",
                                textAlign: "center",
                                "@media (max-width: 900px)": {
                                    fontSize: "20px",
                                },
                            }}
                        >{datailItem?.headTitle}</Typography>
                        <Typography
                            sx={{
                                fontSize: "16px",
                                fontWeight: "500",
                                color: "#737373",
                                textAlign: "center",
                                "@media (max-width: 900px)": {
                                    fontSize: "13px",
                                },
                            }}
                        >
                            {datailItem?.headText}
                        </Typography>
                    </Stack>
                    <Stack direction="column">
                        <Box
                            sx={{
                                width: "100%",
                                height: "fit-content",
                            }}
                        >
                            <img src={datailItem?.headImage} alt="blogs" style={{ width: "100%", height: "auto" }} />
                        </Box>
                    </Stack>
                    <Divider flexItem />
                    <Stack
                        direction="column"
                        spacing={2}
                    >
                        <Typography
                            sx={{
                                whiteSpace: "pre-wrap",
                                wordWrap: "break-word",
                                fontSize: "15px",
                                fontWeight: "500",
                                color: "#737373",
                                "@media (max-width: 900px)": {
                                    fontSize: "12px",
                                },
                            }}
                        >{datailItem?.headDesc}</Typography>

                        {datailItem?.details?.map((item, index) => (
                            <Box 
                                key={index}
                                mt={2}
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: "20px",
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontSize: "20px",
                                        fontWeight: "700",
                                        color: "#000",
                                        "@media (max-width: 900px)": {
                                            fontSize: "15px",
                                        },
                                    }}
                                >{item?.title}</Typography>
                                <Typography
                                    sx={{
                                        whiteSpace: "pre-wrap",
                                        wordWrap: "break-word",
                                        fontSize: "15px",
                                        fontWeight: "500",
                                        color: "#737373",
                                        "@media (max-width: 900px)": {
                                            fontSize: "12px",
                                        },
                                    }}
                                >{item?.description}</Typography>

                                {item?.dataTypes?.map((el, ind) => (
                                    <Box
                                        sx={{
                                            display: "flex",
                                            flexDirection: "column",
                                            gap: "10px",
                                        }}
                                        key={ind}
                                    >
                                        <Typography
                                            sx={{
                                                fontSize: "18px",
                                                fontWeight: "700",
                                                color: "#000",
                                                "@media (max-width: 900px)": {
                                                    fontSize: "15px",
                                                },
                                            }}
                                        >{ind + 1}. {el?.heading}</Typography>
                                        <Typography
                                            sx={{
                                                whiteSpace: "pre-wrap",
                                                wordWrap: "break-word",
                                                fontSize: "15px",
                                                fontWeight: "500",
                                                color: "#737373",
                                                "@media (max-width: 900px)": {
                                                    fontSize: "12px",
                                                },
                                            }}
                                        >{el?.description}</Typography>
                                        {el?.image && el?.image !== "" && (
                                            <Box
                                                sx={{
                                                    width: "100%",
                                                    height: "fit-content",
                                                    paddingTop: "10px",
                                                    paddingBottom: "10px",
                                                }}
                                            >
                                                <img src={el?.image} alt="blogs" style={{ width: "100%", height: "auto" }} />
                                            </Box>
                                        )}

                                    </Box>
                                ))}
                            </Box>
                        ))}
                    </Stack>
                    <Divider flexItem />
                    <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Stack
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="center"
                            spacing={2}
                        >
                            <Stack
                                sx={{
                                    height: "45px",
                                    minWidth: "45px",
                                    width: "45px",
                                    borderRadius: "50%",
                                    background: "var(--app-color-one)",

                                }}
                                justifyContent="center"
                                alignItems="center"
                            >
                                {datailItem?.blogPoster?.avatar()}
                            </Stack>
                            <Box
                                sx={{
                                    width: "80%",
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontWeight: "700",
                                        fontSize: "15px",
                                    }}
                                    noWrap
                                >
                                    {datailItem?.blogPoster?.name}
                                </Typography>
                                <Typography
                                    sx={{
                                        fontWeight: "400",
                                        fontSize: "12px",
                                        color: "gray",
                                    }}
                                    noWrap
                                >
                                    {datailItem?.blogPoster?.designation}
                                </Typography>
                            </Box>
                        </Stack>
                        <Stack
                            direction="row"
                            spacing={2}
                            sx={{
                                flexWrap: "wrap"
                            }}
                        >
                            {datailItem?.blogPoster?.socialMedia?.map((item, index) => (
                                <Stack
                                    key={index}
                                    sx={{
                                        height: "35px",
                                        minWidth: "35px",
                                        width: "35px",
                                        borderRadius: "50%",
                                        background: "#FAFAFA",
                                        border: "1px solid #E5E5E5",
                                        cursor: "pointer"
                                    }}
                                    justifyContent="center"
                                    alignItems="center"
                                    onClick={() => {
                                        window.open(item?.link, '_blank');
                                    }}
                                >
                                    {item?.type === "linkedin" ? (<LinkedinIcon />) : item?.type === "facebook" ? (<FacebookIcon />) : item?.type === "twitter" ? (<TwitterIcon />) : item?.type === "instagram" ? (<InstagramIcon />) : item?.type === "youtube" ? (<YouTubeIcon />) : null}
                                </Stack>
                            ))}
                        </Stack>
                    </Stack>
                </Stack>
            </Grid>
        </Grid>
    )
}

export default BlogContentSection;
