import React from 'react';
import { Box, CircularProgress } from "@mui/material";

function JoineeLoader() {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        margin: "auto",
        width: "100%",
        height: "100%",
        minHeight: "200px",
        padding: "20px",
        marginTop: "10%"
      }}
    >
      <CircularProgress color="success"/>
    </Box>
  )
}

export default JoineeLoader;