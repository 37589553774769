import React from 'react';
import { Grid, Stack, Typography, Box, Button, Chip, InputAdornment, IconButton, Divider, ListItem, List } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";


import { makeStyles } from "@mui/styles";
const useStyles = makeStyles({
    repeatedTextStyle: {
        whiteSpace: "pre-wrap",
        wordWrap: "break-word",
        fontSize: "15px",
        fontWeight: "500",
        color: "#737373",
        "@media (max-width: 900px)": {
            fontSize: "12px",
        },
    },
});

function PrivacyContentSection() {
    const classes = useStyles();
    /**
    *
    * redux dispatch
    *
    */
    const dispatch = useDispatch();


    /**
     *
     * navigate
     *
     */
    const navigate = useNavigate();




    return (
        <Grid container sx={{
            paddingTop: "50px",
            paddingBottom: "60px",
            width: "70%",
            margin: "auto",
            "@media (max-width: 600px)": {
                paddingTop: "40px",
                paddingBottom: "40px",
                width: "100%",
            },
        }}>
            <Grid item xs={12} sm={12}>
                <Stack
                    sx={{ width: "100%", margin: "auto" }}
                    spacing={4}
                    direction="column"
                >
                    <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="center"
                        spacing={1}
                    >
                        <Chip label="License and Notes"
                            sx={{
                                background: "#ECFAE2",
                                '& .MuiChip-label': {
                                    fontWeight: "700",
                                },
                            }}
                        />
                    </Stack>
                    <Stack direction="column">
                        <Typography
                            sx={{
                                fontSize: "45px",
                                fontWeight: "600",
                                textAlign: "center",
                                "@media (max-width: 900px)": {
                                    fontSize: "20px",
                                },
                            }}
                        >Privacy Policy</Typography>
                        <Typography
                            sx={{
                                fontSize: "15px",
                                fontWeight: "500",
                                textAlign: "center",
                                "@media (max-width: 900px)": {
                                    fontSize: "13px",
                                },
                            }}
                        >
                            Last Updated: <span
                                style={{
                                    fontWeight: "700"
                                }}
                            >October 23, 2023</span>
                        </Typography>
                    </Stack>
                    <Divider flexItem />
                    <Stack
                        direction="column"
                        spacing={2}
                    >
                        <Typography
                            sx={{
                                whiteSpace: "pre-wrap",
                                wordWrap: "break-word",
                                fontSize: "15px",
                                fontWeight: "500",
                                color: "#737373",
                                "@media (max-width: 900px)": {
                                    fontSize: "12px",
                                },
                            }}
                        >{`Your privacy is important to us. It is Joinee policy to respect your privacy and comply with any applicable law and regulation regarding any personal information we may collect about you, including across our website, www.joinee.com, and other sites we own and operate. \n\nPersonal information is any information about you which can be used to identify you. This includes information about you as a person (such as name, address, and date of birth), your devices, payment details, and even information about how you use a website or online service. \n\nIn the event our site contains links to third-party sites and services, please be aware that those sites and services have their own privacy policies. After following a link to any third-party content, you should read their posted privacy policy information about how they collect and use personal information. This Privacy Policy does not apply to any of your activities after you leave our site. \n\nThis policy is effective as of 31 October 2023.`}</Typography>

                        <Box
                            mt={2}
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                gap: "15px",
                            }}
                        >
                            <Typography
                                sx={{
                                    fontSize: "20px",
                                    fontWeight: "700",
                                    color: "#000",
                                    "@media (max-width: 900px)": {
                                        fontSize: "15px",
                                    },
                                }}
                            >Information We Collect</Typography>
                            <Typography
                                className={classes?.repeatedTextStyle}
                            >{`Information we collect falls into one of two categories: “voluntarily provided” information and “automatically collected” information. “Voluntarily provided” information refers to any information you knowingly and actively provide us when using or participating in any of our services and promotions. \n\n“Automatically collected” information refers to any information automatically sent by your devices in the course of accessing our products and services.`}</Typography>
                        </Box>

                        <Box
                            mt={2}
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                gap: "15px",
                            }}
                        >
                            <Typography
                                sx={{
                                    fontSize: "20px",
                                    fontWeight: "700",
                                    color: "#000",
                                    "@media (max-width: 900px)": {
                                        fontSize: "15px",
                                    },
                                }}
                            >Log Data</Typography>
                            <Typography
                                className={classes?.repeatedTextStyle}
                            >{`When you visit our website, our servers may automatically log the standard data provided by your web browser. It may include your device’s Internet Protocol (IP) address, your browser type and version, the pages you visit, the time and date of your visit, the time spent on each page, and other details about your visit. \n\nAdditionally, if you encounter certain errors while using the site, we may automatically collect data about the error and the circumstances surrounding its occurrence. This data may include technical details about your device, what you were trying to do when the error happened, and other technical information relating to the problem. You may or may not receive notice of such errors, even in the moment they occur, that they have occurred, or what the nature of the error is. \n\nPlease be aware that while this information may not be personally identifying by itself, it may be possible to combine it with other data to personally identify individual persons.`}</Typography>
                        </Box>
                        <Box
                            mt={2}
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                gap: "15px",
                            }}
                        >
                            <Typography
                                sx={{
                                    fontSize: "20px",
                                    fontWeight: "700",
                                    color: "#000",
                                    "@media (max-width: 900px)": {
                                        fontSize: "15px",
                                    },
                                }}
                            >Device Data</Typography>
                            <Typography
                                className={classes?.repeatedTextStyle}
                            >{`When you visit our website or interact with our services, we may automatically collect data about your device, such as:`}</Typography>
                            <Typography
                                className={classes?.repeatedTextStyle}
                            >&#x2022; Device Type {`\n\n`}&#x2022; Operating System {`\n\n`}&#x2022; Unique device identifiers</Typography>
                            <Typography
                                className={classes?.repeatedTextStyle}
                            >{`Data we collect can depend on the individual settings of your device and software. We recommend checking the policies of your device manufacturer or software provider to learn what information they make available to us.`}</Typography>
                        </Box>
                        <Box
                            mt={2}
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                gap: "15px",
                            }}
                        >
                            <Typography
                                sx={{
                                    fontSize: "20px",
                                    fontWeight: "700",
                                    color: "#000",
                                    "@media (max-width: 900px)": {
                                        fontSize: "15px",
                                    },
                                }}
                            >Personal Information</Typography>
                            <Typography
                                className={classes?.repeatedTextStyle}
                            >{`We may ask for personal information — for example, when you submit content to us or when you contact us — which may include one or more of the following:`}</Typography>
                            <Typography
                                className={classes?.repeatedTextStyle}
                            >&#x2022; Name {`\n\n`}&#x2022; Email</Typography>
                        </Box>
                        <Box
                            mt={2}
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                gap: "15px",
                            }}
                        >
                            <Typography
                                sx={{
                                    fontSize: "20px",
                                    fontWeight: "700",
                                    color: "#000",
                                    "@media (max-width: 900px)": {
                                        fontSize: "15px",
                                    },
                                }}
                            >User-Generated Content</Typography>
                            <Typography
                                className={classes?.repeatedTextStyle}
                            >{`We consider “user-generated content” to be materials (text, image and/or video content) voluntarily supplied to us by our users for the purpose of publication on our website or re-publishing on our social media channels. All user-generated content is associated with the account or email address used to submit the materials. \n\nPlease be aware that any content you submit for the purpose of publication will be public after posting (and subsequent review or vetting process). Once published, it may be accessible to third parties not covered under this privacy policy.`}</Typography>
                        </Box>

                        <Box
                            mt={2}
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                gap: "15px",
                            }}
                        >
                            <Typography
                                sx={{
                                    fontSize: "20px",
                                    fontWeight: "700",
                                    color: "#000",
                                    "@media (max-width: 900px)": {
                                        fontSize: "15px",
                                    },
                                }}
                            >Legitimate Reasons for Processing Your Personal Information</Typography>
                            <Typography
                                className={classes?.repeatedTextStyle}
                            >{`We only collect and use your personal information when we have a legitimate reason for doing so. In which instance, we only collect personal information that is reasonably necessary to provide our services to you.`}</Typography>
                        </Box>
                        <Box
                            mt={2}
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                gap: "15px",
                            }}
                        >
                            <Typography
                                sx={{
                                    fontSize: "20px",
                                    fontWeight: "700",
                                    color: "#000",
                                    "@media (max-width: 900px)": {
                                        fontSize: "15px",
                                    },
                                }}
                            >Collection and Use of Information</Typography>
                            <Typography
                                className={classes?.repeatedTextStyle}
                            >{`We may collect personal information from you when you do any of the following on our website:`}</Typography>
                            <Typography
                                className={classes?.repeatedTextStyle}
                            >&#x2022; Register for an account {`\n\n`}&#x2022; Sign up to receive updates from us via email or social media channels{`\n\n`}&#x2022; Use a mobile device or web browser to access our content{`\n\n`}&#x2022; Contact us via email, social media, or on any similar technologies{`\n\n`}&#x2022;When you mention us on social media</Typography>
                            <Typography
                                className={classes?.repeatedTextStyle}
                            >{`We may collect, hold, use, and disclose information for the following purposes, and personal information will not be further processed in a manner that is incompatible with these purposes:`}</Typography>
                            <Typography
                                className={classes?.repeatedTextStyle}
                            >&#x2022; to provide you with our platform’s core features and services {`\n\n`}&#x2022; to enable you to customize or personalize your experience of our website {`\n\n`}&#x2022; to deliver products and/or services to you {`\n\n`}&#x2022; to contact and communicate with you {`\n\n`}&#x2022; to enable you to access and use our website, associated applications, and associated social media platforms {`\n\n`}&#x2022; to comply with our legal obligations and resolve any disputes that we may have {`\n\n`}&#x2022; for security and fraud prevention, and to ensure that our sites and apps are safe, secure, and used in line with our terms of use</Typography>
                            <Typography
                                className={classes?.repeatedTextStyle}
                            >{`We may combine voluntarily provided and automatically collected personal information with general information or research data we receive from other trusted sources. For example, Our marketing and market research activities may uncover data and insights, which we may combine with information about how visitors use our site to improve our site and your experience on it.`}</Typography>
                        </Box>

                    </Stack>
                    <Divider flexItem />
                    <Stack>
                        <Typography
                            sx={{
                                fontSize: "18px",
                                fontWeight: "700",
                                color: "#000",
                                lineHeight: "2",
                                "@media (max-width: 900px)": {
                                    fontSize: "15px",
                                },
                            }}
                        >Contact us:</Typography>
                        <Typography
                            sx={{
                                whiteSpace: "pre-wrap",
                                wordWrap: "break-word",
                                fontSize: "15px",
                                fontWeight: "500",
                                color: "#737373",
                                "@media (max-width: 900px)": {
                                    fontSize: "12px",
                                },
                            }}
                        >{`Email: support(at)joinee.com \nAddress: Hyderabad, Gachibowli\n\nThank you for your continued support, and we hope our website remains a valuable resource for you.\n\nSincerely,\nThe Joinee Team`}</Typography>
                    </Stack>
                </Stack>
            </Grid>
        </Grid>
    )
}

export default PrivacyContentSection;
