import * as React from "react";
import { Link } from "react-router-dom";
import { Stack, Avatar, IconButton, Typography } from "@mui/material";
import PopoverPopupModel from "../../../components/popover/components/Popover";
import { imageURL } from "../../../Constants/commonURLS";
import {
    AttachMoneyOutlined,
    DashboardOutlined,
    Logout,
    MenuOutlined,
  } from "@mui/icons-material";

export default function UserDropdowns({ user }) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
 

    return (
        <>
            <IconButton
                color="primary"
                component="label"
                onClick={handleClick}
            >
                <Avatar
                    alt="avatar"
                    src={`${imageURL}${user?.image}`}
                    sx={{ width: 30, height: 30, background: "#cde2e7" }}
                  >
                    <span className="profile_letters">
                      {user?.first_name?.charAt(0) +
                        "" +
                        user?.last_name?.charAt(0)}
                    </span>
                  </Avatar>
            </IconButton>

            <PopoverPopupModel
                anchorEl={anchorEl}
                setAnchorEl={setAnchorEl}
                sx={{ maxWidth: "40vw", minWidth: "150px" }}
                popupAdjust={{ marginTop: "15px" }}
            >
                <div className="profile_popover">
                    <div className="pofile_header_pop">
                      <div className="d-flex align-items-center gap-2">
                        <IconButton
                          color="primary"
                          aria-label="upload picture"
                          component="label"
                        >
                          <Avatar
                            alt="avatar"
                            src={`${imageURL}${user?.image}`}
                            sx={{
                              width: 45,
                              height: 45,
                              // bgcolor: deepPurple[500],
                              background: "#ECECEC",
                              borderRadius: "50%",
                            }}
                          >
                            <span className="profile_letters">
                              {user?.first_name?.charAt(0) +
                                "" +
                                user?.last_name?.charAt(0)}
                            </span>
                          </Avatar>
                        </IconButton>
                        <div className="text_in_pop">
                          <div>
                            <Typography
                              variant="p"
                              component="p"
                              className="profile_name_normal"
                            >
                              {user?.first_name + " " + user?.last_name}
                            </Typography>{" "}
                            <Typography
                              variant="p"
                              component="p"
                              className="email_popup"
                            >
                              {user?.email}
                            </Typography>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="profile_header_navigators">
                      <Link
                        onClick={() => {
                          handleClose();
                        }}
                        to="/profile"
                        className="links_in_popover"
                      >
                        <DashboardOutlined fontSize="sm" />
                        <span>My Profile</span>
                      </Link>
                      <Link
                        onClick={() => {
                          handleClose();
                        }}
                        to="/myearning"
                        className="links_in_popover"
                      >
                        <AttachMoneyOutlined fontSize="sm" />

                        <span>My Earnings</span>
                      </Link>
                      <Link
                        onClick={() => {
                          handleClose();
                        }}
                        to="/myearning"
                        className="links_in_popover"
                      >
                        <AttachMoneyOutlined fontSize="sm" />
                        <span>Help Center</span>
                      </Link>

                      <Link
                        onClick={() => {
                          handleClose();
                        }}
                        to="/logout"
                        className="links_in_popover"
                      >
                        <Logout fontSize="sm" />
                        <span>Logout</span>
                      </Link>
                    </div>
                  </div>
            </PopoverPopupModel>
        </>
    );
}
