export default function SpaceCoffeeCupIcon() {
    return (
        <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="iconspace_Coffee Cup">
                <circle id="Shape" opacity="0.1" cx="32" cy="32" r="32" fill="#FAAD13" />
                <path id="Shape_2" fill-rule="evenodd" clip-rule="evenodd" d="M53 27V24C53 23.4477 52.5523 23 52 23H45C44.4477 23 44 23.4477 44 24V34C44 34.5523 44.4477 35 45 35C49.4183 35 53 31.4183 53 27ZM46 25H51V27C51 29.973 48.8377 32.441 46 32.917V25Z" fill="#154E84" />
                <path id="Shape_3" fill-rule="evenodd" clip-rule="evenodd" d="M19.0787 37.8936C14.7486 39.361 12 41.5521 12 44C12 48.4183 20.9543 52 32 52C43.0457 52 52 48.4183 52 44C52 41.5944 49.3456 39.4368 45.1444 37.9703L19.0787 37.8936Z" fill="white" />
                <path id="Shape_4" fill-rule="evenodd" clip-rule="evenodd" d="M21.3708 38C17.4738 39.1443 14 41.8529 14 43.7617C14 47.207 22.0589 50 32 50C41.9411 50 50 47.207 50 43.7617C50 41.8859 46.611 39.2034 42.8299 38.0598L21.3708 38Z" fill="#52ABE2" />
                <path id="Shape_5" fill-rule="evenodd" clip-rule="evenodd" d="M11 44C11 41.06 13.9369 38.5802 18.7577 36.9465L18.9153 36.8931L45.3153 36.9708L45.4739 37.0261C50.1581 38.6613 53 41.1079 53 44C53 49.221 43.5525 53 32 53C20.4475 53 11 49.221 11 44ZM51 44C51 42.2323 48.8408 40.35 44.9717 38.9698L19.2438 38.894C15.2454 40.2783 13 42.1972 13 44C13 47.6156 21.4611 51 32 51C42.5389 51 51 47.6156 51 44Z" fill="#154E84" />
                <path id="Shape_6" fill-rule="evenodd" clip-rule="evenodd" d="M19 21C19 20.4477 19.4477 20 20 20H44C44.5523 20 45 20.4477 45 21V38C45 42.4183 41.4183 46 37 46H27C22.5817 46 19 42.4183 19 38V21Z" fill="#FFC200" />
                <path id="Shape_7" fill-rule="evenodd" clip-rule="evenodd" d="M22 21C22 20.4477 22.4477 20 23 20H44C44.5523 20 45 20.4477 45 21V37C45 41.4183 41.4183 45 37 45H30C25.5817 45 22 41.4183 22 37L22 21Z" fill="white" />
                <path id="Shape_8" fill-rule="evenodd" clip-rule="evenodd" d="M44 19H20C18.8954 19 18 19.8954 18 21V38C18 42.9706 22.0294 47 27 47H37C41.9706 47 46 42.9706 46 38V21C46 19.8954 45.1046 19 44 19ZM20 38V21H44V38C44 41.866 40.866 45 37 45H27C23.134 45 20 41.866 20 38Z" fill="#154E84" />
                <path id="Shape_9" d="M33 16V12C33 11.4477 32.5523 11 32 11C31.4477 11 31 11.4477 31 12V16C31 16.5523 31.4477 17 32 17C32.5523 17 33 16.5523 33 16Z" fill="#52ABE2" />
                <path id="Shape_10" d="M25 16V12C25 11.4477 24.5523 11 24 11C23.4477 11 23 11.4477 23 12V16C23 16.5523 23.4477 17 24 17C24.5523 17 25 16.5523 25 16Z" fill="#52ABE2" />
                <path id="Shape_11" d="M41 16V12C41 11.4477 40.5523 11 40 11C39.4477 11 39 11.4477 39 12V16C39 16.5523 39.4477 17 40 17C40.5523 17 41 16.5523 41 16Z" fill="#52ABE2" />
            </g>
        </svg>
    );
}
