import React, { useState, useEffect, useRef } from "react";
import Sidebar from "../Sidebar/Sidebar";
import { imageURL } from "../../../Constants/commonURLS";
import { Avatar, Box, Stack, Typography, CircularProgress } from "@mui/material";
import Chip from "@mui/joy/Chip";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { useNavigate } from "react-router-dom";
import { getSavedJobsList } from "../../../helpers/dataFetcher/jobs/jobs";
import JobDescription from "../../BrowseJobsList/JobDescription";
import { getJobPostById } from "../../../helpers/dataFetcher/jobs/jobs";
import { getCompanyById } from "../../../helpers/dataFetcher/companies";
import getSymbolFromCurrency from "currency-symbol-map";
import noJobs from "../../../assets/images/nojobsShiyft.png";
import { useLocation, useSearchParams } from "react-router-dom";
import GlobalSearch from "../../../hooks/GlobalSearch";
import { globalTheme } from '../../../assets/MUI/globalStyles';
import { ThemeProvider } from "@mui/material/styles";

function MySavedJobs() {
  const [savedJobs, setSavedJobs] = useState(null);
  const [Job, setJob] = useState({});
  const [company, setCompany] = useState({});
  const cardRef = useRef(null);
  const [jobLoading, setJobLoading] = useState(true);
  const [jobDetailsLoading, setJobDetailsLoading] = useState(true);
  const [mount, setMount] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();
  const searchFilter = searchParams.get("q");
  const selectedJobPostId = searchParams.get("job-post-id");
  const selectedCompanyId = searchParams.get("company-id");
  const savedJobListRef = useRef();

  const [currPage, setCurrPage] = React.useState(1)
  const rowsPerPage = 10;



  useEffect(() => {
    if (!mount) {
      setMount(true);
      return;
    }
    getSavedJobs();
    if (searchFilter && searchFilter !== "") {
      setCurrPage(1);
      setSavedJobs([]);
    }
  }, [searchFilter, currPage]);


  async function getSavedJobs() {
    setJobLoading(true);
    const { data, status } = await getSavedJobsList(currPage, rowsPerPage, searchFilter ?? "");

    if (!data?.data_payload?.length) {
      setJobLoading(false);
      return;
    }

    if (data?.pageNo === 1) {
      setSavedJobs(data?.data_payload);
    } else {
      setSavedJobs((prevItems) => [...prevItems, ...data?.data_payload]);
    }

    if (!selectedJobPostId || selectedJobPostId === "") {
      searchParams.set("job-post-id", data?.data_payload?.[0]?.job_post_id);
      setSearchParams(searchParams);
    }
    if (!selectedCompanyId || selectedCompanyId === "") {
      searchParams.set("company-id", data?.data_payload?.[0]?.company_user_details?.id);
      setSearchParams(searchParams);
    }
    setJobLoading(false);
    return;

  }

  const getjobById = async () => {
    setJobDetailsLoading(true);
    const jobDataList = await getJobPostById(selectedJobPostId);

    const company_data_list = await getCompanyById(selectedCompanyId);
    setCompany(company_data_list?.data);
    setJob(jobDataList?.data);
    setJobDetailsLoading(false);
  };

  useEffect(() => {
    if (selectedJobPostId || selectedJobPostId !== "" && selectedCompanyId || selectedCompanyId !== "") {
      getjobById();
    }
  }, [selectedJobPostId, selectedCompanyId])


  const abbreviateNumber = number => {
    const scaledNumber = number / 1000;
    return `${scaledNumber.toFixed(0)}k`;
  };



  const onJobsListScroll = () => {
    if (savedJobListRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = savedJobListRef.current;
      if (Math.ceil(scrollTop + clientHeight) === Math.ceil(scrollHeight) - 4) {
        setCurrPage(currPage + 1);
      }
    }
  };

  return (
    <>
      <div
        className="d-flex align-items-start gap-2 justify-content-center" style={{ marginTop: "30px", marginLeft: "51px" }}
      >
        <div className="profile_left_sidebar sticky_sidebar profile_left_sidebar_sticky ShifytScroll">
          <Sidebar />
        </div>
        <div
          className="col-lg-10 col-sm-12 col-md-12  font_inherit overflow-auto"
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "12px",
            flexWrap: "wrap",
            height: "80vh",
            marginLeft: "10px",
          }}
        >
          <div
            className="col-lg-4 col-md-5 col-sm-12 hide_scroll_bar"
            style={{ height: "100%" }}
          >
            <div className="saved_jobs">
              <div className="content-page">
                <div className="mt-15"
                  style={{
                    border: "1px solid #EEEEEE",
                    borderRadius: "10px",
                    overflow: "hidden",

                  }}
                >
                  <div className="box-filters-job mb-10"
                    style={{
                      padding: "8px",
                      background: "var(--theme-Shifyt-bg-color)",
                    }}
                  >
                    <ThemeProvider theme={globalTheme}>
                      <GlobalSearch sx={{ width: "100%", background: "#fff" }} />
                    </ThemeProvider>
                  </div>
                  <div className="job-list-list mb-15"
                    style={{
                      overflow: "auto",
                      height: "calc(80vh - 60px)",
                      padding: "5px"
                    }}
                    onScroll={onJobsListScroll}
                    ref={savedJobListRef}
                  >
                    {/* <div className="list-recent-jobs" id="list_jobs"> */}
                    <div>
                      {jobLoading ? (
                        <Stack
                          sx={{
                            height: "100%",
                          }}
                          justifyContent="center"
                          alignItems="center"
                        >
                          <CircularProgress color="success" />
                        </Stack>
                      ) : (
                        <>
                          {savedJobs?.length > 0
                            ? savedJobs?.map((item, index) => (
                              <React.Fragment>
                                <div
                                  className="latest_jobs_main_card_section mb-3"
                                  key={item?.job_post_id}
                                  style={{
                                    backgroundColor:
                                      selectedJobPostId ===
                                        item?.job_post_id
                                        ? "#cde2e7"
                                        : "#FFFFFF",
                                  }}
                                  ref={cardRef}
                                  onClick={() => {
                                    searchParams.set("job-post-id", item?.job_post_id);
                                    searchParams.set("company-id", item?.company_user_details?.id);
                                    setSearchParams(searchParams);
                                  }
                                  }
                                >
                                  <div className="latest_jobs_inside_section">
                                    <div className="title_section_1">
                                      <div className="title_name_logo_sec">
                                        <div
                                          style={{
                                            height: "44px",
                                            width: "44px",
                                            borderRadius: "50%",
                                            border:
                                              "0.5px solid rgba(0, 0, 0, 0.25)",
                                          }}
                                        >
                                          <Avatar
                                            src={`${imageURL}${item?.company_image}`}
                                            style={{
                                              borderRadius: "50%",
                                              borderColor: "rgba(0, 0, 0, 0.25)",
                                              height: "100%",
                                              width: "100%",
                                            }}
                                          >
                                            {item?.company_name?.charAt(0)}
                                          </Avatar>
                                        </div>
                                        <div className="title_sec_divider">
                                          <Typography
                                            fontWeight="md"
                                            textColor="primary.plainColor"
                                            mb={0.5}
                                            style={{
                                              cursor: "pointer",
                                              textTransform: "capitalize",
                                              fontSize: "15px",
                                              fontWeight: "600",
                                              color: "#121212",
                                              fontFamily: "Inter,sans-serif",
                                            }}
                                          // onClick={() => navigate(`../job/${item?.saved_job_post_id?.id}`)}
                                          >
                                            {item?.job_title}
                                            {/* {item?.job_title?.length <= 20 ? item?.job_title : item?.job_title.slice(0, 18) + ".."} */}
                                          </Typography>
                                          <Typography
                                            fontWeight="md"
                                            textColor="primary.plainColor"
                                            mb={0.5}
                                            style={{
                                              cursor: "pointer",
                                              textTransform: "capitalize",
                                              fontSize: "13px",
                                              fontWeight: "400",
                                              color: "#121212",
                                              opacity: "50%",
                                              fontFamily: "Inter,sans-serif",
                                            }}
                                          // onClick={() =>
                                          //   navigate(
                                          //     `../employer-single-list/${item?.saved_job_post_company?.id}`
                                          //   )
                                          // }
                                          >
                                            {item
                                              ?.company_name?.length <= 20
                                              ? item
                                                ?.company_name
                                              : item?.company_name.slice(
                                                0,
                                                18
                                              ) + ".."}
                                          </Typography>
                                        </div>
                                      </div>
                                      <div className="commission_sec">
                                        <div className="title_sec_divider">
                                          <Typography
                                            fontWeight="md"
                                            textColor="primary.plainColor"
                                            mb={0.5}
                                            sx={{
                                              cursor: "pointer",
                                              textTransform: "capitalize",
                                              fontSize: "13px",
                                              fontWeight: "500",
                                              color: "#121212",
                                              fontFamily: "Inter,sans-serif",
                                            }}
                                          >
                                            Earn Upto
                                          </Typography>
                                          <Typography
                                            fontWeight="md"
                                            textColor="primary.plainColor"
                                            mb={0.5}
                                            sx={{
                                              cursor: "pointer",
                                              textTransform: "capitalize",
                                              fontSize: "13px",
                                              fontWeight: "Bold",
                                              color: "#066a4c !important",
                                              fontFamily: "Inter,sans-serif",
                                            }}
                                          >
                                            {getSymbolFromCurrency(item.currency)}{" "}
                                            {abbreviateNumber(
                                              (item?.max_sal *
                                                item
                                                  ?.commission_rate) /
                                              100
                                            )}
                                          </Typography>
                                        </div>
                                      </div>
                                    </div>

                                    <div className="main_skills_sec">
                                      <div className="job_card_top_details_commision_cont">
                                        <div className="job_details"></div>
                                        <span className="mx-2"></span>
                                        <span className="card-job-top--price">
                                          {/* {item.time} */}
                                          <span></span>
                                        </span>
                                      </div>
                                      <div className="jobs_title_card">
                                        <Chip
                                          variant="solid"
                                          color="neutral"
                                          size="sm"
                                          style={{
                                            color: "#777AD6",
                                            background: "#F5ECFF",
                                          }}
                                        >
                                          {item?.job_type.replace(
                                            "_",
                                            " "
                                          )}
                                        </Chip>

                                        <Chip
                                          variant="solid"
                                          color="neutral"
                                          size="sm"
                                          icon={<LocationOnIcon />}
                                          style={{
                                            color: "#081753",
                                            background: "rgba(8, 23, 83, 0.07)",
                                          }}
                                        >
                                          {item?.location
                                            ?.length <= 17
                                            ? item?.location
                                            : item?.location.slice(
                                              0,
                                              35
                                            ) + ".."}
                                        </Chip>
                                        <Chip
                                          variant="solid"
                                          color="neutral"
                                          size="sm"
                                          style={{
                                            color: "#081753",
                                            background: "rgba(8, 23, 83, 0.07)",
                                          }}
                                        >
                                          {item?.min_exp}-
                                          {item?.max_exp}
                                          &nbsp; Yrs
                                        </Chip>
                                        <Chip
                                          variant="soft"
                                          color="neutral"
                                          size="sm"
                                          style={{
                                            background: "#F2F8F6",
                                            color: "#07A996",
                                          }}
                                        >
                                          {getSymbolFromCurrency(
                                            item?.currency
                                          )}
                                          {Math.round(
                                            item?.min_sal /
                                            1000,
                                            2
                                          )}
                                          k-
                                          {Math.round(
                                            item?.max_sal /
                                            1000,
                                            2
                                          )}
                                          k
                                        </Chip>
                                        <Chip
                                          variant="soft"
                                          color="neutral"
                                          size="sm"
                                          style={{
                                            background: "#F2F8F6",
                                            color: "#07A996",
                                          }}
                                        >
                                          {item?.work_mode.replace(
                                            "_",
                                            " "
                                          )}
                                        </Chip>
                                      </div>
                                    </div>

                                    <div
                                      className="skills_section"
                                      style={{ paddingLeft: "10px" }}
                                    >
                                      <ul
                                        className="skill_main_section"
                                        style={{
                                          cursor: "pointer",
                                          textTransform: "capitalize",
                                          fontSize: "14px",
                                          fontWeight: "500",
                                          color: "#121212",
                                          opacity: "50%",
                                          fontFamily: "Inter,sans-serif",
                                        }}
                                      >
                                        {item?.key_skills_details
                                          ?.slice(0, 3)
                                          .map(each => (
                                            <li
                                              key={each.id}
                                              className="typography-field"
                                              style={{ paddingRight: "10px" }}
                                            >
                                              {each?.name}
                                            </li>
                                          ))}
                                      </ul>
                                    </div>
                                    <div
                                      style={{
                                        paddingTop: "10px",
                                        padding: "10px",
                                      }}
                                    >
                                      <div className="time_section">
                                        <Typography
                                          fontWeight="md"
                                          textColor="primary.plainColor"
                                          mb={0.5}
                                          style={{
                                            cursor: "pointer",
                                            textTransform: "capitalize",
                                            fontSize: "12px",
                                            fontWeight: "400",
                                            color: "#121212",
                                            opacity: "50%",
                                            fontFamily: "Inter,sans-serif",
                                            paddingTop: "5px",
                                          }}
                                          className="date_posts"
                                        >
                                          Posted{" "}
                                          <span
                                            style={{
                                              color: "black",
                                              fontWeight: "700",
                                              fontSize: "12px",
                                            }}
                                          >
                                            {Math.floor(
                                              Math.abs(
                                                new Date() -
                                                new Date(
                                                  item?.createdAt
                                                    .slice(0, 10)
                                                    .toLocaleString()
                                                    .replace(/-/g, "/")
                                                )
                                              ) /
                                              (1000 * 3600 * 24)
                                            )}{" "}
                                            days
                                          </span>{" "}
                                          ago
                                        </Typography>

                                        <Typography
                                          fontWeight="md"
                                          textColor="primary.plainColor"
                                          mb={0.5}
                                          style={{
                                            cursor: "pointer",
                                            textTransform: "capitalize",
                                            fontSize: "13px",
                                            fontWeight: "400",
                                            color: "#121212",
                                            opacity: "50%",
                                            fontFamily: "Inter,sans-serif",
                                            paddingTop: "5px",
                                          }}
                                        >
                                          <span
                                            style={{
                                              color: "black",
                                              fontWeight: "700",
                                              fontSize: "12px",
                                            }}
                                          >
                                            {Math.ceil(
                                              Math.abs(
                                                new Date(
                                                  item?.application_deadline
                                                ) -
                                                new Date(
                                                  item?.createdAt
                                                )
                                              ) /
                                              (1000 * 60 * 60 * 24)
                                            )}{" "}
                                            days
                                          </span>{" "}
                                          left to apply
                                        </Typography>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </React.Fragment>
                            )) : (
                              <Box>
                                <Typography
                                  sx={{
                                    textAlign: "center",
                                    margin: "10px",
                                    color: "var(--text-color-one)"
                                  }}
                                >
                                  No data found
                                </Typography>
                              </Box>
                            )}
                        </>
                      )}

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-12 col-md-6 col-lg-7"
            style={{
              overflow: "auto"
            }}
          >
            <div className="job_description_sec mt-3">

              {jobDetailsLoading ? (
                <Stack
                  sx={{
                    height: "100%",
                  }}
                  justifyContent="center"
                  alignItems="center"
                >
                  <CircularProgress color="success" />
                </Stack>
              ) : (
                <>
                  {selectedJobPostId && selectedJobPostId !== "" && typeof Job === "object" && Object.keys(Job).length > 0 ? (
                    <JobDescription jobDetails={Job} company={company} />
                  ) : (
                    <div
                      style={{
                        display: "grid",
                        placeItems: "center",
                        height: "100%",
                        width: "100%",
                        marginTop: "70px",
                      }}
                    >
                      <div className="d-flex align-items-center flex-column">
                        <img
                          src={noJobs}
                          style={{ width: "300px", height: "300px" }}
                          alt="no jobs"
                        />
                        <p>No Job Selected </p>
                      </div>
                    </div>
                  )}
                </>
              )}

            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default MySavedJobs;
