import React, { useState, Fragment, useEffect } from "react";
import { Container } from "reactstrap";
import Sidebar from "../Accounts/Sidebar/Sidebar";
import Earningbonus from "./EarningBonus";
import EarningCandidateReport from "./EarningCandidateReport";
import EarningClientReport from "./EarningClientReport";
import EarningPartnerReport from "./EarningPartnerReport";
import WithdrawMoney from "../MyMoney/WithdrawMoney";
import "../../../src/assets/css/AboutUs/MyEarning/MyEarning.css";
import AspectRatio from "@mui/joy/AspectRatio";
import Card from "@mui/joy/Card";
import Typography from "@mui/joy/Typography";
import Chip from "@mui/joy/Chip";
import { FaWallet } from "react-icons/fa";
import FormControl from "@mui/joy/FormControl";
import FormHelperText from "@mui/joy/FormHelperText";
import RadioGroup from "@mui/joy/RadioGroup";
import Radio from "@mui/joy/Radio";
import Sheet from "@mui/joy/Sheet";
import { StyledEngineProvider, CssVarsProvider } from "@mui/joy/styles";
// import CloseIcon from '@material-ui/icons/Close';
import { ModalClose, ModalDialog } from "@mui/joy";
import { useNavigate } from "react-router-dom";
// import Modal from "react-bootstrap/Modal";
import { makeStyles } from "@mui/styles";
import Modal from "@mui/material/Modal";
import {
  getAllInterviewsList,
  getAllMyReferals,
} from "../../helpers/dataFetcher/jobs/jobs";
import {
  getEarning,
  getEarningTableData,
  getWithAmount,
  getWithTableData,
} from "../../helpers/dataFetcher/profile/update";
import moment from "moment";
import { Skeleton } from "@mui/material";
//TODO PUT A DEFAULT IMAGE AND LOADER HERE
import { styled } from "@mui/system";
import FeatherIcon from "feather-icons-react";
import { Link } from "react-router-dom";
import { Avatar } from "@mui/joy";
import { imageURL } from "../../Constants/commonURLS";
import { deepPurple } from "@mui/material/colors";
import { useForm } from "react-hook-form";
import { Col, Row } from "reactstrap";
import { Form, Input } from "reactstrap";
import {
  TextField,
  MenuItem,
  InputLabel,
  OutlinedInput,
  // FormControl,
  Grid,
  Box,
} from "@mui/material";
import {globalTheme} from '../../assets/MUI/globalStyles';
import { ThemeProvider } from "@mui/material/styles";
import Button from "@mui/joy/Button";
import { PieChart, Pie, Legend, Cell, ResponsiveContainer } from "recharts";
import { getPickersFadeTransitionGroupUtilityClass } from "@mui/x-date-pickers/CalendarPicker/pickersFadeTransitionGroupClasses";
import { AddModeratorSharp } from "@mui/icons-material";
import { MdLogin } from "react-icons/md";
import MyEarningsTabs from "./MyEarningsTabsTable";
import useWebSocket, { ReadyState } from "react-use-websocket";
import { useSelector } from "react-redux";
import webSocketUrl from "../../assets/sockets";
import toast from "react-hot-toast";
import SocketConnection from "../../hooks/socket";
import ComingSoon from '../../Reusable/comingSoon';
const grey = {
  50: "#F3F6F9",
  100: "#E7EBF0",
  200: "#E0E3E7",
  300: "#CDD2D7",
  400: "#B2BAC2",
  500: "#A0AAB4",
  600: "#6F7E8C",
  700: "#3E5060",
  800: "#2D3843",
  900: "#1A2027",
};
const Root = styled("div")(
  ({ theme }) => `
  table {
    font-family: IBM Plex Sans, sans-serif;
    font-size: 0.875rem;
    border-collapse: collapse;
    width: 100%;
  }

  td,
  th {
    border: 1px solid ${theme.palette.mode === "dark" ? grey[800] : grey[200]};
    text-align: left;
    padding: 6px;
  }

  th {
    background-color: ${theme.palette.mode === "dark" ? grey[900] : grey[100]};
  }
  `
);

const style = {
  position: "absolute",
  top: "40%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  background: "#FFFFFF",
  borderRadius: "8px",
  p: 4,
  "@media (max-width: 420px)": {
    width: 300,
    top: "50%",
  },
};

const useStyles = makeStyles({
  withdrawtxt: {
    fontFamily: "Inter,sans-serif !important",
    fontStyle: "normal",
    fontWeight: "600 !important",
    fontSize: "14px !important",
    color: "#000000 !important",
  },
  amountSpan: {
    fontFamily: "Inter,sans-serif !important",
    fontStyle: "normal",
    fontWeight: "500 !important",
    fontSize: "14px !important",
    lineHeight: "15px",
    color: "rgba(0, 0, 0, 0.7) !important",
    paddingLeft: "8px",
  },

  bankCard: {
    "&.css-a62g8g-JoySheet-root": {
      background: "rgba(8, 23, 83, 0.06) !important",
      borderRadius: "8px !important",
      color: "rgba(0, 0, 0, 0.7)",
      fontFamily: "Inter,sans-serif !important",
      fontStyle: "normal",
      fontWeight: "700 !important",
      fontSize: "14px !important",
      marginTop: "12px",
    },
  },
  accountDetails: {
    display: "flex",
    flexDirection: "column",
    gap: "3px",
    paddingLeft: "30px",

    "& > *": {
      color: "rgba(0, 0, 0, 0.7) !important",
      fontFamily: "Inter,sans-serif !important",
      fontStyle: "normal",
      fontWeight: "700 !important",
      fontSize: "14px !important",
    },
  },
  label: {
    color: "rgba(0, 0, 0, 0.7) !important",
    fontFamily: "Inter,sans-serif !important",
    fontStyle: "normal",
    fontWeight: "700 !important",
    fontSize: "14px !important",
    "&.css-1soe16i-JoyRadio-label": {
      color: "rgba(0, 0, 0, 0.7) !important",
      fontFamily: "Inter,sans-serif !important",
      fontStyle: "normal",
      fontWeight: "700 !important",
      fontSize: "14px !important",
    },
  },
  input: {
    "&.css-md26zr-MuiInputBase-root-MuiOutlinedInput-root": {
      height: "48px",

      border: "0.5px solid rgba(0, 0, 0, 0.24)",
      borderRadius: "8px",
    },
  },
});

function MyEarning() {
  const classes = useStyles();
  const [withDrawlModal, setWithDrawlModal] = useState(false);
  const [moneyData, setMoneyData] = useState();
  const [tableData, setTableData] = useState();
  const [withDrawTableData, setWithDrawTableData] = useState();
  const [withDrawData, setWithDrawData] = useState();
  const [status, setStatue] = useState(true);
  const [earningsCount, setEarningsCount] = useState(0);
  const { lastMessage } = SocketConnection();
  const userData = useSelector(state => state.User);
  const navigate = useNavigate();
  const [selectedValue, setSelectedValue] = useState("");

  const handleRadioChange = event => {
    setSelectedValue(event.target.value);
  };

  const [pieData, setPieData] = useState({
    TotalCount: "",
    AvailCount: "",
    WithDrawCount: "",
  });
  const [seshu, setSeshu] = useState([
    {
      count: 30000,
      language: "Total",
    },
    {
      count: 0,
      language: "Available",
    },
    {
      count: 0,
      language: "Withdrew",
    },
  ]);

  const data = [
    {
      count: 30000,
      language: "Total",
    },
    {
      count: 0,
      language: "Available",
    },
    {
      count: 0,
      language: "Withdrew",
    },
  ];

  const handleCloseModal = () => setWithDrawlModal(!withDrawlModal);
  const {
    register,
    handleSubmit,
    setValue,
    control,
    getValues,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    fetchData();
  }, []);

  /*socket integration for earning page start */

  useEffect(() => {
    if (
      lastMessage?.data !== undefined ||
      lastMessage?.data !== null ||
      lastMessage?.data !== ""
    ) {
      const resultant = lastMessage?.data;
      if (resultant !== undefined) {
        const data = JSON.parse(resultant);
        if (data?.type === "myearning") {
          fetchData();
        }
      }
    }
  }, [lastMessage]);

  /*socket integration for earning page End */

  /*getEarningTableData function is used for getting earnings table data */
  async function getTableData() {
    const { data } = await getEarningTableData();
    const finalTableData = data.data?.map(item => {
      return {
        firstName: item.earnings_user_job_post.first_name,
        lastName: item.earnings_user_job_post.last_name,
        date: item.createdAt,
        email: item.earnings_user_job_post.email,
        finalCommission: item.final_commisson_received_value,
      };
    });
    setTableData(finalTableData);
  }

  /*getWithTableData function is used for getting withdrawns table data */
  async function getWithdrawTableData() {
    const { data } = await getWithTableData();
    const finalTableData = data.data?.map(item => {
      return {
        firstName: item.earnings_user_job_post.first_name,
        lastName: item.earnings_user_job_post.last_name,
        date: item.createdAt,
        email: item.earnings_user_job_post.email,
        finalCommission: item.final_commisson_received_value,
      };
    });
    setWithDrawTableData(finalTableData);
  }

  /*getEarning function is used for getting amounts data start*/
  async function fetchData() {
    const { data, status } = await getEarning();
    setMoneyData(data.data);
    getTableData();
    getWithdrawTableData();
  }
  /*getEarning function is used for getting amounts data End*/

  /* Amount withdrawn from modal start */
  const onSubmit = async Adata => {
    console.log(typeof parseInt(Adata.amount));
    const availableAmount = moneyData.earnings_amount;
    const requestingAmount = parseInt(Adata.amount);
    if (requestingAmount <= availableAmount) {
      const { data, status } = await getWithAmount(parseInt(Adata.amount));
      setWithDrawData([data.data]);
    } else {
      toast.error("Insufficient Amount");
    }
  };
  /* Amount withdrawn from modal end */

  return (
    <>
      {/* <div className="mb-3 profile_main_cont">
        <Container fluid className="chat_main_cont" style={{ paddingRight: "0px", paddingLeft: "0px",marginTop:"30px" }}>
          <div className="row">
            <div className="col-sm-12 col-md-2 col-lg-2 profile_left_sidebar">
            <div className="profile_left_sidebar sticky_sidebar profile_left_sidebar_sticky">
              <Sidebar />
            </div>
            </div>
            <div className="col-sm-12 col-md-10 col-lg-10">
              <div className="row">
                <div className="col-sm-12 col-md-4 col-lg-4">
                  <h4 className="leadership-text text-center pt-4">Insights</h4>
                  <p className="leadership-desc text-center">
                    Overall activities on your Accounts
                  </p>
                </div>
                <div className="col-sm-12 col-md-8 col-lg-8">
                  <div className="leadership-bg p-4 mb-4">
                    <div className="row align-items-center">
                      <div className="col-sm-12 col-md-6 col-lg-6">
                        <h4 className="leadership-text text-center">0</h4>
                        <p className="leadership-desc text-center">
                          Total members signup
                        </p>
                      </div>
                      <div className="col-sm-12 col-md-6 col-lg-6 border-left">
                        <div className="text-center">
                          <h4 className="leadership-text text-center text-primary">
                            $ 0.000 USD
                          </h4>
                          <p className="leadership-desc text-center">
                            Money WithDrawed
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12 col-md-10 col-lg-10">
                  <div className="leadership-bg p-4 mb-4">
                    <div className="row align-items-center">
                      <div className="col-sm-12 col-md-6 col-lg-6">
                        <h4 className="leadership-text text-center ">
                          Withdraw fund
                        </h4>
                      </div>
                      <div className="col-sm-12 col-md-6 col-lg-6 border-left">
                        <div className="text-center">
                          <h4 className="leadership-text text-center text-primary">
                            $ 0.000 USD
                          </h4>
                          <p className="leadership-desc text-center">
                            Total commission earned
                          </p>
                        </div>
                      </div>
                    </div>

                    <WithdrawMoney />
                  </div>
                </div>
              </div>
              <div className="mb-4">
                <EarningClientReport />
              </div>
              <div className="mb-4">
                <h5 className="mb-3">Your Candidate Report</h5>
                <EarningCandidateReport />
              </div>
              <div className="mb-4">
                <EarningPartnerReport />
              </div>
              <div className="mb-4">
                <Earningbonus />
              </div>
            </div>
          </div>
        </Container>
      </div>  */}

      

      <div className="mb-3 profile_main_cont ">
        <Container
          fluid
          className="chat_main_cont"
          style={{ paddingRight: "0px", paddingLeft: "0px", marginTop: "30px",marginLeft:"51px"}}
        >
          <div className="row">
            <div className="col-sm-12 col-md-2 col-lg-2 profile_left_sidebar">
              <div className="profile_left_sidebar sticky_sidebar profile_left_sidebar_sticky ShifytScroll">
                <Sidebar />
              </div>
            </div>
            <div className="col-sm-12 col-md-10 col-lg-10">
              {/* <ComingSoon /> */}
              <div className="myearnings_main_cont">
                <div className="myearnings_card_main_cont">
                  <Box className="earning_cards">
                    <div className="earnings_inside_cont">
                      <div className="amount_sec">
                        <div className="dummy_earn_card"></div>
                        <h3 className="amount_txt">Total Earnings</h3>
                      </div>
                      <h3 className="amount_num">2999/-</h3>
                    </div>
                  </Box>

                  <Box className="earning_cards">
                    <div className="earnings_inside_cont">
                      <div className="amount_sec">
                        <div className="dummy_earn_card"></div>
                        <h3 className="amount_txt">Withdraw Funds</h3>
                      </div>
                      <div className="amount_sec2">
                        <h3 className="amount_num2">87,999/-</h3>
                        <FeatherIcon
                          icon="log-out"
                          className="amount_icon"
                          onClick={() => setWithDrawlModal(true)}
                        />
                      </div>
                    </div>
                  </Box>

                  <Box className="earning_cards">
                    <div className="earnings_inside_cont">
                      <div className="amount_sec">
                        <div className="dummy_earn_card"></div>
                        <h3 className="amount_txt">Pending Funds</h3>
                      </div>
                      <div className="amount_sec2">
                        <h3 className="amount_num2">3,999/-</h3>
                       
                      </div>
                    </div>
                  </Box>


                  {withDrawlModal ? (
                    <>
                      <Modal
                        open={withDrawlModal}
                        onClose={handleCloseModal}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                      >
                        <Box sx={{...style,top: "50%"}}>
                          <ModalClose
                              onClick={() => setWithDrawlModal(false)}
                            variant="outlined"
                            sx={{
                              top: "calc(-1/4 * var(--IconButton-size))",
                              right: "calc(-1/4 * var(--IconButton-size))",
                              boxShadow: "0 2px 12px 0 rgba(0 0 0 / 0.2)",
                              borderRadius: "50%",
                              bgcolor: "background.body",
                            }}
                         
                          />
                          <Typography
                            component="h2"
                            className={classes.withdrawtxt}
                          >
                            Withdraw Amount
                          </Typography>

                          <Box
                            component="form"
                            sx={{
                              "& .MuiTextField-root": { width: "90%" },
                              marginTop: "8px",
                            }}
                            // noValidate
                            autoComplete="off"
                          >
                            <div>
                              <ThemeProvider theme={globalTheme}>
                              <Col>
                               
                                <OutlinedInput
                                  placeholder="Amount"
                                  className={classes.input}
                                  fullWidth
                                  type="number"
                                  autoComplete="email"
                                  autoFocus
                                />
                              </Col>
                              </ThemeProvider>
                              <div style={{ marginTop: "8px" }}>
                                <FaWallet />
                                <span className={classes.amountSpan}>
                                  Withdrawable Amount :
                                  <span className={classes.withdrawtxt}>
                                    2165/-
                                  </span>
                                </span>
                              </div>

                              <div className="bank_details_cards">
                                {[0, 1].map((each, index) => (
                                  <StyledEngineProvider injectFirst>
                                    <CssVarsProvider>
                                      <Sheet
                                        variant="outlined"
                                        sx={{
                                          boxShadow: "sm",
                                          borderRadius: "sm",
                                          p: 1,
                                        }}
                                        className={classes.bankCard}
                                      >
                                        <RadioGroup
                                          name={`tiers-${each}`}
                                          value={selectedValue}
                                          onChange={handleRadioChange}
                                          sx={{ gap: 1, "& > div": { p: 1 } }}
                                        >
                                          <FormControl>
                                            <Radio
                                              overlay
                                              value={`medium-${each}`}
                                              label="State Bank of India"
                                              className={classes.label}
                                            />
                                            <div
                                              className={classes.accountDetails}
                                            >
                                              <FormHelperText>
                                                A/C : 8734*******7589
                                              </FormHelperText>
                                              <FormHelperText>
                                                IFSC Code : 38******DB
                                              </FormHelperText>
                                              <FormHelperText>
                                                Hyderabad, India
                                              </FormHelperText>
                                            </div>
                                          </FormControl>
                                        </RadioGroup>
                                      </Sheet>
                                    </CssVarsProvider>
                                  </StyledEngineProvider>
                                ))}
                              </div>

                              <div className="withbtn_cont">
                                <Button
                                  variant="contained"
                                  onClick={() => navigate("../settings")}
                                  style={{
                                    background: "#FFFFFF",
                                    color: "#081854",
                                    fontFamily: "Inter ,sans-serif",
                                    fontWeight: "600",
                                    fontSize: "14px !important",
                                    textAlign: "center",
                                    // marginTop: "10px",
                                    border: "0.5px solid #CDCDCD",
                                    borderRadius: "8px !important",
                                  }}
                                >
                                  Add Bank Details
                                </Button>

                                <Button
                                  variant="contained"
                                  style={{
                                    background: "#081753",
                                    color: "#FFFFFF",
                                    fontFamily: "Inter ,sans-serif",
                                    fontWeight: "600",
                                    fontSize: "14px !important",
                                    textAlign: "center",
                                    // marginTop: "10px",
                                    border: "0.5px solid rgba(0, 0, 0, 0.24)",
                                    borderRadius: "8px !important",
                                  }}
                                >
                                  Withdraw Now
                                </Button>
                              </div>
                            </div>
                          </Box>
                        </Box>
                      </Modal>
                    </>
                  ) : null}
                </div>

                <div style={{ paddingTop: "20px" }}>
                  <MyEarningsTabs
                    tableData={tableData}
                    withDrawTableData={withDrawTableData}
                  />
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </>
  );
}

export default MyEarning;
