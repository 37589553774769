import React, { useRef, useEffect, useState } from 'react';
import { Grid, Stack, Typography, Box, Button, Divider } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import ArrowRight from '../../../../assets/icons/LandingPage/ArrowRight';
import ReferContent from '../../../../assets/icons/LandingPage/ReferContent.svg';
import MouseIcon from '../../../../assets/icons/LandingPage/MouseIcon';
import PenIcon from '../../../../assets/icons/LandingPage/PenIcon';
import JetPlaneIcon from '../../../../assets/icons/LandingPage/JetPlaneIcon';

function ReferAndEarnSection() {

    return (
        <Grid container sx={{ paddingTop: "50px", paddingBottom: "50px", }}>
            <Grid item xs={12} sm={12} >
                <Stack sx={{ gap: "10px" }}>
                    <Link to="#"
                        style={{
                            color: "#1877F2",
                            fontWeight: "500",
                            fontSize: "15px",
                            cursor: "ponter",
                            textAlign: "center"
                        }}
                    >HOW IT WORK</Link>
                    <Typography
                        sx={{
                            fontSize: "48px",
                            fontWeight: "500",
                            textAlign: "center"
                        }}
                    >Refer & Earn</Typography>
                </Stack>
            </Grid>
            <Grid
                item
                xs={12}
                sm={12}
                mt={3}
                sx={{
                    position: "relative",
                    background: "#F7F9FF",
                    display: "grid",
                    gap: "70px",
                    gridTemplateColumns: "repeat(2, 1fr)",
                    padding: "70px",
                    borderRadius: "20px",
                    "@media (max-width: 900px)": {
                        gridTemplateColumns: "repeat(1, 1fr)",
                        padding: "30px",
                        gap: "20px",

                    },
                }}
            >
                <Stack
                    spacing={4}
                >
                    {[
                        { id: "1", icon: MouseIcon, iconColor: "#B5E4CA", title: "Refer a friend", desc: "By uploading your friends resume and email and share the link" },
                        { id: "2", icon: PenIcon, iconColor: "#FFBC99", title: "Sit back relax", desc: "Now your friend will go through interview process ( Screening )" },
                        { id: "3", icon: JetPlaneIcon, iconColor: "#CABDFF", title: "Get paid", desc: "After a successful interview you will get commission from joinee" },
                    ]?.map((item, index) => (
                        <>
                            <Stack
                                key={index}
                                direction="row"
                                sx={{
                                    gap: "10px"
                                }}
                            >
                                <Stack
                                    sx={{
                                        width: "20%",
                                        minWidth: "100px"
                                    }}
                                >
                                    <Box
                                        sx={{
                                            height: "60px",
                                            width: "60px",
                                            borderRadius: "50%",
                                            display: "flex",
                                            background: item?.iconColor,
                                            justifyContent: "center",
                                            alignItems: "center"
                                        }}
                                    >
                                        {item?.icon()}
                                    </Box>
                                </Stack>
                                <Stack
                                    sx={{
                                        width: "80%",
                                        minWidth: "300px",
                                        gap: "8px"
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            fontSize: "24px",
                                            fontWeight: "600"
                                        }}
                                    >{item?.title}</Typography>
                                    <Typography
                                        sx={{
                                            fontSize: "16px",
                                            fontWeight: "500",
                                            color: "#808D9E"
                                        }}
                                    >{item?.desc}</Typography>
                                </Stack>
                            </Stack>
                            {
                                item?.id !== "3" && (
                                    <Divider variant="middle"
                                        sx={{
                                            border: "0.0px solid #E9ECF2",
                                            // height: "0.3px",
                                            "& hr:not([size])": {
                                                height: "0.5px"
                                            }
                                        }} />
                                )
                            }

                        </>
                    ))}
                </Stack>
                <Stack
                    justifyContent="space-between"
                    sx={{
                        gap: "20px",
                        paddingBottom: "10px",
                    }}
                >
                    <Stack>
                        <Typography
                            sx={{
                                fontWeight: "500",
                                fontSize: "40px",
                            }}
                        >Start referring & earn money</Typography>
                        <Typography
                            sx={{
                                fontWeight: "500",
                                fontSize: "15px",
                            }}
                        >Nunc et tellus non erat accumsan aliquam eget non mi. Integer fringilla pellentesque finibus.</Typography>
                    </Stack>
                    <Stack
                        direction="row"
                        justifyContent="space-between"
                    >
                        <Box>
                            <Typography
                                sx={{
                                    fontWeight: "500",
                                    fontSize: "24px",
                                }}
                            >Earned $50k</Typography>
                            <Typography
                                sx={{
                                    fontWeight: "500",
                                    fontSize: "15px",
                                    color: "#808D9E"
                                }}
                            >Commission from joinee</Typography>

                        </Box>
                        <Box>
                            <Button
                                type="submit"
                                size="large"
                                onClick={() => { console.log("Fired...") }}
                                loading={false}
                                loadingPosition="end"
                                variant="contained"
                                sx={{
                                    borderRadius: "50px",
                                    boxShadow: "none",
                                    background: "var(--app-button-color1)",
                                    padding: "8px 30px",
                                    "&:hover": {
                                        boxShadow: "none",
                                        background: "var(--app-button-color1)",
                                    }
                                }}
                            >
                                <span style={{ textTransform: "capitalize", fontWeight: "700" }}>Refer now</span>
                            </Button>
                        </Box>
                    </Stack>
                </Stack>
            </Grid>
        </Grid>
    )
}

export default ReferAndEarnSection;