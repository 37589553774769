import React from "react";
import { Box, CircularProgress } from "@mui/material";
import { Suspense } from "react";
import { Outlet, useLocation, useNavigate, Navigate } from "react-router-dom";
import GlobalNavbar from "../layouts/Navabar/GlobalNavbar";

export default function PrivateOutlet() {
    const navigate = useNavigate();
    const location = useLocation();
    const token = localStorage.getItem("token");

    if (token === "" || token === null || token === undefined) {
       return <Navigate to="/login" state={{ from: location }} replace />;
    }

    if (token) {
        return (
            <React.Fragment>
                <GlobalNavbar />
                <main className="">
                    <Suspense
                        fallback={
                            <Box
                                sx={{
                                    display: "flex",
                                    height: "100vh",
                                    width: "100vw",
                                    alignItems: "center",
                                    justifyContent: "center",
                                }}
                            >
                                <CircularProgress />
                            </Box>
                        }
                    >
                        <Outlet />
                    </Suspense>
                </main>
            </React.Fragment>
        )
    }


}
