export default function Frame1() {
    return (
        <svg width="211" height="106" viewBox="0 0 211 106" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_814_24527)">
                <path d="M32.9251 8.80041C28.473 4.67937 23.642 1.86085 18.4216 2.07959C11.9918 2.32785 8.84012 8.98339 15.2413 15.7767C19.129 19.7077 23.303 23.3448 27.729 26.6579L29.768 19.6504C22.1949 13.9713 14.9084 7.59858 16.9045 5.59398C20.0493 2.65209 25.8931 7.57369 31.4735 13.7891L32.9251 8.80041ZM205.941 70.8844C162.601 79.0273 84.9761 54.1375 54.9456 35.425C48.5804 28.8965 41.3177 16.4698 32.9251 8.80041L31.4659 13.8151C36.7885 19.8 41.8573 26.9968 43.886 29.3955C42.4237 28.5461 36.0474 24.289 29.7718 19.6374L27.7403 26.6189C38.1668 34.622 49.2414 41.7432 60.8496 47.9085C69.2921 57.98 83.4294 69.1152 100.154 76.8922L100.484 76.1969C88.3206 69.7022 77.2418 61.3547 67.6449 51.4539C108.797 70.2522 170.736 81.8041 206.289 72.5538L205.941 70.8844Z" fill="#FFC046" />
            </g>
            <defs>
                <clipPath id="clip0_814_24527">
                    <rect width="205" height="49.7442" fill="white" transform="matrix(-0.960178 -0.279389 -0.279389 0.960178 210.734 57.2754)" />
                </clipPath>
            </defs>
        </svg>
    );
}
