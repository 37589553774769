import React, { useState } from "react";
import MyModal from "./components/Modal";
import OtpInput from "react18-input-otp";
import CountdownTimer from "../../Authentication/CountDown/CountdownTimer";
import { verifyOTP } from "../../helpers/dataFetcher/otpVerifiy/verify";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";

export default function OtpVerificationModal({ open, handleClose, email }) {
    const navigate = useNavigate();
    const [otp, setOtp] = useState("");
    const [hasError, setHasError] = useState(false);
    const handleChange = code => setOtp(code);
    const handleOTP = async () => {
        const { status } = await verifyOTP(email, otp);
        if (status !== 201) {
            setHasError(true);
            toast.error("invalid otp");
            return;
        }
        handleClose();
        navigate("/login");
        setHasError(false);
    };
    return (
        <MyModal
            open={open}
            handleClose={handleClose}
            sx={{ height: "fit-content" }}
            showCloseIcon
            title="Enter OTP"
            size="medium"
        >
            <div className="d-flex justify-content-center pb-0 py-4">
                <OtpInput
                    value={otp}
                    onChange={handleChange}
                    numInputs={6}
                    shouldAutoFocus
                    isInputNum={true}
                    inputStyle="otp_input_items"
                    errorStyle={"otp_input_items_error"}
                    hasErrored={hasError}
                    focusStyle={"otp_input_items_focus"}
                    separator={<span> </span>}
                    onSubmit={handleOTP}
                />
            </div>

            <CountdownTimer email={email} totalSec={20 * 10000} />

            <div className="d-flex justify-content-end">
                <button
                    type="button"
                    className="btn btn-primary"
                    onClick={handleOTP}
                    style={{
                        background: "#cde2e7",
                        color: "#024430",
                        border: "none",
                        fontWeight: "bold"
                    }}
                >
                    Verify OTP
                </button>
            </div>
        </MyModal>
    );
}
