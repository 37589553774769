import React, { useEffect, useState } from "react";
import Button from "@mui/joy/Button";
import { Col, Form, Row } from "reactstrap";
import Modal from "@mui/material/Modal";
import { useSelector } from "react-redux";
import { getAllKeySkills } from "../../../helpers/dataFetcher/jobAttributes/keyskills";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import { Box, Divider, Grid, TextField, Typography } from "@mui/material";
const GOOGLE_MAPS_API_KEY = "AIzaSyDk52HPcdIZWt2glyeWGUyn9hiqAsGMJvk";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import Stack from "@mui/material/Stack";
import "../../../assets/css/applyForJob/index.css";
import { debounce } from "@mui/material/utils";
import { applyForJob } from "../../../helpers/dataFetcher/jobs/jobs";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import ModalClose from "@mui/joy/ModalClose";
import { ModalDialog } from "@mui/joy";
import FeatherIcon from "feather-icons-react";
import { Link } from "react-router-dom";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { getUserInfo } from "../../../helpers/dataFetcher/profile/profile";
import SocketConnection from "../../../hooks/socket";
import { globalTheme } from '../../../assets/MUI/globalStyles';
import { ThemeProvider } from "@mui/material/styles";
import MuiCustomTelInput from "../../../components/formElements/PhoneNumber/PhoneNumber";

function loadScript(src, position, id) {
  if (!position) {
    return;
  }

  const script = document.createElement("script");
  script.setAttribute("async", "");
  script.setAttribute("id", id);
  script.src = src;
  position.appendChild(script);
}

const autocompleteService = { current: null };

export default function CandidateDetails({
  show,
  onHide,
  setStep,
  details,
  resumeDetails,
  purpose,
  screeningQuestions,
  setScreeningQuestions,
}) {
  // const {sendMessage:emitSocket} = SocketConnection()
  const {
    lastMessage,
    sendMessage: sendSocketMessage,
    connectionStatus,
  } = SocketConnection();
  const [applyStatus, setApplyStatus] = useState(false);
  const [location, setLocation] = React.useState(null);
  const [inputValue, setInputValue] = React.useState("");
  const [userInfo, setUserInfo] = useState(null);
  const loaded = React.useRef(false);
  const filter = createFilterOptions();
  const navigate = useNavigate();
  if (typeof window !== "undefined" && !loaded.current) {
    if (!document.querySelector("#google-maps")) {
      loadScript(
        `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&libraries=places`,
        document.querySelector("head"),
        "google-maps"
      );
    }

    loaded.current = true;
  }

  useEffect(() => {
    (async () => {
      const res = await getUserInfo();
      const { data } = res?.data?.data_payload;
      setUserInfo(data);
    })();
  }, []);

  const fetch = React.useMemo(
    () =>
      debounce((request, callback) => {
        autocompleteService.current.getPlacePredictions(request, callback);
      }, 400),
    []
  );

  React.useEffect(() => {
    let active = true;

    if (!autocompleteService.current && window.google) {
      autocompleteService.current =
        new window.google.maps.places.AutocompleteService();
    }
    if (!autocompleteService.current) {
      return undefined;
    }

    if (inputValue === "") {
      setOptions(location ? [location] : []);
      return undefined;
    }

    fetch({ input: inputValue }, results => {
      if (active) {
        let newOptions = [];

        if (location) {
          newOptions = [location];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [location, inputValue, fetch]);

  const [about, setAbout] = useState("");
  const user = useSelector(state => state.User.user);

  useEffect(() => {
    (async () => {
      const res = await getUserInfo();

      const  data  = res?.data?.data_payload;
      setUserInfo(data);
    })();
    handleSkills();
  }, []);

  const [options, setOptions] = useState([]);
  const [skillOptions, setSkillOptions] = useState([]);
  async function handleSkills() {
    const { data } = await getAllKeySkills();
    const skills = data?.data_payload?.map(item => ({ id: item.id, title: item.name }));
    setSkillOptions(skills);
  }
  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  const schema = yup
    .object({
      // phone_number: yup.string().min(8).max(14),
      email: yup.string().email().required(),
      first_name: yup.string().required(),
      last_name: yup.string().required(),
      // dob: yup.date().required(),
      // location: yup.object().required(),
      skills: yup.array().required(),
    })
    .required();

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    getValues,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      work_exp: [
        { designation: "", employer: "", start_date: "", end_date: "" },
      ],
      courses: [
        {
          certificate: "",
          institute: "",
          Specialization: "",
          start_date: "",
          end_date: "",
        },
      ],
    },
  });
  console.log(watch("phone_number")); // watch input value by passing the name of it
  console.log(errors);
  const { fields, append, remove, replace, update } = useFieldArray({
    control,
    name: "work_exp",
  });
  const {
    replace: courseReplace,
    update: courseUpdate,
    fields: courseFields,
    append: courseAppend,
    remove: courseRemove,
  } = useFieldArray({
    control,
    name: "courses",
  });

  useEffect(() => {
    if (resumeDetails) {
      if (resumeDetails?.work_exp?.length > 0) {
        remove(0);
      }
      if (resumeDetails?.courses?.length > 0) {
        courseRemove(0);
      }
      setValue("first_name", resumeDetails.first_name);
      setValue("skills", resumeDetails.skills);
      setValue("last_name", resumeDetails.last_name);
      setValue("email", resumeDetails.email);
      setValue("phone_number", resumeDetails.phone_number);
      // setValue("location", resumeDetails.location);
      // setValue("dob", resumeDetails.dob);
      resumeDetails?.work_exp?.map(item => {
        append(item);
      });
      resumeDetails?.courses?.map(item => {
        courseAppend(item);
      });
    }
  }, [resumeDetails]);
  const onSubmit = async data => {
    if (!data?.phone_number?.startsWith("+")) {
      data.phone_number = `+${data?.phone_number}`;
    }

    const result = await applyForJob(
      {
        ...data,
        phone_number: data?.phone_number,
        about_this_job: "about",
        location: data?.location?.description,
        job_apply_type: purpose,
        skills: data?.skills.map(item => item.id),
        resume: [userInfo?.resume || userInfo?.resume_url],
        // screeningQuestions:screeningQuestions
        company_id: details?.company_id

      },
      details?.id
    );

    setScreeningQuestions(result?.data);
    setApplyStatus(true);
    // setStep(1);

    // if (status === 201) {
    if (result.status === 201) {
      setApplyStatus(false);
      // onHide(false);
      toast.success("applied for job");
      setTimeout(() => {
        // setStep(4)

        if (purpose === "refer") {
          setStep(1);
          onHide();
        } else {
          setStep(4);
        }
      }, 1000);
    } else {
      setApplyStatus(false);
      onHide(false);
      if (typeof result.data.message === "string")
        toast.error(result.data.message);
      else {
        toast.error(result.data.message);
      }
      // if (typeof messageData === "string") toast.error(messageData);
      // else {
      //   toast.error(messageData?.message);
      // }
    }
  };

  console.log(details, "detailsdetails")
  

  return (
    <>
      <Modal
        open={!!show}
        onClose={() => {
          onHide(false);
          setStep(1);
        }}
        backdrop="static"
        size="lg"
        // className="apply_modal"
        keyboard={false}

      >
        {/* {status === 200 ? ( */}
        <ModalDialog
          className="apply_modal"
          size="lg"
          aria-labelledby="layout-modal-title"
          aria-describedby="layout-modal-description"
          layout={"center" || undefined}
          sx={{ background: "white" }}
        >
          <Typography
            id="layout-modal-title"
            component={Link}
            level="inherit"
            to={`../job/${details?.id}`}
            className="text_headling_p d-flex align-items-center gap-2 justify-content-start"
            fontSize="1.25em"
            mb="0.25em"
          >
            <FeatherIcon icon="briefcase" size="14" />
            {details?.job_title}
          </Typography>
          <ModalClose
            onClick={() => {
              onHide(false);
              setStep(1);
            }}
            variant="outlined"
            sx={{
              top: "calc(-1/4 * var(--IconButton-size))",
              right: "calc(-1/4 * var(--IconButton-size))",
              boxShadow: "0 2px 12px 0 rgba(0 0 0 / 0.2)",
              borderRadius: "50%",
              bgcolor: "background.body",

            }}
          />
          <Form
            style={{
              overflowY: "scroll",
            }}
            onSubmit={handleSubmit(onSubmit)}
            className="candidate_details_fields"
          >
            <Row
              style={{
                width: "100%",
                overflowY: "scroll",
                overflowX: "hidden",
              }}
            >
              <ThemeProvider theme={globalTheme}>
                <Col className="mt-4" xs={12} md={6}>
                  <TextField
                    variant="outlined"
                    size="small"
                    className="form-control"
                    label="first Name"
                    type="text"
                    {...register("first_name", { required: true })}
                  ></TextField>
                </Col>
                <Col className="mt-4" xs={12} md={6}>
                  <TextField
                    variant="outlined"
                    size="small"
                    label="last Name"
                    className="form-control"
                    type="text"
                    {...register("last_name", { required: true })}
                  />
                </Col>
                <Col xs={12} md={6} className="mt-4">
                  <TextField
                    variant="outlined"
                    size="small"
                    label="Email"
                    className="form-control"
                    disabled={purpose === "self" ? true : false}
                    error={errors.email ? true : false}
                    {...register("email", { required: true })}
                    type="email"
                    placeholder="Email"
                  />
                </Col>
                <Col className="mt-4" xs={12} md={6}>
                  <MuiCustomTelInput
                    defaultCountry="IN"
                    fullWidth
                    countryCodeEditable={true}
                    value={getValues("phone_number")}
                    onChange={e => {
                      setValue("phone_number", e);
                    }}
                    error={errors.phone_number ? true : false}
                    helperText={"Phone number must be valid"}
                    InputProps={{
                      style: {
                        borderRadius: "8px",
                        height: "48px",
                      },
                    }}
                  />
                </Col>
              </ThemeProvider>
              <Col xs={12} md={12} className="mt-4">
                <Stack
                  direction="row"
                  justifyContent="flex-end"
                  alignItems="center"
                >

                  {applyStatus ? (
                    <Button loading>Loading</Button>
                  ) : (
                    <Button
                      type="submit"
                      style={{
                        background: "#cde2e7",
                        color: "#066a4c",
                      }}
                      className="mt-2"
                    >
                      Submit
                    </Button>
                  )}
                </Stack>
              </Col>
            </Row>
          </Form>
        </ModalDialog>
      </Modal>
    </>
  );
}
