import React, { useState, useEffect } from "react";
import "./login.css";
import TextField from "@mui/material/TextField";
import { Modal } from "react-bootstrap";
import Box from "@mui/material/Box";
import { useForm } from "react-hook-form";
import { updatePassword } from "../helpers/dataFetcher/profile/update";
import {
  updateEmail,
  sendOtpForMail,
} from "../helpers/dataFetcher/profile/update";
import OtpInput from "react18-input-otp";
import CountdownTimer from "./CountDown/CountdownTimer";
import { verifyMobileOTP } from "../helpers/dataFetcher/otpVerifiy/verify";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { getProfile } from "../helpers/dataFetcher/profile/profile";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import { ThemeProvider } from "@mui/material/styles";
import { globalTheme } from "../assets/MUI/globalStyles";

function ChangeEmail() {
  const [userDetails, setUserDetails] = useState();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    reset,
    getValues,
    formState: { errors },
  } = useForm();

  const userData = useSelector(state => state.User);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const { data } = await getProfile();
    setUserDetails(data);
    console.log("userData", data.email);
  };

  const obj = {
    profile_image: userData.user.profile_image,
    last_name: userData.user.last_name,
    first_name: userData.user.first_name,
    email: userDetails?.email,
    dob: userData.user.dob,
    gender: userData.user.gender,
    resume_file: userData.user.resume_file,
    phone_number: userDetails?.phone_number,
    country: userData.user.country,
    state: userData.user.state,
    city: userData.user?.city,
    gender: userData.user?.gender,
    postal_code: userData.user?.postal_code,
    address: userData.user?.address?.description,
    description: userData.user?.about,
    update_type_to_send_email: "email",
  };
  let o = Object.fromEntries(Object.entries(obj).filter(([_, v]) => v != null));

  const [otpModalView, setOtpModalView] = useState(false);
  const [otp, setOtp] = useState("");
  const [hasError, setHasError] = useState(false);
  const handleChange = code => setOtp(code);

  /*Verify otp and update function */
  const handleOTP = async e => {
    const { status } = await verifyMobileOTP(otp);

    /*if (status !== 201) {
      setHasError(true);
      toast.error("invalid otp");
      return;
    } else if (status == 200){
        const newEmail = getValues("newEmail")
        const {data,status} = await updatePassword(newEmail)
    }*/

    if (status === 200) {
      const newEmail = getValues("newEmail");
      const { data, status } = await updateEmail("about_me", o, newEmail);
      if (status === 200) {
        toast.success("Updated Successfully!!");
        reset()
      } else if (status !== 200) {
        toast.error("Something went wrong");
        reset()
        return;
      }
    }

    setOtpModalView(false);
    // navigate("/profile");
    setHasError(false);
  };
  const curr_email = userData?.email
  /*send otp to new email function */
  const changeEmail = async ChangeEmailData => {
    console.log(ChangeEmailData, "email");
    const oldEmail = ChangeEmailData?.oldEmail
    const newMail = ChangeEmailData?.newEmail
    const confirmMail = ChangeEmailData?.confirmEmail
    if (curr_email === oldEmail && newMail === confirmMail) {
      const { status } = await sendOtpForMail("email", ChangeEmailData.newEmail);
      setOtpModalView(true);
    } else {
      toast.error("Incorrect email")
    }
    // const { status } = await sendOtpForMail("email", ChangeEmailData.newEmail);
    // setOtpModalView(true);
  };

  return (
    <ThemeProvider theme={globalTheme}>
    <div className="main_container">
      <h1 className="password_heading">Email</h1>
      <p className="password_paragraph">
        Please enter your current email to change your email
      </p>
      {/* <hr /> */}
      <Box
        component="form"
        onSubmit={handleSubmit(changeEmail)}
        // noValidate
        sx={{ mt: 1 }}
        className="form_container"
      >
      
          <div className="bank_card_cont">
            <p>Current Email</p>
            <div className="bank_input_filed_card">
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Old email"
                name="email"
                type="email"
                autoComplete="email"
                // error={errors.oldPassword ? true : false}
                {...register("oldEmail", { required: true })}
                InputProps={{
                  style: { borderRadius: "5px" },
                }}
              />
            </div>
          </div>
          {/* <hr /> */}
          <div className="bank_card_cont">
            <p>New Email</p>
            <div className="bank_input_filed_card">
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="New email"
                name="email"
                type="email"
                autoComplete="email"
                // error={errors.oldPassword ? true : false}
                {...register("newEmail", { required: true })}
                InputProps={{
                  style: { borderRadius: "5px" },
                }}
              />

            </div>
          </div>
          {/* <hr /> */}
          <div className="bank_card_cont">
            <p>Confirm Email</p>
            <div className="bank_input_filed_card">
              {/* <TextField
              margin="normal"
              required
              // fullWidth
              label="Confirm password"
              name="email"
              type="password"
              autoComplete="password"
              autoFocus
              // error={errors.oldPassword ? true : false}
              {...register("oldPassword", { required: true })}
              InputProps={{
                style: { borderRadius: "5px", width: "30vw" },
              }}
            /> */}

              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Confirm email"
                name="email"
                type="email"
                autoComplete="email"
                // error={errors.oldPassword ? true : false}
                {...register("confirmEmail", { required: true })}
                InputProps={{
                  style: { borderRadius: "5px" },
                }}
              />
            </div>

          </div>
          {/* <hr /> */}
          <div className="d-flex justify-content-center submit-button mt-4">
            <Stack spacing={2} direction="row">
              <Button
                variant="contained"
                type="submit"
                style={{
                  // background: "#081753",
                  // color: "#ffffff",
                  background: "#cde2e7",
                  color: "#066a4c",
                  textTransform: "capitalize",
                }}
              >
                Update
              </Button>
            </Stack>
          </div>
      
      </Box>
      {otpModalView ? (
        <>
          <Modal
            // {...props}
            // onClick={()=>setOtpModalView(false)}
            show={otpModalView}
            backdrop="static"
            className="custom-delete-modal mt-5 pt-5"
            keyboard={false}
          >
            <Modal.Header
              className="border-0 px-4 bg-transparent"
              onClick={() => setOtpModalView(false)}
              closeButton
            >
              OTP Verify
            </Modal.Header>
            <Modal.Body
              className="pt-0"
              style={{ padding: "30px", backgroundColor: "#bebebe" }}
            >
              <div className="d-flex justify-content-center pb-0 py-4">
                <OtpInput
                  value={otp}
                  onChange={handleChange}
                  numInputs={6}
                  shouldAutoFocus
                  isInputNum={true}
                  inputStyle="otp_input_items"
                  errorStyle={"otp_input_items_error"}
                  hasErrored={hasError}
                  focusStyle={"otp_input_items_focus"}
                  separator={<span> </span>}
                  onSubmit={handleOTP}
                />
              </div>

              <CountdownTimer email={email} totalSec={20 * 10000} />

              <div className="d-flex justify-content-end">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={handleOTP}
                  style={{
                    background: "#cde2e7",
                    color: "#024430",
                    border: "none",
                    fontWeight: "bold"
                  }}
                >
                  Verify OTP
                </button>
              </div>
            </Modal.Body>
          </Modal>
        </>
      ) : (
        ""
      )}
    </div>
    </ThemeProvider>
  );
}

export default ChangeEmail;
