import React, { useState, useEffect } from "react";
import { Col, Container, Row } from "reactstrap";
import { useSelector } from "react-redux";
import Sidebar from "../Accounts/Sidebar/Sidebar";
import { Stack, CircularProgress } from "@mui/material";
import JobsListChat from "./JobsListChat";
// import chatImg from "../../assets/images/chat.svg";
import chatImg from "../../assets/images/chatShiyft2.png";
import NoDataFoundComponent from "../../Nodatafound/NoDataFoundReusable";
import "./DemoChat.css";
import {
  findChatBasedOnId,
  findContactsBasedOnUserId,
  getAllChats,
  sendUserMessages,
} from "../../helpers/dataFetcher/chats/chats";
import { getAllAppliedJobsList } from "../../helpers/dataFetcher/chats/chats";
import JobDrawChatDescription from "../BrowseJobsList/JobDrawChatDescription";
import { getJobDetailsById } from "../../helpers/dataFetcher/jobs/jobs";
import { useNavigate, useSearchParams } from "react-router-dom";
import SocketConnection from "../../hooks/socket";
import { get_chat_active_user_list } from "../../helpers/sockets";

function DemoChat() {
  const [postImg, setPostImg] = useState("");
  const [modelOpen, setModelOpen] = useState(false);
  const [jobs, setJobs] = useState("");
  const [jobId, setJobId] = useState("");
  const [userId, setUserId] = useState("");

  const [jobLoading, setJobLoading] = useState(true);
  const [jobDetailsLoading, setJobDetailsLoading] = useState(true);

  const [searchParams, setSearchParams] = useSearchParams();
  const [currPage, setCurrPage] = useState(1);
  const [mount, setMount] = useState(false);

  const pageSize = 15;
  const searchFilter = searchParams.get("q");
  const selectedJobPostId = searchParams.get("job-post-id");



  const [appliedJobs, setAppliedJobs] = useState([]);

  const userData = useSelector(state => state.User);

  useEffect(() => {
    setUserId(userData.user.id);
  }, [userData]);


  const toggleModel = () => {
    setModelOpen(!modelOpen);
  };

  const handleImageUpload = async data => {
    const url = await getPreSignedURL(data);
    setPostImg(url);
    toggleModel();
  };



  jQuery(document).ready(function () {
    $(".company_chat_list a").click(function () {
      $(".chatbox").addClass("showbox");
      return false;
    });

    $(".chat-icon").click(function () {
      $(".chatbox").removeClass("showbox");
    });
  });

  useEffect(() => {
    if(!mount) {
      setMount(true);
      return;
    }
    getAllJobs();
    if(searchFilter && searchFilter !== "") {
      setCurrPage(1);
      setAppliedJobs([]);
    }
  }, [searchFilter, currPage]);


  async function getAllJobs() {
    setJobLoading(true);
    const { data, status } = await getAllAppliedJobsList(currPage, pageSize, searchFilter ?? "");

    if (!data?.data_payload?.length) {
      setJobLoading(false);
      return;
    }

    if (data?.pageNo === 1) {
      setAppliedJobs(data?.data_payload);
    } else {
      setAppliedJobs((prevItems) => [...prevItems, ...data?.data_payload]);
    }
    if (!selectedJobPostId || selectedJobPostId === "") {
      searchParams.set("job-post-id", data?.data_payload[0].job_post_id);
      setSearchParams(searchParams);
    }
    setJobLoading(false);
    return;
  }

  useEffect(() => {
    if (selectedJobPostId && selectedJobPostId !== "") {
      getjobById();
    }
    localStorage.setItem("jobId", jobId)
  }, [selectedJobPostId]);

  const getjobById = async () => {
    setJobDetailsLoading(true);
    const { data, status } = await getJobDetailsById(selectedJobPostId);
    if (status === 200) {
      setJobs(data?.data_payload)
      setJobDetailsLoading(false);
    }
    setJobDetailsLoading(false);
  }


  return (
    <>
      <Container
        fluid
        className="chat_main_cont"
        style={{
          paddingRight: "0px",
          paddingLeft: "0px",
          marginTop: "30px",
          marginLeft: "51px",
        }}
      >
        <div className="row">
          <div className="col-sm-12 col-md-0 col-lg-2">
            {/* <Sidebar /> */}
            <div className="profile_left_sidebar sticky_sidebar profile_left_sidebar_sticky ShifytScroll">
              <Sidebar />
            </div>
          </div>
          <div
            className="col-sm-12 col-md-12 col-lg-10"
            style={{ paddingLeft: "28px", height: "80vh", overflowY: "scroll" }}
          >
            {/* {appliedJobs?.length > 0 ? (
              <> */}
            <div className="demochat_main_cont">
              <div className="col-sm-12 col-md-6 col-lg-4 jobsPageWidth">
                <JobsListChat
                  appliedJobs={appliedJobs}
                  setCurrPage={setCurrPage}
                  currPage={currPage}
                  jobLoading={jobLoading}
                />
              </div>

              <div className="col-sm-12 col-md-6 col-lg-8">
                {jobDetailsLoading ? (
                  <Stack
                    sx={{
                      height: "100%",
                    }}
                    justifyContent="center"
                    alignItems="center"
                  >
                    <CircularProgress color="success" />
                  </Stack>
                ) : (
                  <>
                    {jobs ? (
                      <div
                        className="col-12 col-md-11 col-lg-11"
                        style={{ marginTop: "33px", marginLeft: "10px" }}
                      >
                        <div className=" mt-3">
                          <JobDrawChatDescription jobDetails={jobs} />
                        </div>

                      </div>
                    ) : (
                      <div className="chat_main_box_cont">
                        <div
                          style={{
                            display: "grid",
                            placeItems: "center",
                            height: "100%",
                            width: "100%",
                          }}
                        >
                          <div className="d-flex flex-column justify-content-center align-items-center gap-2 pt-4 mt-3">
                            <div className="contact_no_show">
                              <img src={chatImg} alt="chat" />
                              <p>Joinee Chat for Web</p>
                              <span className="pat-20 end_to_end">
                                End to End Encrypted
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </>
                )}

              </div>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
}

export default DemoChat;
