import { Box, Stack, IconButton, CircularProgress } from "@mui/material";
import { Link } from "react-router-dom";
import React from "react";
import MyModal from "./components/Modal";
import { useState } from "react";
import { imageURL } from "../../Constants/commonURLS";
import { PdfButton } from "../../assets/MUI/globalStyles";
import DownloadIcon from "@mui/icons-material/Download";

export default function ShowChatAttachmentModal({
  open,
  handleClose,
  edit,
  setOpen,
  msg
}) {


  const [loading, setLoading] = useState(
    (() => {
      if(msg?.message?.endsWith("pdf") || msg?.message?.endsWith("json") || msg?.message?.endsWith("docx")) {
       return true
      } else {
        return false
      }
    })()
  );

  const handleIframeLoad = () => {
      setLoading(false);
  };

  return (
    <MyModal
      open={open}
      setOpen={setOpen}
      handleClose={handleClose}
      size="medium"
      sx={{
        width: "fit-content",
        height: "fit-content",
        maxWidth: "60vw",
        padding: "0px",
        position: "relative"
      }}
    >
      {loading && (
        <Stack
          sx={{
            height: "100%",
          }}
          justifyContent="center"
          alignItems="center"
        >
          <CircularProgress color="success" />
        </Stack>
      )}
      <Stack>
        {(() => {
          if (msg.message.endsWith("pdf")) {
            return (
              <iframe width={"100%"} height={"100%"}
                style={{
                  borderRadius: "5px",
                  display: loading ? 'none' : 'block'
                }}
                onLoad={handleIframeLoad}
                src={`${imageURL}${msg.message}`}>
              </iframe>
            );
          } else if (msg.message.endsWith("png") || msg.message.endsWith("jpeg")) {
            return (
              <img
                src={`${imageURL}${msg.message}`}
                style={{ width: "100%" }}
              />
            );
          } else if (msg.message.endsWith("svg")) {
            return (
              <img
                src={`${imageURL}${msg.message}`}
                style={{ width: "100%" }}
              />
            );
          } else if (msg.message.endsWith("json")) {
            return (
              <iframe width={"100%"} height={"100%"}
                style={{
                  borderRadius: "5px",
                  display: loading ? 'none' : 'block'
                }}
                onLoad={handleIframeLoad}
                src={`${imageURL}${msg.message}`}>
              </iframe>
            );
          } else if (msg.message.endsWith("docx")) {
            return (
              <iframe width={"100%"} height={"100%"}
                style={{
                  borderRadius: "5px",
                  display: loading ? 'none' : 'block'
                }}
                onLoad={handleIframeLoad}
                src={`${imageURL}${msg.message}`}>
              </iframe>
            );
          }
        })()}
      </Stack>
    </MyModal>
  );
}
