import React from 'react';
import { Grid } from "@mui/material";
import NewFooter from '../../layouts/footer/NewFooter';
import JoinBoxSection from '../Careers/JoinBoxSection';
import TeamSection from "./Components/TeamSection"
import CompanyHired from './Components/CompanyHired';
import AboutBannerSection from './Components/AboutBannerSection';
import MissionSection from './Components/ MissionSection';

function AboutPage() {
    return (
        <React.Fragment>
            <Grid container
                sx={{
                    background: "linear-gradient(120deg,rgba(250, 204, 21, 0.08), #ffffff 75%, rgba(219, 248, 231, 0.48))",
                    minHeight: "100vh",
                    margin: "auto",
                    position: "relative",
                    zIndex: "1",
                    "&::before": {
                        content: "''",
                        position: "absolute",
                        top: "0",
                        bottom: "0",
                        width: "100%",
                        zIndex: "-1",
                        opacity: "0.5",
                        background: "linear-gradient(to right, rgba(0, 0, 0, 0.1) 1px, transparent 1px) 0 0/10px 100%, linear-gradient(to right, rgba(0, 0, 0, 0.1) 1px, transparent 1px) 25% 0/10px 100%, linear-gradient(to right, rgba(0, 0, 0, 0.1) 1px, transparent 1px) 50% 0/10px 100%, linear-gradient(to right, rgba(0, 0, 0, 0.1) 1px, transparent 1px) 75% 0/10px 100%",
                        // background: "repeating-linear-gradient(to right, rgba(0, 0, 0, 0.1) 1px, transparent 1px, transparent 5px) 0 0/10px 100%",
                        backgroundRepeat: "no-repeat"
                    }
                }}
            >
                {/* Bnner Section */}
                <Grid item xs={12} sm={12}
                    sx={{
                        paddingLeft: "5%",
                        paddingRight: "5%",
                    }}
                >
                    <AboutBannerSection />
                </Grid>

                {/* Hired  section */}
                <Grid item xs={12} sm={12}
                    sx={{
                        paddingLeft: "5%",
                        paddingRight: "5%",
                    }}
                >
                    <CompanyHired />
                </Grid>

                {/* Mission section */}
                <Grid item xs={12} sm={12}
                    sx={{
                        paddingLeft: "5%",
                        paddingRight: "5%",
                        background: "#fafafa78"
                    }}
                >
                    <MissionSection />
                </Grid>

                {/* Team section */}
                <Grid item xs={12} sm={12}
                    sx={{
                        paddingLeft: "5%",
                        paddingRight: "5%",
                    }}
                >
                    <TeamSection />
                </Grid>
            </Grid>
            <JoinBoxSection />
            <NewFooter />
        </React.Fragment >
    )
}

export default AboutPage