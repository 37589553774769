import React, { useRef, useState, useEffect } from 'react';
import { Grid, Stack, Typography, Box, Divider, Button, InputAdornment, CircularProgress, IconButton, FormControlLabel, Radio } from "@mui/material";
import GoogleIcon from '../../../assets/icons/LandingPage/GoogleIcon';
import AppleLogoIcon from '../../../assets/icons/LandingPage/AppleLogoIcon';
import TextField from '../../../components/formElements/TextField';
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { Link, useNavigate } from "react-router-dom";
import { useGoogleLogin } from '@react-oauth/google';
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import {
    getUserByToken,
    getUserWithToken,
    login,
} from "../../../helpers/dataFetcher/profile/profile";
import { useDispatch } from "react-redux";
import { addUserAction } from "../../../store/auth/actions";

function LoginAuthForm() {
    const [showPassword, setShowPassword] = useState(false);
    const [loginStatus, setLoginStatus] = useState(false);
    const [error, setError] = useState(false);
    const [rememberMe, setRememberMe] = useState(false);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {
        register,
        handleSubmit,
        setValue,
        watch,
        getValues,
        formState: { errors },
    } = useForm();


    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const LoginWithGoogle = useGoogleLogin({
        onSuccess: tokenResponse => console.log(tokenResponse),
    });

    useEffect(() => {
        const userCreds = JSON.parse(localStorage.getItem("user_creds"));
        if (userCreds) {
            setValue("email", userCreds?.email);
            setValue("password", userCreds?.password);
            setRememberMe(true);
        }
    }, [])




    const loginFunc = async data => {
        setLoginStatus(true);
        const { status, data: loginObj } = await login({
            email: data?.email,
            password: data?.password,
        });
        if (status !== 200) {
            setError(true);
            toast.error("Invalid Credentials");
            setLoginStatus(false);
            return;
        }
        setLoginStatus(false);
        setUserDetailsForActions(loginObj);
        if (rememberMe) {
            localStorage.setItem("user_creds", JSON.stringify(data));
        } else {
            localStorage.removeItem("user_creds");
        }
        return;
    };

    async function setUserDetailsForActions(
        loginObj,
        type = "normal",
        loginType = "normal"
    ) {
        navigate("/");
        localStorage.setItem("token", loginObj.access);
        localStorage.setItem("user_id", loginObj.id);
        localStorage.setItem("user_type", loginObj.usertype);

        const { status: userStatus, data: userData } = await getUserWithToken({
            Authorization: "Bearer " + loginObj.access,
        });
        if (userStatus == 200) {
            dispatch(
                addUserAction({
                    ...userData?.data_payload,
                    access: loginObj.access,
                    type: type,
                    loginType: loginType,
                })
            );
        }
    }


    return (
        <React.Fragment>
            <Box
                sx={{
                    minWidth: "300px",
                    width: "60%",
                    '@media (max-width:1200px)': {
                        width: "70%",
                    },
                    '@media (max-width: 900px)': {
                        width: "90%",
                    },
                    '@media (max-width: 600px)': {
                        width: "95%",
                    },
                }}
            >
                <form onSubmit={handleSubmit(loginFunc)}>
                    <Stack
                        sx={{
                            width: "100%",
                            gap: "35px",
                        }}
                    >
                        <Stack>
                            <Typography
                                sx={{
                                    fontWeight: "600",
                                    fontSize: "30px",
                                    textAlign: "center",
                                }}
                            >Sign in to Joinee</Typography>
                            <Typography
                                sx={{
                                    fontWeight: "400",
                                    fontSize: "16px",
                                    textAlign: "center",
                                    color: "#737373"
                                }}
                            >Sign in as Job Seeker</Typography>
                        </Stack>
                        <Stack
                            direction="column"
                            spacing={2}
                        >
                            <Stack
                                direction="row"
                                justifyContent="space-between"
                                alignItems="center"
                                spacing={3}
                            >
                                <Button component="label" variant="text" startIcon={<GoogleIcon />}
                                    onClick={LoginWithGoogle}
                                    sx={{
                                        border: "1px solid #DFE1E6",
                                        color: "#000",
                                        borderRadius: "8px",
                                        width: "50%"
                                    }}
                                >
                                    <Box sx={{
                                        textTransform: "none",
                                        fontWeight: "600",
                                        fontSize: "14px",
                                    }}>
                                        Sign In with Google
                                    </Box>
                                </Button>
                                <Button component="label" variant="text" startIcon={<AppleLogoIcon />}
                                    sx={{
                                        border: "1px solid #DFE1E6",
                                        color: "#000",
                                        borderRadius: "8px",
                                        width: "50%"
                                    }}
                                >
                                    <Box sx={{
                                        textTransform: "none",
                                        fontWeight: "600",
                                        fontSize: "14px",
                                    }}>
                                        Sign In with Apple
                                    </Box>
                                </Button>
                            </Stack>
                            <Stack>
                                <Divider sx={{ fontSize: "11px" }}>Or with email</Divider>
                            </Stack>
                            <Stack
                                direction="column"
                                spacing={1.5}
                            >
                                <Box>
                                    <TextField
                                        id="email"
                                        name="email"
                                        type="email"
                                        placeholder="Email or username"
                                        autoFocus
                                        error={errors?.email?.type === "required" ? true : false}
                                        useFormRef={{ ...register("email", { required: true, pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i }) }}
                                        helperText={
                                            errors?.email?.type === "required" ? "Email or username is required" : errors?.email?.type === "pattern" ? "Invalid value" : null
                                        }
                                    />
                                </Box>
                                <Box>
                                    <TextField
                                        id="password"
                                        name="password"
                                        type={showPassword ? "text" : "password"}
                                        placeholder="Password"
                                        error={errors?.password?.type === "required" ? true : false}
                                        useFormRef={{ ...register("password", { required: true }) }}
                                        helperText={
                                            errors?.password?.type === "required" ? "Password is required" : null
                                        }
                                        endAdornment={
                                            <InputAdornment position="end"
                                                sx={{
                                                    paddingRight: "10px"
                                                }}
                                            >
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowPassword}
                                                    edge="end"
                                                    size="small"
                                                >
                                                    {showPassword ? (
                                                        <VisibilityOff fontSize="inherit" />
                                                    ) : (
                                                        <Visibility fontSize="inherit" />
                                                    )}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                    />
                                </Box>
                                <Stack
                                    direction="row"
                                    justifyContent="space-between"
                                    alignItems="center"
                                    spacing={3}
                                >
                                    <Box>
                                        <FormControlLabel
                                            sx={{
                                                '& .MuiSvgIcon-root': {
                                                    fontSize: 17,
                                                    color: "var(--app-button-color1)"
                                                },
                                                '& .MuiFormControlLabel-label': {
                                                    fontSize: "13px",
                                                    fontWeight: "700"
                                                }

                                            }}
                                            control={
                                                <Radio
                                                    checked={rememberMe}
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        setRememberMe(!rememberMe)
                                                    }}
                                                    value={rememberMe}
                                                />
                                            }
                                            label="Remember me"
                                        />
                                    </Box>
                                    <Box>
                                        <Link to="../forget-password">
                                            <span
                                                style={{
                                                    fontSize: "12px",
                                                    fontWeight: "700",
                                                    color: "#000"
                                                }}
                                            >Forgot Password?</span>
                                        </Link>
                                    </Box>
                                </Stack>
                            </Stack>
                        </Stack>
                        <Stack>

                            <Button
                                variant="outlined"
                                type="submit"
                                style={{
                                    fontSize: "16px",
                                    border: "0px",
                                    color: "#fff",
                                    background: "#044B36",
                                    borderRadius: "50px",
                                    padding: "7px"
                                }}
                                disabled={loginStatus}
                            >
                                {loginStatus ? <CircularProgress color="success" size={25} /> : (
                                    <span
                                        style={{
                                            textTransform: "none",
                                            fontWeight: "600",
                                            fontSize: "14px",
                                        }}
                                    >
                                        Sign In
                                    </span>
                                )}


                            </Button>
                        </Stack>
                        <Stack>
                            <Typography
                                sx={{
                                    fontSize: "15px",
                                    fontWeight: "400",
                                    color: "#737373",
                                    textAlign: "center"
                                }}
                            >
                                Don’t have an account?
                                <Link to="../registration"
                                    style={{
                                        fontWeight: "700",
                                        color: "#000"
                                    }}
                                > Sign Up
                                </Link>
                            </Typography>

                        </Stack>
                    </Stack>
                </form>
            </Box>
        </React.Fragment>
    )
}

export default LoginAuthForm