import React, { useState, useEffect } from "react";
import DashBoardPage1 from "./DashBoardPage1";
import DashBoardPage2 from "./DashBoardPage2";
import {
    getAllMyReferals,
    getAllSelfReferedJobs,getAllReferedJobs
  } from "../../../helpers/dataFetcher/jobs/jobs";

function DashBoardMain() {
  const [status, setStatus] = useState(false);
  const [referJob, setReferJob] = useState("");
  const [appliedJobs,setAppliedJobs] = useState("")
  const [byRefer,setByRefer] = useState("")
  const [page,setPage] = useState(1)
  const [pageSize,setPageSize] = useState(10)
  const search = ''

  useEffect(() => {
    getAllJobs();
  }, []);

  async function getAllJobs() {
    const myReferData = await getAllMyReferals(page,pageSize,search);
    const appliedData = await getAllSelfReferedJobs(page,pageSize,search);
    const byRefer = await getAllReferedJobs(page,pageSize,search);
    setReferJob(myReferData.data?.overall_count);
    setAppliedJobs(appliedData.data?.overall_count)
    setByRefer(byRefer?.data?.overall_count)
    if (status === 200) {
      setStatus(true);
    }
  }

  return (
    <>
      <div className="dashboard_main_sec">
        <div className="col-sm-12 col-md-12 col-lg-12">
          <DashBoardPage1 referJob={referJob} appliedJobs={appliedJobs}  byRefer={byRefer}/>
        </div>
        <div className="col-sm-12 col-md-12 col-lg-12">
          <DashBoardPage2 />
        </div>
      </div>
    </>
  );
}

export default DashBoardMain;
