import React from "react";
import {
  PrimaryJoineeBox,
  GreyTypography,
  BlackTypography,
  SubscribeInputEle,
  PrimaryJoineeButton,
} from "../../../assets/MUI/materialUiStyles";
import { Stack, Grid, Box } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

function Questions() {

  const faqData = [
    {
      id: "1",
      que: "What makes Joinee different from other job platforms?",
      ans: "Joinee stands out due to its streamlined approach. For companies, we offer a simplified hiring process with a vast pool of candidates. For job seekers, we provide a user-friendly interface and genuine job listings"
    },
    {
      id: "2",
      que: "How does the referral program work?",
      ans: "Joinee stands out due to its streamlined approach. For companies, we offer a simplified hiring process with a vast pool of candidates. For job seekers, we provide a user-friendly interface and genuine job listings"
    },
    {
      id: "3",
      que: "Are there specific industries or job types supported by Joinee?",
      ans: " Joinee stands out due to its streamlined approach. For companies, we offer a simplified hiring process with a vast pool of candidates. For job seekers, we provide a user-friendly interface and genuine job listings"
    },
    {
      id: "4",
      que: "How secure is the data shared on Joinee's platform?",
      ans: " Joinee stands out due to its streamlined approach. For companies, we offer a simplified hiring process with a vast pool of candidates. For job seekers, we provide a user-friendly interface and genuine job listings"
    },
    {
      id: "5",
      que: "Can companies try out Joinee's services before committing?",
      ans: " Joinee stands out due to its streamlined approach. For companies, we offer a simplified hiring process with a vast pool of candidates. For job seekers, we provide a user-friendly interface and genuine job listings"
    },
  ]

  return (
    <Grid container sx={{ paddingTop: "50px", paddingBottom: "100px" }} >
      <Grid lg={12} md={12} sm={12}>
        <Stack
          sx={{
            margin: "auto",
            width: "60%",
            "@media (max-width: 900px)": {
              width: "100%",
            },
          }}
        >
          <Typography
            sx={{
              textAlign: "center",
              fontSize: "40px",
              fontWeight: "500",
            }}
          >
            Frequently asked questions about joinee
          </Typography>

          <Stack
            sx={{
              border: "1px solid",
              borderRadius: "10px",
              overflow: "hidden",
            }}
            mt={3}
          >
            {faqData?.map((item, index) => (
              <Accordion
                sx={{
                  boxShadow: "none",
                }}
                key={index}
                id={`accord_${item?.id}`}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls={`accord_${item?.id}`}
                  id={`accord_${item?.id}`}
                  sx={{
                    padding: "10px 15px"
                  }}
                >
                  <Typography sx={{ fontWeight: "600", fontSize: "14px" }}>Q. {item?.que}</Typography>
                </AccordionSummary>
                <AccordionDetails
                  id={`accord_${item?.id}`}
                >
                  <Typography
                    sx={{ fontSize: "13px" }}
                  >
                    {item?.ans}
                  </Typography>
                </AccordionDetails>
              </Accordion>
            ))}
          </Stack>
          <Stack
            justifyContent="center"
            alignItems="center"
            p={2}
          >
            <Box
              sx={{
                border: "1px solid #066a4c",
                color: "#fff",
                padding: "8px 15px",
                fontSize: "12px",
                fontWeight: "700",
                borderRadius: "50px",
                background: "#066a4c",
                cursor: "pointer",
                "&:hover": {
                  background: "#fff",
                  color: "#000",
                }
              }}
            >Still, have any questions? Contact us</Box>
          </Stack>
        </Stack>
      </Grid>
    </Grid>
  );
}

export default Questions;
