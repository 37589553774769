import { combineReducers } from "redux";
import modalsReducer from "../components/modals/modalsSlice";

import ProfileSidebar from "./Layout/reducers";
import CompanyReducer from "./company/reducers";
import JobApplyStatusReducer from './JobApplyStatus/reducer';

import User from "./auth/reducers";
const rootReducer = combineReducers({
  ProfileSidebar :ProfileSidebar,
  User: User,
  CompanyReducer: CompanyReducer,
  JobApplyStatusReducer: JobApplyStatusReducer,
  modals: modalsReducer,
});

export default rootReducer;
