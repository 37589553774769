export const formatdate = obj => {
    const dateString = obj;
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "short" });
    const year = date.getFullYear();

    const formattedDate = `${day} - ${month} - ${year}`;
    return formattedDate;

  };
