export default function HireIcon() {
    return (
        <svg  width="224" height="169" viewBox="0 0 233 234" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_814_23904)">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M86.1134 71.5225C86.1134 71.5225 85.2281 69.3044 85.983 67.2726L92.8145 62.8829L94.4362 71.1031L88.4807 72.4452L86.1134 71.5225Z" fill="#FFB7E7" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M98.2955 52.9779C98.2955 52.9779 92.5358 65.9141 91.3615 68.5796C91.231 68.8592 90.9327 69.0363 90.6344 68.999C89.6092 68.8965 87.3352 68.6728 86.0863 68.5423C85.8719 68.5237 85.6856 68.4025 85.5644 68.2254C85.4433 68.0484 85.406 67.8247 85.4712 67.6196C86.4032 64.4508 89.6839 53.36 89.6839 53.36L96.5992 50.9928L98.2955 52.9779Z" fill="#FFDB3B" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M108.755 147.49C111.104 148.422 116.397 150.248 123.508 151.022L125.261 144.75L109.361 143.743C109.351 143.734 109.016 145.057 108.755 147.49Z" fill="#EC78C6" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M130.398 151.387C135.254 151.368 140.65 150.762 146.326 149.15L145.469 143.735L137.649 141.936L128.655 146.596C128.655 146.596 129.289 149.308 130.398 151.387Z" fill="#EC78C6" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M141.449 198.783C141.449 198.783 141.626 204.692 141.617 207.469C141.608 207.684 141.692 207.889 141.85 208.047C141.999 208.196 142.204 208.289 142.419 208.28C143.425 208.271 145.009 208.252 145.009 208.252L144.786 203.182L142.102 199.333L141.449 198.783Z" fill="#2658A8" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M108.757 147.484C108.561 149.274 108.412 151.669 108.477 154.567C108.636 161.408 109.027 186.703 108.552 190.095C108.086 193.488 102.969 200.114 96.1563 204.532L96.0352 209.145L101.403 209.649L122.476 196.172C122.476 196.172 121.423 187.849 121.656 183.105C121.87 178.371 126.595 161.408 123.063 152.601L123.51 150.998C116.399 150.243 111.115 148.416 108.757 147.484Z" fill="#FFB7E7" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M146.326 149.153C140.65 150.765 135.254 151.371 130.398 151.39C130.715 152.005 131.078 152.555 131.488 152.974C131.488 152.974 131.852 161.092 134.452 168.119C137.062 175.156 142.029 192.444 142.029 192.444L142.561 197.104L148.628 208.344L158.619 209.658L160.52 206.014C160.52 206.014 154.751 194.802 153.633 191.708C152.738 189.276 148.236 161.176 146.326 149.153Z" fill="#FFB7E7" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M96.1547 204.531C96.1547 204.531 92.7529 206.469 91.9514 209.088C91.1499 211.707 93.5172 215.416 105.605 212.276C117.199 209.265 119.268 204.139 120.452 208.976C120.554 209.358 120.918 209.591 121.309 209.535C122.316 209.368 124.171 209.069 125.14 208.911C125.503 208.855 125.774 208.538 125.774 208.165C125.755 206.506 125.671 202.005 125.252 199.349C124.721 196.031 123.062 193.477 120.126 195.863C117.199 198.24 99.0812 212.322 96.1547 204.531Z" fill="#1B3E77" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M141.627 191.12C141.627 191.12 139.549 192.966 140.024 196.013C140.5 199.061 142.531 199.779 143.286 202.64C144.051 205.501 144.284 208.782 146.753 211.177C149.223 213.572 154.722 213.843 158.804 213.032C162.886 212.221 163.856 209.704 160.435 205.837C160.435 205.837 152.765 209.881 148.822 206.61C144.88 203.339 145.598 199.937 144.899 197.654C144.19 195.37 142.774 195.091 142.056 192.947L142.037 192.481L141.627 191.12Z" fill="#1B3E77" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M111.148 108.86C111.148 108.86 106.554 123.594 107.243 140.026C107.243 140.026 105.146 142.384 107.281 145.273C109.424 148.153 135.958 151.918 147.981 143.53C147.981 143.53 148.689 122.886 139.509 109.279L120.319 106.744L111.148 108.86Z" fill="#3471D2" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M136.978 114.151C140.016 114.682 142.039 113.648 142.039 113.648C142.039 113.648 142.551 114.16 140.333 110.544C140.072 110.106 139.792 109.677 139.513 109.258L120.323 106.723L111.161 108.848C111.161 108.848 111.115 108.997 111.031 109.286L110.677 110.554L111.646 111.402C111.646 111.402 112 111.756 112.699 112.222L113.072 112.464C113.286 112.585 113.491 112.706 113.696 112.818L114.051 113.005C114.395 113.172 114.768 113.34 115.169 113.499C115.579 113.657 116.036 113.816 116.52 113.946C117.406 114.188 118.412 114.375 119.531 114.449L119.652 114.459L120.314 114.477C121.553 114.487 122.914 114.356 124.396 114.021C124.797 113.937 125.197 113.834 125.607 113.713L125.84 113.797C126.372 113.974 127.732 114.393 129.307 114.515L129.988 114.552H130.342C130.705 114.542 131.069 114.524 131.432 114.468C131.917 114.403 132.392 114.282 132.849 114.114C133.25 113.965 133.632 113.76 133.995 113.499L134.163 113.555C134.489 113.657 135.263 113.881 136.148 114.03L136.558 114.095L136.978 114.151Z" fill="#1B3E77" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M122.381 66.4185C122.381 66.4185 118.923 65.7382 117.143 64.6943C117.143 64.6943 117.823 58.0771 119.473 54.6101C121.123 51.143 124.925 49.7171 128.532 51.3481C132.148 52.9698 132.875 56.8096 133.295 60.8918C133.714 64.9739 133.761 65.8034 133.761 65.8034C133.761 65.8034 131.953 66.782 129.035 66.8379L122.381 66.4185Z" fill="#FE9F3A" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M119.594 56.6403C119.594 56.6403 119.072 61.7197 119.827 63.4532C120.582 65.1774 121.738 65.5968 121.738 65.5968L121.477 67.3676L122.735 72.5868L124.292 79.2786L125.671 78.6821L128.579 71.4125L129.772 68.4208L130.061 61.8968C130.061 61.8968 133.789 61.5985 134.972 59.7438C136.156 57.8892 132.614 56.2488 129.194 58.2899C129.194 58.2899 125.438 57.0783 123.984 52.0548C123.975 52.0548 120.964 53.9282 119.594 56.6403Z" fill="#FFB7E7" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M121.924 57.6482C121.924 57.6482 121.616 59.1114 121.635 60.5281C121.635 60.5933 121.691 60.6492 121.756 60.6585L122.343 60.6958C122.418 60.6958 122.474 60.6492 122.483 60.5747C122.483 60.5001 122.427 60.4442 122.362 60.4349L121.905 60.4069C121.905 59.0555 122.194 57.7041 122.194 57.7041C122.213 57.6295 122.166 57.5643 122.092 57.555C122.008 57.5363 121.933 57.5829 121.924 57.6482Z" fill="black" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M121.962 62.7801C121.962 62.7801 121.981 62.1184 122.4 62.0252C122.819 61.932 122.987 62.2116 122.987 62.2116C122.987 62.2116 123.015 61.7269 123.462 61.7269C123.91 61.7362 124.068 62.2209 124.068 62.2209C124.068 62.2209 123.351 62.8826 121.962 62.7801Z" fill="#FE5E3A" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M122.343 62.7914C122.343 62.7914 122.772 63.4158 123.285 63.2853C123.797 63.1549 123.807 62.3906 123.807 62.3906C123.807 62.3906 123.201 62.81 122.343 62.7914Z" fill="#FE5E3A" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M123.545 57.0592C123.545 57.0592 123.955 56.6958 124.412 56.7796C124.617 56.8169 124.812 56.9381 124.999 57.199C125.045 57.255 125.12 57.2736 125.185 57.227C125.241 57.1897 125.26 57.1058 125.213 57.0499C124.971 56.7144 124.71 56.5746 124.449 56.528C123.871 56.4162 123.358 56.8728 123.358 56.8728C123.303 56.9194 123.293 57.0033 123.349 57.0592C123.414 57.1058 123.489 57.1058 123.545 57.0592Z" fill="black" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M119.924 56.6778C119.924 56.6778 120.334 56.3143 120.791 56.3982C120.996 56.4355 121.192 56.5567 121.378 56.8176C121.425 56.8735 121.499 56.8922 121.564 56.8456C121.62 56.8083 121.639 56.7244 121.593 56.6685C121.35 56.333 121.089 56.1932 120.828 56.1466C120.25 56.0347 119.738 56.4914 119.738 56.4914C119.682 56.538 119.673 56.6219 119.729 56.6778C119.784 56.7244 119.868 56.7244 119.924 56.6778Z" fill="black" />
                <path d="M123.593 58.2789C123.581 58.5883 123.742 58.8458 123.954 58.854C124.165 58.8622 124.346 58.6179 124.358 58.3085C124.37 57.9991 124.208 57.7416 123.997 57.7335C123.786 57.7253 123.605 57.9695 123.593 58.2789Z" fill="black" />
                <path d="M120.227 58.054C120.215 58.3634 120.377 58.6209 120.588 58.629C120.799 58.6372 120.98 58.393 120.992 58.0836C121.004 57.7742 120.842 57.5168 120.631 57.5086C120.42 57.5004 120.239 57.7446 120.227 58.054Z" fill="black" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M121.692 65.7096C121.692 65.7096 123.528 66.5297 126.417 65.1317C126.482 65.1038 126.501 65.0199 126.473 64.9547C126.445 64.8894 126.361 64.8615 126.305 64.8987C123.556 66.2315 121.804 65.4766 121.804 65.4766C121.739 65.4486 121.664 65.4766 121.636 65.5418C121.608 65.6071 121.617 65.6816 121.692 65.7096Z" fill="black" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M125.196 65.4756C125.196 65.4756 124.469 67.0134 121.477 67.3582L121.738 65.662C121.738 65.662 122.978 66.2212 125.196 65.4756Z" fill="black" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M130.006 59.2787C129.922 59.8845 130.089 60.3598 130.462 60.742C130.462 60.742 132.364 60.1641 132.736 59.5117C133.109 58.8593 131.665 58.5518 130.006 59.2787Z" fill="black" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M121.187 62.8174C121.187 62.8174 121.989 63.0132 122.883 62.8454C123.499 62.7336 124.142 62.4633 124.626 61.8575C124.673 61.8016 124.664 61.7177 124.608 61.6711C124.552 61.6245 124.468 61.6338 124.421 61.6897C123.983 62.2396 123.396 62.4819 122.837 62.5844C121.998 62.7336 121.243 62.5565 121.243 62.5565C121.178 62.5378 121.103 62.5844 121.085 62.6497C121.066 62.7336 121.113 62.7988 121.187 62.8174Z" fill="black" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M124.257 61.29C124.257 61.29 124.313 61.7839 124.9 62.0728C124.965 62.1008 125.04 62.0728 125.068 62.0169C125.096 61.9517 125.068 61.8771 125.002 61.8398C124.555 61.6255 124.509 61.262 124.509 61.262C124.499 61.1874 124.434 61.1408 124.359 61.1502C124.304 61.1595 124.248 61.2154 124.257 61.29Z" fill="black" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M126.624 72.3616C126.624 72.3616 124.723 77.1334 124.546 78.3637C124.546 78.3637 123.828 72.5573 123.781 71.5414L120.743 68.2142L118.991 68.8852C118.991 68.8852 113.697 67.9625 110.062 71.5414C110.062 71.5414 107.825 82.4179 110.51 90.7686C110.51 90.7686 108.729 94.8228 110.267 99.1193C110.267 99.1193 109.401 103.295 110.277 105.811C110.277 105.811 108.813 108.542 111.181 111.04C113.548 113.537 121.908 113.211 126.26 112.046C126.26 112.046 128.292 115.066 133.987 112.223C133.987 112.223 138.209 112.708 139.97 110.285C141.732 107.861 140.445 106.1 140.445 106.1C140.445 106.1 142.57 102.894 140.725 99.8836C140.725 99.8836 143.819 92.2598 141.489 87.7303C141.489 87.7303 144.938 78.8763 143.279 74.7196C143.158 74.4213 142.57 74.4959 142.375 74.2256C139.905 70.8051 131.396 70.0316 131.396 70.0316L126.624 72.3616Z" fill="#019CEF" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M131.486 97.4806L132.11 99.1955C132.175 99.3726 132.082 99.5776 131.905 99.6429C131.01 99.9877 128.494 100.966 127.581 101.33C127.497 101.367 127.394 101.358 127.31 101.32C127.217 101.283 127.152 101.209 127.124 101.115L126.509 99.3819L131.486 97.4806ZM126.5 99.3819L124.813 94.5914C124.785 94.4982 124.785 94.405 124.831 94.3211C124.868 94.2373 124.952 94.172 125.036 94.1441C125.959 93.8365 128.494 92.9977 129.389 92.6995C129.575 92.6342 129.771 92.7367 129.836 92.9138L131.486 97.4899L126.5 99.3819Z" fill="#0173AD" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M148.776 145.731C148.776 145.731 145.672 129.598 144.712 124.565C144.544 123.698 144.992 122.822 145.803 122.449C151.143 119.961 170.333 111.042 170.333 111.042L170.948 114.369L148.776 145.731Z" fill="#F6C500" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M148.778 145.732L174.417 130.876L171.528 112.348C171.528 112.348 153.96 120.82 148.442 123.476C147.454 123.951 146.877 124.986 146.97 126.067C147.454 131.24 148.778 145.732 148.778 145.732Z" fill="#FFDB3B" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M104.289 44.8972L107.019 45.3911C107.019 45.3911 108.166 48.9327 106.05 51.8406L103.506 51.8685L104.289 44.8972Z" fill="#FFDB3B" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M88.8931 56.033L89.6853 53.3675L96.6006 51.0002L98.3063 52.976L97.5792 54.607L88.8931 56.033Z" fill="#F6C500" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M94.1336 62.3617L95.7553 58.7083L95.2986 57.4221L93.4998 56.5553L93.0525 56.7417C93.0525 56.7417 92.7915 58.4659 93.6769 59.3607C93.6769 59.37 93.5278 60.9823 94.1336 62.3617Z" fill="#F6C500" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M87.0781 62.1757L86.2393 65.009C87.134 65.3725 88.8209 65.8851 89.2775 64.7946L87.3111 62.1105L87.0781 62.1757Z" fill="#F6C500" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M86.1743 65.2405C86.1743 65.2405 84.8882 63.0503 84.8882 61.9505C84.8882 60.8508 85.1957 59.5087 86.137 59.3782C86.137 59.3782 85.6617 56.4517 87.6096 56.0696C87.6096 56.0696 87.3113 54.0658 88.2154 53.7582L89.1288 53.3202C89.1288 53.3202 91.2537 54.2708 91.8688 55.6688C92.4932 57.0668 91.701 58.7631 90.7784 58.7538C90.7784 58.7538 91.6544 61.6523 89.6786 62.0158C89.6786 62.0158 90.2378 64.2619 89.2499 64.9329C88.262 65.5946 86.7149 64.1687 86.5937 63.8052L86.1743 65.2405Z" fill="#FFB7E7" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M94.4394 71.1138C94.4394 71.1138 96.2102 68.0009 97.8692 65.9971C99.5281 63.9933 99.1367 63.1358 98.6893 61.654C98.242 60.1721 97.7666 58.0285 97.4777 57.5811C97.1981 57.1338 94.1691 55.7078 93.4515 55.7171C92.7338 55.7264 92.594 58.103 94.9613 59.2867C94.9613 59.2867 94.3648 60.666 94.924 61.598C94.924 61.598 91.7366 64.3195 91.6714 67.6747L92.0908 70.508L94.4394 71.1138Z" fill="#FFB7E7" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M154.552 122.056L165.195 119.036C165.195 119.036 167.693 114.721 164.478 112.615C164.478 112.615 164.934 104.683 161.327 99.5107C161.327 99.5107 156.36 84.4309 147.105 77.3291C147.105 77.3291 145.316 75.1296 143.209 74.6356C142.603 74.4958 142.669 74.6636 141.886 74.85L139.947 92.9121L140.488 97.0688C140.488 97.0688 143.685 100.713 147.291 102.652C147.291 102.652 145.903 106.696 150.581 110.052C150.581 110.07 149.612 117.936 154.552 122.056Z" fill="#019CEF" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M84.095 74.2198L93.3498 74.3223C93.3498 74.3223 95.2417 74.7231 94.9808 76.6896C94.7198 78.6561 93.471 79.8957 93.471 79.8957C93.471 79.8957 96.3322 81.0234 93.7972 84.8912C93.7972 84.8912 94.3936 85.7859 94.8503 87.5101C94.8503 87.5101 96.4999 85.264 97.1896 86.0841C97.1896 86.0841 98.8766 79.3924 101.468 80.6692C101.468 80.6692 105.046 74.1825 110.061 71.5449L111.943 88.5073L110.135 95.3016C110.135 95.3016 105.904 99.5981 101.98 100.912C101.98 100.912 96.6584 108.657 87.9256 107.753C79.2021 106.849 81.1965 96.2802 81.3363 94.2019C81.3363 94.2019 78.9783 86.7272 81.5134 82.3934C81.5134 82.4027 81.2524 76.9039 84.095 74.2198Z" fill="#019CEF" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M200.534 71.208C200.534 70.5836 200.021 70.071 199.398 70.071C194.584 70.071 175.859 70.071 171.044 70.071C170.422 70.071 169.908 70.5836 169.908 71.208C169.908 76.2129 169.908 96.2602 169.908 101.265C169.908 101.889 170.422 102.402 171.044 102.402C175.859 102.402 194.584 102.402 199.398 102.402C200.021 102.402 200.534 101.889 200.534 101.265C200.534 96.2602 200.534 76.2129 200.534 71.208Z" fill="#C6ECFF" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M169.781 92.7014C169.781 96.4481 169.781 99.6448 169.781 101.267C169.781 101.966 170.349 102.534 171.043 102.534C173.567 102.534 179.887 102.534 186.036 102.534C186.108 102.534 186.162 102.478 186.162 102.404C186.162 102.329 186.108 102.273 186.036 102.273C179.887 102.273 173.567 102.273 171.043 102.273C170.484 102.273 170.033 101.826 170.033 101.267C170.033 99.6448 170.033 96.4574 170.033 92.7014C170.033 92.6269 169.979 92.571 169.907 92.571C169.835 92.571 169.781 92.6362 169.781 92.7014Z" fill="black" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M200.661 85.4044C200.661 79.421 200.661 73.596 200.661 71.2007C200.661 70.5017 200.093 69.9332 199.399 69.9332C195.468 69.9332 182.278 69.9332 174.939 69.9332C174.867 69.9332 174.813 69.9891 174.813 70.0637C174.813 70.1382 174.867 70.1942 174.939 70.1942H199.399C199.958 70.1942 200.409 70.6415 200.409 71.2007C200.409 73.596 200.409 79.421 200.409 85.4044C200.409 85.479 200.472 85.5349 200.535 85.5349C200.607 85.5349 200.661 85.479 200.661 85.4044Z" fill="black" />
                <path d="M201.38 112.92H184.623V124.719H201.38V112.92Z" fill="#FFDB3B" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M184.617 124.851H201.375C201.449 124.851 201.505 124.795 201.505 124.721V112.921C201.505 112.847 201.44 112.791 201.375 112.791C201.3 112.791 201.244 112.847 201.244 112.921V124.59H184.627C184.552 124.59 184.496 124.646 184.496 124.721C184.487 124.795 184.543 124.851 184.617 124.851Z" fill="black" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M184.532 113.032L192.92 119.457C192.961 119.496 193.033 119.496 193.074 119.457L201.452 113.032C201.514 112.992 201.524 112.901 201.483 112.848C201.442 112.796 201.349 112.783 201.298 112.822L193.002 119.195L184.706 112.822C184.645 112.783 184.573 112.783 184.522 112.848C184.47 112.914 184.48 112.979 184.532 113.032Z" fill="black" />
                <path d="M200.949 116.775C203.047 116.775 204.747 115.074 204.747 112.976C204.747 110.879 203.047 109.178 200.949 109.178C198.851 109.178 197.151 110.879 197.151 112.976C197.151 115.074 198.851 116.775 200.949 116.775Z" fill="#FE5E3A" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M89.185 115.598L90.1304 123.296C90.1754 123.668 90.3255 124.007 90.5656 124.278C90.8057 124.548 91.1058 124.717 91.4359 124.768L98.2636 125.834L91.4359 126.9C91.1058 126.951 90.8057 127.12 90.5656 127.391C90.3255 127.661 90.1754 128 90.1304 128.372L89.185 136.07L88.2397 128.372C88.1947 128 88.0446 127.661 87.8045 127.391C87.5644 127.12 87.2643 126.951 86.9342 126.9L80.1064 125.834L86.9342 124.768C87.2643 124.717 87.5644 124.548 87.8045 124.278C88.0446 124.007 88.1947 123.668 88.2397 123.296L89.185 115.598Z" fill="#FFDB3B" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M73.0782 30.8613L105.269 43.5365C105.269 43.5365 106.118 49.259 103.76 53.0895L69.9653 55.3543C75.8556 46.2766 73.7586 34.0301 73.0782 30.8613Z" fill="#FE5E3A" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M65.5853 27.9929C66.2004 29.0647 70.907 38.2076 63.0223 55.8317C63.0223 55.8317 56.5821 39.6615 65.52 27.8904L65.5853 27.9929Z" fill="#F6C500" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M73.0782 30.8635C73.7586 34.0323 75.8556 46.2787 69.9747 55.3657L63.0312 55.8317C71.4472 37.0333 65.529 27.8904 65.529 27.8904L73.0782 30.8635Z" fill="#FFDB3B" />
                <path d="M178.841 79.3893H173.361V84.8694H178.841V79.3893Z" fill="white" />
                <path d="M178.841 85.4976H173.361V90.9778H178.841V85.4976Z" fill="white" />
                <path d="M178.841 91.602H173.361V97.0822H178.841V91.602Z" fill="white" />
                <path d="M184.918 79.3893H179.438V84.8694H184.918V79.3893Z" fill="#FE5E3A" />
                <path d="M184.918 85.4976H179.438V90.9778H184.918V85.4976Z" fill="white" />
                <path d="M184.918 91.602H179.438V97.0822H184.918V91.602Z" fill="white" />
                <path d="M190.993 79.3893H185.513V84.8694H190.993V79.3893Z" fill="white" />
                <path d="M190.993 85.4976H185.513V90.9778H190.993V85.4976Z" fill="white" />
                <path d="M190.993 91.602H185.513V97.0822H190.993V91.602Z" fill="white" />
                <path d="M197.074 79.3893H191.594V84.8694H197.074V79.3893Z" fill="white" />
                <path d="M197.074 85.4976H191.594V90.9778H197.074V85.4976Z" fill="white" />
                <path d="M197.074 91.602H191.594V97.0822H197.074V91.602Z" fill="white" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M179.793 72.1212C179.495 72.261 179.159 72.3449 178.814 72.3449C177.556 72.3449 176.54 71.329 176.54 70.0801C176.54 68.8313 177.556 67.8154 178.814 67.8154C180.063 67.8154 181.079 68.8313 181.079 70.0801C181.079 70.1547 181.135 70.2106 181.2 70.2106C181.275 70.2106 181.331 70.1547 181.331 70.0801C181.331 68.6821 180.194 67.5544 178.805 67.5544C177.407 67.5544 176.27 68.6915 176.27 70.0801C176.27 71.4688 177.407 72.6059 178.805 72.6059C179.196 72.6059 179.569 72.5127 179.895 72.3542C179.961 72.3263 179.989 72.2424 179.961 72.1771C179.933 72.1212 179.849 72.0933 179.793 72.1212Z" fill="black" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M186.196 72.1212C185.898 72.261 185.563 72.3449 185.218 72.3449C183.959 72.3449 182.944 71.329 182.944 70.0801C182.944 68.8313 183.959 67.8154 185.218 67.8154C186.467 67.8154 187.482 68.8313 187.482 70.0801C187.482 70.1547 187.538 70.2106 187.604 70.2106C187.678 70.2106 187.734 70.1547 187.734 70.0801C187.734 68.6821 186.597 67.5544 185.208 67.5544C183.81 67.5544 182.673 68.6915 182.673 70.0801C182.673 71.4688 183.81 72.6059 185.208 72.6059C185.6 72.6059 185.973 72.5127 186.299 72.3542C186.364 72.3263 186.392 72.2424 186.364 72.1771C186.336 72.1212 186.252 72.0933 186.196 72.1212Z" fill="black" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M192.618 72.1212C192.319 72.261 191.984 72.3449 191.639 72.3449C190.381 72.3449 189.365 71.329 189.365 70.0801C189.365 68.8313 190.381 67.8154 191.639 67.8154C192.888 67.8154 193.904 68.8313 193.904 70.0801C193.904 70.1547 193.96 70.2106 194.025 70.2106C194.1 70.2106 194.155 70.1547 194.155 70.0801C194.155 68.6821 193.018 67.5544 191.63 67.5544C190.232 67.5544 189.095 68.6915 189.095 70.0801C189.095 71.4688 190.232 72.6059 191.63 72.6059C192.021 72.6059 192.394 72.5127 192.72 72.3542C192.785 72.3263 192.813 72.2424 192.785 72.1771C192.757 72.1212 192.674 72.0933 192.618 72.1212Z" fill="black" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M140.994 36.1389C140.994 36.1389 138.851 33.0819 140.938 32.3177C143.026 31.5441 146.074 32.6066 146.074 32.6066C146.074 32.6066 147.826 29.4378 152.104 29.1116C156.372 28.7854 157.77 31.3391 157.77 31.3391C157.77 31.3391 162.16 27.1264 166.727 28.1423C171.293 29.1582 170.147 34.9179 170.147 34.9179C170.147 34.9179 177.426 35.3373 176.653 42.2994C175.888 49.2707 167.053 51.4889 160.175 50.706C160.175 50.706 160.203 54.1265 153.446 52.2345C153.446 52.2345 148.068 58.5162 145.859 61.1071C145.785 61.2003 145.645 61.219 145.542 61.1631C145.44 61.0978 145.393 60.9673 145.431 60.8555C146.53 57.9383 149.028 51.3025 149.028 51.3025C149.028 51.3025 140.072 52.7005 137.481 49.336C134.88 45.9715 138.785 43.0729 138.785 43.0729C138.785 43.0729 132.774 42.4485 133.305 38.9442C133.827 35.4305 140.994 36.1389 140.994 36.1389Z" fill="#C6ECFF" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M113.835 92.4839C113.835 92.4839 116.929 94.1429 121.244 93.7141C121.319 93.7048 121.365 93.6396 121.365 93.5743C121.356 93.4998 121.291 93.4532 121.225 93.4625C116.994 93.8819 113.965 92.2695 113.965 92.2695C113.891 92.2323 113.816 92.2602 113.788 92.3255C113.751 92.3721 113.769 92.4559 113.835 92.4839Z" fill="black" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M124.414 79.1846C124.414 79.1846 123.454 87.8149 123.659 92.3631C123.547 92.6706 122.503 95.8954 123.109 99.1014C122.96 99.4649 121.869 102.391 123.034 105.225C122.96 105.458 122.652 106.548 122.643 107.778C122.633 108.72 122.801 109.754 123.36 110.565C123.398 110.621 123.482 110.64 123.537 110.602C123.593 110.565 123.612 110.481 123.565 110.425C123.034 109.652 122.885 108.682 122.894 107.788C122.913 106.427 123.286 105.262 123.286 105.262C123.295 105.234 123.295 105.197 123.286 105.169C122.046 102.233 123.351 99.1667 123.351 99.1667C123.37 99.1387 123.37 99.1201 123.36 99.0921C122.727 95.7742 123.901 92.4283 123.901 92.4283C123.91 92.4097 123.91 92.4004 123.91 92.3817C123.705 87.8522 124.665 79.2219 124.665 79.2219C124.675 79.1473 124.619 79.0914 124.544 79.0821C124.488 79.0634 124.423 79.11 124.414 79.1846Z" fill="black" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M120.602 124.19C120.602 124.19 126.399 123.081 132.718 116.399C132.765 116.343 132.765 116.268 132.709 116.212C132.653 116.166 132.578 116.166 132.522 116.222C126.278 122.839 120.555 123.938 120.555 123.938C120.49 123.948 120.434 124.022 120.453 124.088C120.462 124.162 120.527 124.209 120.602 124.19Z" fill="black" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M107.152 140.121L108.168 141.043C108.224 141.09 108.308 141.09 108.354 141.034C108.401 140.978 108.392 140.894 108.345 140.847L107.32 139.925C107.264 139.878 107.189 139.878 107.133 139.934C107.096 139.99 107.105 140.065 107.152 140.121Z" fill="black" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M150.524 110.184C150.952 110.408 151.409 110.576 151.922 110.613C151.987 110.622 152.061 110.567 152.061 110.492C152.071 110.417 152.015 110.362 151.94 110.352C151.465 110.306 151.036 110.156 150.645 109.951C150.58 109.914 150.505 109.942 150.477 110.007C150.44 110.073 150.458 110.156 150.524 110.184Z" fill="black" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M147.247 102.792L148.85 103.435C148.915 103.463 148.99 103.426 149.018 103.36C149.046 103.295 149.018 103.221 148.943 103.193L147.34 102.55C147.275 102.522 147.2 102.559 147.172 102.624C147.145 102.689 147.182 102.764 147.247 102.792Z" fill="black" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M85.8567 70.3921L94.6641 71.131L94.8807 75.1025L83.7909 74.2007L85.8567 70.3921Z" fill="#3471D2" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M86.7197 59.838C86.7197 59.838 88.6676 61.0123 89.581 62.1121C89.6276 62.168 89.7114 62.1773 89.7674 62.1307C89.8233 62.0841 89.8326 62.0003 89.786 61.9443C88.854 60.8166 86.8595 59.6143 86.8595 59.6143C86.7943 59.5771 86.7197 59.5957 86.6825 59.6609C86.6452 59.7169 86.6545 59.8007 86.7197 59.838Z" fill="black" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M87.9548 56.4737C87.9548 56.4737 89.8001 57.7692 90.7041 58.8782C90.7508 58.9342 90.8346 58.9435 90.8905 58.8969C90.9465 58.8503 90.9558 58.7664 90.9092 58.7105C89.9865 57.5828 88.1039 56.2593 88.1039 56.2593C88.0387 56.222 87.9641 56.2314 87.9268 56.2873C87.8802 56.3432 87.8988 56.4271 87.9548 56.4737Z" fill="black" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M95.8742 56.5651C95.8742 56.5651 95.6785 56.8167 95.3896 56.9938C95.2125 57.1057 94.9889 57.1989 94.7652 57.1802C94.178 57.115 93.8611 56.5558 93.8611 56.5558C93.8239 56.4905 93.7399 56.4719 93.684 56.5092C93.6188 56.5465 93.6095 56.621 93.6374 56.6863C93.6374 56.6863 94.0382 57.3573 94.7372 57.4412C95.0261 57.4691 95.2964 57.3666 95.5294 57.2175C95.8462 57.0125 96.07 56.7235 96.07 56.7235C96.1166 56.6676 96.0979 56.5837 96.042 56.5371C96.0047 56.4999 95.9208 56.5092 95.8742 56.5651Z" fill="black" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M90.957 55.4282C90.957 55.4282 90.6122 55.6985 90.3512 56.0433C90.1648 56.2949 90.0157 56.5839 90.0343 56.8355C90.0529 57.0312 90.1648 57.2456 90.3233 57.4413C90.6681 57.8607 91.2087 58.2335 91.2087 58.2335C91.2646 58.2708 91.3484 58.2615 91.3857 58.1962C91.423 58.1403 91.4137 58.0564 91.3485 58.0191C91.3485 58.0191 90.9757 57.7675 90.6681 57.4413C90.4724 57.2456 90.3046 57.0219 90.2859 56.8169C90.2766 56.6118 90.4071 56.3975 90.5563 56.2017C90.7986 55.8849 91.1155 55.6332 91.1155 55.6332C91.1714 55.5866 91.1807 55.5027 91.1434 55.4468C91.0968 55.3909 91.0222 55.3816 90.957 55.4282Z" fill="black" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M89.4627 58.3732C89.4627 58.3732 89.1575 58.6876 88.9455 59.0645C88.7948 59.339 88.6861 59.6454 88.7385 59.8922C88.7834 60.0836 88.9231 60.281 89.1066 60.4535C89.5049 60.8225 90.0908 61.1189 90.0908 61.1189C90.1512 61.1483 90.2331 61.1277 90.2613 61.0581C90.2906 60.9976 90.2701 60.9158 90.2004 60.8876C90.2004 60.8876 89.797 60.6886 89.4483 60.4069C89.2279 60.2394 89.0314 60.0405 88.9853 59.8398C88.9484 59.6379 89.0488 59.4079 89.1701 59.1938C89.3674 58.8471 89.6474 58.555 89.6474 58.555C89.6965 58.5013 89.6944 58.4169 89.6499 58.3666C89.5962 58.3174 89.5119 58.3195 89.4627 58.3732Z" fill="black" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M88.4355 61.9258C88.4355 61.9258 88.1784 62.2138 87.999 62.5631C87.8684 62.8117 87.792 63.0841 87.8487 63.3075C87.8953 63.4744 88.0256 63.643 88.1949 63.7962C88.5607 64.1145 89.086 64.3457 89.086 64.3457C89.1499 64.376 89.236 64.3439 89.2662 64.2801C89.2884 64.2177 89.2659 64.1383 89.1939 64.1095C89.1939 64.1095 88.8395 63.9477 88.5154 63.7221C88.3233 63.5813 88.1445 63.4214 88.1045 63.245C88.0579 63.0782 88.1378 62.8719 88.2367 62.679C88.4057 62.365 88.6334 62.0989 88.6334 62.0989C88.6908 62.0469 88.6763 61.9661 88.6257 61.9167C88.5671 61.8688 88.4848 61.8752 88.4355 61.9258Z" fill="black" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M145.989 152.118C145.989 152.118 145.467 154.131 143.798 155.156C143.733 155.194 143.724 155.277 143.761 155.333C143.798 155.389 143.882 155.408 143.938 155.371C145.69 154.29 146.24 152.174 146.24 152.174C146.259 152.109 146.212 152.034 146.147 152.015C146.072 152.006 146.007 152.043 145.989 152.118Z" fill="black" />
                <path d="M127.574 92.4008C127.537 92.4008 127.491 92.3822 127.463 92.3449C123.38 86.2403 121.805 72.2137 121.787 72.0739C121.777 71.9993 121.833 71.9434 121.899 71.9341C121.973 71.9248 122.029 71.9807 122.038 72.0459C122.057 72.1857 123.632 86.1564 127.668 92.2051C127.705 92.261 127.696 92.3449 127.63 92.3822C127.621 92.4008 127.593 92.4008 127.574 92.4008Z" fill="white" />
                <path d="M128.03 92.4005C127.974 92.4005 127.927 92.3632 127.909 92.3073C125.541 84.842 128.086 73.2479 128.114 73.1361C128.132 73.0708 128.198 73.0242 128.272 73.0335C128.337 73.0522 128.384 73.1174 128.375 73.192C128.347 73.3038 125.812 84.8327 128.16 92.2327C128.179 92.298 128.142 92.3725 128.076 92.3912C128.058 92.4005 128.048 92.4005 128.03 92.4005Z" fill="white" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M127.154 98.0822L125.467 93.2917C125.439 93.1985 125.439 93.1053 125.486 93.0214C125.533 92.9375 125.607 92.8723 125.7 92.8444C126.623 92.5368 129.149 91.698 130.062 91.3998C130.239 91.3345 130.444 91.437 130.509 91.6141L132.159 96.1902L127.154 98.0822Z" fill="#FE9F3A" />
                <path d="M128.916 96.0974C129.611 96.0974 130.175 95.5341 130.175 94.8392C130.175 94.1443 129.611 93.581 128.916 93.581C128.222 93.581 127.658 94.1443 127.658 94.8392C127.658 95.5341 128.222 96.0974 128.916 96.0974Z" fill="white" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M132.14 96.1904L132.755 97.9245C132.818 98.0898 132.723 98.3149 132.546 98.3822C131.643 98.7262 129.14 99.7165 128.216 100.068C128.137 100.098 128.027 100.102 127.942 100.059C127.858 100.015 127.781 99.9306 127.756 99.8645L127.161 98.1228L132.152 96.2235L132.14 96.1904Z" fill="#FFDB3B" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M73.1618 184.584C73.1618 184.584 69.555 158.739 71.1674 153.091C72.7704 147.443 75.4266 162.607 74.9512 175.487L73.8981 184.397L73.1618 184.584Z" fill="#6BB135" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M50.0219 30.5382C49.8262 30.6127 49.6211 30.678 49.4347 30.7152C46.8344 31.2744 43.675 30.3238 41.578 28.7394C41.522 28.6928 41.4382 28.7114 41.4009 28.7674C41.3543 28.8233 41.373 28.9072 41.4289 28.9444C43.5818 30.5754 46.8251 31.5447 49.5 30.9669C49.7236 30.9203 49.9473 30.8457 50.1803 30.7525C50.2269 30.8178 50.2735 30.883 50.3201 30.9482C51.9791 33.1105 54.9149 33.912 57.5245 33.8281C57.5991 33.8281 57.6457 33.7629 57.6457 33.6976C57.6457 33.6231 57.5804 33.5672 57.5152 33.5765C54.9894 33.6604 52.1282 32.8961 50.5252 30.7991C50.4972 30.7525 50.4506 30.7059 50.4227 30.6593C51.0098 30.3704 51.5411 29.923 51.8486 29.3452C52.0443 28.991 52.1468 28.6089 52.0443 28.3014C51.9697 28.0777 51.802 27.8913 51.4945 27.7608C50.6091 27.3973 49.8168 28.087 49.6864 28.9444C49.5745 29.4943 49.7423 30.0535 50.0219 30.5382ZM50.2642 30.4356C50.8234 30.1654 51.3174 29.7553 51.6063 29.2147C51.7834 28.8885 51.8952 28.525 51.7275 28.2641C51.6529 28.1522 51.5411 28.059 51.3733 27.9845C50.6557 27.6862 50.0219 28.2734 49.9194 28.9724C49.8448 29.4757 49.9939 29.9883 50.2642 30.4356Z" fill="black" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M48.4746 42.444C48.1992 42.5473 47.9238 42.6312 47.6633 42.6829C45.6834 43.1025 43.4652 42.8443 41.5225 42.4053C41.448 42.3924 41.381 42.4311 41.3661 42.5021C41.3438 42.5731 41.3885 42.6441 41.4629 42.6571C43.4429 43.096 45.7057 43.3542 47.7154 42.9346C48.0653 42.8636 48.4449 42.7474 48.7873 42.586C48.8989 42.6312 49.018 42.67 49.1297 42.7022C51.0129 43.1993 53.432 42.8184 55.2705 42.3343C55.3375 42.3149 55.3822 42.2439 55.3673 42.1794C55.345 42.1083 55.278 42.0696 55.2035 42.089C53.3948 42.5667 51.0427 42.9475 49.1892 42.4569C49.1669 42.4505 49.1371 42.444 49.1073 42.4311C49.5167 42.2052 49.8666 41.9147 50.0824 41.5532C50.209 41.3272 50.2462 41.1207 50.2015 40.9335C50.1569 40.7463 50.0378 40.5849 49.8815 40.4558C49.4274 40.0943 48.5863 39.991 48.0504 40.3525C47.7303 40.5655 47.574 40.8044 47.5219 41.0303C47.4474 41.3789 47.6187 41.7339 47.9238 42.0309C48.0727 42.1923 48.2662 42.3343 48.4746 42.444ZM48.7798 42.3149C49.2339 42.1019 49.6284 41.8049 49.8517 41.4305C49.9484 41.2756 49.9708 41.1336 49.941 41.0045C49.9112 40.8754 49.8219 40.7592 49.7103 40.6688C49.3307 40.3654 48.631 40.275 48.1844 40.5784C47.9387 40.7463 47.8047 40.9206 47.7675 41.1013C47.7079 41.3724 47.8643 41.6371 48.095 41.863C48.2886 42.0373 48.5268 42.1987 48.7798 42.3149Z" fill="black" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M50.205 51.7233C49.8322 52.1427 49.3382 52.4689 48.8815 52.6926C46.8031 53.7085 44.3613 53.5314 42.199 52.9256C42.1338 52.9069 42.0592 52.9442 42.0406 53.0188C42.0126 53.084 42.0685 53.1586 42.1338 53.1772C44.3519 53.7923 46.8591 53.9694 48.9934 52.9256C49.4966 52.6833 50.0464 52.3011 50.4565 51.8258C50.5124 51.8445 50.5684 51.8631 50.615 51.8724C52.5535 52.4596 55.1725 52.2266 57.0552 51.453C57.1204 51.4251 57.1483 51.3505 57.1297 51.2853C57.1017 51.22 57.0179 51.1921 56.962 51.2107C55.1352 51.9656 52.5908 52.1893 50.6895 51.6208C50.6709 51.6115 50.6523 51.6115 50.6336 51.6021C50.8573 51.2853 51.0158 50.9311 51.053 50.549C51.0996 50.0271 50.7268 49.5517 50.2701 49.3467C49.7948 49.1323 49.2449 49.1883 48.984 49.7381C48.7696 50.1855 48.8628 50.5863 49.1238 50.9218C49.3754 51.2666 49.8042 51.5462 50.205 51.7233ZM50.3727 51.5183C50.5871 51.22 50.7454 50.8938 50.7827 50.5303C50.82 50.1203 50.5125 49.7568 50.149 49.589C49.8041 49.4306 49.3941 49.4585 49.189 49.8593C49.0213 50.2041 49.1145 50.5117 49.3102 50.7727C49.5618 51.0989 49.9999 51.3598 50.3727 51.5183Z" fill="black" />
                <path d="M201.322 111.633C201.23 111.555 201.1 111.516 200.944 111.516C200.696 111.516 200.513 111.62 200.396 111.816C200.279 112.011 200.213 112.337 200.213 112.794C200.305 112.65 200.422 112.533 200.591 112.455C200.761 112.376 200.944 112.337 201.139 112.337C201.374 112.337 201.582 112.389 201.765 112.494C201.948 112.598 202.091 112.742 202.195 112.937C202.3 113.133 202.352 113.367 202.352 113.641C202.352 113.902 202.3 114.124 202.195 114.332C202.091 114.528 201.935 114.684 201.739 114.802C201.543 114.919 201.309 114.971 201.022 114.971C200.644 114.971 200.344 114.893 200.135 114.724C199.913 114.554 199.77 114.319 199.679 114.019C199.587 113.72 199.548 113.354 199.548 112.911C199.548 112.246 199.666 111.751 199.887 111.412C200.122 111.072 200.474 110.903 200.97 110.903C201.348 110.903 201.648 111.007 201.856 111.216C202.065 111.425 202.182 111.685 202.221 111.985H201.517C201.478 111.816 201.413 111.711 201.322 111.633ZM200.474 113.133C200.344 113.25 200.278 113.407 200.278 113.628C200.278 113.837 200.344 114.006 200.461 114.137C200.578 114.254 200.748 114.319 200.97 114.319C201.165 114.319 201.322 114.254 201.439 114.137C201.556 114.019 201.609 113.85 201.609 113.654C201.609 113.446 201.556 113.276 201.439 113.159C201.335 113.041 201.165 112.976 200.957 112.976C200.761 112.963 200.604 113.015 200.474 113.133Z" fill="white" />
                <path d="M149.62 38.5341L150.026 45.611L148.615 45.6918L148.443 42.6762L145.403 42.8504L145.575 45.866L144.165 45.9468L143.759 38.87L145.17 38.7891L145.337 41.7074L148.377 41.5332L148.21 38.615L149.62 38.5341Z" fill="#3471D2" />
                <path d="M151.435 45.5095L152.846 45.4287L152.44 38.3518L151.03 38.4326L151.435 45.5095Z" fill="#3471D2" />
                <path d="M157.904 45.1598L156.166 42.5023L155.51 42.5399L155.667 45.288L154.257 45.3688L153.851 38.2919L156.502 38.1401C157.037 38.1094 157.529 38.1788 157.929 38.3511C158.329 38.5233 158.637 38.7741 158.825 39.0805C159.038 39.3855 159.181 39.7676 159.204 40.1567C159.23 40.6188 159.108 41.0406 158.863 41.445C158.592 41.8265 158.218 42.1163 157.692 42.2929L159.558 45.065L157.904 45.1598ZM155.447 41.4455L156.614 41.3786C157.003 41.3564 157.29 41.2424 157.473 41.0367C157.656 40.831 157.74 40.5822 157.72 40.2417C157.702 39.9256 157.59 39.6636 157.385 39.5046C157.181 39.3455 156.884 39.2649 156.495 39.2872L155.327 39.3541L155.447 41.4455Z" fill="#3471D2" />
                <path d="M161.821 38.9822L161.923 40.7575L164.282 40.6223L164.346 41.741L161.987 41.8762L162.094 43.7487L164.769 43.5955L164.836 44.7628L160.751 44.9969L160.345 37.92L164.431 37.6859L164.498 38.8532L161.823 39.0065L161.821 38.9822Z" fill="#3471D2" />
                <path d="M167.56 37.3834L167.669 42.2812L166.478 42.3495L166.003 37.4726L167.56 37.3834ZM167.245 44.6722C166.978 44.6876 166.755 44.6271 166.575 44.4667C166.396 44.3305 166.311 44.1158 166.298 43.8969C166.284 43.6537 166.346 43.455 166.508 43.2994C166.668 43.1194 166.883 43.0339 167.151 43.0186C167.394 43.0046 167.592 43.0664 167.772 43.2269C167.952 43.3874 168.037 43.5777 168.049 43.7966C168.063 44.0398 168.001 44.2385 167.839 44.3942C167.679 44.5742 167.488 44.6583 167.245 44.6722Z" fill="#3471D2" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M73.3675 188.079C73.3675 188.079 72.1187 178.135 75.9399 162.496C77.8784 154.583 80.6744 145.841 82.5477 145.823C84.421 145.804 84.3464 154.509 79.7983 161.396C79.7983 161.396 86.5553 162.161 78.3723 168.694C78.3723 168.694 83.6661 167.464 77.4962 180.502L75.6882 188.387L73.3675 188.079Z" fill="#7BC53E" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M73.897 190.874C73.897 190.874 76.4599 175.813 82.8069 169.699C89.1631 163.585 108.456 160.053 108.456 160.053C108.456 160.053 110.739 173.688 94.1866 172.337C94.1866 172.337 95.9108 177.081 87.877 175.832C87.877 175.832 88.8463 180.594 82.2757 179.364C82.2757 179.364 84.9039 184.043 78.6129 183.716C78.6129 183.716 76.5718 187.127 75.4907 190.93L73.897 190.874Z" fill="#6BB135" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M74.9501 190.25C74.9501 190.25 69.1904 167.22 58.3792 167.146C47.5773 167.071 32.4417 185.59 35.4893 192.897C37.1949 197.007 44.5856 186.513 44.5856 186.513C44.5856 186.513 48.3509 191.014 52.0229 181.844C52.0229 181.844 54.0547 187.51 59.6001 178.423C59.6001 178.423 61.7903 184.481 65.7513 180.557C65.7513 180.557 71.362 186.121 73.3099 190.465L74.9501 190.25Z" fill="#6BB135" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M69.2768 212.325C53.731 202.399 64.6821 188.382 64.6821 188.382H83.1264C83.1264 188.382 94.068 202.399 78.5223 212.325H69.2768Z" fill="#FE9F3A" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M83.6018 207.754C82.3622 209.283 80.6846 210.774 78.485 212.19C77.8513 212.19 69.2769 212.19 69.2769 212.19C69.2023 212.19 69.1558 212.246 69.1558 212.321C69.1558 212.395 69.2117 212.451 69.2769 212.451H78.5224C78.5503 212.451 78.5689 212.442 78.5969 212.433C80.8523 210.997 82.5393 209.469 83.8068 207.922C83.8441 207.866 83.8348 207.782 83.7789 207.745C83.723 207.689 83.639 207.698 83.6018 207.754Z" fill="black" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M61.2979 201.608C60.0583 194.962 64.2989 189.11 64.7463 188.513H83.1346C83.2092 188.513 83.2558 188.457 83.2558 188.383C83.2558 188.308 83.1999 188.252 83.1346 188.252H64.681C64.6438 188.252 64.6065 188.271 64.5785 188.299C64.5785 188.299 59.7042 194.534 61.0369 201.654C61.0556 201.729 61.1115 201.775 61.186 201.757C61.2606 201.747 61.3072 201.673 61.2979 201.608Z" fill="black" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M129.808 67.3551C129.808 67.3551 131.038 67.1967 131.383 67.9236C131.727 68.6506 132.408 70.8222 132.408 70.8222L128.465 75.7431C128.465 75.7431 128.111 72.798 126.62 72.3506L129.77 68.4083L129.808 67.3551Z" fill="#019CEF" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M121.476 67.3494L123.787 71.5527C123.787 71.5527 121.988 72.9973 121.187 75.0197L118.689 69.6887C118.689 69.6887 119.649 66.8647 120.73 66.7622C121.261 66.7156 121.55 66.8927 121.55 66.8927L121.476 67.3494Z" fill="#019CEF" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M94.1566 171.863L92.8984 171.36C92.8238 171.332 92.7585 171.37 92.7306 171.435C92.7026 171.5 92.74 171.575 92.8052 171.603L94.0727 172.106C94.1379 172.134 94.2125 172.096 94.2404 172.031C94.2498 171.966 94.2218 171.891 94.1566 171.863Z" fill="black" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M87.8118 175.404L86.5536 174.9C86.479 174.872 86.4138 174.91 86.3859 174.975C86.3579 175.04 86.3952 175.115 86.4604 175.143L87.7279 175.646C87.7931 175.674 87.8678 175.637 87.8957 175.571C87.9144 175.497 87.877 175.422 87.8118 175.404Z" fill="black" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M82.1122 179.001L81.5064 178.377C81.4598 178.321 81.3759 178.321 81.3293 178.377C81.2734 178.424 81.2734 178.507 81.32 178.563L81.9258 179.188C81.9817 179.234 82.0563 179.244 82.1122 179.188C82.1588 179.132 82.1681 179.048 82.1122 179.001Z" fill="black" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M44.7073 186.214L45.108 184.574C45.1267 184.508 45.0801 184.434 45.0055 184.415C44.931 184.397 44.8657 184.443 44.8471 184.508L44.4463 186.149C44.4184 186.214 44.4743 186.288 44.5395 186.307C44.6234 186.326 44.698 186.288 44.7073 186.214Z" fill="black" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M52.0662 181.642L52.467 180.001C52.4856 179.936 52.439 179.861 52.3644 179.843C52.2899 179.824 52.2246 179.871 52.206 179.936L51.8052 181.576C51.7773 181.642 51.8332 181.716 51.8984 181.735C51.9823 181.753 52.0475 181.716 52.0662 181.642Z" fill="black" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M59.8376 178.163L60.0888 177.135C60.0975 177.064 60.0613 176.995 59.9958 176.978C59.9212 176.959 59.8546 177.001 59.8369 177.071L59.5856 178.099C59.5679 178.169 59.604 178.238 59.6786 178.256C59.7532 178.275 59.8199 178.233 59.8376 178.163Z" fill="black" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M79.4435 161.258L78.2785 161.686C78.204 161.714 78.176 161.789 78.204 161.854C78.2226 161.919 78.3065 161.957 78.3625 161.929L79.5367 161.5C79.602 161.472 79.6392 161.397 79.6113 161.332C79.5833 161.267 79.5181 161.239 79.4435 161.258Z" fill="black" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M78.259 168.395L77.094 168.824C77.0194 168.852 76.9915 168.926 77.0194 168.992C77.0381 169.057 77.122 169.094 77.1779 169.066L78.3522 168.637C78.4174 168.609 78.4548 168.535 78.4268 168.47C78.3988 168.404 78.3242 168.376 78.259 168.395Z" fill="black" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M74.5691 168.5C74.5691 168.5 73.2176 177.541 73.0219 183.794C73.0126 183.869 73.0779 183.925 73.1431 183.925C73.2084 183.925 73.2735 183.869 73.2735 183.804C73.4693 177.569 74.8207 168.537 74.8207 168.537C74.83 168.463 74.7834 168.398 74.7089 168.388C74.6343 168.379 74.5784 168.435 74.5691 168.5Z" fill="black" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M109.099 79.1293C109.099 79.1293 107.347 86.0821 108.857 90.8073C108.857 90.8073 107.534 93.6872 108.214 97.0517L109.677 95.7469C109.677 95.7469 109.761 92.6154 110.544 90.7421C110.544 90.7421 108.484 87.3589 109.099 79.1293Z" fill="#0173AD" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M109.63 73.4645C109.63 73.4645 108.856 77.7144 108.865 82.2999C108.865 85.3475 109.219 88.5443 110.338 90.7624C110.217 91.1259 109.518 93.2229 109.536 95.1801C109.546 95.2547 109.602 95.3106 109.667 95.3106C109.741 95.3106 109.797 95.2547 109.797 95.1801C109.769 93.0738 110.59 90.7997 110.59 90.7997C110.608 90.7624 110.608 90.7252 110.59 90.6972C109.452 88.5163 109.117 85.3382 109.117 82.3092C109.117 77.7424 109.881 73.5204 109.881 73.5204C109.89 73.4459 109.844 73.3807 109.769 73.3713C109.713 73.3434 109.639 73.39 109.63 73.4645Z" fill="black" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M95.5279 96.0996C95.5279 96.0996 97.5877 91.7937 96.1431 86.1924C96.1431 86.1924 95.1551 86.938 94.8662 87.6277C94.8662 87.6184 96.3294 91.2346 95.5279 96.0996Z" fill="#0173AD" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M94.759 87.9269C94.759 87.9269 96.6603 91.5523 94.9361 97.3494C94.9082 97.4146 94.9547 97.4892 95.0199 97.5078C95.0945 97.5265 95.1598 97.4892 95.1878 97.424C96.9493 91.5058 94.992 87.8057 94.992 87.8057C94.964 87.7405 94.8802 87.7218 94.815 87.7498C94.7591 87.7871 94.731 87.871 94.759 87.9269Z" fill="black" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M132.234 70.8512L128.561 75.4366C128.505 75.1104 128.394 74.5512 128.179 73.992C127.937 73.3862 127.583 72.7804 127.042 72.417C126.977 72.3797 126.902 72.389 126.865 72.4542C126.828 72.5102 126.837 72.594 126.893 72.6313C127.387 72.9668 127.713 73.5354 127.937 74.0852C128.263 74.9334 128.338 75.7628 128.338 75.7628C128.347 75.8188 128.375 75.856 128.431 75.8747C128.477 75.8933 128.533 75.8747 128.571 75.8281L132.429 71.0096C132.476 70.9537 132.466 70.8698 132.411 70.8232C132.373 70.786 132.28 70.7953 132.234 70.8512Z" fill="black" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M118.879 70.3871L121.079 75.0284C121.098 75.075 121.153 75.103 121.2 75.103C121.247 75.103 121.293 75.075 121.321 75.0377C121.321 75.0377 122.356 73.1644 123.577 71.9249C123.633 71.8783 123.623 71.7944 123.577 71.7385C123.521 71.6919 123.446 71.6919 123.39 71.7385C122.402 72.745 121.536 74.1523 121.219 74.6929L119.122 70.2752C119.094 70.21 119.01 70.182 118.954 70.21C118.87 70.2473 118.851 70.3311 118.879 70.3871Z" fill="black" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M142.865 82.067C142.865 82.067 143.825 85.096 143.331 87.8733C143.331 87.8733 145.381 94.5465 143.266 99.8029C142.595 99.2624 141.97 98.6659 141.355 98.0042C141.355 98.0042 143.266 92.8502 141.476 87.7429C141.485 87.7335 142.576 84.6579 142.865 82.067Z" fill="#0173AD" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M143.497 76.5726C143.497 76.5726 142.891 83.8888 141.316 87.7379C141.297 87.7659 141.297 87.8032 141.316 87.8311C141.316 87.8311 143.05 92.3047 141.307 97.3282C141.279 97.3934 141.325 97.468 141.381 97.496C141.456 97.5239 141.521 97.4866 141.549 97.4121C143.217 92.603 141.763 88.2878 141.577 87.7845C143.152 83.8888 143.758 76.5819 143.758 76.5819C143.767 76.5073 143.711 76.4514 143.637 76.4421C143.571 76.4514 143.506 76.498 143.497 76.5726Z" fill="black" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M126.381 113.975L125.832 113.808C125.962 113.845 126.148 113.91 126.381 113.975L126.475 114.003L127.052 114.152L127.174 114.18L127.444 114.246L127.612 114.274L127.733 114.301L127.845 114.32L128.022 114.357L128.329 114.404L128.497 114.432L128.6 114.441L128.665 114.451L128.73 114.46L128.907 114.488L129.317 114.525L128.73 114.46L128.609 114.441L128.348 114.395L127.863 114.311L127.63 114.264L127.192 114.171L127.015 114.134L126.381 113.975Z" fill="#003753" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M120.985 114.48L121.693 114.443C121.209 114.48 120.743 114.49 120.305 114.49L120.985 114.48Z" fill="#003753" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M123.781 114.162L124.387 114.032C124.145 114.088 123.912 114.134 123.669 114.181L122.97 114.302L123.185 114.274C123.371 114.237 123.576 114.2 123.781 114.162Z" fill="#003753" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M133.666 113.707C133.536 113.791 133.396 113.866 133.256 113.94C133.107 114.005 132.967 114.071 132.818 114.127C133.117 114.015 133.396 113.875 133.666 113.707Z" fill="#003753" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M116.511 113.957C116.241 113.882 115.97 113.798 115.719 113.715L116.203 113.873L116.511 113.957Z" fill="#003753" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M113.069 112.478L113.693 112.832C113.488 112.711 113.274 112.599 113.069 112.478Z" fill="#003753" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M110.665 110.558C110.963 109.458 111.149 108.852 111.149 108.852L111.019 109.291L110.665 110.558Z" fill="#003753" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M139.998 110.016L139.96 109.95L140.333 110.556L139.998 110.016Z" fill="#003753" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M81.615 94.2393L82.342 93.0557C82.3793 92.9998 82.37 92.9159 82.3048 92.8786C82.2395 92.8413 82.1649 92.86 82.1276 92.9159L81.4007 94.0995C81.3634 94.1648 81.3728 94.2393 81.438 94.2766C81.4939 94.3139 81.5778 94.2953 81.615 94.2393Z" fill="black" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M81.8838 82.5001L82.7784 82.3603C82.853 82.351 82.8996 82.2857 82.8903 82.2112C82.881 82.1366 82.8158 82.09 82.7412 82.0993L81.8464 82.2391C81.7719 82.2484 81.7253 82.3137 81.7346 82.3882C81.7532 82.4628 81.8092 82.5187 81.8838 82.5001Z" fill="black" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M103.041 42.5193L81.5396 34.0474C81.4651 34.0194 81.3905 34.0567 81.3719 34.122C81.3532 34.1872 81.3812 34.2618 81.4464 34.2897L102.957 42.7616C103.022 42.7896 103.097 42.7523 103.125 42.687C103.143 42.6218 103.097 42.5472 103.041 42.5193Z" fill="black" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M65.7004 27.9237L65.6351 27.8118C65.6165 27.7746 65.5698 27.7559 65.5232 27.7466C65.486 27.7466 65.4394 27.7652 65.4115 27.7932C56.4363 39.6203 62.8951 55.865 62.8951 55.865C62.923 55.9116 62.9696 55.9489 63.0162 55.9489C63.0721 55.9489 63.1187 55.921 63.1374 55.8744C71.0687 38.185 66.3248 29.0048 65.7004 27.9237ZM65.514 28.1194L63.0349 55.4922C70.5841 38.418 66.2409 29.4242 65.514 28.1194Z" fill="black" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M63.0334 55.9606L69.9768 55.4946C70.0513 55.4853 70.1072 55.4294 70.0979 55.3548C70.0886 55.2803 70.0233 55.2337 69.9581 55.2337L63.0147 55.6997C62.9401 55.709 62.8936 55.7649 62.8936 55.8395C62.9029 55.9047 62.9681 55.9606 63.0334 55.9606Z" fill="black" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M156.24 141.403L158.468 140.107L158.039 132.829L156.138 133.35L153.873 139.52C153.873 139.52 154.507 140.788 156.203 139.632L156.24 141.403Z" fill="#C58D00" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M156.781 125.068C156.781 125.068 156.399 129.038 154.078 132.393C153.351 133.456 153.538 136.624 153.398 138.395C153.249 140.166 155.132 140.259 155.849 138.274C156.557 136.28 156.119 135.404 156.371 134.872C156.623 134.341 157.21 133.978 157.21 133.978L157.378 141.499C157.378 141.499 159.148 143.698 161.227 140.558C161.227 140.558 163.65 140.641 164.703 138.405C164.703 138.405 167.574 138.638 168.338 135.702C169.112 132.766 167.797 125.981 166.12 123.017L159.27 123.129L156.781 125.068Z" fill="#FFB7E7" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M153.507 122.141L165.921 118.366L166.443 123.194L156.61 125.813L153.507 122.141Z" fill="#3471D2" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M156.662 125.915L166.82 124.498C166.681 124.135 166.513 123.734 166.252 123.25L156.708 125.617L156.662 125.915Z" fill="black" />
            </g>
            <defs>
                <clipPath id="clip0_814_23904">
                    <rect width="233" height="233" fill="white" transform="translate(0 0.4505)" />
                </clipPath>
            </defs>
        </svg>
    );
}
