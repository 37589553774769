import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./login.css";
import {
  getUserByToken,
  getUserWithToken,
  login,
} from "../helpers/dataFetcher/profile/profile";
import { Col, Form, Row } from "reactstrap";
import google from "../assets/images/google.png";
import linkedinButtonImage from "../assets/images/linkedinButtonImage.png";
import linkedinImageButton from "../assets/images/linkedinImageButton.png";
import googleButtonImage from "../assets/images/googleButtonImage.png";
import linkedin from "../assets/images/linkedin2.png";
import { useDispatch } from "react-redux";
import { addUserAction } from "../store/auth/actions";
import { useForm } from "react-hook-form";
import { useRef } from "react";
import { ErrorOutlineOutlined } from "@mui/icons-material";
import { CssVarsProvider } from "@mui/joy/styles";
import CircularProgress from "@mui/joy/CircularProgress";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { useLinkedIn } from "react-linkedin-login-oauth2";
import { linkedinDeatilsWithToken } from "../helpers/dataFetcher/auth/linkedin";
import { googleLogout, useGoogleLogin } from "@react-oauth/google";
import getUserDetailsFromGoogle from "../helpers/dataFetcher/auth/google";
import { toast } from "react-hot-toast";
import SignIn from './SignInPage';

function Login() {
  const [loginStatus, setLoginStatus] = useState(false);
  const googleLogin = useGoogleLogin({
    onSuccess: codeResponse => getUserDetailsFromGoogle(codeResponse),
    onError: error => console.log("Login Failed:", error),
  });
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    getValues,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();
  const [error, setError] = useState(false);
  const dispatch = useDispatch();
  const [password, setPassword] = useState("");
  const [divClick, setDivClick] = useState(false);
  const [passwordClick, setPasswordClick] = useState(false);
  const usePasswordRef = useRef(null);
  const ref = useRef(null);
  const [id, setId] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  let id1;
  async function setUserDetailsForActions(
    loginObj,
    type = "normal",
    loginType = "normal"
  ) {
    navigate("/");
    localStorage.setItem("token", loginObj.access);
    localStorage.setItem("user_id", loginObj.id);
    localStorage.setItem("user_type", loginObj.usertype);

    const { status: userStatus, data: userData } = await getUserWithToken({
      Authorization: "Bearer " + loginObj.access,
    });
    if (userStatus == 200) {
      dispatch(
        addUserAction({
          ...userData?.data_payload,
          access: loginObj.access,
          type: type,
          loginType: loginType,
        })
      );
    }
  }
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = event => {
    event.preventDefault();
  };
  useEffect(() => {
    const handleClickOutside = event => {
      if (ref.current && !ref.current.contains(event.target)) {
        // setDivClick(false);
      } else {
        setError(false);
        setDivClick(true);
      }
      if (
        usePasswordRef.current &&
        !usePasswordRef.current.contains(event.target)
      ) {
        // setPasswordClick(false);
      } else {
        setError(false);
        setPasswordClick(true);
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  const loginFunc = async data => {
    setLoginStatus(true);
    const { status, data: loginObj } = await login({
      email: data?.email,
      password: data?.password,
    });
    if (status !== 200) {
      setError(true);
      setLoginStatus(false);
      return;
    }
    setLoginStatus(false);
    setUserDetailsForActions(loginObj);
    return;
  };
  const { linkedInLogin } = useLinkedIn({
    clientId: "78yn8vmivtm2to",
    redirectUri: `${window.location.origin}/linkedin`,
    onSuccess: async code => {
      console.log(code);
      const { status, data: loginObj } = await login({
        email: "support@Shifyt.com",
        password: "123456",
        login_type: "LinkedIn",
        linkedIn_token: code,
      });
      if (status !== 200) {
      } else {
        setUserDetailsForActions(loginObj, "external", "linkedin");
      }
    },
    scope: "r_emailaddress r_liteprofile",
    onError: error => {
      console.log(error);
    },
  });
  return (
    <>
      <section className="vh-100">
        <SignIn />
      </section>
    </>
  );
}

export default Login;
