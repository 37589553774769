import React from 'react';
import { Grid, Stack, Typography, Box, Button, Chip } from "@mui/material";
import EmojIcon from '../../../assets/icons/Careers/EmojIcon';
import UserIcon from '../../../assets/icons/Careers/UserIcon';
import TrophyIcon from '../../../assets/icons/Careers/TrophyIcon';
import MedalIcon from '../../../assets/icons/Careers/MedalIcon';

function BlogsTestimonialSection() {

    return (
        <Grid container sx={{
            paddingTop: "40px", paddingBottom: "80px",
            "@media (max-width: 600px)": {
                paddingTop: "20px",
                paddingBottom: "30px",
            },
        }}>
            <Grid item xs={12} sm={12}
                sx={{
                    paddingTop: "20px",
                }}
            >
                <Box>
                    <Stack
                        direction="column"
                        sx={{
                            gap: "10px"
                        }}
                    >
                        <Typography
                            sx={{
                                color: "var(--text-color-one)",
                            }}
                        >Testimonial</Typography>
                        <Typography
                            sx={{
                                fontSize: "30px",
                                fontWeight: "600",
                                width: "70%",
                                "@media (max-width: 1600px)": {
                                    fontSize: "25px",
                                },
                                "@media (max-width: 1200px)": {
                                    fontSize: "20px",
                                },
                                "@media (max-width: 900px)": {
                                    fontSize: "18px",
                                    width: "100%",
                                },
                                "@media (max-width: 600px)": {
                                    fontSize: "15px",
                                    width: "100%",
                                },
                            }}
                        >What Customer Says</Typography>
                    </Stack>
                    <Box
                        sx={{
                            display: "grid",
                            gridTemplateColumns: "repeat(1, 1fr)",
                            position: "relative",
                            "&::before, &::after": {
                                content: '" "',
                                position: "absolute",
                                zIndex: "2",
                                boxShadow: "inset -50px 0 150px #ffffff, inset 50px 0 45px #ffffff",
                                height: "100%",
                                minHeight: "100%",
                                width: "100%"
                            },
                            gap: "20px",
                            marginTop: "20px",
                        }}
                    >
                        <Stack
                            sx={{
                                gap: "20px",
                                overflow: "hidden",
                                paddingRight: "50px",
                            }}
                            direction="row"
                        >
                            {[
                                { id: "1", icon: UserIcon, title: "Powered by People", text: "Tortor pulvinar vestibulum eget aliquet penatibus interdum pellentesque. Diam ultrices in risus ac nunc imperdiet ultricies. Viverra est nunc condimentum aliquam pharetra ac.", },
                                { id: "2", icon: TrophyIcon, title: "Inspire and Provoke", text: "Tortor pulvinar vestibulum eget aliquet penatibus interdum pellentesque. Diam ultrices in risus ac nunc imperdiet ultricies. Viverra est nunc condimentum aliquam pharetra ac.", },
                                { id: "3", icon: MedalIcon, title: "Make it Right", text: "Tortor pulvinar vestibulum eget aliquet penatibus interdum pellentesque. Diam ultrices in risus ac nunc imperdiet ultricies. Viverra est nunc condimentum aliquam pharetra ac.", },
                                { id: "4", icon: EmojIcon, title: "Be Transparent", text: "Tortor pulvinar vestibulum eget aliquet penatibus interdum pellentesque. Diam ultrices in risus ac nunc imperdiet ultricies. Viverra est nunc condimentum aliquam pharetra ac.", },
                            ]?.map((item, index) => (
                                <Stack
                                    sx={{
                                        boxShadow: "0px 4px 11px -1px #0A0A0A0A",
                                        border: "1px solid #E5E5E5",
                                        background: "linear-gradient(0deg, #FFFFFF, #FFFFFF), linear-gradient(0deg, #E5E5E5, #E5E5E5)",
                                        minHeight: "150px",
                                        gap: "10px"
                                    }}
                                    p={4}
                                    key={index}
                                    direction="column"

                                >
                                    <Typography
                                        sx={{
                                            fontSize: "15px",
                                            "@media (max-width: 600px)": {
                                                fontSize: "12px",
                                            },
                                        }}
                                    >"{item?.text}"</Typography>
                                    <Stack
                                        direction="row"
                                        justifyContent="flex-start"
                                        alignItems="center"
                                        spacing={2}
                                        mt={2}
                                    >
                                        <Stack
                                            sx={{
                                                height: "45px",
                                                width: "45px",
                                                borderRadius: "50%",
                                                background: "var(--app-color-one)",

                                            }}
                                            justifyContent="center"
                                            alignItems="center"
                                        >
                                            {/* <img
                                                src={data?.image}
                                                style={{
                                                    width: "50px",
                                                }}
                                            /> */}
                                            {item?.icon()}
                                        </Stack>
                                        <Box
                                            sx={{
                                                width: "80%",
                                            }}
                                        >
                                            <Typography
                                                sx={{
                                                    fontWeight: "700",
                                                    fontSize: "15px",
                                                }}
                                                noWrap
                                            >
                                                 Robert Hope
                                            </Typography>
                                            <Typography
                                                sx={{
                                                    fontWeight: "400",
                                                    fontSize: "12px",
                                                    color: "gray",
                                                }}
                                                noWrap
                                            >
                                                Referal Partner
                                            </Typography>
                                        </Box>

                                    </Stack>
                                </Stack>
                            ))}
                        </Stack>
                        <Stack
                        sx={{
                            gap: "20px",
                            overflow: "hidden",
                            paddingLeft: "50px",
                        }}
                        direction="row"
                        >
                            {[
                                { id: "1", icon: UserIcon, title: "Powered by People", text: "Tortor pulvinar vestibulum eget aliquet penatibus interdum pellentesque. Diam ultrices in risus ac nunc imperdiet ultricies. Viverra est nunc condimentum aliquam pharetra ac.", },
                                { id: "2", icon: TrophyIcon, title: "Inspire and Provoke", text: "Tortor pulvinar vestibulum eget aliquet penatibus interdum pellentesque. Diam ultrices in risus ac nunc imperdiet ultricies. Viverra est nunc condimentum aliquam pharetra ac.", },
                                { id: "3", icon: MedalIcon, title: "Make it Right", text: "Tortor pulvinar vestibulum eget aliquet penatibus interdum pellentesque. Diam ultrices in risus ac nunc imperdiet ultricies. Viverra est nunc condimentum aliquam pharetra ac.", },
                                { id: "4", icon: EmojIcon, title: "Be Transparent", text: "Tortor pulvinar vestibulum eget aliquet penatibus interdum pellentesque. Diam ultrices in risus ac nunc imperdiet ultricies. Viverra est nunc condimentum aliquam pharetra ac.", },
                            ]?.map((item, index) => (
                                <Stack
                                    sx={{
                                        boxShadow: "0px 4px 11px -1px #0A0A0A0A",
                                        border: "1px solid #E5E5E5",
                                        background: "linear-gradient(0deg, #FFFFFF, #FFFFFF), linear-gradient(0deg, #E5E5E5, #E5E5E5)",
                                        minHeight: "150px",
                                        gap: "10px"
                                    }}
                                    p={4}
                                    key={index}
                                    direction="column"

                                >
                                    <Typography
                                        sx={{
                                            fontSize: "15px",
                                            "@media (max-width: 600px)": {
                                                fontSize: "12px",
                                            },
                                        }}
                                    >"{item?.text}"</Typography>
                                    <Stack
                                        direction="row"
                                        justifyContent="flex-start"
                                        alignItems="center"
                                        spacing={2}
                                        mt={2}
                                    >
                                        <Stack
                                            sx={{
                                                height: "50px",
                                                width: "50px",
                                                borderRadius: "50%",
                                                background: "var(--app-color-one)",

                                            }}
                                            justifyContent="center"
                                            alignItems="center"
                                        >
                                            {/* <img
                                                src={data?.image}
                                                style={{
                                                    width: "50px",
                                                }}
                                            /> */}
                                            {item?.icon()}
                                        </Stack>
                                        <Box
                                            sx={{
                                                width: "80%",
                                            }}
                                        >
                                            <Typography
                                                sx={{
                                                    fontWeight: "700",
                                                    fontSize: "15px",
                                                }}
                                                noWrap
                                            >
                                                Alec benjamin
                                            </Typography>
                                            <Typography
                                                sx={{
                                                    fontWeight: "400",
                                                    fontSize: "12px",
                                                    color: "gray",
                                                }}
                                                noWrap
                                            >
                                                Account Manager
                                            </Typography>
                                        </Box>

                                    </Stack>
                                </Stack>
                            ))}
                        </Stack>
                    </Box>
                </Box>
            </Grid>
        </Grid>
    )
}

export default BlogsTestimonialSection;