import React, { useState, useCallback } from 'react'
import { Box, IconButton, Grid, Typography, Autocomplete, TextField, MenuItem } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import PropTypes from 'prop-types';
import InputLabel from '@mui/material/InputLabel';
import CloseIcon from '@mui/icons-material/Close';

export default function AutoComplete({
    id,
    placeholder,
    label,
    startAdornment,
    options,
    variant,
    value,
    setValue,
    setFormikValue,
    deleteOption,
    sx,
    error,
    helperText,
    required,
    disabled,
    onChange,
    actions,
    multiple,
    createNewHandler,
    valueAccessor,
    labelAccessor,
    onInputChange,
    // onKeyUp,
    ...rest
}) {

    /***
     * 
     * Note: Multiselect is not applicable here
     * 
     */
    const [inputValue, setInputValue] = useState("");
    const [menuOpen, setMenuOpen] = useState(false);


    const handleValueChange = (event, newValue) => {

        if (newValue !== '') {
            setValue(newValue?.[valueAccessor]);
            setFormikValue(id, newValue?.[valueAccessor]);
            onChange(newValue);
        };
    };

    const handleCreateOption = (event) => {
        handleValueChange(null, '');
        setMenuOpen(false);
    };

    return (
        <Box>
            {label ? <InputLabel required={required}>{label}</InputLabel> : null}
            <Autocomplete
                sx={[
                    sx,
                    {
                        // border: '1px solid var(--border-color-1)',
                    },
                    error
                        ? {
                            border: '1px solid var(--error-color)',
                        }
                        : null,
                ]}
                {...rest}
                disabled={disabled}
                options={options}
                value={
                    options &&
                    options.length > 0 &&
                    value !== undefined &&
                    (() => {
                        const filteredOptions = options.filter((opt) => opt[valueAccessor] === value);
                        return filteredOptions.length > 0 ? filteredOptions[0] : null;
                    })()
                }
                isOptionEqualToValue={(option, value) =>
                    option.value === value.value
                }
                // getOptionSelected={useCallback((option, value) => option.value === value.value)}
                onChange={handleValueChange}
                onOpen={() => setMenuOpen(true)}
                onClose={() => { setMenuOpen(false); setInputValue("") }}
                getOptionLabel={(option) => {
                    if (!option || !option.label) {
                        return inputValue;
                    }
                    return option.label;
                }}
                renderInput={(params) => {
                    return (
                        <TextField
                            {...params}
                            onChange={(e) => { { onInputChange ? onInputChange(e) : null } setInputValue(e.target.value) }}
                            variant={variant}
                            placeholder={placeholder}

                        />
                    )
                }}
                renderOption={(props, option, { selected }) => {
                    return (
                        <MenuItem
                            {...props}
                            key={props?.id}
                            sx={{ justifyContent: "space-between", fontSize: "13px" }}
                        >
                            {option?.label}
                            <Box sx={{ marginLeft: "auto" }}>
                                {selected ? (
                                    <IconButton size="small" onClick={(e) => e.stopPropagation()}>
                                        <CheckIcon fontSize='small' sx={{ marginLeft: "auto", color: "var(--border-color-1)" }} />
                                    </IconButton>
                                ) : null}
                                {deleteOption ? (
                                    <IconButton size="small" onClick={(e) => {
                                        e.stopPropagation();
                                        deleteOption(option?.value);
                                    }}>
                                        <CloseIcon fontSize='small' sx={{ color: "var(--border-color-1)" }} />
                                    </IconButton>
                                ) : null}

                            </Box>
                        </MenuItem>
                    )
                }}
                noOptionsText={
                    <Box sx={{
                        padding: "5px",
                        cursor: "pointer"
                    }}
                        onClick={(e) => {
                            { createNewHandler ? createNewHandler(inputValue) : null };
                            handleCreateOption(e);
                        }}
                    >Create new '{inputValue}'</Box>

                }
                open={menuOpen}
            />
            {error ? (
                <Typography
                    variant="body2"
                    sx={{
                        fontSize: '11px',
                        fontWeight: '400',
                        color: 'var(--error-color)',
                    }}
                >
                    {helperText}
                </Typography>
            ) : null}
        </Box>
    )
}



AutoComplete.propTypes = {
    placeholder: PropTypes.string,
    label: PropTypes.string,
    options: PropTypes.array,
    startAdornment: PropTypes.node,
    sx: PropTypes.object,
    error: PropTypes.bool,
    helperText: PropTypes.string,
    setValue: PropTypes.func,
    setFormikValue: PropTypes.func,
    required: PropTypes.bool,
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
    actions: PropTypes.array,
    valueAccessor: PropTypes.string,
    labelAccessor: PropTypes.string,
    value: PropTypes.any,
};

AutoComplete.defaultProps = {
    placeholder: null,
    label: null,
    options: [],
    startAdornment: <></>,
    sx: {},
    error: false,
    helperText: null,
    setValue: function () { },
    setFormikValue: function () { },
    required: false,
    disabled: false,
    onChange: function () { },
    actions: [],
    valueAccessor: 'value',
    labelAccessor: 'label',
};




// follow this format for better understanding

{/* <AutoComplete
    id="list"
    name="list"
    label="Select List"
    placeholder="Enter list name"
    required
    value={rest.values.dltTemplateId}
    setFormikValue={rest.setFieldValue}
    createNewHandler={(value) => { console.log(value) }}
    error={
        rest.touched.dltTemplateId && Boolean(rest.errors.dltTemplateId)
    }
    selectOnFocus
    // clearOnBlur
    freeSolo
    handleHomeEndKeys
    variant="standard"
    deleteOption={(value) => {
        console.log(value, "jsadassada")
    }}
    helperText={rest.touched.dltTemplateId && rest.errors.dltTemplateId}
    options={(listData || [])
        .map(item => ({
            label: item.title,
            value: item.id,
        }))}
    onInputChange={(e) => {
        if (e.target.value) {
            setFormData({ title: e.target.value, id: "" });
            searchParams.set("list", e.target.value);
            setSearchParams(searchParams);
        }
    }}
    onChange={(e) => {
        console.log(e, "OnChangeValue")
    }}
/>  */}