import React from "react";
import { styled } from "@mui/material/styles";
import { Box, Stack, Typography } from "@mui/material";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import ProfileCreationFrame1 from "./ProfileCreateFrame";
import ProfileCreationFrame2 from "../../../../assets/icons/LandingPage/ProfileCreationFrame2";
import ProfileCreateFrame3 from "./ProfileCreationFrame3";
import DownArrow from "./DownArrow";
import UpArrow from "./LineVector";
import LineVector2 from "./LineVector2";
import {
  HiringTypography,
  HiringSubTypography,
} from "../../../../assets/MUI/globalStyles";
import SpaceCoffeeCupIcon from "../../../../assets/icons/LandingPage/SpaceCoffeeCupIcon";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

function HiringProcessSection() {
  return (
    <>
      <Grid
        container
        spacing={0}
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          mx: "auto",
          paddingBottom: "5px",
        }}
      >
        <Grid
          sm={12}
          md={12}
          item
          sx={{ textAlign: "center", marginBottom: "30px" }}
          spacing={1}
        >
          <Stack
            direction="column"
            spacing={2}
            sx={{
              position: "relative",
            }}
          >
            <HiringTypography
              variant="h2"
            >
              Joinee hiring process
            </HiringTypography>
            <HiringSubTypography variant="h4">
              As easy as pie. Create completely customizable conversational
              surveys and
              <br /> traditional forms in only a couple of steps.
            </HiringSubTypography>
          </Stack>
        </Grid>

        <Grid
          item
          sx={{
            width: "auto",
            display: "flex",
            height: "fit-content",
            justifyContent: "space-between",
            alignItems: "center",
            margin: "auto",
            gap: "10px",
            padding: "8px",
            flexDirection: "row",
            "@media (max-width: 600px)": {
              width: "100%",
              flexDirection: "column",
            },
            "@media (max-width: 900px)": {
              width: "90%",
              flexDirection: "column",
              gap: "20px",
              marginBottom: "30px",
            },
            "@media (min-width:1200px)": {
              width: "80%",
            },
          }}
        >
          <Stack>
            <ProfileCreationFrame1 width={"100%"} />
          </Stack>
          <Stack direction="column" sx={{ position: "relative" }} spacing={1}>
            <Stack direction="row" spacing={2}>
              <Box
                sx={{
                  border: "1px solid #066a4c",
                  color: "#fff",
                  padding: "8px 15px",
                  fontSize: "15px",
                  borderRadius: "14px",
                  background: "#066a4c",
                  cursor: "pointer",
                  "&:hover": {
                    background: "#fff",
                    color: "#000",
                  },
                }}
              >
                STEP 1
              </Box>
              <HiringTypography variant="h2">
                Sign up with joinee
              </HiringTypography>
            </Stack>
            <HiringSubTypography variant="h4">
              Select from one of our form types and use our <br /> handcrafted
              form builder to easily build forms using our <br /> 15+ form
              fields, logic and much more.
            </HiringSubTypography>
          </Stack>
        </Grid>

        <Grid
          item
          sx={{
            width: "auto",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            "@media (max-width: 600px)": {
              width: "100%",
              display: "none",
            },
            "@media (max-width: 900px)": {
              width: "90%",
              display: "none",
            },
            "@media (min-width:1200px)": {
              width: "80%",
            },
          }}
        >
          <Box
            sx={{
              width: { lg: "50%" },
              justifyContent: "center",
              alignItems: "center",
              mx: "auto",
            }}
          >
            <DownArrow width={"100%"} />
          </Box>
        </Grid>

        <Grid
          item
          sx={{
            width: "auto",
            display: "flex",
            height: "fit-content",
            justifyContent: "space-between",
            alignItems: "center",
            margin: "auto",
            gap: "10px",
            padding: "8px",
            flexDirection: "row",
            "@media (max-width: 600px)": {
              width: "100%",
              flexDirection: "column",
              flexDirection: "column-reverse",
            },
            "@media (max-width: 900px)": {
              width: "90%",
              flexDirection: "column-reverse",
              gap: "20px",
              marginBottom: "30px",
            },
            "@media (min-width:1200px)": {
              width: "80%",
            },
          }}
        >

          <Stack
            direction="column"
            sx={{ position: "relative" }}
            spacing={1}
          >
            <Stack direction="row" spacing={2}>
              <Box
                sx={{
                  border: "1px solid #066a4c",
                  color: "#fff",
                  padding: "8px 15px",
                  fontSize: "15px",
                  borderRadius: "14px",
                  background: "#066a4c",
                  // width: "142.35px",
                  cursor: "pointer",
                  "&:hover": {
                    background: "#fff",
                    color: "#000",
                  },
                }}
              >
                STEP 2
              </Box>
              <HiringTypography variant="h2">
                Post Requirement
              </HiringTypography>
            </Stack>
            <HiringSubTypography variant="h4">
              Pick one of our stock themes, or create your custom
              <br /> theme with the most advanced theme editor on any <br />{" "}
              online survey building tool.
            </HiringSubTypography>
          </Stack>
          <Stack>
            <ProfileCreationFrame2 width={"100%"} />
          </Stack>
        </Grid>

        <Grid
          item
          sx={{
            width: "auto",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            "@media (max-width: 600px)": {
              width: "100%",
              display: "none",
            },
            "@media (max-width: 900px)": {
              width: "90%",
              display: "none",
            },
            "@media (min-width:1200px)": {
              width: "80%",
            },
          }}
        >
          <Box
            sx={{
              width: { lg: "50%" },
              justifyContent: "center",
              alignItems: "center",
              mx: "auto",
            }}
          >
            <UpArrow width={"100%"} />
          </Box>
        </Grid>

        <Grid
          item
          sx={{
            width: "auto",
            display: "flex",
            height: "fit-content",
            justifyContent: "space-between",
            alignItems: "center",
            margin: "auto",
            gap: "10px",
            padding: "8px",
            flexDirection: "row",
            "@media (max-width: 600px)": {
              width: "100%",
              flexDirection: "column",
            },
            "@media (max-width: 900px)": {
              width: "90%",
              flexDirection: "column",
              gap: "20px",
              marginBottom: "30px",
            },
            "@media (min-width:1200px)": {
              width: "80%",
            },
          }}
        >
          <Stack>
            <ProfileCreateFrame3 width={"100%"}/>
          </Stack>
          <Stack
            direction="column"
            sx={{
              position: "relative",
              "@media (max-width:1200px)": {
                paddingLeft: "20px",
              },
            }}
            spacing={1}
          >
            <Stack direction="row" spacing={1}>
              <Box
                sx={{
                  border: "1px solid #066a4c",
                  color: "#fff",
                  padding: "8px 15px",
                  fontSize: "15px",
                  borderRadius: "14px",
                  background: "#066a4c",
                  cursor: "pointer",
                  "&:hover": {
                    background: "#fff",
                    color: "#000",
                  },
                }}
              >
                STEP 3
              </Box>
              <HiringTypography variant="h2">
                Assign to account manager
              </HiringTypography>
            </Stack>
            <HiringSubTypography variant="h4">
              Share your forms with the outside world by embedding <br />
              it on your website or by sharing it via email or social
              <br /> media.
            </HiringSubTypography>
          </Stack>
        </Grid>
        <Grid
          item
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            alignItems: "center",
            width: "80%",
            "@media (max-width: 600px)": {
              display: "none",
            },
            "@media (max-width: 900px)": {
              display: "none",
            },
          }}
        >
          <Box sx={{ width: "50%" }}>
            <LineVector2 width={"200"} />
          </Box>
        </Grid>
        <Grid
          sm={12}
          md={12}
          lg={12}
          sx={{ textAlign: "center" }}
          spacing={2}
        >
          <Stack
            direction="column"
            sx={{
              position: "relative",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: "8px",
            }}
            spacing={1}
          >
            <SpaceCoffeeCupIcon />
            <HiringTypography variant="h2">Sit back & Relax</HiringTypography>
            <HiringSubTypography variant="h4">
              Have your data collected easily by saving responses
              <br /> that aren't submitted, having your respondents
              <br /> continue when they come back and much more!
            </HiringSubTypography>
            <Box
              sx={{
                border: "1px solid #066a4c",
                color: "#fff",
                padding: "5px 15px",
                fontSize: "15px",
                borderRadius: "50px",
                background: "#066a4c",
                cursor: "pointer",
                "&:hover": {
                  background: "#fff",
                  color: "#000",
                },
                "@media (max-width: 900px)": {
                  fontSize: "12px",
                },
              }}
            >Request a demo</Box>
          </Stack>
        </Grid>
      </Grid>
    </>
  );
}

export default HiringProcessSection;
