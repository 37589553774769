import React, { useRef, useEffect, useState } from 'react';
import { Grid, Stack, Typography, Box, Chip } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import ArrowIcon from '../../../../assets/icons/LandingPage/ArrowIcon';
import ArrowRight from '../../../../assets/icons/LandingPage/ArrowRight';
import ArrowLeft from '../../../../assets/icons/LandingPage/ArrowLeft';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
    carouselStyle: {
        overflow: "visible !important",
        zIndex: 0,
        position: "relative",
        // background: "#fff",
        // borderRadius: "10px",
    },

});
function CompanyTestimonialSection() {

    const navigate = useNavigate();
    const [scrollData, setScrollData] = useState(null);
    const [slideData, setSlideData] = useState(null);

    const carouselRef = useRef();


    const classes = useStyles();
    const responsive = {
        superLargeDesktop: {
            breakpoint: { max: 4000, min: 3000 },
            items: 5,
        },
        desktop2: {
            breakpoint: { max: 3001, min: 1600 },
            items: 4,
        },
        desktop1: {
            breakpoint: { max: 1601, min: 1000 },
            items: 3,
            // gap: 2,
        },
        tablet2: {
            breakpoint: { max: 1001, min: 770 },
            items: 2,
        },
        tablet1: {
            breakpoint: { max: 771, min: 464 },
            items: 1,
        },
        mobile: {
            breakpoint: { max: 465, min: 0 },
            items: 1,
        },
    };

    const storyData = [
        {
            id: "1",
            image: "https://cdn-icons-png.flaticon.com/512/9308/9308310.png",
            name: "Hari Thota",
            title: "President, SRI Tech Solutions",
            color: "#e9f7d2",
            hover: "#f0f988",
            desc: "Joinee has been a valuable partner in helping us grow our team. We used Joinee because it helps us quickly find great talent globally in the ultra-competitive market climate."
        },
        {
            id: "2",
            image: "https://cdn-icons-png.flaticon.com/512/9308/9308310.png",
            name: "Abhishek Mukherjee",
            title: "Head of Talent & Recruitment, Invences.Inc",
            color: "#d4e9e1",
            hover: "#9be2c7",
            desc: "I recently had an opportunity to work with Joinee when I was hiring for my company. It was a great experience! They have such a wide variety of qualified engineers, and they responded to my request very quickly."
        },
        {
            id: "3",
            image: "https://cdn-icons-png.flaticon.com/512/9308/9308310.png",
            name: "Neel Jadhav",
            title: "VP, Marketing- Katonic AI",
            color: "#d9e2f5",
            hover: "#b0c0e2",
            desc: "The current market is hard to find qualified people, but Joinee helped us to find an efficient way to hire. The hiring process was so easy and fast, I would say it took less than half the time it would have taken without them."
        },
        {
            id: "4",
            image: "https://cdn-icons-png.flaticon.com/512/9308/9308310.png",
            name: "Dinesh",
            title: "COO, Worke",
            color: "#d4e9e1",
            hover: "#a2d2c0",
            desc: "They has brought us very good solutions so far. You can count us as happy customers."
        },
        {
            id: "5",
            image: "https://cdn-icons-png.flaticon.com/512/9308/9308310.png",
            name: "Karim Sayani",
            title: "Head Of Technology, zolaa",
            color: "#e9f7d2",
            hover: "#f0f988",
            desc: "Big kudos to Joinee Team. I would highly recommend them anyone who is looking for remote development in any technology."
        },

    ];

    const CustomButtonGroup = ({ next, previous, goToSlide, ...rest }) => {
        const { carouselState: { currentSlide, slidesToShow }, } = rest;
        setScrollData(slidesToShow);
        setSlideData(currentSlide);
    };


    return (
        <Grid container sx={{ paddingTop: "100px", paddingBottom: "100px", }}>
            <Grid item xs={12} sm={12} >
                <Stack sx={{ width: "100%", margin: "auto" }} spacing={2}>
                    <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="flex-start"
                    >
                        <Chip label="TESTIMONIALS" onClick={() => { }}
                            sx={{
                                background: "#E6F0ED",
                                '& .MuiChip-label': {
                                    fontWeight: "700",
                                },
                            }}
                        />
                    </Stack>
                    <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="space-between"
                    >
                        <Typography
                            sx={{
                                fontSize: "45px",
                                fontWeight: "500",
                                "@media (max-width: 900px)": {
                                    fontSize: "20px",
                                },
                            }}
                        >Testimonials</Typography>

                        <Stack direction="row" spacing={3}>
                            <Box
                                sx={{
                                    border: "1px solid gray",
                                    height: "40px",
                                    width: "40px",
                                    borderRadius: "50%",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    opacity: slideData == 0 && "0.5",
                                    cursor: "pointer"
                                }}
                                onClick={() => {
                                    if (carouselRef?.current) {
                                        carouselRef?.current?.previous();
                                    }
                                }}
                            ><ArrowLeft /></Box>
                            <Box
                                sx={{
                                    border: "1px solid gray",
                                    height: "40px",
                                    width: "40px",
                                    borderRadius: "50%",
                                    display: "flex",
                                    justifyContent: "center",
                                    opacity: slideData * scrollData === storyData?.length + 1 && "0.5",
                                    alignItems: "center",
                                    cursor: "pointer"
                                }}
                                onClick={() => {
                                    if (carouselRef?.current) {
                                        carouselRef?.current?.next();
                                    }
                                }}
                            ><ArrowRight /></Box>
                        </Stack>
                    </Stack>
                </Stack>
            </Grid>
            <Grid
                item
                xs={12}
                sm={12}
                mt={3}
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "40px",
                    position: "relative",
                }}
            >
                <Stack
                    sx={{
                        // overflow: "hidden",
                        margin: "auto",
                        width: "100%",
                        "@media (max-width: 600px)": {
                        },
                    }}
                >

                    <Carousel
                        ref={carouselRef}
                        responsive={responsive}
                        className={classes.carouselStyle}
                        arrows={false}
                        renderButtonGroupOutside={true}
                        customButtonGroup={<CustomButtonGroup />}
                    >

                        {storyData?.map((data, index) => (
                            <Stack
                                key={index}
                                sx={{
                                    border: "1px solid gray",
                                    borderRadius: "10px",
                                    position: "relative",
                                    width: "340px",
                                    minHeight: "320px",
                                    margin: "auto",

                                    background: data?.hover,
                                    "&:hover > div:first-child": {
                                        transform: "rotate(-15deg) translate(10px,-30px)",
                                        transition: "transform 0.2s",
                                        marginTop: "-35px",
                                        background: data?.hover,
                                    },
                                    "&:hover > div:last-child": {
                                        transition: "transform 0.2s",
                                        marginBottom: "0px",
                                    },
                                    "@media (max-width: 600px)": {
                                        marginRight: "unset",
                                    },
                                }}

                            >
                                <Stack
                                    // p={2}
                                    sx={{
                                        background: data?.color,
                                        position: "absolute",
                                        borderRadius: "10px",
                                        border: "1px solid gray",
                                        height: "100%",
                                        width: "100%",
                                        zIndex: "999",
                                        transition: "transform 0.2s",
                                    }}
                                    pb={2}
                                >
                                    <Stack
                                        direction="row"
                                        justifyContent="space-between"
                                        alignItems="center"
                                        p={2}
                                    >
                                        <Box
                                            sx={{
                                                width: "80%",
                                            }}
                                        >
                                            <Typography
                                                sx={{
                                                    fontWeight: "700",
                                                    fontSize: "15px",
                                                }}
                                                noWrap
                                            >
                                                {data?.name}
                                            </Typography>
                                            <Typography
                                                sx={{
                                                    fontWeight: "400",
                                                    fontSize: "12px",
                                                    color: "gray",
                                                    textTransform: "uppercase"
                                                }}
                                                noWrap
                                            >
                                                {data?.title}
                                            </Typography>
                                        </Box>
                                        <Box
                                            sx={{
                                                width: "50px",
                                            }}
                                        >
                                            <img
                                                src={data?.image}
                                                style={{
                                                    width: "50px",
                                                }}
                                            />
                                        </Box>
                                    </Stack>
                                    <Stack p={2} pt={0}>
                                        <Stack
                                            p={2}
                                            sx={{
                                                background: "#fff",
                                                border: "1px solid gray",
                                                borderRadius: "10px",
                                            }}
                                        >
                                            <Stack
                                                sx={{
                                                    display: "-webkit-box",
                                                    WebkitBoxOrient: "vertical",
                                                    WebkitLineClamp: 6,
                                                    overflow: "hidden",
                                                    minHeight: "120px",
                                                }}
                                            >
                                                <Typography
                                                    sx={{
                                                        fontSize: "16px",
                                                        wordWrap: "break-word",
                                                        overflowWrap: "break-word",
                                                        lineheight: "27.38px",
                                                        letter: "-1.5%"
                                                    }}
                                                >
                                                    {data?.desc}
                                                </Typography>
                                            </Stack>
                                            <Box mt={2}><Link
                                                to="#"
                                                style={{
                                                    color: "var(--text-color)",
                                                    fontSize: "15px",
                                                    fontWeight: "700",
                                                }}
                                            >READMORE <ArrowIcon /> </Link></Box>
                                        </Stack>
                                    </Stack>
                                </Stack>
                                <Stack
                                    direction="row"
                                    justifyContent="center"
                                    alignItms="center"
                                    sx={{
                                        transition: "transform 0.3s ease",
                                        marginTop: "auto",
                                    }}
                                    p={1}
                                >
                                    {/* <Link
                                        to="#"
                                        style={{
                                            color: "var(--text-color)",
                                            fontSize: "15px",
                                            fontWeight: "700",
                                        }}
                                    ><ArrowIcon /> WATCH VIDEO  </Link> */}
                                </Stack>
                            </Stack>
                        ))}

                    </Carousel>
                </Stack>

            </Grid>

        </Grid >
    )
}

export default CompanyTestimonialSection;