export default function LayerIcon() {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3 16.5L12 21L21 16.5" stroke="#737373" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M3 12L12 16.5L21 12" stroke="#737373" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M12 3L3 7.5L12 12L21 7.5L12 3Z" stroke="#737373" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>

    );
}
