import React from "react";
import { Link, useNavigate } from "react-router-dom";
import Button from "@mui/joy/Button";

function CompanyOverViewPage({ company }) {
  return (
    <>
      <div className="">
        <div className="">
          <ul className="company_essential_details">
            <li>
              <div className="sidebar-text-info">
                <span className="text-title_txt">Description</span>
                <span className="comany_details_list_items_culture">
                  {company?.company?.describe_your_culture}
                </span>
              </div>{" "}
            </li>
            <li>
              <div className="sidebar-text-info">
                <span className="text-title_txt">Industry</span>
                <span className="comany_details_list_items_culture">
                  {company?.company?.industry ? company.company.industry : "NA"}
                </span>
              </div>{" "}
            </li>
            <li>
              <div className="sidebar-text-info">
                <span className="text-title_txt">Company Size</span>
                <div className="comany_details_list_items_culture">
                  <span className="">{company?.company?.size_of_company}</span>
                </div>
              </div>
            </li>
            <li>
              <div className="sidebar-text-info">
                <span className="text-title_txt">Founded</span>
                <div className="comany_details_list_items_culture">
                  <span className="">
                    {company?.company?.founded ? company.company.founded : "NA"}
                  </span>
                </div>
              </div>
            </li>
            {/* <li>
              <div className="sidebar-text-info">
                <span className="text-title_txt">Email</span>
                <div className="comany_details_list_items_culture">
                  <span className="">
                    {company?.email ? company.email : "NA"}
                  </span>
                </div>
              </div>
            </li> */}
            {/* <li>
              <div className="sidebar-text-info">
                <span className="text-title_txt">Phone</span>
                <div className="comany_details_list_items_culture">
                  <span className="">
                    {company?.phone_number ? company.phone_number : "NA"}
                  </span>
                </div>
              </div>
            </li> */}
            <li>
              <div className="sidebar-text-info">
                <span className="text-title_txt">Location</span>
                <span className="comany_details_list_items_culture">
                  {company?.company?.location}
                </span>
              </div>
            </li>

            {/* <li>
              <div className="sidebar-text-info">
                <div className="company_perk_container">
                  <a href={company?.company?.google_reviews}>
                    <Button
                      variant="outlined"
                      className="apply-now-btn cursor-pointerf"
                      fullWidth
                      style={{
                        background: "#ffffff",
                        color: "#111111",
                        fontFamily: "Plus Jakarta Display,sans-serif",
                        border: "1px solid #EEEEEE",
                        fontWeight: "400",
                        fontSize: "16px",
                        marginRight: "23px",
                        borderRadius: "24px",
                      }}
                    >
                      View {company?.company?.company_name}
                    </Button>
                  </a>
                </div>
              </div>
            </li>

            <li>
              <div className="sidebar-text-info">
                <div className="company_perk_container">
                  <a href="">
                    <Button
                      variant="outlined"
                      className="apply-now-btn cursor-pointerf"
                      fullWidth
                      style={{
                        background: "#ffffff",
                        color: "#012480",
                        fontFamily: "Plus Jakarta Display,sans-serif",
                        border: "1px solid rgba(25, 103, 210, 0.23)",
                        fontWeight: "400",
                        fontSize: "16px",
                        marginRight: "23px",
                        borderRadius: "24px",
                      }}
                    >
                      Send Message
                    </Button>
                  </a>
                </div>
              </div>
            </li> */}

            <li>
              <div className="sidebar-text-info">
                <span className="text-title">
                  Where you can find this company
                </span>
                <div className="d-flex align-items-center gap-2">
                  <Link
                    className="comany_details_list_items"
                    target={"_blank"}
                    to={`//${company?.company?.google_reviews}`}
                  >
                    <i className="bx bxl-google"></i>
                  </Link>
                  <Link
                    className="comany_details_list_items"
                    target={"_blank"}
                    to={`//${company?.company?.linkedin_url}`}
                  >
                    <i className="bx bxl-linkedin"></i>
                  </Link>
                  <Link
                    className="comany_details_list_items"
                    target={"_blank"}
                    to={`//${company?.company?.facebook_url}`}
                  >
                    <i className="bx bxl-facebook" undefined></i>
                  </Link>
                  <Link
                    className="comany_details_list_items"
                    target={"_blank"}
                    to={`//${company?.company?.twitter_url}`}
                  >
                    <i className="bx bxl-twitter"></i>
                  </Link>
                </div>
              </div>
            </li>
          </ul>
          {/* <span className="text-title">Company Perks</span>
          <hr />
          <div className="company_perk_container">
            {company?.company?.perks?.map(perk => (
              <span className="chip_perk">{perk}</span>
            ))}
          </div> */}
        </div>
      </div>
    </>
  );
}

export default CompanyOverViewPage;
