import React, { useEffect } from "react";
import "../../assets/JobList/MainSerachFilter.css";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { PlacesAutocomplete } from "../../data/locationSearch";
import { Form } from "reactstrap";
import { Search } from "@mui/icons-material";
import { useForm } from "react-hook-form";
import {globalTheme} from '../../assets/MUI/globalStyles';
import { ThemeProvider } from "@mui/material/styles";
import {  OutlinedInput,Button } from "@mui/material";

function MainSearchFilterBar({ setJobsList, setLoad, limit, offset }) {
  const [searchLocation, setSearchLocation] = useState("");
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();
  let [searchParams, setSearchParams] = useSearchParams();
  useEffect(() => {
    setValue("job_name", searchParams.get("title"));
  }, [searchParams.get("title")]);
  const onSubmit = async data => {
     setSearchParams({
      title: data.job_name?data.job_name:"",
      location: searchLocation,
    });
  };

  return (
    <>
    <ThemeProvider theme={globalTheme}>
      <section className="section-box-2">
        <div className="none-bg">
          <div className="comp_filter_search_cont">
            <div className="box-shadow-bdrd-15 box-filters">
              <Form className="row" onSubmit={handleSubmit(onSubmit)}>
                <div className="col-lg-5 col-sm-12 col-md-5 ">
                  <OutlinedInput
                    startDecorator={
                      <Search
                        style={{
                          color: `${errors.job_name ? "danger" : "#066a4c"}`,
                        }}
                      />
                    }
                    type="text"
                    className="input-search-job mb-1"
                    error={errors.job_name ? true : false}
                    {...register("job_name", { required: false })}
                    placeholder="Job title or Company name..."
                    sx={{width:"100%"}}
                    onChange={(e) => {
                      if(searchParams.get("title")) {
                        if(e.target.value?.length === 0) {
                          searchParams.set("title", e.target.value);
                          setSearchParams(searchParams);
                        }
                      }
                    }}
                  />
                </div>
                <div className="col-lg-5 col-sm-12 col-md-5">
                  <div className="box-search-job search-job-cont">
                    <div className="form-search-job search-job-cont">
                      <PlacesAutocomplete
                        className="input-search-location"
                        location={searchLocation}
                        setLocation={e => {
                          setSearchLocation(e);
                          if(searchParams.get("location")) {
                            if(e?.length === 0) {
                              searchParams.set("location", e);
                              setSearchParams(searchParams);
                            }
                          }
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className=" col-lg-2 col-sm-12 col-md-2 find_jobs_btn">
                    <Button
                      type="submit"
                      style={{
                        height: "50px",
                        width: "100%",
                        fontFamily: "Inter,sans-serif",
                        background: "#cde2e7",
                        color: "#066a4c",
                      }}
                    >
                      Find Jobs
                    </Button>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </section>
      </ThemeProvider>
    </>
  );
}
export default MainSearchFilterBar;
