import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import Typography from "@mui/material/Typography";
import { Menu, MenuItem, Stack } from "@mui/material";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import useOnclickOutside from "react-cool-onclickoutside";

export default function JobSearchBox({
  required,
  sx,
  error,
  helperText,
  searchResult,
  icon,
  location,
  setLocation,
  invalid,
  placeHolder,
  className = "",
  ...rest
}) {

  const ITEM_HEIGHT = 48;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    setValue(location);
    return () => {
      setValue("");
    };
  }, [location]);

  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {},
    debounce: 300,
  });
  const ref = useOnclickOutside(() => {
    clearSuggestions();
  });


  const handleInput = e => {
    // setLocation(e.target.value);
    setValue(e.target.value);
  };

  const handleSelect =
    ({ description }) =>
      () => {
        setValue(description, false);
        // setLocation(description);
        clearSuggestions();
        getGeocode({ address: description }).then(results => {
          const { lat, lng } = getLatLng(results[0]);
        });
      };



  const renderSuggestions = () =>
    data.map(suggestion => {
      const {
        place_id,
        structured_formatting: { main_text, secondary_text },
      } = suggestion;

      return (
        <li
          key={place_id}
          onClick={handleSelect(suggestion)}
          className="map_items"
        >
          <strong>{main_text}</strong> <small>{secondary_text}</small>
        </li>
      );
    });



  return (
    <Box>

      {/**
       *
       *
       * input field
       *
       */}
      <Stack direction="row" spacing={1}>
        {icon && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            {icon()}
          </Box>
        )}
        <span ref={ref} style={{ position: "relative", height: "100%", width: "100%" }}>
          <input
            value={value}
            onChange={handleInput}
            disabled={!ready}
            style={{ border: "none", height: "100%", width: "100%", outline: "none", borderRadius: "0px", background: "transparent", ...sx }}
            placeholder={placeHolder}
            invalid={invalid}
            varient="outline"
            {...rest}
          />
          {status === "OK" && <ul className="map_list" style={{ zIndex: 999, position: "absolute", background: "white" }}>{renderSuggestions()}</ul>}
        </span>

      </Stack>



      {/* {searchResult ? (
        <Menu
          id="long-menu"
          MenuListProps={{
            'aria-labelledby': 'long-button',
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          PaperProps={{
            style: {
              maxHeight: ITEM_HEIGHT * 4.5,
              width: '20ch',
            },
          }}
        >
          {searchResult.map((option) => (
            <MenuItem key={option} selected={option === 'Pyxis'} onClick={handleClose}>
              {option}
            </MenuItem>
          ))}
        </Menu>
      ) : null} */}
    </Box>
  );
}
