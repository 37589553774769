export default function ServerIcon() {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g opacity="0.12">
                <path d="M1.99936 5.2C1.99936 4.0799 1.99936 3.51984 2.21735 3.09202C2.40909 2.71569 2.71505 2.40973 3.09138 2.21799C3.5192 2 4.07925 2 5.19936 2H18.7994C19.9195 2 20.4795 2 20.9073 2.21799C21.2837 2.40973 21.5896 2.71569 21.7814 3.09202C21.9994 3.51984 21.9994 4.0799 21.9994 5.2V6.8C21.9994 7.9201 21.9994 8.48016 21.7814 8.90798C21.5896 9.28431 21.2837 9.59027 20.9073 9.78201C20.4795 10 19.9195 10 18.7994 10H5.19936C4.07925 10 3.5192 10 3.09138 9.78201C2.71505 9.59027 2.40909 9.28431 2.21735 8.90798C1.99936 8.48016 1.99936 7.9201 1.99936 6.8V5.2Z" fill="#163300" />
                <path d="M1.99936 17.2C1.99936 16.0799 1.99936 15.5198 2.21735 15.092C2.40909 14.7157 2.71505 14.4097 3.09138 14.218C3.5192 14 4.07925 14 5.19936 14H18.7994C19.9195 14 20.4795 14 20.9073 14.218C21.2837 14.4097 21.5896 14.7157 21.7814 15.092C21.9994 15.5198 21.9994 16.0799 21.9994 17.2V18.8C21.9994 19.9201 21.9994 20.4802 21.7814 20.908C21.5896 21.2843 21.2837 21.5903 20.9073 21.782C20.4795 22 19.9195 22 18.7994 22H5.19936C4.07925 22 3.5192 22 3.09138 21.782C2.71505 21.5903 2.40909 21.2843 2.21735 20.908C1.99936 20.4802 1.99936 19.9201 1.99936 18.8V17.2Z" fill="#163300" />
            </g>
            <path d="M5.99936 6H6.00936M5.99936 18H6.00936M5.19936 10H18.7994C19.9195 10 20.4795 10 20.9073 9.78201C21.2837 9.59027 21.5896 9.28431 21.7814 8.90798C21.9994 8.48016 21.9994 7.92011 21.9994 6.8V5.2C21.9994 4.0799 21.9994 3.51984 21.7814 3.09202C21.5896 2.71569 21.2837 2.40973 20.9073 2.21799C20.4795 2 19.9195 2 18.7994 2H5.19936C4.07925 2 3.5192 2 3.09138 2.21799C2.71505 2.40973 2.40909 2.71569 2.21735 3.09202C1.99936 3.51984 1.99936 4.07989 1.99936 5.2V6.8C1.99936 7.92011 1.99936 8.48016 2.21735 8.90798C2.40909 9.28431 2.71505 9.59027 3.09138 9.78201C3.5192 10 4.07925 10 5.19936 10ZM5.19936 22H18.7994C19.9195 22 20.4795 22 20.9073 21.782C21.2837 21.5903 21.5896 21.2843 21.7814 20.908C21.9994 20.4802 21.9994 19.9201 21.9994 18.8V17.2C21.9994 16.0799 21.9994 15.5198 21.7814 15.092C21.5896 14.7157 21.2837 14.4097 20.9073 14.218C20.4795 14 19.9195 14 18.7994 14H5.19936C4.07925 14 3.5192 14 3.09138 14.218C2.71505 14.4097 2.40909 14.7157 2.21735 15.092C1.99936 15.5198 1.99936 16.0799 1.99936 17.2V18.8C1.99936 19.9201 1.99936 20.4802 2.21735 20.908C2.40909 21.2843 2.71505 21.5903 3.09138 21.782C3.5192 22 4.07925 22 5.19936 22Z" stroke="#163300" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    );
}
