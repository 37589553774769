import React from 'react';
import { Grid, Stack, Typography, Box, Button } from "@mui/material";

function JoinBoxSection() {
    return (
        <Grid container sx={{ paddingTop: "0px", paddingBottom: "20px", width: "90%", margin: "auto" }}>
            <Grid item xs={12} sm={12}>
                <Stack
                    sx={{
                        background: "#163300",
                        padding: "10px",
                        paddingTop: "70px",
                        paddingBottom: "70px",
                    }}
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                >
                    <Typography
                        sx={{
                            fontSize: "50px",
                            fontWeight: "600",
                            width: "60%",
                            color: "#fff",
                            textAlign: "center",
                            "@media (max-width: 1600px)": {
                                fontSize: "40px",
                            },
                            "@media (max-width: 1200px)": {
                                fontSize: "35px",
                            },
                            "@media (max-width: 900px)": {
                                fontSize: "30px",
                                width: "100%",
                            },
                            "@media (max-width: 600px)": {
                                fontSize: "25px",
                                width: "100%",
                            },
                        }}
                    >Experience the seamless recruitment through joinee</Typography>
                    <Typography
                        sx={{
                            fontSize: "15px",
                            fontWeight: "400",
                            width: "60%",
                            textAlign: "center",
                            color: "#FFFFFF",
                            "@media (max-width: 900px)": {
                                fontSize: "12px",
                                width: "100%",
                            },
                            "@media (max-width: 600px)": {
                                fontSize: "11px",
                                width: "100%",
                            },
                        }}
                    >Join over 300+ partners and customers already growing with joinee</Typography>
                    <Stack
                        direction="row"
                        spacing={2}
                        sx={{
                            width: "fit-content",
                            marginTop: "30px"
                        }}
                    >
                        <Button
                            type="submit"
                            size="large"
                            onClick={() => { console.log("Fired...") }}
                            loading={false}
                            loadingPosition="end"
                            variant="contained"
                            sx={{
                                borderRadius: "50px",
                                boxShadow: "none",
                                width: "fit-content",
                                background: "#9FE870",
                                color: "#000",
                                padding: "8px 30px",
                                "&:hover": {
                                    background: "#fff",
                                }
                            }}
                        >
                            <span style={{ textTransform: "capitalize", fontWeight: "600" }}>Join now</span>
                        </Button>
                        <Button
                            type="submit"
                            size="large"
                            onClick={() => { console.log("Fired...") }}
                            loading={false}
                            loadingPosition="end"
                            variant="contained"
                            sx={{
                                borderRadius: "50px",
                                boxShadow: "none",
                                width: "fit-content",
                                background: "#fff",
                                color: "#000",
                                padding: "8px 30px",
                                "&:hover": {
                                    background: "#9FE870",
                                }
                            }}
                        >
                            <span style={{ textTransform: "capitalize", fontWeight: "600" }}>Contact Sales</span>
                        </Button>
                    </Stack>
                </Stack>
            </Grid>
        </Grid>
    )
}

export default JoinBoxSection