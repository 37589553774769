import React, { useRef, useEffect, useState } from 'react';
import { Grid, Stack, Typography, Box, Button } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import ArrowRight from '../../../../assets/icons/LandingPage/ArrowRight';
import ReferContent from '../../../../assets/icons/LandingPage/ReferContent.svg';

function ApplyForJobSection() {

    return (
        <Grid container sx={{ paddingTop: "50px", paddingBottom: "50px", }}>
            <Grid item xs={12} sm={12} >
                <Stack sx={{ gap: "10px" }}>
                    <Link to="#"
                        style={{
                            color: "#1877F2",
                            fontWeight: "500",
                            fontSize: "15px",
                            cursor: "ponter",
                            textAlign: "center"
                        }}
                    >HOW IT WORK</Link>
                    <Typography
                        sx={{
                            fontSize: "48px",
                            fontWeight: "500",
                            textAlign: "center"
                        }}
                    >Apply for a job</Typography>
                </Stack>
            </Grid>
            <Grid
                item
                xs={12}
                sm={12}
                mt={3}
                sx={{
                    position: "relative",
                    background: "#F7F9FF",
                    display: "grid",
                    gap: "10px",
                    gridTemplateColumns: "repeat(2, 1fr)",
                    paddingTop: "40px",
                    paddingBottom: "0px",
                    paddingLeft: "5%",
                    paddingRight: "5%",
                    "@media (max-width: 600px)": {
                        gridTemplateColumns: "repeat(1, 1fr)",
                        paddingBottom: "10px",
                    },
                }}
            >
                <Stack
                    justifyContent="center"
                    alignItems="center"
                >
                    <Box>
                        <img src={ReferContent} alt="apply now" />
                    </Box>
                </Stack>
                <Stack
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    sx={{
                        gap: "20px",
                        paddingBottom: "10px",
                    }}
                >
                    <Box>
                        <Typography
                            sx={{
                                fontWeight: "600",
                                fontSize: "40px",
                            }}
                        >Make it dead easy for you to apply for top jobs through joinee.</Typography>
                    </Box>
                    <Box>
                        <Typography
                            sx={{
                                fontWeight: "700",
                                fontSize: "14px",
                            }}
                        >Online payments are easier to process, give you more information online, and make processing faster.</Typography>
                    </Box>
                    <Box>
                        <Typography
                            sx={{
                                fontWeight: "500",
                                fontSize: "16px",
                                color: "#757B8A"
                            }}
                        >Payment with us is easy and straight forward, you can pay in dollars, euros and bitcoin or ether tokens.We accept all major currencies and cryptocurrencies</Typography>
                    </Box>
                    <Box>
                        <Button
                            type="submit"
                            size="large"
                            onClick={() => { console.log("Fired...") }}
                            loading={false}
                            loadingPosition="end"
                            variant="contained"
                            sx={{
                                borderRadius: "50px",
                                boxShadow: "none",
                                background: "var(--app-button-color1)",
                                padding: "8px 30px",
                                "&:hover": {
                                    boxShadow: "none",
                                    background: "var(--app-button-color1)",
                                }
                            }}
                        >
                            <span style={{ textTransform: "capitalize", fontWeight: "700" }}>Apply for job</span>
                        </Button>
                    </Box>
                </Stack>
            </Grid>

        </Grid >
    )
}

export default ApplyForJobSection;