import React, { useEffect, useState, lazy, Suspense, memo } from "react";
import MainSearchFilterBar from "../MainSearchFilterBar/MainSearchFilterBar";
import JobsList from "./Alljobs/JobsList";
import {
  findAllJobs,
  findAllFuncArea,
  findJobTypes,
  findKeySkills,
  findSalaryRangeTypes,
  findWorkMode,
} from "../../helpers/dataFetcher/jobs/jobs";
import {
  fetchFilteredJobs,
  applyFilteredJobs,
} from "../../helpers/dataFetcher/jobs/jobs";
import SidePanelFilters from "./JobsListSidePanel/SidePanelFilter";
import { useSearchParams } from "react-router-dom";
// import SmallScreenFilter from "../MainSearchFilterBar/smallScreenFilter";
import SocketConnection from "../../hooks/socket";
import Button from "@mui/joy/Button";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import { useSelector } from "react-redux";
import "../../assets/css/Jobs/jobsPage.css";

const SmallScreenFilter = lazy(() =>
  import("../MainSearchFilterBar/smallScreenFilter")
);

function MainJobsList() {
  const [load, setLoad] = useState(true);
  const [jobsList, setJobsList] = useState([]);
  const [filterJobslist, setFilterJobsList] = useState([])
  const [searchJobsList, setSearchJobsList] = useState([])
  const [saveJobsList, setSaveJobsList] = useState([])
  const [saveCheck, setSaveCheck] = useState('')
  const [serched, setSeached] = useState(true);
  const [functionalArea, setFunctionalArea] = useState();
  const [jobTypes, setJobTypes] = useState();
  const [work, setWork] = useState();
  const [keySkills, setKeySkills] = useState();
  const [filterList, setFilterList] = useState([]);
  const [salaryRange, setSalaryRange] = useState();
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(3);
  const [filterApplied, setFilterApplied] = useState(true);
  const [workMode, setWorkMode] = useState();
  const [filterClear, setFilterClear] = useState(false);

  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10)
  const [totalPages, setTotalPages] = useState(1)

  /*search */

  const [funcSearch, setFuncSearch] = useState('')
  const [keySkillSearch, setKeySkillSearch] = useState('')


  const {
    lastMessage,
    sendMessage: sendSocketMessage,
    connectionStatus,
  } = SocketConnection();
  const userData = useSelector(state => state.User);

  useEffect(() => {
    // window.scrollTo(0, 0);
    setLoad(true);
    fetchAllJobs();
  }, []);

 
  let [searchParams, _] = useSearchParams();



  useEffect(()=>{
    if(hasMore){
      filterJobsBased()
    }
  },[page])

  useEffect(()=>{
  setSearchJobsList([])
  },[searchParams.get("location"), searchParams.get("title")])

  
 useEffect(()=>{
setFilterJobsList([])
 },[searchParams.get("fn_areas"),
 searchParams.get("job_type"),
 searchParams.get("exp"),
 searchParams.get("salary"),
 searchParams.get("key_skills"),
 searchParams.get("work_mode")])

  useEffect(() => {
    // console.log("filters")
    // setPage(1)
    setTimeout(()=>{
      fechJobsBasedonOffset(
        searchParams.get("title"),
        searchParams.get("location"),
        searchParams.getAll("fn_areas"),
        searchParams.getAll("job_type"),
        searchParams.get("exp"),
        searchParams.getAll("salary"),
        searchParams.getAll("key_skills"),
        searchParams.getAll("work_mode"),
      );
    },600)
 
  }, [
    limit,
    offset,
    searchParams.get("title"),
    searchParams.get("location"),
    searchParams.get("fn_areas"),
    searchParams.get("job_type"),
    searchParams.get("exp"),
    searchParams.get("salary"),
    searchParams.get("key_skills"),
    searchParams.get("work_mode"),
  ]);

 

  useEffect(() => {
    setPage(1)
  }, [
    limit,
    offset,
    searchParams.get("title"),
    searchParams.get("location"),
    searchParams.get("fn_areas"),
    searchParams.get("job_type"),
    searchParams.get("exp"),
    searchParams.get("salary"),
    searchParams.get("key_skills"),
    searchParams.get("work_mode"),
  ]);

  const setSeshuModal = () => {
    filterJobsBased();
  };

  

  /* Testing purpose of filters Bug */

  /*candidate id passing test in progress start*/
  const fechJobsBasedonOffset = async (
    title,
    location,
    fn_area,
    job_type,
    exp,
    salary,
    key_skills,
    work_mode,
    
  ) => {


 // Sample input data
const inputData = {
  title: title,
  location: location, // or any other value
};


// Check if title and location have values
const hasTitle = inputData.title !== undefined && inputData.title !== null && inputData.title !== '';
const hasLocation = inputData.location !== undefined && inputData.location !== null && inputData.location !== '';

// Construct searchParam based on the conditions
let searchParams = '';
let locationSearch = location



// if (hasTitle && hasLocation) {
//   // Both title and location have values
//   searchParams = `${inputData.title}-${inputData.location}`;
// } else if (hasTitle) {
//   // Only title has a value
//   searchParams = inputData.title;
// } 
// // else if (hasLocation) {
// //   // Only location has a value
// //   searchParams = inputData.location;
// // }

if(hasTitle){
  searchParams = inputData.title;
}

// Output the result



    if (localStorage.getItem("token") !== null && hasMore) {
  
      const response = await findAllJobs({
        job_post_title: title,
        location: location,
        job_type: job_type,
        // experience: exp,
        experience: exp ? `0,${exp}` : "",
        salary: salary,
        functional_area: fn_area,
        key_skills: key_skills,
        work_mode: work_mode,
        limit: limit,
        offset: offset,
        pageNo:page,
        pageSize:pageSize,
        candidate_id: userData.user.id,
        searchParam:searchParams
  
      });
    
      if (response.status === 200) {
        setLoad(false);
        setFilterApplied(false);
      }

      if(fn_area.length > 0 || key_skills?.length > 0 || work_mode?.length > 0 || job_type?.length > 0){
        setJobsList([])
        setSearchJobsList([])
        setSaveJobsList([])
        setSaveCheck('')
        let dummyData = [...filterJobslist]
        setFilterJobsList([...response?.data, ...dummyData])
        setHasMore(response?.data?.length > 0);
      }else if(searchParams || locationSearch){
        setJobsList([])
        setFilterJobsList([])
        setSaveJobsList([])
        setSaveCheck('')
        let dummyData = [...searchJobsList]
    
        setSearchJobsList([...response?.data, ...dummyData])
        setHasMore(response?.data?.length > 0);
      }
      else if(searchParams == "" || locationSearch == ""){
    
        setFilterJobsList([])
        setSearchJobsList([])
        // setSaveJobsList([])
        // setSaveCheck('')
        let dummyData = [...jobsList];
    
  
      
        setJobsList([...response?.data, ...dummyData])
        setHasMore(response?.data?.length > 0);
      }
   
      else {
     
        setFilterJobsList([])
        setSearchJobsList([])
        setSaveJobsList([])
        setSaveCheck('')
        let dummyData = [...jobsList];
    
  
      
        setJobsList([...response?.data, ...dummyData])
        // setJobsList((prevData) => [...prevData, ...response?.data]);
        setHasMore(response?.data?.length > 0);
   
      }





   
      if(page === response?.totalPageSize){
        setHasMore(false)
      }
     
  
    } else {
     
      const response = await findAllJobs({
        job_post_title: title,
        location: location,
        job_type: job_type,
        // experience: exp,
        experience: exp ? `0,${exp}` : "",
        salary: salary,
        functional_area: fn_area,
        key_skills: key_skills,
        work_mode: work_mode,
        pageNo:page,
        pageSize:pageSize,
        searchParam:searchParams,
        limit: limit,
        offset: offset,
      });


      // if (response.status === 200) {
      //   setLoad(false);
      //   setFilterApplied(false);
      // }
     
      // let dummyData = [...jobsList]
      // setJobsList([...response?.data, ...dummyData])
   
      // setTotalPages(response?.totalPageSize)
      // setHasMore(response?.data?.length > 0);


      if (response.status === 200) {
        setLoad(false);
        setFilterApplied(false);
      }

      if(fn_area.length > 0 || key_skills?.length > 0 || work_mode?.length > 0 || job_type?.length > 0){
        setJobsList([])
        setSearchJobsList([])
        setSaveJobsList([])
        setSaveCheck('')
        let dummyData = [...filterJobslist]
        setFilterJobsList([...response?.data, ...dummyData])
        setHasMore(response?.data?.length > 0);
      }else if(searchParams || locationSearch){
        setJobsList([])
        setFilterJobsList([])
        setSaveJobsList([])
        setSaveCheck('')
        let dummyData = [...searchJobsList]
    
        setSearchJobsList([...response?.data, ...dummyData])
        setHasMore(response?.data?.length > 0);
      }
      else if(searchParams == "" || locationSearch == ""){
    
        setFilterJobsList([])
        setSearchJobsList([])
        // setSaveJobsList([])
        // setSaveCheck('')
        let dummyData = [...jobsList];
    
  
      
        setJobsList([...response?.data, ...dummyData])
        setHasMore(response?.data?.length > 0);
      }
   
      else {
     
        setFilterJobsList([])
        setSearchJobsList([])
        setSaveJobsList([])
        setSaveCheck('')
        let dummyData = [...jobsList];
    
  
      
        setJobsList([...response?.data, ...dummyData])
        // setJobsList((prevData) => [...prevData, ...response?.data]);
        setHasMore(response?.data?.length > 0);
   
      }
      if(page === response?.totalPageSize){
        setHasMore(false)
      }


    }
  };

  /*candidate id passing test in progress end*/

  const fetchAllJobs = async () => {
    const funcResponse = await findAllFuncArea(funcSearch);
    const fetchJobTypes = await findJobTypes();
    const fetchSalaryRange = await findSalaryRangeTypes();
    const fetchKeySkills = await findKeySkills(keySkillSearch);
    const fetchWorkMode = await findWorkMode();
    setFunctionalArea(funcResponse?.data);
    setJobTypes(fetchJobTypes?.data);
    setKeySkills(fetchKeySkills?.data);
    setWorkMode(fetchWorkMode?.data);
    setSalaryRange(fetchSalaryRange?.data);
  };




  const handleSearch = async (text, location) => {
    setFilterList([...filterList, text, location]);

    const response = await fetchFilteredJobs(text, location);

    setJobsList(response?.data);
  };

  const expFilter = async exp => {
    setFilterList([...filterList, exp]);
    const response = await applyFilteredJobs(exp);
    setJobsList(response?.data);
  };
  const sidebarFilter = e => {};
  //  var top
  //   window.addEventListener("scroll", function(event) {
  //       top = this.scrollY

  // }, false);
  const [scroll, setScroll] = useState();
  useEffect(() => {
    window.addEventListener(
      "scroll",
      function (event) {
        var top = this.scrollY;
        setScroll(top);
      },
      false
    );
  }, [scroll]);


  /* Infinite scroll pagination */

  const handleScroll = () => {
    const scrollHeight = document.documentElement.scrollHeight;
    const scrollTop = document.documentElement.scrollTop;
    const clientHeight = document.documentElement.clientHeight;
  
    // Check if the user is scrolling down and nearing the bottom of the page
    if (scrollHeight > clientHeight && scrollTop > lastScrollTop && scrollTop + clientHeight >= scrollHeight - 10 && hasMore) {
      if(hasMore){
    
        setPage((prevPage) => prevPage + 1);
      }
   
    }
  
    // Update lastScrollTop for the next scroll event
    lastScrollTop = scrollTop;
  };
  
  let lastScrollTop = 0;
  
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
  
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [hasMore]);
  



    /* Search implementation for categories */

    const categoriesGet = async()=>{
    setLoad(true)
    const funcResponse = await findAllFuncArea(funcSearch);
    setFunctionalArea(funcResponse?.data);
    setLoad(false)
    }

    const keySkillsGet = async()=>{
      setLoad(true)
      const skillResponse = await findKeySkills(keySkillSearch);
      setKeySkills(skillResponse?.data);
      setLoad(false)
      }


    useEffect(()=>{
      const timerId = setTimeout(() => {
        if(funcSearch){
          categoriesGet();
        }else if(keySkillSearch){
          keySkillsGet()
        }
      
      }, 500);
      return () => clearTimeout(timerId);

    },[funcSearch,keySkillSearch])
  
    /* Search implementation for categories */


    const filterJobsBased = () => {
      setTimeout(()=>{
        fechJobsBasedonOffset(
          searchParams.get("title"),
          searchParams.get("location"),
          searchParams.getAll("fn_areas"),
          searchParams.getAll("job_type"),
          searchParams.get("exp"),
          searchParams.getAll("salary"),
          searchParams.getAll("key_skills"),
          searchParams.getAll("work_mode"),
          
        );
      },600)
   
    };


    // useEffect(()=>{
    //   filterJobsBased()
    // },[saveCheck])

console.log(jobsList,"1")
console.log(searchJobsList,"2")
console.log(filterJobslist,"3")
  return (
    <>
      <div className="section-box pt-2">
        <div className="employer_jobs_list_cont">
          <div className="row" style={{ position: "relative" }}>
            <div className="col-lg-3 col-md-4 col-sm-12 side_panel_container">
              <SidePanelFilters
                setJobsList={setJobsList}
                sidebarFilter={sidebarFilter}
                functionalArea={functionalArea}
                jobTypes={jobTypes}
                workTypes={workMode}
                salaryRange={salaryRange}
                keySkills={keySkills}
                top={scroll}
                updateSkills={data => setKeySkills(data)}
                updateSalaryRange={data => setSalaryRange(data)}
                update={data => setFunctionalArea(data)}
                expFilter={val => expFilter(val)}
                filterJobs={() => filterJobsBased()}
                setFilterClear={setFilterClear}

                setFuncSearch={setFuncSearch}
                setKeySkillSearch={setKeySkillSearch}
              />
            </div>

            <div className="col-lg-9 col-md-12 col-sm-12">
              <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12">
                  <MainSearchFilterBar
                    limit={limit}
                    offset={offset}
                    setJobsList={setJobsList}
                    setLoad={setLoad}
                  />
                </div>

                <div className="sm_job_filter">
                  <Suspense fallback={<div>Loading...</div>}>
                    <SmallScreenFilter
                      functionalArea={functionalArea}
                      keySkills={keySkills}
                      salaryRange={salaryRange}
                      jobTypes={jobTypes}
                      workTypes={workMode}
                      filterJobs={() => filterJobsBased()}
                    />
                  </Suspense>
                </div>

                <div className="col-lg-12 col-md-12 col-sm-12">
                  <JobsList
                    jobsList={jobsList}
                    filterJobslist={filterJobslist}
                    saveJobsList={saveJobsList}
                    setJobsList={setJobsList}
                    searchJobsList={searchJobsList}
                    functionalArea={functionalArea}
                    jobTypes={jobTypes}
                    salaryRange={salaryRange}
                    keySkills={keySkills}
                    update={data => setFunctionalArea(data)}
                    updateSkills={data => setKeySkills(data)}
                    updateSalaryRange={data => setSalaryRange(data)}
                    expFilter={val => expFilter(val)}
                    load={load}
                    // fechJobsBasedonOffset={() => filterJobsBased()}
                    fechJobsBasedonOffset={filterJobsBased}
                    setSaveCheck={setSaveCheck}
                    setSeshuModal={setSeshuModal}
                  />{" "}
                  {/* {jobsList.length >= 10 ? (
                    <Button
                      sx={{
                        // backgroundColor: "#081753",
                        background: "#cde2e7",
                        color: "#066a4c",
                        fontFamily: "Inter,sans-serif",
                        opacity:`${jobsList.length == 0?"50%":""}`,
                        '@media (min-width:300px) and (max-width:992px)':{
                          marginBottom:"10%"
                        }
                      }}
                      variant="solid"
                      className="refer-now-btn cursor-pointer "
                      disabled={jobsList.length == 0}
                      onClick={() => {
                        // setOffset(offset + 11);
                        setOffset(offset);
                        setLimit(limit + 11);
                      }}
                    >
                      Load More
                    </Button>
                  ) : (
                    ""
                  )} */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default MainJobsList;
