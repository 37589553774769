export default function Frame2() {
    return (
        <svg width="32" height="31" viewBox="0 0 32 31" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.06445 2.20346L4.46956 14.5689" stroke="#FFC046" stroke-width="4" stroke-linecap="round" />
            <path d="M28.9351 3.77847L11.8822 19.8812" stroke="#FFC046" stroke-width="4" stroke-linecap="round" />
            <path d="M29.6544 22.4714L17.236 26.1223" stroke="#FFC046" stroke-width="4" stroke-linecap="round" />
        </svg>

    );
}
